import {
  Book,
  CastForEducation,
  Category,
  FilterList,
  FormatAlignJustify,
  Home,
  LibraryBooks,
  HowToReg,
  LocalOffer,
  // AddCircle,
  ImportExport,
  Assessment,
  List,
  AddBox,
  Search,
  BarChart,
  CollectionsBookmark,
  BurstMode,
  Room,
  MonetizationOn,
  Notifications,
  FeaturedPlayList,
  CardMembership,
  ScheduleRounded,
  // RedeemOutlined,
  Notes,
  PersonOutline,
  NoteAdd,
  Email,
  GroupAdd,
  // WhatsApp,
  FormatShapes,
  DateRange,
  // DonutLarge,
  ContactMail,
  Redeem,
  Announcement,
  AccountTree,
  Settings,
  Https,
  CheckBox,
  BookOutlined,
  Adjust,
  Person,
  HomeSharp,
  Send,
  WorkRounded,
  FreeBreakfast,
  AddComment,
} from "@material-ui/icons";

import DashboardPage from "pages/Dashboard/DashboardPage";

import CourseCategoryPage from "pages/MasterData/ManageCourse/CourseCategory/CourseCategoryPage";
import CourseLevelPage from "pages/MasterData/ManageCourse/CourseLevel/CourseLevelPage";
import CourseTeachMethodPage from "pages/MasterData/ManageCourse/CourseTeachMethod/CourseTeachMethodPage";
import CourseTypePage from "pages/MasterData/ManageCourse/CourseType/CourseTypePage";

import ListCoursePage from "pages/MasterData/ManageCourse/Course/List/ListCoursePage";
import CreateCoursePage from "pages/MasterData/ManageCourse/Course/Create/CreateCoursePage";
import DetailCoursePage from "pages/MasterData/ManageCourse/Course/Detail/DetailCoursePage";
import ListModulePage from "pages/MasterData/ManageCourse/Course/Module/List/ListModulePage";
import ListSubModulePage from "pages/MasterData/ManageCourse/Course/Module/SubModule/List/ListSubModulePage";
import CreateQuestionBankPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/QuestionBank/Create/CreateQuestionBankPage";
import ListQuestionBankPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/QuestionBank/List/ListQuestionBankPage";
import DetailQuestionBankPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/QuestionBank/Detail/DetailQuestionBankPage";

import ListInstructorPage from "pages/MasterData/ManageInstructor/List/ListInstructorPage";
import CreateInstructorPage from "pages/MasterData/ManageInstructor/Create/CreateInstructorPage";
import DetailInstructorPage from "pages/MasterData/ManageInstructor/Detail/DetailInstructorPage";

import ListAdminPage from "pages/MasterData/ManageAdmin/List/ListAdminPage";
import CreateAdminPage from "pages/MasterData/ManageAdmin/Create/CreateAdminPage";
import DetailAdminPage from "pages/MasterData/ManageAdmin/Detail/DetailAdminPage";

import ListPromoCodePage from "pages/MasterData/ManagePromoCode/ListPromoCodePage";
import SearchVoucherPage from "pages/MasterData/ManageVoucher/Search/SearchVoucherPage";
// import GenerateVoucherPage from "pages/MasterData/ManageVoucher/Generate/GenerateVoucherPage";
import CreateVoucherPage from "pages/MasterData/ManageVoucher/Create/CreateVoucherPage";
import ExportVoucherPage from "pages/MasterData/ManageVoucher/Export/ExportVoucherPage";

import ListCourseReportPage from "pages/ReportData/CourseReport/List/ListCourseReportPage";
import DetailCourseReportPage from "pages/ReportData/CourseReport/List/Detail/DetailCourseReportPage";
import ExportCourseReportPage from "pages/ReportData/CourseReport/Export/ExportCourseReportPage";
import ScheduleCourseReportPage from "pages/ReportData/CourseReport/List/Schedule/ScheduleCourseReportPage";

import TransactionReportPage from "pages/ReportData/TransactionReport/List/TransactionReportPage";
import TransactionStatisticPage from "pages/ReportData/TransactionReport/Statistic/TransactionStatisticPage";

import InstructorReportPage from "pages/ReportData/InstructorReport/InstructorReportPage";
import ListCourseInstructorPage from "pages/ReportData/InstructorReport/ListCourse/ListCourseInstructorPage";
import ListParticipantCoursePage from "pages/ReportData/InstructorReport/ListCourse/ListParticipant/ListParticipantCoursePage";
import ListTransactionCoursePage from "pages/ReportData/InstructorReport/ListCourse/ListTransaction/ListTransactionCoursePage";
import ListUserReportPage from "pages/ReportData/UserReport/UserReportPage";
import DetailUserReportPage from "pages/ReportData/UserReport/Detail/DetailUserReportPage";
import ListReferralReportPage from "pages/ReportData/UserReport/Referral/ReferralReportPage";

import InstructorListCoursePage from "pages/InstructorRole/MasterData/Course/List/InstructorListCoursePage";
import InstructorDetailCoursePage from "pages/InstructorRole/MasterData/Course/InstructorDetailCoursePage";

import InstructorReportListCoursePage from "pages/InstructorRole/ReportData/Course/List/ListCourseReportPage";
import InstructorReportListParticipantCoursePage from "pages/InstructorRole/ReportData/Course/List/ListParticipant/ListParticipantCoursePage";
import InstructorReportListTransactionCoursePage from "pages/InstructorRole/ReportData/Course/List/ListTransaction/ListTransactionCoursePage";

import ListVideoQuizPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/VideoQuiz/List/ListVideoQuizPage";
import CreateVideoQuizPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/VideoQuiz/Create/CreateVideoQuizPage";
import DetailVideoQuizPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/VideoQuiz/Detail/DetailVideoQuizPage";

import ListCourseBundlePage from "pages/MasterData/ManageCourse/CourseBundle/List/ListCourseBundlePage";
import CreateCourseBundle from "pages/MasterData/ManageCourse/CourseBundle/Create/CreateCourseBundle";
import DetailCourseBundle from "pages/MasterData/ManageCourse/CourseBundle/Detail/DetailCourseBundle";

import ListMainSlideshow from "pages/MasterData/ManageMainSlideshow/ListMainSlideshow";
import ListCourseSection from "pages/MasterData/ManageCourseSection/ListCourseSection";
import ListPaymentMethod from "pages/MasterData/ManagePaymentMethod/ListPaymentMethod";
import ListEventPlace from "pages/MasterData/ManageEventPlace/ListEventPlace";
import ListGalleryPage from "pages/MasterData/ManageEventPlace/Gallery/List/ListGalleryPage";

import ListWebinarPage from "pages/MasterData/ManageCourse/Course/Webinar/List/ListWebinarPage";

import ListOfflineSchedule from "pages/MasterData/ManageCourse/Course/OfflineSchedule/List/ListOfflineSchedulePage";

import ListOfflineSessionPage from "pages/MasterData/ManageCourse/Course/OfflineSchedule/OfflineSession/ListOfflineSessionPage";

import PushNotificationTopicPage from "pages/MasterData/PushNotificationMobile/Topic/PushNotificationTopicPage";
import PushNotificationEmailPage from "pages/MasterData/PushNotificationMobile/Email/PushNotificationEmailPage";
import WebinarScheduleReportPage from "pages/ReportData/CourseReport/List/WebinarSchedule/WebinarScheduleReportPage";
import ListMembershipPage from "pages/MasterData/ManageMemership/List/ListMembershipPage";
import CreateMembershipPage from "pages/MasterData/ManageMemership/Create/CreateMembershipPage";
import DetailMembershipPage from "pages/MasterData/ManageMemership/Detail/DetailMembershipPage";

import InstructorListCourseWebinarPage from "pages/InstructorRole/MasterData/Course/Webinar/List/InstructorListCourseWebinarPage";
import InstructorDetailWebinarPage from "pages/InstructorRole/MasterData/Course/Webinar/Detail/InstructorDetailWebinarPage";
import InstructorListCourseOfflinePage from "pages/InstructorRole/MasterData/Course/Offline/List/InstructorListCourseOfflinePage";
import InstructorDetailOfflinePage from "pages/InstructorRole/MasterData/Course/Offline/Detail/InstructorDetailOfflinePage";
import InstructorDashboardPage from "pages/InstructorRole/Dashboard/InstructorDashboardPage";
import UpdateScheduleVoucherPage from "pages/MasterData/ManageVoucher/UpdateSchedule/UpdateScheduleVoucherPage";
// import RedeemVoucherPage from "pages/Synchronization/Voucher/RedeemVoucher/RedeemVoucherPage";
import ListVoucherAttempt from "pages/Synchronization/Voucher/RedeemVoucherAttempt/ListVoucherAttempt";

import ListNotificationReportPage from "pages/ReportData/NotificationReport/List/NotificationReportPage";
import GenerateCertificatePage from "pages/Synchronization/User/GenerateCertificate/GenerateCertificatePage";
import CreateUserPage from "pages/Synchronization/User/CreateUser/CreateUserPage";
import ListTemplateMessagePage from "pages/MasterData/WhatsappBlast/TemplateMessage/ListTemplateMessagePage";
import ListReminderCoursePage from "pages/MasterData/WhatsappBlast/ReminderCourse/ListReminderCoursePage";
import ListLogWhatsappPage from "pages/Log/Whatsapp/ListLogWhatsappPage";
// import ListLearningProgressPage from "pages/Bypass/LearningProgress/ListLearningProgressPage";
import ListLoginLogPage from "pages/Log/Login/ListLoginLogPage";
import EmailBlastMessagePage from "pages/MasterData/EmailBlast/BlastMessage/BlastMessagePage";
import BlastMessagePage from "pages/MasterData/WhatsappBlast/BlastMessage/BlastMessagePage";

import ListEmailReminderCoursePage from "pages/MasterData/EmailBlast/ReminderCourse/ListReminderCoursePage";
import ListEmailTemplateMessagePage from "pages/MasterData/EmailBlast/TemplateMessage/ListTemplateMessagePage";

import ListNotificationTemplateMessagePage from "pages/MasterData/PushNotificationMobile/TemplateMessage/ListTemplateMessagePage";
import ListNotificationReminderCoursePage from "pages/MasterData/PushNotificationMobile/ReminderCourse/ListReminderCoursePage";
import ListCourseAssessorCertificatePage from "pages/MasterData/ManageCourse/CourseAssessorCertificate/ListCourseAssessorCertificatePage";
import ListPopUpAdsPage from "pages/MasterData/ManagePopUpAds/ListPopUpAdsPage";
import NotificationWhatsappReportPage from "pages/ReportData/NotificationReport/Whatsapp/NotificationWhatsappReportPage";
import NotificationEmailReportPage from "pages/ReportData/NotificationReport/Email/NotificationEmailReportPage";
import ListParticipantPage from "pages/ReportData/CourseReport/List/WebinarSchedule/Participant/ListParticipantPage";
import InstructorListCoursePracticeAssignmentPage from "pages/InstructorRole/MasterData/Course/PracticeAssignment/List/InstructorListCoursePracticeAssignmentPage";
import InstructorListParticipantPracticeAssignmentPage from "pages/InstructorRole/MasterData/Course/PracticeAssignment/Detail/InstructorListParticipantPracticeAssignmentPage";
import ListQuizMixAndMatchPage from "pages/MasterData/ManageCourse/Course/Module/SubModule/VideoQuiz/MixAndMatch/ListQuizMixAndMatchPage";
import ListQuizMultipleChoicePage from "pages/MasterData/ManageCourse/Course/Module/SubModule/VideoQuiz/MultipleChoice/ListMultipleChoicePage";
import ListQuizTrueOrFalsePage from "pages/MasterData/ManageCourse/Course/Module/SubModule/VideoQuiz/TrueOrFalse/ListTrueOrFalsePage";
import InstructorListCourseAssignmentPage from "pages/InstructorRole/MasterData/Course/Assignment/List/InstructorListCourseAssignmentPage";
import InstructorListParticipantAssignmentPage from "pages/InstructorRole/MasterData/Course/Assignment/Detail/InstructorListParticipantAssignmentPage";
import ListInstructorAssistancePage from "pages/MasterData/ManageInstructorAssistance/List/ListInstructorAssistancePage";
import CreateInstructorAssistancePage from "pages/MasterData/ManageInstructorAssistance/Create/CreateInstructorAssistancePage";
import DetailInstructorAssistancePage from "pages/MasterData/ManageInstructorAssistance/Detail/DetailInstructorAssistancePage";
import ListRolePage from "pages/MasterData/ManageRole/ListRolePage";
import ListPermissionPage from "pages/MasterData/Permission/ManagePermission/List/ListPermissionPage";
import CreatePermissionPage from "pages/MasterData/Permission/ManagePermission/Create/CreatePermissionPage";
import DetailPermissionPage from "pages/MasterData/Permission/ManagePermission/Detail/DetailPermissionPage";
import ListSetPermissionPage from "pages/MasterData/Permission/SetPermission/ListSetPermissionPage";
import ListEmailLogPage from "pages/Log/Email/ListEmailLogPage";
import ListExportCourseLogPage from "pages/Log/ExportCourse/ListExportCourseLogPage";
import ListFaceRecognitonLogPage from "pages/Log/FaceRecognition/ListFaceRecognitionLogPage";
import ListLearningProgressCoursePage from "pages/Bypass/Course/ListLearningProgressCoursePage";
import BulkVoidVoucherPage from "pages/MasterData/ManageVoucher/BulkVoid/BulkVoidVoucherPage";
import AssessmentReportPage from "pages/ReportData/AssessmentReport/AssessmentReportPage";
import DetailAssessmentPage from "pages/ReportData/AssessmentReport/Detail/DetailAssessmentPage";
import DetailCourseLogPage from "pages/ReportData/AssessmentReport/Detail/Log/DetailCourseLogPage";
import ListUserReminderPage from "pages/Bypass/UserReminder/ListUsreReminderPage";
import ListBrand from "pages/MasterData/ManageBrand/ListBrand";
import ListJobOPening from "pages/MasterData/ManageBrand/ManageJobOpening/ListJobOpening";
import ListCandidatePage from "pages/ReportData/MassiveHiringReport/ListCandidate/ListCandidatePage";
import ListCourseBonusPage from "pages/MasterData/ManageCourse/CourseBonus/ListCourseBonusPage";
import ListCustomSectionPage from "pages/MasterData/ManageCustomSection/ListCustomSectionPage";
import ListDigitalProduct from "pages/MasterData/ManageDigitalProduct/List/ListDigitalProductPage";
import CreateDigitalProductPage from "pages/MasterData/ManageDigitalProduct/Create/CreateDigitalProductPage";
import DetailDigitalProductPage from "pages/MasterData/ManageDigitalProduct/Detail/DetailDigitalProductPage";
import ListBundleDigitalProductPage from "pages/MasterData/ManageDigitalProduct/Bundle/List/ListBundleDigitalProductPage";
import CreateBundleDigitalProductPage from "pages/MasterData/ManageDigitalProduct/Bundle/Create/CreateBundleDigitalProductPage";
import DetailBundleDigitalProductPage from "pages/MasterData/ManageDigitalProduct/Bundle/Detail/DetailBundleDigitalProductPage";

const mainRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    mininame: "D",
    icon: Home,
    iconColor: "Primary",
    component: DashboardPage,
    layout: "",
    roles: ["SUPERADMIN", "DATA_MANAGER"],
    invisible: false,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    mininame: "D",
    icon: Home,
    iconColor: "Primary",
    component: InstructorDashboardPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: false,
  },

  {
    title: "Master Data",
    roles: ["SUPERADMIN", "INSTRUCTOR"],
  },
  /**
   * Course Group Menu [SUPERADMIN]
   */
  {
    collapse: true,
    name: "Kelola Kelas",
    icon: LibraryBooks,
    iconColor: "Success",
    state: "courseDataCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/kategori-kelas",
        name: "Kategori",
        icon: Category,
        component: CourseCategoryPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/level-kelas",
        name: "Level",
        icon: FilterList,
        component: CourseLevelPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/tipe-kelas",
        name: "Tipe",
        icon: FormatAlignJustify,
        component: CourseTypePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/metode-belajar-kelas",
        name: "Metode Belajar",
        icon: CastForEducation,
        component: CourseTeachMethodPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/asesor-kelas",
        name: "Asesor",
        icon: Redeem,
        component: ListCourseAssessorCertificatePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/bonus-kelas",
        name: "Bonus Kelas",
        icon: FreeBreakfast,
        component: ListCourseBonusPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Event Place [SUPERADMIN]
       */
      {
        path: "/event-place/:eventPlaceId/gallery",
        name: "Kelola Galeri Tempat Acara",
        component: ListGalleryPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/event-place",
        name: "Data Tempat Acara",
        icon: Room,
        component: ListEventPlace,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Manage Course
       */
      {
        layout: "",
        path: "/kelas/tambah",
        component: CreateCoursePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:id/detail",
        component: DetailCoursePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      // Question Bank - Course
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/bank-soal/:id/detail",
        component: DetailQuestionBankPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/bank-soal/tambah",
        component: CreateQuestionBankPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/bank-soal",
        component: ListQuestionBankPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      // Video Quiz - Mix and Match
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/video-quiz/:videoQuizId/mix-and-match",
        component: ListQuizMixAndMatchPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      // Video Quiz - Multiple Choice
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/video-quiz/:videoQuizId/pilihan-ganda",
        component: ListQuizMultipleChoicePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      // Video Quiz - True or False
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/video-quiz/:videoQuizId/benar-atau-salah",
        component: ListQuizTrueOrFalsePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      // Video Quiz - Course
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/video-quiz/:id/detail",
        component: DetailVideoQuizPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/video-quiz/tambah",
        component: CreateVideoQuizPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul/:subModuleId/video-quiz",
        component: ListVideoQuizPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      // Module & Sub Module - Course
      {
        layout: "",
        path: "/kelas/:courseId/modul/:moduleId/sub-modul",
        component: ListSubModulePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/modul",
        component: ListModulePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/jadwal-offline/:OfflineScheduleId/sesi",
        component: ListOfflineSessionPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/jadwal-offline",
        component: ListOfflineSchedule,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/kelas/:courseId/webinar",
        component: ListWebinarPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/kelas",
        name: "Data Kelas",
        icon: Book,
        component: ListCoursePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Manage Course Bundle
       */
      {
        layout: "",
        path: "/bundle-kelas/:courseBundleId/detail",
        component: DetailCourseBundle,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/bundle-kelas/tambah",
        component: CreateCourseBundle,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/bundle-kelas",
        name: "Paket Kelas",
        icon: CollectionsBookmark,
        component: ListCourseBundlePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  /**
   * Voucher Group Menu [SUPERADMIN]
   */
  {
    collapse: true,
    name: "Kelola Voucher",
    icon: LocalOffer,
    iconColor: "Warning",
    state: "voucherDataCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/cari-voucher",
        name: "Pencarian",
        icon: Search,
        component: SearchVoucherPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/create-voucher",
        name: "Tambah",
        icon: AddBox,
        component: CreateVoucherPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/void-voucher",
        name: "Void Voucher",
        icon: Adjust,
        component: BulkVoidVoucherPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/ekspor-voucher",
        name: "Export Voucher",
        icon: ImportExport,
        component: ExportVoucherPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  /**
   * Kelola Halaman Utama [SUPERADMIN]
   */
  {
    collapse: true,
    name: "Kelola Halaman Utama",
    icon: HomeSharp,
    iconColor: "Warning",
    state: "halamanUtamaState",
    roles: ["SUPERADMIN"],
    views: [
      /**
       * Main Slideshow [SUPERADMIN]
       */
      {
        path: "/main-slideshow",
        name: "Banner Utama",
        icon: BurstMode,
        component: ListMainSlideshow,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Main Course Section [SUPERADMIN]
       */
      {
        path: "/section-course",
        name: "Sorotan Menu Kelas",
        icon: FeaturedPlayList,
        component: ListCourseSection,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Pop Up Ads [SUPERADMIN]
       */
      {
        path: "/pop-up-ads",
        name: "Pop Up Iklan",
        icon: Announcement,
        component: ListPopUpAdsPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Custom Section [SUPERADMIN]
       */
      {
        path: "/custom-section",
        name: "Sorotan Bagian Khusus",
        icon: AddComment,
        component: ListCustomSectionPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },
  {
    collapse: true,
    name: "Instruktur",
    icon: HowToReg,
    iconColor: "Success",
    state: "instructorCollapse",
    roles: ["SUPERADMIN"],
    views: [
      /**
       * Manage Instructor [SUPERADMIN]
       */
      {
        layout: "",
        path: "/instructor/:id/detail",
        component: DetailInstructorPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/instructor/create",
        component: CreateInstructorPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/instructor",
        name: "Data Instruktur",
        icon: HowToReg,
        component: ListInstructorPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      /**
       * Manage Instructor Assistance [SUPERADMIN]
       */
      {
        layout: "",
        path: "/asisten-instruktur/:id/detail",
        component: DetailInstructorAssistancePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/asisten-instruktur/tambah",
        component: CreateInstructorAssistancePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/asisten-instruktur",
        name: "Data Asisten Instruktur",
        icon: HowToReg,
        component: ListInstructorAssistancePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  // My Course [INSTRUCTOR]
  {
    path: "/my-course/:id/detail",
    component: InstructorDetailCoursePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:courseId/offline/:scheduleId",
    component: InstructorDetailOfflinePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:id/offline",
    component: InstructorListCourseOfflinePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:courseId/webinar/:scheduleId",
    component: InstructorDetailWebinarPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:id/webinar",
    component: InstructorListCourseWebinarPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:id/tugas-praktik-mandiri/:course_sub_module_id",
    component: InstructorListParticipantPracticeAssignmentPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:id/tugas-praktik-mandiri",
    component: InstructorListCoursePracticeAssignmentPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:id/uji-keterampilan/:course_sub_module_id",
    component: InstructorListParticipantAssignmentPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course/:id/uji-keterampilan",
    component: InstructorListCourseAssignmentPage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/my-course",
    name: "Kelas Saya",
    icon: Book,
    component: InstructorListCoursePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: false,
  },

  /**
   * Push Notification [SUPERADMIN]
   */
  {
    collapse: true,
    name: "Notifikasi Mobile",
    icon: Notifications,
    state: "notificationCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/push-notification/template",
        name: "Kelola Template Notifikasi",
        icon: FormatShapes,
        component: ListNotificationTemplateMessagePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/push-notification/reminder",
        name: "Kelola Pengingat Jadwal Kelas",
        icon: DateRange,
        component: ListNotificationReminderCoursePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/push-notification/topic",
        name: "Topik",
        icon: List,
        component: PushNotificationTopicPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/push-notification/email",
        name: "Email",
        icon: Email,
        component: PushNotificationEmailPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  /**
   * Blast Whatsapp [SUPERADMIN]
   */
  {
    collapse: true,
    name: "Blast Pesan",
    icon: Send,
    state: "whatsappCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/whatsapp-blast/template",
        name: "Template -Whatsapp",
        icon: FormatShapes,
        iconColor: "Success",
        component: ListTemplateMessagePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/whatsapp-blast/reminder",
        name: "Pengingat Jadwal -Whatsapp",
        icon: DateRange,
        iconColor: "Success",
        component: ListReminderCoursePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/whatsapp-blast/message",
        name: "Kirim Pesan -Whatsapp",
        icon: ContactMail,
        iconColor: "Success",
        component: BlastMessagePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/email-blast/template",
        name: "Template -Email",
        icon: FormatShapes,
        iconColor: "Warning",
        component: ListEmailTemplateMessagePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/email-blast/reminder",
        name: "Pengingat Jadwal -Email",
        icon: DateRange,
        iconColor: "Warning",
        component: ListEmailReminderCoursePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/email-blast/message",
        name: "Kirim Pesan -Email",
        icon: ContactMail,
        iconColor: "Warning",
        component: EmailBlastMessagePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  // Digital Product [SUPERADMIN]
  {
    collapse: true,
    name: "Kelola Produk Digital",
    icon: LibraryBooks,
    state: "digitalProductDataCollapse",
    roles: ["SUPERADMIN"],
    views: [
      // Master Data
      {
        path: "/digital-product/:id/detail",
        component: DetailDigitalProductPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/digital-product/create",
        component: CreateDigitalProductPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/digital-product",
        name: "Data Produk Digital",
        icon: Book,
        component: ListDigitalProduct,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      // Bundle
      {
        path: "/bundle-digital-product/:id/detail",
        component: DetailBundleDigitalProductPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/bundle-digital-product/create",
        component: CreateBundleDigitalProductPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/bundle-digital-product",
        name: "Paket Produk Digital",
        icon: CollectionsBookmark,
        component: ListBundleDigitalProductPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  // Promo Code [SUPERADMIN]
  {
    path: "/promo-code",
    name: "Kode Promo",
    icon: LocalOffer,
    component: ListPromoCodePage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },

  /**
   * Membership [SUPERADMIN]
   */
  {
    layout: "",
    path: "/membership/:id/detail",
    component: DetailMembershipPage,
    roles: ["SUPERADMIN"],
    invisible: true,
  },
  {
    layout: "",
    path: "/membership/create",
    component: CreateMembershipPage,
    roles: ["SUPERADMIN"],
    invisible: true,
  },
  {
    path: "/membership",
    name: "Membership",
    icon: CardMembership,
    component: ListMembershipPage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  /**
   * Payment Method [SUPERADMIN]
   */
  {
    path: "/payment-method",
    name: "Metode Pembayaran",
    icon: MonetizationOn,
    component: ListPaymentMethod,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },

  /**
   * Brand [SUPERADMIN]
   */
  {
    path: "/brand/:brandId/job-opening",
    name: "Kelola Lowongan Kerja",
    component: ListJobOPening,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: true,
  },
  {
    path: "/brand",
    name: "Kelola Brand",
    icon: WorkRounded,
    component: ListBrand,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },

  {
    title: "Report Data",
    roles: ["SUPERADMIN", "DATA_MANAGER", "INSTRUCTOR"],
  },
  /**
   * Course Report Group Menu
   */
  {
    collapse: true,
    name: "Laporan Kelas",
    icon: Assessment,
    iconColor: "Default",
    state: "courseReportCollapse",
    roles: ["SUPERADMIN", "DATA_MANAGER"],
    views: [
      {
        layout: "",
        path: "/report/list-course/:courseId/schedule",
        component: ScheduleCourseReportPage,
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: true,
      },
      {
        layout: "",
        path: "/report/list-course/:courseId/webinar-schedule/:webinarScheduleId/participant",
        component: ListParticipantPage,
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: true,
      },
      {
        layout: "",
        path: "/report/list-course/:courseId/webinar-schedule",
        component: WebinarScheduleReportPage,
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: true,
      },
      {
        layout: "",
        path: "/report/list-course/:courseId",
        component: DetailCourseReportPage,
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: true,
      },
      {
        path: "/report/list-course",
        name: "Data Kelas",
        icon: List,
        component: ListCourseReportPage,
        layout: "",
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: false,
      },
      {
        path: "/report/export-course",
        name: "Export Data Kelas",
        icon: ImportExport,
        component: ExportCourseReportPage,
        layout: "",
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: false,
      },
    ],
  },
  /**
   * Transaction Report Group Menu
   */
  {
    collapse: true,
    name: "Laporan Transaksi",
    icon: Assessment,
    iconColor: "Default",
    state: "transactionReportCollapse",
    roles: ["SUPERADMIN", "DATA_MANAGER"],
    views: [
      {
        path: "/report/transaction/list",
        name: "Data Transaksi",
        icon: List,
        component: TransactionReportPage,
        layout: "",
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: false,
      },
      {
        path: "/report/transaction/statistic",
        name: "Statistik Transaksi",
        icon: BarChart,
        component: TransactionStatisticPage,
        layout: "",
        roles: ["SUPERADMIN", "DATA_MANAGER"],
        invisible: false,
      },
    ],
  },
  /**
   * Instructor Report Group Menu
   */
  {
    collapse: true,
    name: "Laporan Instruktur",
    icon: Assessment,
    iconColor: "Default",
    state: "instructorReportCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        layout: "",
        path: "/report/instructor/:instructorId/course/:courseId/transaction",
        component: ListTransactionCoursePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/report/instructor/:instructorId/course/:courseId/participant",
        component: ListParticipantCoursePage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/report/instructor/:instructorId/course",
        component: ListCourseInstructorPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/report/instructor",
        name: "Data Instruktur",
        icon: List,
        component: InstructorReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },
  /**
   * User Report Group Menu
   */
  {
    collapse: true,
    name: "Laporan Peserta",
    icon: Assessment,
    iconColor: "Default",
    state: "UserReportCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        layout: "",
        path: "/report/user/:id/detail",
        component: DetailUserReportPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/report/user",
        name: "Data Peserta",
        icon: List,
        component: ListUserReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/report/referral",
        name: "Data Referral",
        icon: List,
        component: ListReferralReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },
  /**
   * Notification Report Group Menu
   */
  {
    collapse: true,
    name: "Laporan Notifikasi",
    icon: Assessment,
    iconColor: "Default",
    state: "NotificationReportCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/report/notification",
        name: "Data Notifikasi",
        icon: List,
        component: ListNotificationReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/report/notification-whatsapp",
        name: "Data Notifikasi Whatsapp",
        icon: List,
        component: NotificationWhatsappReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      {
        path: "/report/notification-email",
        name: "Data Notifikasi Email",
        icon: List,
        component: NotificationEmailReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  /**
   * Assessment Report Group Menu
   */
  {
    collapse: true,
    name: "Laporan Penilaian",
    icon: Assessment,
    iconColor: "Default",
    state: "AssessmentReportCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/report/penilaian/:administratorId/kelas/:courseId/log",
        component: DetailCourseLogPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/report/penilaian/:administratorId/kelas",
        component: DetailAssessmentPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/report/penilaian",
        name: "Data Penilaian",
        icon: List,
        component: AssessmentReportPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  /**
   * Massive Hiring Report Menu
   *
   */
  {
    collapse: true,
    name: "Laporan Perekrutan",
    icon: Assessment,
    iconColor: "Default",
    state: "MassiveHiringCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/report/massive-hiring-candidate",
        name: "Data Kandidat",
        icon: List,
        component: ListCandidatePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
    ],
  },

  // Report My Course [INSTRUCTOR]
  {
    path: "/report/my-course/:id/transaction",
    component: InstructorReportListTransactionCoursePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/report/my-course/:id/participant",
    component: InstructorReportListParticipantCoursePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: true,
  },
  {
    path: "/report/my-course",
    name: "Laporan Kelas",
    icon: Assessment,
    component: InstructorReportListCoursePage,
    layout: "/admin-instructor",
    roles: ["INSTRUCTOR"],
    invisible: false,
  },

  {
    title: "Sinkronisasi",
    roles: ["SUPERADMIN", "DATA_MANAGER"],
  },

  /**
   *  Synchronization Voucher
   */
  {
    collapse: true,
    name: "Voucher",
    icon: LocalOffer,
    iconColor: "Info",
    state: "VoucherSynchronizeCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/synchronization/voucher/update-schedule",
        name: "Ubah Jadwal voucher",
        icon: ScheduleRounded,
        component: UpdateScheduleVoucherPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },
      // {
      //   path: "/synchronization/voucher/redeem",
      //   name: "Redeem Voucher",
      //   icon: RedeemOutlined,
      //   component: RedeemVoucherPage,
      //   layout: "",
      //   roles: ["SUPERADMIN"],
      //   invisible: false,
      // },
    ],
  },

  /**
   *  Synchronization User
   */
  {
    collapse: true,
    name: "Peserta",
    icon: PersonOutline,
    iconColor: "Warning",
    state: "UserSynchronizeCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/synchronization/user/generate-certificate",
        name: "Generate Sertifikat",
        icon: NoteAdd,
        component: GenerateCertificatePage,
        roles: ["SUPERADMIN"],
        layout: "",
        invisible: false,
      },
      {
        path: "/synchronization/user/generate-participant",
        name: "Tambah Peserta",
        icon: GroupAdd,
        component: CreateUserPage,
        roles: ["SUPERADMIN"],
        layout: "",
        invisible: false,
      },
    ],
  },

  {
    title: "Ops",
    roles: ["SUPERADMIN", "ADMIN_OPERATION"],
  },

  /**
   *  Bypass Learning Progress
   */
  // {
  //   path: "/learning-progress/bypass",
  //   name: "Bypass",
  //   icon: DonutLarge,
  //   component: ListLearningProgressPage,
  //   layout: "",
  //   roles: ["SUPERADMIN"],
  //   invisible: false,
  // },
  {
    path: "/learning-progress/course",
    name: "Progres Peserta",
    icon: BookOutlined,
    component: ListLearningProgressCoursePage,
    layout: "",
    roles: ["SUPERADMIN", "ADMIN_OPERATION"],
    invisible: false,
    use_permission: true,
    code: "281-1",
  },
  {
    path: "/learning-progress/user-reminder",
    name: "Pengingat Peserta",
    icon: Person,
    component: ListUserReminderPage,
    layout: "",
    invisible: false,
    use_permission: true,
    code: "291-1",
  },

  {
    title: "Log",
    roles: ["SUPERADMIN"],
  },

  /**
   *  Logging
   */
  {
    path: "/log/voucher",
    name: "Log Redeem Voucher",
    icon: Notes,
    component: ListVoucherAttempt,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  {
    path: "/log/whatsapp",
    name: "Log Whatsapp",
    icon: List,
    component: ListLogWhatsappPage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  {
    path: "/log/login",
    name: "Log Login",
    icon: List,
    component: ListLoginLogPage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  {
    path: "/log/email",
    name: "Log Email",
    icon: List,
    component: ListEmailLogPage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  {
    path: "/log/export-kelas",
    name: "Log Export Kelas",
    icon: List,
    component: ListExportCourseLogPage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  {
    path: "/log/verifikasi-wajah",
    name: "Log Verifikasi Wajah",
    icon: List,
    component: ListFaceRecognitonLogPage,
    layout: "",
    roles: ["SUPERADMIN"],
    invisible: false,
  },
  {
    title: "Pengaturan",
    roles: ["SUPERADMIN"],
  },
  /**
   * Manage Administrator [SUPERADMIN]
   */
  {
    layout: "",
    path: "/administrator/create",
    component: CreateAdminPage,
    roles: ["SUPERADMIN"],
    invisible: true,
  },
  {
    layout: "",
    path: "/administrator/:id/detail",
    component: DetailAdminPage,
    roles: ["SUPERADMIN"],
    invisible: true,
  },
  {
    collapse: true,
    name: "Kelola Admin",
    icon: HowToReg,
    iconColor: "warning",
    state: "adminCollapse",
    roles: ["SUPERADMIN"],
    views: [
      {
        path: "/administrator",
        name: "Data Admin",
        icon: HowToReg,
        component: ListAdminPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
      },

      /**
       * Manage Role [SUPERADMIN]
       */
      {
        path: "/role",
        name: "Data Role",
        icon: AccountTree,
        component: ListRolePage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
        use_permission: true,
        code: "141-1",
      },
    ],
  },

  /**
   * Permission [SUPERADMIN]
   */
  {
    collapse: true,
    name: "Kelola Akses",
    icon: Settings,
    state: "permissionCollapse",
    roles: ["SUPERADMIN"],
    use_permission: true,
    parent_code: ["151", "152"],
    views: [
      /**
       * Manage Permission [SUPERADMIN]
       */
      {
        layout: "",
        path: "/permission/:id/detail",
        component: DetailPermissionPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        layout: "",
        path: "/permission/tambah",
        component: CreatePermissionPage,
        roles: ["SUPERADMIN"],
        invisible: true,
      },
      {
        path: "/permission",
        name: "Data Akses",
        icon: Https,
        component: ListPermissionPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
        use_permission: true,
        code: "151-1",
      },
      /**
       * Set Permission [SUPERADMIN]
       */
      {
        path: "/set-permission",
        name: "Atur Akses",
        icon: CheckBox,
        component: ListSetPermissionPage,
        layout: "",
        roles: ["SUPERADMIN"],
        invisible: false,
        use_permission: true,
        code: "152-1",
      },
    ],
  },
];

export default mainRoutes;
