import React, { useState } from "react";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  ViewModule,
  DateRange,
  MoreVert,
  Delete,
  InfoOutlined,
  FileCopy,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import ChipData from "components/Chip/ChipData";

export default function CourseDataTable({
  loading,
  data,
  pagination,
  handleDeleteData,
  handleChangePage,
  handleChangeRowsPage,
  handleDuplicateCourse,
}) {
  const history = useHistory();

  const [menuEl, setMenuEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [course, setCourse] = useState({});

  const columns = ["#", "Nama", "Tipe", "Kategori", "Metode", "Status", "Opsi"];

  const menuId = openMenu ? "menu" : undefined;

  const handleMenu = (e, course) => {
    setMenuEl(e.currentTarget);
    setOpenMenu(true);
    setCourse(course);
  };

  const handleCloseMenu = () => {
    setMenuEl(null);
    setOpenMenu(false);
    setCourse({});
  };

  const MenuList = course => {
    return (
      <Menu
        id={menuId}
        open={openMenu}
        anchorEl={menuEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuItem
          className="d-flex"
          onClick={() => history.push(`/kelas/${course.id}/detail`)}
        >
          <InfoOutlined className="mr-2" />
          Detail
        </MenuItem>
        <Divider className="my-1" />
        <MenuItem
          className="d-flex"
          onClick={() => history.push(`/kelas/${course.id}/modul`)}
        >
          <ViewModule className="mr-2" />
          Modul Belajar
        </MenuItem>
        {[1, 4].includes(course?.course_teach_method?.id) && (
          <MenuItem
            className="d-flex"
            onClick={() => handleOpenSchedule(course)}
          >
            <DateRange className="mr-2" />
            Jadwal Kelas
          </MenuItem>
        )}
        <MenuItem
          className="d-flex"
          onClick={() => handleDuplicateCourse(course?.id)}
        >
          <FileCopy className="mr-2" />
          Duplikat Kelas
        </MenuItem>
        <Divider className="my-1" />
        <MenuItem
          className="d-flex"
          onClick={() => handleDeleteData(course.id)}
        >
          <Delete className="mr-2" />
          Hapus
        </MenuItem>
      </Menu>
    );
  };

  const handleOpenSchedule = course => {
    const url =
      course.course_teach_method.id === 4
        ? `/kelas/${course.id}/jadwal-offline`
        : `/kelas/${course.id}/webinar`;

    history.push(url);
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  component="td"
                  align="center"
                  style={{ whiteSpace: "normal" }}
                >
                  {course.name}
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.course_type.name} />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData
                    color="primary"
                    text={course.course_category.name}
                  />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData
                    color="primary"
                    text={course.course_teach_method.name}
                  />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.status} />
                </TableCell>
                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="Option"
                    aria-describedby={menuId}
                    onClick={e => handleMenu(e, course)}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={8}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        {openMenu && MenuList(course)}

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={8}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
