import React from "react";

import { Box } from "@material-ui/core";

import Spinner from "./Indicator/Spinner";

import "./LoadingScreen.scss";

export default function LoadingScreen() {
  return (
    <>
      <Box id="loading-screen">
        <Spinner />
      </Box>
    </>
  );
}
