const video_quiz_types = {
  MIX_AND_MATCH: "MIX_AND_MATCH",
  QUIZ: "QUIZ",
  TRUE_OR_FALSE: "TRUE_OR_FALSE",
};

const VideoQuizConstant = {
  ALL_VIDEO_QUIZ_CONSTANT_TYPES: Object.keys(video_quiz_types),
  VIDEO_QUIZ_CONSTANT_TYPES: video_quiz_types,
};

export default VideoQuizConstant;
