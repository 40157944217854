import React from "react";

import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

export default function PreviewQuestionAnswer({
  data,
  handleDeleteQuestionAnswer,
  handleEditQuestionAnswer,
}) {
  return (
    <Card className="p-4">
      <Grid container>
        <Grid item xs={12} className="mb-2">
          <h2 className="text-muted">Preview Soal dan Jawaban</h2>
        </Grid>

        {!data.length && (
          <Grid item xs={12}>
            <h3 className="text-muted text-center">
              Tidak Ada Soal dan Jawaban
            </h3>
          </Grid>
        )}

        {data.map((quiz, key) => (
          <Grid key={key} item xs={12} className="mb-2">
            <div className="d-flex justify-content-between">
              <div
                className="questions"
                dangerouslySetInnerHTML={{ __html: quiz.question }}
              />
              <div>
                <IconButton onClick={() => handleEditQuestionAnswer(key)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteQuestionAnswer(key)}>
                  <Delete />
                </IconButton>
              </div>
            </div>
            <RadioGroup defaultValue="">
              {quiz?.answers.map((item, key_answer) => (
                <>
                  <Box className="d-flex">
                    <FormControlLabel
                      key={key_answer}
                      value={key_answer}
                      control={<Radio color="primary" />}
                      checked={item?.correct ? true : false}
                    />
                    <div
                      className="questions"
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    />
                  </Box>
                </>
              ))}
            </RadioGroup>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
