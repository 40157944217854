import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { Delete, Edit } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import formatPeriod from "helpers/formatPeriod";
import formatStatusBlast from "helpers/formatStatusBlast";

export default function ReminderCourseDataTable({
  loading,
  data,
  getDetailData,
  handleDeleteData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const columns = ["#", "Nama Kelas", "Periode", "Status", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((reminder, index) => (
              <TableRow key={reminder.id}>
                <TableCell component="td" scope="row" align="center">
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>
                <TableCell component="td" align="center">
                  {reminder.course.name}
                </TableCell>
                <TableCell component="td" align="center">
                  {formatPeriod(reminder.period)}
                </TableCell>
                <TableCell component="td" align="center">
                  {formatStatusBlast(reminder.status)}
                </TableCell>
                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="Edit"
                    onClick={() => getDetailData(reminder.id)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDeleteData(reminder.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
