import React from "react";

import { TablePagination } from "@material-ui/core";

export default function Pagination(props) {
  const {
    pagination,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPage,
    colspan,
  } = props;

  return (
    <TablePagination
      rowsPerPageOptions={[10, 50, 100, 500, 1000]}
      colSpan={colspan}
      count={pagination?.total_records || 0}
      rowsPerPage={pagination?.limit}
      page={pagination?.current_page ? pagination?.current_page - 1 : 0}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPage}
      labelRowsPerPage="Baris per halaman"
    />
  );
}
