import {
  Box,
  Card,
  Container,
  Grid,
  SnackbarContent,
  makeStyles,
  IconButton,
  FormGroup,
  OutlinedInput,
  Checkbox,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import PreviewQuizAnswer from "../List/PreviewQuizAnswer";
import Spinner from "components/Loading/Indicator/Spinner";

const snackbarStyles = makeStyles(snackbarTheme);

export default function DetailVideoQuizPage() {
  const { courseId, moduleId, subModuleId, id } = useParams();

  const styles = { ...snackbarStyles() };
  const { register, watch, setValue } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState();

  const [quiz, setQuiz] = useState([]);
  const [edit, setEdit] = useState(null);

  /**
   * Fetch Data
   */
  const getDetailVideoQuiz = async (courseId, moduleId, subModuleId, id) => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${id}`
      );

      const dataQuiz = data.data.video_quiz_questions.map(videoQuiz => ({
        question: videoQuiz.question,
        answers: videoQuiz.video_quiz_answers,
      }));
      setQuiz(dataQuiz);

      setValue("duration", data.data.duration);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const putCreateVideoQuiz = async (courseId, moduleId, subModuleId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = {
        duration: watch("duration"),
        quiz,
      };

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${id}`,
        payload
      );

      Swal.fire("Sukses", "Video Quiz berhasil di perbarui", "success");

      history.push(
        `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/video-quiz`
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleCheckCorrectAnswer = index => {
    for (let x = 0; x < 4; x++)
      setValue(`answers[${x}].correct`, x === index ? 1 : 0);
  };

  const handleAddQuestionAnswer = () => {
    const question = watch("question");
    const answers = watch("answers");
    let correct_check = false;
    let error_check = false;

    setError(null);

    if (!question) {
      setError("Pertanyaan tidak boleh kosong");
      error_check = true;
    }

    answers.forEach(item => {
      const { answer, correct } = item;

      if (correct) correct_check = true;

      if (!answer) {
        setError("Jawaban tidak boleh kosong");
        error_check = true;
      }
    });

    if (!correct_check) setError("Tandai salah satu jawaban benar");

    if (!error_check && correct_check) {
      setQuiz(prevQuiz => [...prevQuiz, { question, answers }]);

      setValue("question", "");
      setValue("answers", []);
    }
  };

  const handleEditQuestionAnswer = key => {
    let quizzes = quiz[key];

    setEdit(key);
    setValue("question", quizzes.question);
    setValue("answers", quizzes.answers);

    document.documentElement.scrollTop = 0;
  };

  const handleUpdateQuestionAnswer = () => {
    let quizzes = [...quiz];
    const question = watch("question");
    const answers = watch("answers");
    let correct_check = false;
    let error_check = false;

    setError(null);

    if (!question) {
      setError("Pertanyaan tidak boleh kosong");
      error_check = true;
    }

    answers.forEach(item => {
      const { answer, correct } = item;

      if (correct) correct_check = true;

      if (!answer) {
        setError("Jawaban tidak boleh kosong");
        error_check = true;
      }
    });

    if (!correct_check) setError("Tandai salah satu jawaban benar");

    if (!error_check && correct_check) {
      quizzes[edit] = {
        question,
        answers,
      };

      setQuiz(quizzes);
      setEdit(null);
      setValue("question", "");
      setValue("answers", []);
    }
  };

  const handleDeleteQuestionAnswer = key => {
    let quizzes = quiz;

    quizzes = quizzes.filter(item => item !== quiz[key]);

    if (edit === null) setQuiz(quizzes);
  };

  useEffect(() => {
    getDetailVideoQuiz(courseId, moduleId, subModuleId, id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Kuis Video</title>
      </Helmet>

      <DefaultHeader
        section="Detail Data"
        subsection="Kuis Video"
        routeBack={`/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/video-quiz`}
      />

      <Container
        maxWidth={false}
        component={Box}
        id="course-create-video-quiz-page"
      >
        <Grid container>
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <>
                <Card className="p-4">
                  {error && (
                    <Grid container>
                      <Grid item xs={12} className="mb-2">
                        <SnackbarContent
                          classes={{ root: styles.errorSnackbar }}
                          action={
                            <Box
                              component={IconButton}
                              padding="0!important"
                              onClick={() => setError(null)}
                            >
                              <Box
                                component="span"
                                color={theme.palette.white.main}
                              >
                                ×
                              </Box>
                            </Box>
                          }
                          message={<Box>{error}</Box>}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Durasi</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          step="1"
                          {...register("duration")}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Pertanyaan</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          {...register("question")}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Jawaban</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <Box className="d-flex">
                          <Checkbox
                            color="primary"
                            onClick={() => handleCheckCorrectAnswer(0)}
                            checked={watch("answers[0].correct") ? true : false}
                          />
                          <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Opsi 1"
                            value={watch("answers[0].answer") || ""}
                            {...register("answers[0].answer")}
                          />
                        </Box>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <Box className="d-flex">
                          <Checkbox
                            color="primary"
                            onClick={() => handleCheckCorrectAnswer(1)}
                            checked={watch("answers[1].correct") ? true : false}
                          />
                          <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Opsi 1"
                            value={watch("answers[1].answer") || ""}
                            {...register("answers[1].answer")}
                          />
                        </Box>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <Box className="d-flex">
                          <Checkbox
                            color="primary"
                            onClick={() => handleCheckCorrectAnswer(2)}
                            checked={watch("answers[2].correct") ? true : false}
                          />
                          <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Opsi 1"
                            value={watch("answers[2].answer") || ""}
                            {...register("answers[2].answer")}
                          />
                        </Box>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <Box className="d-flex">
                          <Checkbox
                            color="primary"
                            onClick={() => handleCheckCorrectAnswer(3)}
                            checked={watch("answers[3].correct") ? true : false}
                          />
                          <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Opsi 1"
                            value={watch("answers[3].answer") || ""}
                            {...register("answers[3].answer")}
                          />
                        </Box>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (edit === null) {
                              handleAddQuestionAnswer();
                            } else {
                              handleUpdateQuestionAnswer();
                            }
                          }}
                        >
                          {edit === null ? "Tambah Soal" : "Ubah Soal"}
                        </Button>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <Button
                          variant="contained"
                          className="btn btn-success"
                          onClick={() =>
                            putCreateVideoQuiz(
                              courseId,
                              moduleId,
                              subModuleId,
                              id
                            )
                          }
                        >
                          Perbarui
                        </Button>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Card>

                <PreviewQuizAnswer
                  data={quiz}
                  handleEditQuestionAnswer={handleEditQuestionAnswer}
                  handleDeleteQuestionAnswer={handleDeleteQuestionAnswer}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
