const status = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  REJECT: "REJECT",
};

const course_types = {
  SINGLE: "SINGLE",
  BUNDLE: "BUNDLE",
};

const TransactionConstant = {
  ALL_STATUS: Object.keys(status),
  STATUS: status,

  ALL_COURSE_TYPE: Object.keys(course_types),
  COURSE_TYPE: course_types,
};

export default TransactionConstant;
