import React from "react";

import {
  Box,
  Button,
  Card,
  CardHeader,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import componentStyles from "assets/theme/components/cards/dashboard/card-light-table.js";

const useStyles = makeStyles(componentStyles);

export default function CardParticipantRated({ data }) {
  const classes = useStyles();
  const history = useHistory();

  const columns = ["Nama Peserta", "Nama Kelas", ""];

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          className={classes.cardHeader}
          title="Peserta Belum Dinilai"
          titleTypographyProps={{
            component: Box,
            marginBottom: "0!important",
            variant: "h3",
          }}
        />
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {columns.map((column, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                    className="text-center"
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map(el => (
                  <TableRow key={el.id}>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                      className="text-center"
                    >
                      {el.user.name}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      className="text-center"
                    >
                      {el.course?.name}
                    </TableCell>
                    <TableCell component="td" align="center">
                      <Button
                        variant="contained"
                        size="xl"
                        color="default"
                        onClick={() => {
                          let url = `/admin-instructor/my-course/${el.course_id}/webinar/${el.course_webinar_id}`;

                          if (el.course?.course_teach_method_id === 4)
                            url = `/admin-instructor/my-course/${el.course_id}/offline/${el.course_offline_schedule_id}`;

                          if (el.course?.course_teach_method_id === 2)
                            url = `/admin-instructor/my-course/${el.course_id}/uji-keterampilan`;

                          return history.push(url);
                        }}
                      >
                        Beri Nilai
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell component="td" colSpan={6}>
                    <Box textAlign="center">Tidak Ada Data</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}
