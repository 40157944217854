import {
  Box,
  Card,
  Grid,
  TableBody,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "services/api";
import http from "services/http";

export default function InstructorOfflineSessionDataTable() {
  const { courseId, scheduleId } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [sessionData, setSessionData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (courseId, scheduleId, page = 1) => {
    try {
      setLoading(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.instructor.course.main}/${courseId}/offline-schedule/${scheduleId}/session`,
        {
          params: {
            limit,
            page,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setSessionData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, scheduleId, page + 1);

  useEffect(() => {
    getList(courseId, scheduleId);
  }, []);

  /**
   * Table Data
   */
  const columns = ["#", "Tanggal", "Jam Mulai", "Jam Selesai"];

  return (
    <Grid container>
      <Grid item xs={12}>
        {error ? (
          <Box textAlign="center" color={themeColors.error.main}>
            {error}
          </Box>
        ) : (
          <Grid>
            <Card>
              <TableContainer component={Paper} width="100%">
                <Box component={Table} alignItems="center">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: themeColors.primary.main,
                      }}
                    >
                      {columns.map((item, key) => (
                        <TableCell
                          key={key}
                          style={{ fontWeight: 700 }}
                          align="center"
                          width={key === 0 ? "100px" : null}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading && sessionData.length ? (
                      sessionData.map((session, index) => (
                        <TableRow key={session.id}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="center"
                            style={{ wordWrap: "break-word" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell component="td" align="center">
                            {moment(session?.date).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell component="td" align="center">
                            {moment(
                              `${session?.date} ${session?.start_time}`
                            ).format("HH:mm")}
                          </TableCell>
                          <TableCell component="td" align="center">
                            {moment(
                              `${session?.date} ${session?.end_time}`
                            ).format("HH:mm")}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell component="td" colSpan={8}>
                          {loading ? (
                            <Box textAlign="center">Harap Tunggu...</Box>
                          ) : (
                            <Box textAlign="center">Tidak ada data</Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <Pagination
                        pagination={pagination}
                        colSpan={8}
                        onChangePage={handleChangePage}
                      />
                    </TableRow>
                  </TableFooter>
                </Box>
              </TableContainer>
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
