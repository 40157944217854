const periods = {
  ONE_HOUR_BEFORE: "ONE_HOUR_BEFORE",
  ONE_DAY_BEFORE: "ONE_DAY_BEFORE",
  ONE_DAY_AFTER: "ONE_DAY_AFTER",
  THREE_DAY_AFTER: "THREE_DAY_AFTER",
  SEVEN_DAY_AFTER: "SEVEN_DAY_AFTER",
  EIGHT_DAY_AFTER: "EIGHT_DAY_AFTER",
  TEN_DAY_AFTER: "TEN_DAY_AFTER",
  TWELVE_DAY_AFTER: "TWELVE_DAY_AFTER",
  LAST_DAY: "LAST_DAY",
};

const PeriodConstant = {
  ALL_PERIODS: Object.keys(periods),
  PERIODS: periods,
};

export default PeriodConstant;
