import React, { useEffect, useState } from "react";

import { Container, Box, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import moment from "moment";

import DefaultHeader from "components/Headers/DefaultHeader";

import CardStatisticTransaction from "pages/Dashboard/CardStatisticTransaction";
import http from "services/http";
import api from "services/api";
import getParams from "helpers/getParams";
import { useHistory } from "react-router-dom";

export default function TransactionStatisticPage() {
  const [loading, setLoading] = useState(false);

  const [statisticData, setStatisticData] = useState([]);
  const [period, setPeriod] = useState("DAY");

  const query = getParams();
  const history = useHistory();

  const getStatisticData = async period => {
    try {
      setLoading(true);

      const { data } = await http.get(api.v1.admin.statistic.main, {
        params: { period },
      });

      let statistics = {
        labels: [],
        datasets: [{ label: "Transaksi", data: [] }],
      };
      const { data: transactions } = data;

      if (period === "DAY")
        transactions.map(item => {
          statistics.labels.push(`${item.hour}:00`);
          statistics.datasets[0].data.push(item.count);
        });

      if (period === "WEEK")
        for (let i = 0; i < 7; i++) {
          const date = moment()
            .subtract(i, "d")
            .format("YYYY-MM-DD")
            .toString();
          const dateLabels = moment()
            .subtract(i, "d")
            .format("D MMM YYYY")
            .toString();

          const data = transactions.find(item => item.date === date);

          statistics.datasets[0].data.push(data?.date ? data.count : 0);

          statistics.labels.push(dateLabels);
        }

      if (["MONTH", "THREE_MONTH"].includes(period)) {
        let days;

        if (period === "MONTH") days = 30;
        if (period === "THREE_MONTH") days = 90;

        for (let i = 0; i < days; i++) {
          const date = moment()
            .subtract(i, "d")
            .format("YYYY-MM-DD")
            .toString();
          const dateLabels = moment()
            .subtract(i, "d")
            .format("D MMM YYYY")
            .toString();

          const data = transactions.find(item => item.date === date);

          statistics.datasets[0].data.push(data?.date ? data.count : 0);

          statistics.labels.push(dateLabels);
        }
      }

      if (["SIX_MONTH", "YEAR"].includes(period)) {
        let months;

        if (period === "SIX_MONTH") months = 6;
        if (period === "YEAR") months = 12;

        for (let i = 0; i < months; i++) {
          const month = moment().subtract(i, "M").format("MMMM").toString();
          const year = moment().subtract(i, "M").format("YYYY").toString();
          const dateLabels = moment()
            .subtract(i, "M")
            .format("MMMM YYYY")
            .toString();

          const data = transactions.find(
            item => `${item.year}` === year && item.month === month
          );

          statistics.datasets[0].data.push(data?.year ? data.count : 0);

          statistics.labels.push(dateLabels);
        }
      }

      setStatisticData(statistics);
    } catch (error) {
      setLoading(false);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePeriode = val => {
    history.push(`/report/transaction/statistic?periode=${val}`);

    setTimeout(() => {
      setPeriod(val);
    }, 0);
  };

  useEffect(() => {
    getStatisticData(period);
    if (query.get("periode")) setPeriod(query.get("periode"));
  }, [period]);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Statistik Transaksi</title>
      </Helmet>

      <DefaultHeader
        section="Statistik Transaksi"
        subsection="Laporan Transaksi"
      />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <CardStatisticTransaction
              statistics={statisticData}
              changePeriod={val => handleChangePeriode(val)}
              period={period}
              loading={loading}
              fullOption={true}
              refresh={() => getStatisticData(period)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
