import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { baseActions } from "redux/actions";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import http from "services/http";
import api from "services/api";
import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import ReferralReportDataTable from "./ReferralReportDataTable";

export default function ReferralReportPage() {
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      use_referral: {
        user_id: "",
        name: "",
        referral_point: null,
        point: null,
      },
      filter: {
        filter_search: "",
      },
    },
  });

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [userData, setUserData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [showModal, setShowModal] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { filter_search } = watch("filter");

      const { data } = await http.get(`${api.v1.admin.report.user}/referral`, {
        params: { limit, page, filter_search },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setUserData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  const postUseReferral = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { referral_point, point, user_id } = payload.use_referral;

      if (point > referral_point)
        return Swal.fire(
          "Gagal",
          "Point Penukaran Melebihi Point yang dimiliki",
          "error"
        );

      const payloads = {
        point,
        user_id,
      };

      await http.post(`${api.v1.admin.report.user}/referral`, payloads);

      getList();

      handleCloseModal();

      Swal.fire("Sukses", "Berhasil Menggunakan Point Referral", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpen = user => {
    setValue("use_referral.user_id", user.id);
    setValue("use_referral.referral_point", user.referral_point);
    setValue("use_referral.name", user.name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Referral</title>
      </Helmet>

      <DefaultHeader section="Data Referral" subsection="Laporan Peserta" />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-end">
                    <Grid sm={12}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <Grid className="mr-2">
                          <OutlinedInput
                            type="text"
                            placeholder="Cari berdasarkan Nama"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                            className="mr-3"
                            onKeyPress={e => e.key === "Enter" && getList()}
                            {...register("filter.filter_search")}
                          />

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => getList()}
                          >
                            <Box
                              component={Search}
                              width="1.25rem!important"
                              height="1.25rem!important"
                              position="relative"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <ReferralReportDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={userData}
                    pagination={pagination}
                    loading={loadingData}
                    handleOpen={handleOpen}
                  />
                </Card>

                {/* Modal Exchange */}
                <Dialog
                  open={showModal}
                  fullWidth={true}
                  maxWidth="md"
                  onClose={handleCloseModal}
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Gunakan Referral</h2>
                    </Box>

                    <form onSubmit={handleSubmit(postUseReferral)}>
                      <Grid container className="py-4">
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="name"
                              disabled
                              {...register("use_referral.name")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormLabel>Referral Point</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              disabled
                              {...register("use_referral.referral_point")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Point Yang Ingin Ditukar</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("use_referral.point")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
