import Select from "react-select";
import { Controller } from "react-hook-form";

function ReactSelect({
  label,
  showLabel = true,
  showRequired = false,
  rounded = false,
  options,
  name,
  control,
  defaultValue,
  onChange,
  ...props
}) {
  return (
    <>
      {showLabel ? (
        <label className="form-label !font-medium !font-Poppins">
          {label}
          {showRequired ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ fieldState: { invalid, error } }) => (
          <>
            <Select
              options={options}
              onChange={onChange}
              // onChange={(options) => (
              //     onChange(options?.map((op) => op.value))
              // )}
              // value={options.find((op) => op.value === value)}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: `${
                    state.isFocused && !invalid
                      ? "1px solid #FDE047"
                      : invalid
                      ? "1px solid #EF4444"
                      : "1px solid #e5e7eb"
                  }`,
                  // border: state.isFocused ? "1px solid #FDE047" : "1px solid #e5e7eb",
                  "&:hover": {
                    border: invalid ? "1px solid #EF4444" : "1px solid #FDE047",
                  },
                  borderRadius: rounded
                    ? "9999px !important;"
                    : "0.375rem !important",
                  padding: "0.12rem 0.4rem !important",
                  boxShadow:
                    state.isFocused && !invalid
                      ? "0 0 0 1px #FDE047"
                      : invalid
                      ? "0 0 0 1px solid #EF4444"
                      : 0,
                }),
              }}
              {...props}
            />
            {invalid ? <p className="error-message">{error.message}</p> : null}
          </>
        )}
      />
    </>
  );
}

export default ReactSelect;
