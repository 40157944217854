import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

export default function PushNotificationTopicPage() {
  const dispatch = useDispatch();
  const { register, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      push_notification: {
        id: null,
        title: "",
        description: "",
        image_url: "",
        payload: {
          url_redirect: "",
        },
      },
    },
  });

  const [topicOptions, setTopicOptions] = useState([]);

  /**
   * Fetch Data
   */

  const getTopicNotification = async () => {
    try {
      const { data } = await http.get(api.v1.admin.topic_notification.main, {
        params: { page: 1, limit: 100 },
      });

      const record = data.data.records.map(item => ({
        label: item.label,
        value: item.id,
      }));

      setTopicOptions(record);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    }
  };

  const pushNotification = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { push_notification } = payload;

      await http.post(
        api.v1.admin.push_notification_topic.main,
        push_notification
      );

      Swal.fire("Sukses", "Notif Berhasil Dikirim", "success");
      reset();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getTopicNotification();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Notifikasi Mobile</title>
      </Helmet>

      <DefaultHeader subsection="Notifikasi Mobile" section="Topik" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(pushNotification)}>
                      <Grid container>
                        {/* Topik */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="topic-label">Topik</FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="topic-label"
                                value={
                                  watch("push_notification.id") ||
                                  topicOptions[0]?.value
                                }
                                {...register("push_notification.id")}
                              >
                                {topicOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Judul */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="title-label">Judul</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                {...register("push_notification.title")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Link Gambar */}
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="url-label">Link Gambar</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="url"
                                {...register("push_notification.image_url")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Url */}
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="url-label">Redirect Url</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="url"
                                {...register(
                                  "push_notification.payload.url_redirect"
                                )}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Deskripsi */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="description-label">
                                Deskripsi
                              </FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                multiline={true}
                                {...register("push_notification.description")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Kirim
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
