import VideoQuizConstant from "constant/VideoQuizConstant";

const formatVideoQuiz = string => {
  switch (string) {
    case VideoQuizConstant.VIDEO_QUIZ_CONSTANT_TYPES.MIX_AND_MATCH:
      return "Mix and Match";
    case VideoQuizConstant.VIDEO_QUIZ_CONSTANT_TYPES.QUIZ:
      return "Quiz";
    case VideoQuizConstant.VIDEO_QUIZ_CONSTANT_TYPES.TRUE_OR_FALSE:
      return "True or False";
    default:
      return "-";
  }
};

export default formatVideoQuiz;
