const status = {
  WAITING_PERIOD: "WAITING_PERIOD",
  IN_QUEUE: "IN_QUEUE",
  FINISHED: "FINISHED",
};

const statusLogWhatsapp = {
  QUEUED: "QUEUED",
  FAILED: "FAILED",
  SENT: "SENT",
  DELIVERED: "DELIVERED",
  UNDELIVERED: "UNDELIVERED",
  READ: "READ",
};

const statusLogEmail = {
  SEND: "SEND",
  DELIVERED: "DELIVERED",
  OPEN: "OPEN",
  NOT_FOUND: "NOT_FOUND",
};

const statusLogExportCourse = {
  PENDING: "PENDING",
  PROCESS: "PROCESS",
  FAILED: "FAILED",
  FINISHED: "FINISHED",
  DELETED: "DELETED",
};

const StatusConstant = {
  ALL_STATUS: Object.keys(status),
  STATUS: status,

  ALL_STATUS_LOG_WHATSAPP: Object.keys(statusLogWhatsapp),
  STATUS_LOG_WHATSAPP: statusLogWhatsapp,

  ALL_STATUS_LOG_EMAIL: Object.keys(statusLogEmail),
  STATUS_LOG_EMAIL: statusLogEmail,

  ALL_STATUS_LOG_EXPORT_COURSE: Object.keys(statusLogExportCourse),
  STATUS_LOG_EXPORT_COURSE: statusLogExportCourse,
};

export default StatusConstant;
