import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";

export default function ExportCourseReportPage() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      tanggalAwal: null,
      tanggalAkhir: null,
      progressDone: false,
    },
  });

  const [toggleActive, setToggleActive] = useState(true);

  const statusCourseReportOptions = ["Semua", "Tuntas", "Sedang Berjalan"];
  const statusByDateReportOptions = ["Penyelesaian", "Transaksi"];
  const statusWebinarOptions = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Belum Ada Jadwal",
      value: "NOT_SELECTED_SCHEDULE",
    },
    {
      label: "Sudah Ada Jadwal",
      value: "SELECTED_SCHEDULE",
    },
  ];
  const partnerOptions = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Dengan Partner",
      value: "WITH_PARTNER",
    },
    {
      label: "Tanpa Partner",
      value: "WITHOUT_PARTNER",
    },
  ];

  /**
   * Fetch Data
   */
  const onSubmit = async (val) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const {
        tanggalAwal,
        tanggalAkhir,
        status,
        by_date,
        webinar_status,
        partner,
        email_recipient,
      } = val;

      const params = {
        start_date: tanggalAwal,
        end_date: tanggalAkhir,
        by_date,
        webinar_status,
        partner,
        email_recipient,
      };

      if (status === "Tuntas") params.status = "DONE";
      else if (status === "Sedang Berjalan") params.status = "ONGOING";
      else delete params.status;

      if (by_date === "Penyelesaian") params.by_date = "COMPLETED";
      else if (by_date === "Transaksi") params.by_date = "PURCHASE";
      else delete params.by_date;

      if (toggleActive) params.all = true;

      await http.get(`${api.v1.admin.report.course.main}/export`, {
        params,
      });

      Swal.fire("Sukses", "Laporan Data Kelas berhasil di export", "success");

      reset();
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Export Laporan Data Kelas</title>
      </Helmet>

      <DefaultHeader section="Export Data Kelas" subsection="Laporan Kelas" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container xs={12}>
                        <Grid item xs={12} md={4}>
                          {/* Berdasarkan Tanggal */}
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-competence-aspect-label">
                                Berdasarkan Tanggal
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-competence-aspect-label"
                                value={watch("by_date") || "Penyelesaian"}
                                {...register("by_date")}
                              >
                                {statusByDateReportOptions.map((val, key) => (
                                  <MenuItem key={key} value={val}>
                                    {val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        {/* Tanggal Awal */}
                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Tanggal Awal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("tanggalAwal")}
                            />
                            {errors.tanggalAwal && (
                              <FormHelperText
                                component={Box}
                                color={theme.palette.error.main + "!important"}
                              >
                                {errors.tanggalAwal.message}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {/* Tanggal Akhir */}
                          <FormGroup>
                            <FormLabel>Tanggal Akhir</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("tanggalAkhir")}
                            />
                            {errors.tanggalAkhir && (
                              <FormHelperText
                                component={Box}
                                color={theme.palette.error.main + "!important"}
                              >
                                {errors.tanggalAkhir.message}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {/* Progress Kelas Yang Sudah Tuntas */}
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-competence-aspect-label">
                                Status
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-competence-aspect-label"
                                value={watch("status") || "Semua"}
                                {...register("status")}
                              >
                                {statusCourseReportOptions.map((val, key) => (
                                  <MenuItem key={key} value={val}>
                                    {val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {/* status webinar yang jadwal */}
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-competence-aspect-label">
                                Webinar
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-competence-aspect-label"
                                value={watch("webinar_status") || "ALL"}
                                {...register("webinar_status")}
                              >
                                {statusWebinarOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-competence-aspect-label">
                                Semua
                              </FormLabel>
                              <Switch
                                color="primary"
                                checked={toggleActive}
                                onChange={(e) =>
                                  setToggleActive(e.target.checked)
                                }
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {/* Partner */}
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-competence-aspect-label">
                                Partner
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-competence-aspect-label"
                                value={watch("partner") || "ALL"}
                                {...register("partner")}
                              >
                                {partnerOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Email Penerima</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="email"
                              {...register("email_recipient")}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid container className="mt-5">
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Export Data Kelas
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
