import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";

export default function ExportVoucherPage() {
  const [loadingData, setLoadingData] = useState(false);
  const [courses, setCourses] = useState([]);

  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      tanggalAwal: moment().format("yyyy-MM-DD"),
      tanggalAkhir: moment().format("yyyy-MM-DD"),
      course: "",
      voucher: "",
      voucherUsed: false,
    },
  });

  const getCourses = async () => {
    try {
      setLoadingData(true);

      const { data } = await http.get(api.v1.admin.course.main, {
        params: { option: "true" },
      });

      const { records } = data.data;

      setCourses(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      setLoadingData(false);
    }
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { tanggalAwal, tanggalAkhir, course, voucherUsed } = val;

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Voucher - ${course.name}.xlsx`;

      const res = await http.get(
        `${api.v1.admin.voucher.main}/${course.id}/export`,
        {
          params: {
            used: voucherUsed,
            start_date: tanggalAwal,
            end_date: tanggalAkhir,
          },
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);

      Swal.fire("Sukses", "Voucher berhasil di export", "success");

      reset();
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => getCourses(), []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Export Voucher</title>
      </Helmet>

      <DefaultHeader section="Export Voucher" subsection="Kelola Voucher" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container xs={12}>
                          {/* Tanggal Awal */}
                          <Grid item xs={12} md={6}>
                            <FormGroup>
                              <FormLabel>Tanggal Awal</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="date"
                                {...register("tanggalAwal", {
                                  required: "Tidak boleh kosong",
                                })}
                              />
                              {errors.tanggalAwal && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.tanggalAwal.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {/* Tanggal Akhir */}
                            <FormGroup>
                              <FormLabel>Tanggal Akhir</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="date"
                                {...register("tanggalAkhir", {
                                  required: "Tidak boleh kosong",
                                })}
                              />
                              {errors.tanggalAkhir && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.tanggalAkhir.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {/* Pilih Kelas */}
                        <FormGroup>
                          <FormLabel>Pilih Kelas</FormLabel>
                          <Controller
                            control={control}
                            name="course"
                            rules={{
                              required: "Tidak boleh kosong",
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                options={courses}
                                getOptionLabel={option => option.name || ""}
                                getOptionSelected={(option, val) =>
                                  val === undefined ||
                                  val === "" ||
                                  option.id === val.id
                                }
                                closeIcon={null}
                                value={value}
                                onChange={(e, val) => {
                                  onChange(val);
                                }}
                                popupIcon={
                                  <Box
                                    component={KeyboardArrowDown}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                  />
                                }
                                renderInput={params => (
                                  <TextField {...params} variant="filled" />
                                )}
                              />
                            )}
                          />
                          {errors.course && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.course.message}
                            </FormHelperText>
                          )}
                        </FormGroup>

                        {/* Voucher Terpakai */}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                name="voucherUsed"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Checkbox
                                    checked={value}
                                    onChange={e => onChange(e.target.checked)}
                                    color="primary"
                                  />
                                )}
                              />
                            }
                            label={
                              <Box
                                component="div"
                                fontSize={14}
                                fontWeight={600}
                              >
                                Voucher Terpakai
                              </Box>
                            }
                          />
                        </FormGroup>

                        <Grid container className="mt-5">
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Export Voucher
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
