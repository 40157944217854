import React from "react";

import { Chip } from "@material-ui/core";

export default function ChipData({ color, text, style }) {
  return (
    <Chip
      color={color}
      style={{ fontSize: "12px", ...style }}
      className="font-weight-bold"
      label={text}
    />
  );
}
