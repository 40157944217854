import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import themeColors from "assets/theme/colors";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { KeyboardArrowDown } from "@material-ui/icons";
import TemplateMessageDataTable from "./TemplateMessageDataTable";
import ReactQuill from "react-quill";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["link", "image", "video", "formula"], // add's image support
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"],
];

export default function ListTemplateMessagePage() {
  const dispatch = useDispatch();

  const { setValue, handleSubmit, register, watch } = useForm({
    defaultValues: {
      template_message: {
        name: "",
        text: "",
        language: "ID",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [templateMessage, setTemplateMessage] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalTemplateMessage, setTypeModalTemplateMessage] =
    useState("CREATE");
  const [showModalTemplateMessage, setShowModalTemplateMessage] =
    useState(false);

  const languageOptions = [
    {
      label: "ID",
      value: "ID",
    },
    {
      label: "EN",
      value: "EN",
    },
  ];

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.email.template.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setTemplateMessage(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.email.template.main}/${id}`
      );

      setValue("template_message", {
        id,
        name: data.data.name,
        text: data.data.text,
        language: data.data.language,
      });

      setTypeModalTemplateMessage("UPDATE");
      setShowModalTemplateMessage(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { template_message } = payload;

      await http.post(api.v1.admin.email.template.main, {
        ...template_message,
      });

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Template Email berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { template_message } = payload;

      await http.put(
        `${api.v1.admin.email.template.main}/${template_message.id}`,
        {
          ...template_message,
        }
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Template Email berhasil di Perbarui",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.email.template.main}/${id}`);

      Swal.fire("Sukses", "Data Template Email berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalTemplateMessage("CREATE");
    setShowModalTemplateMessage(true);
  };

  const handleCloseModal = () => {
    setShowModalTemplateMessage(false);
    setValue("template_message", {
      name: "",
      text: "",
      language: "",
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Template Email",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Template Email</title>
      </Helmet>

      <DefaultHeader section="Data Template Email" />

      <Container maxWidth={false} component={Box} id="template-email">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <TemplateMessageDataTable
                      loading={loadingData}
                      data={templateMessage}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Template Message */}
                <Dialog
                  open={showModalTemplateMessage}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Template Email</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalTemplateMessage === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("template_message.name")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                              <FormLabel>Bahasa</FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-language"
                                value={
                                  watch("template_message.language") ||
                                  languageOptions[0].value
                                }
                                {...register("template_message.language")}
                              >
                                {languageOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Teks</FormLabel>
                            <ReactQuill
                              theme="snow"
                              value={watch("template_message.text") || ""}
                              onChange={val =>
                                setValue("template_message.text", val)
                              }
                              modules={{
                                toolbar: toolbarOptions,
                              }}
                              className="question-field"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalTemplateMessage === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
