import ReportCourseTypeConstant from "constant/ReportCourseTypeConstant";

const formatReportCourseType = string => {
  switch (string) {
    case ReportCourseTypeConstant.REPORT_COURSE_TYPES.COMPLETED:
      return "Penyelesaian";
    case ReportCourseTypeConstant.REPORT_COURSE_TYPES.PURCHASE:
      return "Pembelian";
    default:
      return "-";
  }
};

export default formatReportCourseType;
