import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

const typeSubmissions = [
  {
    label: "File",
    value: "FILE",
  },
  {
    label: "Link",
    value: "URL",
  },
];

const ModalSubmission = ({
  openModal,
  onClose,
  type,
  courseId,
  userName,
  courseSubmoduleId,
  userId,
  refetch,
}) => {
  const dispatch = useDispatch();

  const { register, watch, setValue, reset } = useForm({
    defaultValues: {
      assignment: {
        name: "",
        type: "FILE",
      },
    },
  });

  /**
   * Fetch Data
   */
  const submitAssignment = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.heic|\.mp4|\.mkv|\.3gp|\.mp3|\.pdf|\.xlsx|\.xls|\.doc|\.docx|\.pptx)$/i;

      const payload = watch("assignment");

      const formData = new FormData();

      formData.append("type", payload.type);
      formData.append("user_id", userId);

      if (payload.type === "FILE") {
        if (payload.file_submission[0]) {
          if (!allowedExtensions.exec(payload.file_submission[0].name)) {
            return Swal.fire("Gagal", "Format file tidak sesuai", "error");
          } else {
            formData.append("file_submission", payload.file_submission[0]);
          }
        } else {
          delete formData.delete("type");
        }
      }

      if (payload.type === "URL") {
        if (payload.url) {
          formData.append("url", payload.url);
        } else {
          delete formData.delete("type");
        }
      }

      await http.post(
        `${api.v1.admin.learning_progress.main}/course/${courseId}/${
          type === "TPM" ? "practice_assignment" : "assignment"
        }/${courseSubmoduleId}/submission`,
        formData
      );

      Swal.fire("Sukses", "Submit Tugas Berhasil", "success");

      handleClose();
      refetch();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    setValue("assignment.name", userName);
  }, [openModal, userName]);

  return (
    <Dialog
      open={openModal}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="md"
    >
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Submit Tugas</h2>
        </Box>

        <Grid container xs={12} className="mt-4">
          <Grid item xs={12}>
            <FormGroup>
              <FormLabel>Nama Peserta</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                disabled
                {...register("assignment.name")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormLabel>Tipe</FormLabel>
              <Autocomplete
                id="combo-box-type"
                options={typeSubmissions}
                getOptionLabel={option => option.label}
                closeIcon={null}
                onInputChange={(e, val) => {
                  const { value } = typeSubmissions.filter(
                    data => data.label === val
                  )[0];

                  setValue("assignment.type", value);
                }}
                value={
                  typeSubmissions.filter(
                    data => data.value === watch("assignment.type")
                  )[0]
                }
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={8}>
            <FormGroup>
              <FormLabel>Tugas</FormLabel>
              {watch("assignment.type") === "FILE" ? (
                <OutlinedInput
                  fullWidth
                  type="file"
                  placeholder="Default input"
                  inputProps={{
                    accept:
                      ".jpg,.jpeg,.png,.heic,.mp4,.mkv,.3gp,.mp3,.pdf,.xlsx,.xls,.doc,.docx,.pptx",
                  }}
                  {...register("assignment.file_submission")}
                />
              ) : (
                <OutlinedInput
                  fullWidth
                  type="url"
                  {...register("assignment.url")}
                />
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container className="mt-2">
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={submitAssignment}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default ModalSubmission;
