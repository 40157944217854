import React, { useEffect, useState } from "react";

import { Box, Card, Container, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import CourseTeachMethodDataTable from "./CourseTeachMethodDataTable";

export default function CourseTeachMethodPage() {
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [teachMethodData, setTeachMethodData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(api.v1.admin.course.teach_method, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setTeachMethodData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Metode Kelas</title>
      </Helmet>

      <DefaultHeader section="Metode Belajar" subsection="Kelola Kelas" />

      <Container maxWidth={false} component={Box} id="course-teach-method-page">
        <Grid container>
          <Grid item xs={12}>
            {!error && (
              <Card>
                <CourseTeachMethodDataTable
                  loading={loadingData}
                  data={teachMethodData}
                  pagination={pagination}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPage={handleChangeRowsPage}
                />
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
