import {
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

const ModalExport = ({ openModal, onClose, course_id, schedule_id }) => {
  const dispatch = useDispatch();
  const { register, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      filter: {
        progress: "all",
        attendance: "ALL",
        event_place_id: null,
        start_date: "",
        end_date: "",
      },
    },
  });

  const [error, setError] = useState(null);
  const [eventPlaceOptions, setEventPlaceOptions] = useState([]);

  const progressOptions = [
    { label: "Semua", value: "all" },
    { label: "Proses", value: "progress" },
    { label: "Selesai", value: "finished" },
  ];

  const attendanceOptions = [
    { label: "Semua", value: "ALL" },
    { label: "Belum Absen", value: "NOT_YET" },
    { label: "Sudah Absen", value: "ALREADY" },
  ];

  /**
   * Fetch Data
   */
  const getList = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.event_place.main}`);

      const { records } = data.data;
      setEventPlaceOptions(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const exportData = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Data Kelas Offline.xlsx`;

      const params = {
        ...val.filter,
        schedule_id,
      };

      const res = await http.get(
        `${api.v1.admin.report.course.main}/${course_id}/export/offline-course`,
        {
          params,
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Dialog
      open={openModal}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="lg"
    >
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Export Jadwal</h2>
        </Box>

        <form onSubmit={handleSubmit(exportData)}>
          <Grid container className="py-4">
            {error && (
              <Grid item xs={12}>
                <Box textAlign="center" color={themeColors.error.main}>
                  {error}
                </Box>
              </Grid>
            )}
            <Grid item sm={4} xs={12}>
              <FormGroup>
                <FormControl variant="outlined" fullWidth>
                  <FormLabel id="option-progress">Progress Belajar</FormLabel>
                  <Select
                    IconComponent={KeyboardArrowDown}
                    labelId="option-progress"
                    value={watch("filter.progress")}
                    {...register("filter.progress")}
                  >
                    {progressOptions.map((val, key) => (
                      <MenuItem key={key} value={val.value}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>

            <Grid item sm={4} xs={12}>
              <FormGroup>
                <FormControl variant="outlined" fullWidth>
                  <FormLabel id="option-attendance">
                    Berdasrakan Absen
                  </FormLabel>
                  <Select
                    IconComponent={KeyboardArrowDown}
                    labelId="option-attendance"
                    value={watch("filter.attendance")}
                    {...register("filter.attendance")}
                  >
                    {attendanceOptions.map((val, key) => (
                      <MenuItem key={key} value={val.value}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>

            {!schedule_id && (
              <>
                <Grid item sm={4} xs={12}>
                  <FormGroup>
                    <FormLabel>Tempat</FormLabel>

                    <Autocomplete
                      id="combo-box-category"
                      options={eventPlaceOptions}
                      getOptionLabel={option => option.name}
                      closeIcon={null}
                      onChange={(e, val) => {
                        setValue("filter.event_place_id", val.id);
                      }}
                      value={eventPlaceOptions.find(
                        data => data.id === watch("filter.event_place_id")
                      )}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormGroup>
                    <FormLabel>Tanggal Awal</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="date"
                      required
                      {...register("filter.start_date")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <FormGroup>
                    <FormLabel>Tanggal Akhir</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="date"
                      required
                      {...register("filter.end_date")}
                    />
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container className="mt-5">
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Export Data Kelas
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

export default ModalExport;
