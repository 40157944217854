import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import localStorage from "helpers/localStorage";

export default function PermissionDataTable({
  loading,
  data,
  pagination,
  handleDeleteData,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const history = useHistory();

  const permissions = JSON.parse(localStorage.get("permissions"))?.map(
    permission => permission.code
  );

  const columns = ["#", "Kode", "Nama", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((permission, index) => (
              <TableRow key={permission.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {permission.code}
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {permission.name}
                </TableCell>
                <TableCell component="td" align="center">
                  {permissions?.includes("151-2") && (
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        history.push(`/permission/${permission.id}/detail`)
                      }
                    >
                      <Edit />
                    </IconButton>
                  )}

                  {permissions?.includes("151-5") && (
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDeleteData(permission.id)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
