import React from "react";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

export default function BenefitDataTable({ data, handleDeleteData = null }) {
  const columns = ["Deksripsi", "Tooltip", "Opsi"];

  return (
    data.length !== 0 && (
      <TableContainer component={Paper} width="100%">
        <Table>
          <TableHead>
            <TableRow
              style={{
                backgroundColor: themeColors.primary.main,
              }}
            >
              {columns.map((item, key) => (
                <TableCell key={key} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, key) => (
              <TableRow key={key}>
                <TableCell style={{ whiteSpace: "normal" }}>
                  {item.benefit}
                </TableCell>
                <TableCell style={{ whiteSpace: "normal" }}>
                  {item.tooltip}
                </TableCell>
                <TableCell align="center" width="10px">
                  {handleDeleteData !== null ? (
                    <IconButton onClick={() => handleDeleteData(key)}>
                      <Delete />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
