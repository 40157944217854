import {
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

const ModalScore = ({
  openModal,
  onClose,
  courseId,
  scheduleId,
  userId,
  userName,
  refetch,
  detailData,
}) => {
  const dispatch = useDispatch();
  const { register, watch, setValue, reset } = useForm({
    defaultValues: {
      assignment: {
        name: "",
        score: 0,
        comment: null,
      },
    },
  });

  const [dataDemonstrationSkill, setDataDemonstrationSkills] = useState([]);

  const columns = ["", "Indikator", "Bobot Nilai", "Nilai"];

  const optionScore = [1, 2, 3, 4];

  setValue("assignment.name", userName);

  /**
   * Fetch Data
   */
  const submitAssignment = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.report.course.webinar_schedule(
          courseId
        )}/${scheduleId}/participant/${userId}/submit_assignment`,
        {
          score: parseInt(watch("assignment.score")),
          comment: watch("assignment.comment"),
        }
      );

      Swal.fire("Sukses", "Submit Nilai Berhasil", "success");

      handleClose();
      refetch(courseId, scheduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleClose = () => {
    reset();
    onClose();
    resetValueScore(detailData);
  };

  const handleChangeScore = (index, scoreUpdate) => {
    const dataScore = [...dataDemonstrationSkill];

    const percentageSkills = dataScore[index].percentage;
    dataScore[index].score = scoreUpdate;
    dataScore[index].finalScore = (percentageSkills / 100) * scoreUpdate;

    const average = dataScore.reduce((a, b) => b.finalScore + a, 0);

    const finalScore = ((average / 4) * 100).toFixed(0);

    setValue("assignment.score", +finalScore);

    setDataDemonstrationSkills(dataScore);
  };

  const resetValueScore = data => {
    const scoreData = data.demonstration_skills?.map(data => ({
      id: data.id,
      skills: data.skills,
      percentage: data.percentage,
      score: 0,
      finalScore: 0,
    }));

    setDataDemonstrationSkills(scoreData);
  };

  useEffect(() => {
    resetValueScore(detailData);
  }, [detailData]);

  return (
    <Dialog
      open={openModal}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="md"
    >
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Submit Nilai</h2>
        </Box>

        {!dataDemonstrationSkill?.length ? (
          <Grid container xs={12} className="mt-4">
            <Grid item sm={6} xs={12}>
              <FormGroup>
                <FormLabel>Nama Peserta</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="text"
                  disabled
                  {...register("assignment.name")}
                />
              </FormGroup>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormGroup>
                <FormLabel>Nilai</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="number"
                  {...register("assignment.score")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>Komentar</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="text"
                  {...register("assignment.comment")}
                />
              </FormGroup>
            </Grid>
          </Grid>
        ) : (
          <Grid container className="mt-4">
            <Grid item xs={12} sm={12}>
              <TableContainer component={Paper} width="100%">
                <Box component={Table} alignItems="center">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: themeColors.primary.main,
                      }}
                    >
                      {columns.map((item, key) => (
                        <TableCell
                          key={key}
                          style={{ fontWeight: 700 }}
                          align="center"
                          width={key === 0 ? "100px" : null}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataDemonstrationSkill.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell
                          component="td"
                          scope="row"
                          align="center"
                          style={{ wordWrap: "break-word" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="td"
                          align="center"
                          style={{ whiteSpace: "normal" }}
                        >
                          {item.skills}
                        </TableCell>
                        <TableCell
                          component="td"
                          align="center"
                          style={{ whiteSpace: "normal" }}
                        >
                          {item.percentage}%
                        </TableCell>
                        <TableCell
                          component="td"
                          align="center"
                          style={{ whiteSpace: "normal" }}
                        >
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                onChange={val =>
                                  handleChangeScore(index, val.target.value)
                                }
                                value={item.score}
                              >
                                {optionScore.map((val, key) => (
                                  <MenuItem key={key} value={val}>
                                    {val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      style={{
                        backgroundColor: "gray",
                      }}
                    >
                      <TableCell
                        component="td"
                        colSpan={3}
                        align="center"
                        style={{
                          whiteSpace: "normal",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Nilai Akhir
                      </TableCell>
                      <TableCell
                        component="td"
                        align="center"
                        style={{
                          whiteSpace: "normal",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {watch("assignment.score")}
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  <TableBody>
                    <TableRow>
                      <TableCell component="td" colSpan={8}>
                        <FormGroup>
                          <FormLabel>Komentar</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("assignment.comment")}
                          />
                        </FormGroup>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Box>
              </TableContainer>
            </Grid>
          </Grid>
        )}

        <Grid container className="mt-2">
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={submitAssignment}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default ModalScore;
