import React from "react";

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import ChipData from "components/Chip/ChipData";

export default function InstructorListCourseDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const history = useHistory();

  const columns = [
    "#",
    "Nama",
    "Tipe",
    "Kategori",
    "Tingkat",
    "Metode",
    "Status",
    "Opsi",
  ];
  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  component="td"
                  align="center"
                  style={{ whiteSpace: "normal" }}
                >
                  {course.name}
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.course_type.name} />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData
                    color="primary"
                    text={course.course_category.name}
                  />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.course_level.name} />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData
                    color="primary"
                    text={course.course_teach_method.name}
                  />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.status} />
                </TableCell>
                <TableCell component="td" align="center">
                  {course.course_teach_method.id === 2 && (
                    <Button
                      variant="contained"
                      size="xl"
                      color="default"
                      className="mr-1"
                      onClick={() =>
                        history.push(
                          `/admin-instructor/my-course/${course.id}/uji-keterampilan`
                        )
                      }
                    >
                      UK
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="xl"
                    color="default"
                    className="mr-1"
                    onClick={() =>
                      history.push(
                        `/admin-instructor/my-course/${course.id}/tugas-praktik-mandiri`
                      )
                    }
                  >
                    TPM
                  </Button>
                  {course.course_teach_method.id === 1 && (
                    <Button
                      variant="contained"
                      size="xl"
                      color="default"
                      className="mr-1"
                      onClick={() =>
                        history.push(
                          `/admin-instructor/my-course/${course.id}/webinar`
                        )
                      }
                    >
                      Jadwal
                    </Button>
                  )}
                  {course.course_teach_method.id === 4 && (
                    <Button
                      variant="contained"
                      size="xl"
                      color="default"
                      className="mr-1"
                      onClick={() =>
                        history.push(
                          `/admin-instructor/my-course/${course.id}/offline`
                        )
                      }
                    >
                      Jadwal
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    size="xl"
                    color="default"
                    onClick={() =>
                      history.push(
                        `/admin-instructor/my-course/${course.id}/detail`
                      )
                    }
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={8}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={8}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
