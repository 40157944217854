import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import moment from "moment";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import PromoCodeDataTable from "./PromoCodeDataTable";
import ReactQuill from "react-quill";

export default function ListPromoCodePage() {
  const dispatch = useDispatch();
  const { register, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      promo_code: {
        name: "",
        code: "",
        expired_at: moment(),
        limit: null,
        one_time_use: null,
        target_course: "ALL",
        target_platform: "ALL",
        type: "",
        value: null,
        show_on_list: "",
        description: "",
        selected_courses: [],
        selected_course_bundles: [],
      },
      generate_promo_code: {
        name: "",
        prefix: "",
        expired_at: moment(),
        count: null,
        one_time_use: null,
        target_course: "ALL",
        target_platform: "ALL",
        type: "",
        value: null,
        show_on_list: "",
        description: "",
        selected_courses: [],
        selected_course_bundles: [],
      },
      filter: {
        code: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [promoCodeData, setPromoCodeData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });
  const [optionCourseData, setOptionCourseData] = useState([]);
  const [optionBundleCourseData, setOptionBundleCourseData] = useState([]);

  const [typeModalPromoCode, setTypeModalPromoCode] = useState("CREATE");
  const [showModalPromoCode, setShowModalPromoCode] = useState(false);

  const [showModalGenerate, setShowModalGenerate] = useState(false);

  const typeOptions = ["PERCENTAGE", "NOMINAL"];
  const targetCourseOptions = [
    { label: "Semua Kelas & Bundle Kelas", value: "ALL" },
    { label: "Semua Kelas", value: "ALL_COURSE" },
    { label: "Kelas Prakerja", value: "PRAKERJA" },
    { label: "Kelas Umum", value: "GENERAL" },
    { label: "Semua Bundle Kelas", value: "ALL_COURSE_BUNDLE" },
    { label: "Pilih Kelas", value: "COURSE_SELECTED" },
    { label: "Pilih Bundle Kelas", value: "COURSE_BUNDLE_SELECTED" },
    { label: "Pilih Kelas & Bundle Kelas", value: "SELECTED" },
  ];
  const oneTimeUseOptions = [
    { label: "Tidak", value: 0 },
    { label: "Ya", value: 1 },
  ];
  const targetPlatformOptions = [
    { label: "Semua", value: "ALL" },
    { label: "Website", value: "WEB" },
    { label: "Mobile", value: "MOBILE" },
  ];

  const showOnListOptions = [
    { label: "Tidak", value: "0" },
    { label: "Ya", value: "1" },
  ];

  /**
   * Fetch Data
   */
  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionBundleCourse = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course_bundle.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionBundleCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { code } = watch("filter");

      const { data } = await http.get(`${api.v1.admin.promo_code.main}`, {
        params: { limit, page, code },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setPromoCodeData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(`${api.v1.admin.promo_code.main}/${id}`);

      setTypeModalPromoCode("UPDATE");
      setShowModalPromoCode(true);

      data.data.expired_at = moment(data.data.expired_at).format(
        "YYYY-MM-DDTHH:mm"
      );
      data.data.selected_courses = data.data.courses.map(item => ({
        label: item.name,
        value: item.id,
      }));
      delete data.data.courses;

      data.data.selected_course_bundles = data.data.course_bundles.map(
        item => ({
          label: item.name,
          value: item.id,
        })
      );
      delete data.data.course_bundles;

      setValue("promo_code", data.data);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { promo_code } = payload;
      let selected_courses = [];
      let selected_course_bundles = [];

      if (promo_code.target_course === "SELECTED") {
        selected_courses = promo_code.selected_courses.map(item => item.value);
        selected_course_bundles = promo_code.selected_course_bundles.map(
          item => item.value
        );
      }

      if (promo_code.target_course === "COURSE_SELECTED")
        selected_courses = promo_code.selected_courses.map(item => item.value);

      if (promo_code.target_course === "COURSE_BUNDLE_SELECTED")
        selected_course_bundles = promo_code.selected_course_bundles.map(
          item => item.value
        );

      promo_code.show_on_list = promo_code.show_on_list === "1" ? 1 : 0;
      promo_code.selected_course_bundles = selected_course_bundles;
      promo_code.selected_courses = selected_courses;
      promo_code.expired_at = moment(promo_code.expired_at);

      await http.post(api.v1.admin.promo_code.main, promo_code);

      setShowModalPromoCode(false);

      Swal.fire("Sukses", "Data Kode Promo berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { promo_code } = payload;

      let selected_courses = [];
      let selected_course_bundles = [];

      if (promo_code.target_course === "SELECTED") {
        selected_courses = promo_code.selected_courses.map(item => item.value);
        selected_course_bundles = promo_code.selected_course_bundles.map(
          item => item.value
        );
      }

      if (promo_code.target_course === "COURSE_SELECTED")
        selected_courses = promo_code.selected_courses.map(item => item.value);

      if (promo_code.target_course === "COURSE_BUNDLE_SELECTED")
        selected_course_bundles = promo_code.selected_course_bundles.map(
          item => item.value
        );

      promo_code.selected_course_bundles = selected_course_bundles;
      promo_code.selected_courses = selected_courses;
      promo_code.expired_at = moment(promo_code.expired_at);

      await http.put(
        `${api.v1.admin.promo_code.main}/${promo_code.id}`,
        promo_code
      );

      setShowModalPromoCode(false);

      Swal.fire("Sukses", "Data Kode Promo berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.promo_code.main}/${id}`);

      Swal.fire("Sukses", "Data Kode Promo berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const handleExport = async (id, code) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Data Kode Promo (${code}).xlsx`;

      const res = await http.get(
        `${api.v1.admin.promo_code.main}/${id}/export`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const setShowOnHomePage = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(`${api.v1.admin.promo_code.main}/${id}/show-on-homepage`);

      getList();

      Swal.fire(
        "Sukses",
        "Promo Code Berhasil Ditampilkan di Halaman Depan",
        "success"
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const resetShowOnHomePage = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(`${api.v1.admin.promo_code.main}/reset-show-on-homepage`);

      getList();

      Swal.fire(
        "Sukses",
        "Promo Code Berhasil Direset di Halaman Depan",
        "success"
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const generatePromoCode = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { generate_promo_code } = payload;

      let selected_courses = [];
      let selected_course_bundles = [];

      if (generate_promo_code.target_course === "SELECTED") {
        selected_courses = generate_promo_code.selected_courses.map(
          item => item.value
        );
        selected_course_bundles =
          generate_promo_code.selected_course_bundles.map(item => item.value);
      }

      if (generate_promo_code.target_course === "COURSE_SELECTED")
        selected_courses = generate_promo_code.selected_courses.map(
          item => item.value
        );

      if (generate_promo_code.target_course === "COURSE_BUNDLE_SELECTED")
        selected_course_bundles =
          generate_promo_code.selected_course_bundles.map(item => item.value);

      generate_promo_code.show_on_list =
        generate_promo_code.show_on_list === "1" ? 1 : 0;
      generate_promo_code.selected_course_bundles = selected_course_bundles;
      generate_promo_code.selected_courses = selected_courses;
      generate_promo_code.expired_at = moment(generate_promo_code.expired_at);

      const res = await http.post(
        `${api.v1.admin.promo_code.main}/generate`,
        generate_promo_code,
        { responseType: "blob" }
      );

      const exportDate = moment().format("D MMMM YYYY hh:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Promo Code.xlsx`;

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();

      Swal.fire("Sukses", "Kode Promo berhasil dibuat", "success");

      setShowModalGenerate(false);
      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalPromoCode("CREATE");
    setValue("promo_code", {
      type: typeOptions[0],
      show_on_list: showOnListOptions[0].value,
      target_course: targetCourseOptions[0].value,
      one_time_use: oneTimeUseOptions[0].value,
      target_platform: targetCourseOptions[0].value,
    });

    setShowModalPromoCode(true);
  };

  const handleGenerateOpenModal = async () => {
    setTypeModalPromoCode("CREATE");
    setValue("generate_promo_code", {
      type: typeOptions[0],
      show_on_list: showOnListOptions[0].value,
      target_course: targetCourseOptions[0].value,
      one_time_use: oneTimeUseOptions[0].value,
      target_platform: targetCourseOptions[0].value,
    });

    setShowModalGenerate(true);
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data kode promo",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleShowOnHomePage = id => {
    Swal.fire({
      icon: "question",
      title: "Apakah anda yakin?",
      text: "Anda akan menampilkan promo code dihalaman depan",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        setShowOnHomePage(id);
      }
    });
  };

  const handleResetShowOnHomePage = () => {
    Swal.fire({
      icon: "question",
      title: "Apakah anda yakin?",
      text: "Anda akan meriset promo code dihalaman depan",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        resetShowOnHomePage();
      }
    });
  };

  useEffect(() => {
    getList();
    getOptionCourses();
    getOptionBundleCourse();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Kode Promo</title>
      </Helmet>

      <DefaultHeader section="Data Kode Promo" />

      <Container maxWidth={false} component={Box} id="promo-code-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid
                  className="mb-4"
                  container
                  component={Box}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenModal()}
                    >
                      Tambah Data
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="ml-2"
                      onClick={() => handleGenerateOpenModal()}
                    >
                      Generate
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleResetShowOnHomePage()}
                    >
                      Atur Ulang Countdown
                    </Button>
                  </Grid>
                </Grid>

                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex align-items-center justify-content-end">
                      <OutlinedInput
                        type="text"
                        placeholder="Cari berdasarkan kode"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-3"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.code")}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                    <PromoCodeDataTable
                      loading={loadingData}
                      data={promoCodeData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleShowOnHomePage={handleShowOnHomePage}
                      handleExport={handleExport}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Promo Code */}
                <Dialog
                  open={showModalPromoCode}
                  onClose={() => setShowModalPromoCode(false)}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Kode Promo</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalPromoCode === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("promo_code.name")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Kode</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("promo_code.code")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Limit</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("promo_code.limit")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Tipe
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={
                                  watch("promo_code.type") || typeOptions[0]
                                }
                                {...register("promo_code.type")}
                              >
                                {typeOptions.map((val, key) => (
                                  <MenuItem key={key} value={val}>
                                    {val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Tampilkan di list
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={
                                  watch("promo_code.show_on_list") ||
                                  showOnListOptions[0].value
                                }
                                {...register("promo_code.show_on_list")}
                              >
                                {showOnListOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Nilai</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("promo_code.value")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Satu Kali Pakai
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={
                                  watch("promo_code.one_time_use") ||
                                  oneTimeUseOptions[0].value
                                }
                                {...register("promo_code.one_time_use")}
                              >
                                {oneTimeUseOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Tanggal Kadaluarsa</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("promo_code.expired_at")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-target-platform-label">
                                Target Platform
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-target-platform-label"
                                value={
                                  watch("promo_code.target_platform") ||
                                  oneTimeUseOptions[0].value
                                }
                                {...register("promo_code.target_platform")}
                              >
                                {targetPlatformOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Tipe Target Kelas
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("promo_code.target_course")}
                                {...register("promo_code.target_course")}
                              >
                                {targetCourseOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={8}>
                          <FormGroup>
                            <FormLabel>Deskripsi</FormLabel>
                            <ReactQuill
                              theme="snow"
                              onChange={val =>
                                setValue("promo_code.description", val)
                              }
                              value={watch("promo_code.description") || ""}
                            />
                          </FormGroup>
                        </Grid>

                        {(watch("promo_code.target_course") === "SELECTED" ||
                          watch("promo_code.target_course") ===
                            "COURSE_SELECTED") && (
                          <Grid
                            item
                            sm={12}
                            className={
                              watch("promo_code.target_course") ===
                                "COURSE_SELECTED" && "mb-4"
                            }
                          >
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Kelas yang dipilih
                                </FormLabel>
                                <ReactSelect
                                  isMulti
                                  options={optionCourseData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val =>
                                    setValue("promo_code.selected_courses", val)
                                  }
                                  value={watch("promo_code.selected_courses")}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>
                        )}

                        {(watch("promo_code.target_course") === "SELECTED" ||
                          watch("promo_code.target_course") ===
                            "COURSE_BUNDLE_SELECTED") && (
                          <Grid item sm={12} className="mb-4">
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Bundle Kelas yang dipilih
                                </FormLabel>
                                <ReactSelect
                                  isMulti
                                  options={optionBundleCourseData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val =>
                                    setValue(
                                      "promo_code.selected_course_bundles",
                                      val
                                    )
                                  }
                                  value={watch(
                                    "promo_code.selected_course_bundles"
                                  )}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>
                        )}

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalPromoCode === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Generate */}
                <Dialog
                  open={showModalGenerate}
                  onClose={() => setShowModalGenerate(false)}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Generate Kode Promo</h2>
                    </Box>

                    <form onSubmit={handleSubmit(generatePromoCode)}>
                      <Grid container className="py-4">
                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("generate_promo_code.name")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Prefix</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("generate_promo_code.prefix")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Jumlah Kode Promo</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("generate_promo_code.count")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Tipe
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={
                                  watch("generate_promo_code.type") ||
                                  typeOptions[0]
                                }
                                {...register("generate_promo_code.type")}
                              >
                                {typeOptions.map((val, key) => (
                                  <MenuItem key={key} value={val}>
                                    {val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Nilai</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("generate_promo_code.value")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormLabel>Tanggal Kadaluarsa</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("generate_promo_code.expired_at")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-target-platform-label">
                                Target Platform
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-target-platform-label"
                                value={
                                  watch(
                                    "generate_promo_code.target_platform"
                                  ) || oneTimeUseOptions[0].value
                                }
                                {...register(
                                  "generate_promo_code.target_platform"
                                )}
                              >
                                {targetPlatformOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={4}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Tipe Target Kelas
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch(
                                  "generate_promo_code.target_course"
                                )}
                                {...register(
                                  "generate_promo_code.target_course"
                                )}
                              >
                                {targetCourseOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12}>
                          <FormGroup>
                            <FormLabel>Deskripsi</FormLabel>
                            <ReactQuill
                              theme="snow"
                              onChange={val =>
                                setValue("generate_promo_code.description", val)
                              }
                              value={
                                watch("generate_promo_code.description") || ""
                              }
                            />
                          </FormGroup>
                        </Grid>

                        {(watch("generate_promo_code.target_course") ===
                          "SELECTED" ||
                          watch("generate_promo_code.target_course") ===
                            "COURSE_SELECTED") && (
                          <Grid
                            item
                            sm={12}
                            className={
                              watch("generate_promo_code.target_course") ===
                                "COURSE_SELECTED" && "mb-4"
                            }
                          >
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Kelas yang dipilih
                                </FormLabel>
                                <ReactSelect
                                  isMulti
                                  options={optionCourseData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val =>
                                    setValue(
                                      "generate_promo_code.selected_courses",
                                      val
                                    )
                                  }
                                  value={watch(
                                    "generate_promo_code.selected_courses"
                                  )}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>
                        )}

                        {(watch("generate_promo_code.target_course") ===
                          "SELECTED" ||
                          watch("generate_promo_code.target_course") ===
                            "COURSE_BUNDLE_SELECTED") && (
                          <Grid item sm={12} className="mb-4">
                            <FormGroup>
                              <FormControl variant="outlined" fullWidth>
                                <FormLabel id="option-status-promo-code-label">
                                  Bundle Kelas yang dipilih
                                </FormLabel>
                                <ReactSelect
                                  isMulti
                                  options={optionBundleCourseData}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={val =>
                                    setValue(
                                      "generate_promo_code.selected_course_bundles",
                                      val
                                    )
                                  }
                                  value={watch(
                                    "generate_promo_code.selected_course_bundles"
                                  )}
                                />
                              </FormControl>
                            </FormGroup>
                          </Grid>
                        )}

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Generate
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
