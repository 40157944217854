import React, { useState } from "react";

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExitToApp,
  Edit,
} from "@material-ui/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function UserReportDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleClickDirectLogin,
}) {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const columns = ["", "#", "Nama", "Email", "Nomor Telepon", "Action"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((user, index) => (
              <>
                <TableRow key={user?.id}>
                  <TableCell align="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen(prev => ({
                          ...prev,
                          [user.id]: !prev[user.id],
                        }))
                      }
                    >
                      {user.gender ? (
                        open[user.id] ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )
                      ) : null}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {pagination.current_page > 1
                      ? (pagination.current_page - 1) * pagination.limit +
                        (index + 1)
                      : index + 1}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {user.name}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {user.phone_number}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        history.push(`/report/user/${user.id}/detail`)
                      }
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      onClick={() => handleClickDirectLogin(user.email)}
                    >
                      <ExitToApp />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open[user.id]} timeout="auto" unmountOnExit>
                      <Grid container className="mb-2">
                        <Grid
                          item
                          xs={12}
                          md={12}
                          component={Box}
                          className="pl-4 pt-2"
                        >
                          <Typography variant="h3" gutterBottom component="div">
                            Detail Data
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem>
                              <ListItemText
                                primary="Jenis Kelamin"
                                secondary={
                                  user.gender === "M"
                                    ? "Laki Laki"
                                    : "Perempuan"
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                primary="Tanggal Lahir"
                                secondary={moment(user.birth).format(
                                  "D MMM YYYY"
                                )}
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <Grid>
                            <List dense disablePadding={true}>
                              <ListItem>
                                <ListItemText
                                  primary="Provinsi"
                                  secondary={user.province}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemText
                                  primary="Kota"
                                  secondary={user.city}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemText
                                  primary="Alamat"
                                  secondary={user.address}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
