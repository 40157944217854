import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Delete, Edit, ViewModule } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const submoduleType = {
  VIDEO: "Video",
  EBOOK: "Ebook",
  TEXT: "Teks",
  QUIZ: "Kuis",
  PRACTICE_ASSIGNMENT: "Practice Assignment",
  ASSIGNMENT: "Assignment",
  FACE_RECOGNITION: "Verifikasi Wajah",
  REFLECTIVE_JOURNAL: "Jurnal Reflektif",
};

const DroppableComponent = onDragEnd => props => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {provided => (
          <TableBody
            innerRef={provided.innerRef}
            {...provided.droppableProps}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableComponent = (id, index) => props => {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <TableRow
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

export default function SubModuleDataTable({
  loading,
  data,
  getDetailData,
  handleDeleteData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleChangeOrder,
}) {
  const { courseId, moduleId } = useParams();

  const history = useHistory();

  const columns = ["#", "Judul", "Tipe", "Opsi"];

  const onDragEnd = result => {
    handleChangeOrder(result);
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody component={DroppableComponent(onDragEnd)}>
          {!loading && data.length ? (
            <>
              {data.map((subModule, index) => (
                <TableRow
                  component={DraggableComponent(subModule.id.toString(), index)}
                  key={subModule.id.toString()}
                >
                  <TableCell component="td" scoped="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {subModule.title}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {submoduleType[subModule.type]}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {subModule.type === "QUIZ" && (
                      <IconButton
                        aria-label="Question Bank"
                        onClick={() =>
                          history.push(
                            `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModule.id}/bank-soal`
                          )
                        }
                      >
                        <ViewModule />
                      </IconButton>
                    )}
                    {subModule.type === "VIDEO" && (
                      <IconButton
                        aria-label="Video Quiz"
                        onClick={() =>
                          history.push(
                            `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModule.id}/video-quiz`
                          )
                        }
                      >
                        <ViewModule />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        getDetailData(courseId, moduleId, subModule.id)
                      }
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() =>
                        handleDeleteData(courseId, moduleId, subModule.id)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={4}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
