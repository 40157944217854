import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  Switch,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import OfflineScheduleDataTable from "./OfflineScheduleDataTable";

function ListOfflineSchedule() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      offline_schedule: {
        code: "",
        closed_at: moment().format("YYYY-MM-DDTHH:mm"),
        instructor_id: null,
        event_place_id: null,
        max_participant: 0,
        sync_participant: 0,
        notes: "",
      },
      schedule: {
        file_excel: null,
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [showModalImport, setShowModalImport] = useState(false);

  const [OfflineScheduleData, setOfflineScheduleData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [placeOption, setPlaceOption] = useState([]);
  const [instructorOption, setInstructorOption] = useState([]);

  const [syncParticipantToggle, setSyncParticipantToggle] = useState(false);

  const [typeOfflineSchedule, setTypeOfflineSchedule] = useState("CREATE");
  const [showOfflineSchedule, setShowOfflineSchedule] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (courseId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setOfflineScheduleData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getPlace = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.event_place.main}`, {
        params: { option: true },
      });

      const { records } = data.data;
      setPlaceOption(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${id}`
      );

      setValue("offline_schedule", {
        id,
        code: data.data.code,
        closed_at: moment(data.data.closed_at).format("YYYY-MM-DDTHH:mm"),
        notes: data.data.notes,
        sync_participant: data.data.sync_participant,
        event_place_id: data.data.event_place_id,
        instructor_id: data.data.instructor_id,
        max_participant: data.data.max_participant,
        // assignment_submit_url: data.data.assignment_submit_url,
        // assignment_result_url: data.data.assignment_result_url,
      });

      setSyncParticipantToggle(data.data.sync_participant ? true : false);
      setTypeOfflineSchedule("UPDATE");
      setShowOfflineSchedule(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const getInstructor = async course_id => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${course_id}`
      );

      setInstructorOption(data.data.instructors);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getFormatExcel = async courseId => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = "Format Import Offline Schedule.xlsx";

      const res = await http.get(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/template-import`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { offline_schedule } = payload;

      offline_schedule.sync_participant = syncParticipantToggle ? 1 : 0;
      offline_schedule.closed_at = moment(offline_schedule.closed_at);

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule`,
        offline_schedule
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Jadwal Offline berhasil di tambahkan",
        "success"
      );

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const importSchedule = async payload => {
    try {
      const { schedule } = payload;

      const formData = new FormData();

      formData.append("file_excel", schedule?.file_excel[0]);

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/import`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      handleCloseModalImport();

      Swal.fire("Sukses", "Import jadwal offline berhasil", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { offline_schedule } = payload;

      offline_schedule.sync_participant = syncParticipantToggle ? 1 : 0;
      offline_schedule.closed_at = moment(offline_schedule.closed_at);

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${offline_schedule.id}`,
        offline_schedule
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Jadwal berhasil diperbarui", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${id}`
      );

      Swal.fire("Sukses", "Data Jadwal berhasil dihapus", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId);
  };

  const handleCloseModal = () => {
    setShowOfflineSchedule(false);
    setSyncParticipantToggle(false);
    reset();
  };

  const handleCloseModalImport = () => {
    reset();
    setShowModalImport(false);
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Jadwal Offline",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList(courseId);
    getInstructor(courseId);
    getPlace();
  }, []);
  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Jadwal Offline</title>
      </Helmet>

      <DefaultHeader
        section="Data Jadwal Offline"
        subsection="Data Kelas"
        routeBack="/kelas"
      />

      <Container maxWidth={false} component={Box} id="course-offline-schedule">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setTypeOfflineSchedule("CREATE");
                      setShowOfflineSchedule(true);
                    }}
                  >
                    Tambah Data
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowModalImport(true);
                    }}
                    className="ml-2"
                  >
                    Import Jadwal
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      getFormatExcel(courseId);
                    }}
                    className="ml-2"
                  >
                    Unduh Format
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    {" "}
                    <OfflineScheduleDataTable
                      loading={loadingData}
                      data={OfflineScheduleData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Event Place */}
                <Dialog
                  open={showOfflineSchedule}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Data Tempat Acara</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeOfflineSchedule === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Kode</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("offline_schedule.code")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal Tutup Jadwal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("offline_schedule.closed_at")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Tempat</FormLabel>

                            <Autocomplete
                              id="combo-box-category"
                              options={placeOption}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onChange={(e, val) => {
                                setValue(
                                  "offline_schedule.event_place_id",
                                  val.id
                                );

                                setValue(
                                  "offline_schedule.max_participant",
                                  val.max_participant
                                );
                              }}
                              value={placeOption.find(
                                data =>
                                  data.id ===
                                  watch("offline_schedule.event_place_id")
                              )}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Maksimal Peserta</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("offline_schedule.max_participant")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Pengajar</FormLabel>

                            <Autocomplete
                              id="combo-box-category"
                              options={instructorOption}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onChange={(e, val) => {
                                setValue(
                                  "offline_schedule.instructor_id",
                                  val.id
                                );
                              }}
                              value={instructorOption.find(
                                data =>
                                  data.id ===
                                  watch("offline_schedule.instructor_id")
                              )}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Catatan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("offline_schedule.notes")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Link Pengumpulan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register(
                                "offline_schedule.assignment_submit_url"
                              )}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Link Spreadsheet</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register(
                                "offline_schedule.assignment_result_url"
                              )}
                            />
                          </FormGroup>
                        </Grid> */}

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel id="option-competence-aspect-label">
                              Sinkronisasi Peserta
                            </FormLabel>
                            <FormControl variant="outlined" fullWidth>
                              <Switch
                                color="primary"
                                checked={syncParticipantToggle}
                                onChange={e =>
                                  setSyncParticipantToggle(e.target.checked)
                                }
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeOfflineSchedule === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Import */}
                <Dialog
                  open={showModalImport}
                  onClose={() => handleCloseModalImport()}
                  fullWidth={true}
                  maxWidth="md"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Import Jadwal</h2>
                    </Box>
                    <form onSubmit={handleSubmit(importSchedule)}>
                      <Grid container className="pt-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>File Excel</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="file"
                              {...register("schedule.file_excel")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} className="mt-4">
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Import
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ListOfflineSchedule;
