import React, { useEffect, useState } from "react";

import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";

import http from "services/http";
import api from "services/api";
import componentStyles from "assets/theme/views/admin/dashboard.js";

import DashboardStatsHeader from "./DashboardStatsHeader";
import CardTopCourse from "./CardTopCourse";
import CardLatestTransaction from "./CardLatestTransaction";
import CardStatisticTransaction from "./CardStatisticTransaction";
import getParams from "helpers/getParams";
import CardUserDevice from "./CardUserDevice";

const useStyles = makeStyles(componentStyles);

export default function DashboardPage() {
  const [loading, setLoading] = useState({
    dashboard_data: false,
    statistic_data: false,
    user_device_data: false,
  });

  const [totalCourse, setTotalCourse] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalInstructor, setTotalInstructor] = useState(0);
  const [topCourses, setTopCourses] = useState([]);
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [period, setPeriod] = useState("DAY");
  const [userDevicesData, setUserDevicesData] = useState();

  const classes = useStyles();
  const query = getParams();

  /**
   * Fetch Data
   */
  const getDashboardData = async () => {
    try {
      setLoading(prev => ({ ...prev, dashboard_data: true }));

      const { data } = await http.get(api.v1.admin.dashboard.main);

      const {
        total_course,
        total_user,
        total_instructor,
        top_course_participant,
        current_transaction,
      } = data.data;

      setTotalCourse(total_course);
      setTotalUser(total_user);
      setTotalInstructor(total_instructor);

      setTopCourses(top_course_participant);
      setCurrentTransactions(current_transaction);
    } catch (error) {
      setLoading(prev => ({ ...prev, dashboard_data: false }));

      console.log(error);
    } finally {
      setLoading(prev => ({ ...prev, dashboard_data: false }));
    }
  };

  const getUserDevice = async () => {
    try {
      setLoading(prev => ({ ...prev, user_device_data: true }));

      const { data } = await http.get(
        `${api.v1.admin.dashboard.main}/user-device`
      );

      const { user_device } = data.data;

      // user device
      let userDevice = {
        labels: ["All", "Mobile", "Web"],
        datasets: [
          {
            data: [user_device.all, user_device.mobile, user_device.web],
            backgroundColor: [
              "rgb(23,43,77)",
              "rgb(252,211,77)",
              "rgb(251,99,64)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setUserDevicesData(userDevice);
    } catch (error) {
      setLoading(prev => ({ ...prev, user_device_data: false }));

      console.log(error);
    } finally {
      setLoading(prev => ({ ...prev, user_device_data: false }));
    }
  };

  const getStatisticData = async period => {
    try {
      setLoading(prev => ({ ...prev, statistic_data: true }));

      const { data } = await http.get(api.v1.admin.statistic.main, {
        params: { period },
      });

      let statistics = {
        labels: [],
        datasets: [{ label: "Transaksi", data: [] }],
      };
      const { data: transactions } = data;

      if (period === "DAY")
        transactions.map(item => {
          statistics.labels.push(`${item.hour}:00`);
          statistics.datasets[0].data.push(item.count);
        });

      if (period === "WEEK")
        for (let i = 0; i < 7; i++) {
          const date = moment()
            .subtract(i, "d")
            .format("YYYY-MM-DD")
            .toString();
          const dateLabels = moment()
            .subtract(i, "d")
            .format("D MMM YYYY")
            .toString();

          const data = transactions.find(item => item.date === date);

          statistics.datasets[0].data.push(data?.date ? data.count : 0);

          statistics.labels.push(dateLabels);
        }

      if (period === "MONTH")
        for (let i = 0; i < 30; i++) {
          const date = moment()
            .subtract(i, "d")
            .format("YYYY-MM-DD")
            .toString();
          const dateLabels = moment()
            .subtract(i, "d")
            .format("D MMM YYYY")
            .toString();

          const data = transactions.find(item => item.date === date);

          statistics.datasets[0].data.push(data?.date ? data.count : 0);

          statistics.labels.push(dateLabels);
        }

      setStatisticData(statistics);
    } catch (error) {
      setLoading(prev => ({ ...prev, statistic_data: false }));

      console.log(error);
    } finally {
      setLoading(prev => ({ ...prev, statistic_data: false }));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePeriode = val => {
    setTimeout(() => {
      setPeriod(val);
      const aboutPageNode = document.getElementById("statisticCard");

      aboutPageNode.scrollIntoView();
    }, 0);
  };

  useEffect(() => {
    getDashboardData();
    getUserDevice();
    if (query.get("periode")) setPeriod(query.get("periode"));
  }, []);

  useEffect(() => {
    getStatisticData(period);
  }, [period]);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Dashboard</title>
      </Helmet>

      <DashboardStatsHeader
        totalCourse={totalCourse}
        totalUser={totalUser}
        totalInstructor={totalInstructor}
        loading={loading.dashboard_data}
      />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading.dashboard_data ? (
              <CardTopCourse topCourses={topCourses} />
            ) : (
              <Skeleton
                variant="rect"
                height={419}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading.dashboard_data ? (
              <CardLatestTransaction transactions={currentTransactions} />
            ) : (
              <Skeleton
                variant="rect"
                height={374}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading.user_device_data ? (
              <CardUserDevice userDevices={userDevicesData} />
            ) : (
              <Skeleton
                variant="rect"
                height={374}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            xl={12}
            classes={{ root: classes.gridItemRoot }}
            id={"statisticCard"}
          >
            <CardStatisticTransaction
              statistics={statisticData}
              changePeriod={val => handleChangePeriode(val)}
              period={period}
              loading={loading.statistic_data}
              refresh={() => getStatisticData(period)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
