import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import OfflineSessionDataTable from "./OfflineSessionDataTable";

function ListOfflineSessionPage() {
  const dispatch = useDispatch();
  const { courseId, OfflineScheduleId } = useParams();

  const { register, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      offline_session: {
        date: moment().format("yyyy-MM-DD"),
        start_time: moment().format("HH:mm"),
        end_time: moment().format("HH:mm"),
        start_attendance: moment().format("YYYY-MM-DDTHH:mm"),
        end_attendance: moment().format("YYYY-MM-DDTHH:mm"),
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [offlineSessionData, setOfflineSessionData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeOfflineSession, setTypeOfflineSession] = useState("CREATE");
  const [showOfflineSession, setShowOfflineSession] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (courseId, OfflineScheduleId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${OfflineScheduleId}/offline-schedule-session`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setOfflineSessionData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${OfflineScheduleId}/offline-schedule-session/${id}`
      );

      setValue("offline_session", {
        id,
        date: moment(data.data.date).format("yyyy-MM-DD"),
        start_time: moment(data.data.start_time, "HH:mm:ss").format("HH:mm"),
        end_time: moment(data.data.end_time, "HH:mm:ss").format("HH:mm"),
        start_attendance: moment(data.data.start_attendance).format(
          "YYYY-MM-DDTHH:mm"
        ),
        end_attendance: moment(data.data.end_attendance).format(
          "YYYY-MM-DDTHH:mm"
        ),
      });

      setTypeOfflineSession("UPDATE");
      setShowOfflineSession(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { offline_session } = payload;

      offline_session.start_attendance = moment(
        offline_session.start_attendance
      );
      offline_session.end_attendance = moment(offline_session.end_attendance);

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${OfflineScheduleId}/offline-schedule-session`,
        offline_session
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Sesi Offline berhasil di tambahkan", "success");

      getList(courseId, OfflineScheduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { offline_session } = payload;

      offline_session.start_attendance = moment(
        offline_session.start_attendance
      );
      offline_session.end_attendance = moment(offline_session.end_attendance);

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${OfflineScheduleId}/offline-schedule-session/${offline_session.id}`,
        offline_session
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Sesi berhasil diperbarui", "success");

      getList(courseId, OfflineScheduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.main}/${courseId}/offline-schedule/${OfflineScheduleId}/offline-schedule-session/${id}`
      );

      Swal.fire("Sukses", "Data Sesi berhasil dihapus", "success");

      getList(courseId, OfflineScheduleId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) =>
    getList(courseId, OfflineScheduleId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId, OfflineScheduleId);
  };

  const handleCloseModal = () => {
    setShowOfflineSession(false);
    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Jadwal Sesi Offline",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList(courseId, OfflineScheduleId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Sesi Offline</title>
      </Helmet>

      <DefaultHeader
        section="Data Sesi Offline"
        subsection="Data Jadwal Offline"
        routeBack={`/kelas/${courseId}/jadwal-offline`}
      />

      <Container
        maxWidth={false}
        component={Box}
        id="course-offline-schedule-session"
      >
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setTypeOfflineSession("CREATE");
                      setShowOfflineSession(true);
                    }}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <OfflineSessionDataTable
                      loading={loadingData}
                      data={offlineSessionData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Crate & Update Offlien Session */}
                <Dialog
                  open={showOfflineSession}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Data Sesi Offline</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeOfflineSession === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("offline_session.date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Waktu Mulai</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="time"
                              {...register("offline_session.start_time")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Waktu Selesai</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="time"
                              {...register("offline_session.end_time")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Waktu Mulai Absen</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("offline_session.start_attendance")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Waktu Selesai Absen</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("offline_session.end_attendance")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeOfflineSession === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ListOfflineSessionPage;
