import { Box, Card, Container, Grid } from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import http from "services/http";
import api from "services/api";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import moment from "moment";
import NotificationEmailReportDataTable from "./NotificationEmailReportDataTable";

export default function NotificationEmailReportPage() {
  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const dispatch = useDispatch();

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(api.v1.admin.report.notification_email, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleExportNotification = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Data Notifikasi Email.xlsx`;

      const res = await http.get(
        `${api.v1.admin.report.notification_email}/${id}/export`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Notifikasi Email</title>
      </Helmet>

      <DefaultHeader
        section="Data Notifikasi Email"
        subsection="Laporan Notifikasi Email"
      />

      <Container
        maxWidth={false}
        component={Box}
        id="notification-whatsapp-blast"
      >
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <NotificationEmailReportDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={data}
                    pagination={pagination}
                    loading={loadingData}
                    handleExportNotification={handleExportNotification}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
