import StatusConstant from "constant/StatusConstant";

const formatStatusBlast = string => {
  switch (string) {
    case StatusConstant.STATUS.WAITING_PERIOD:
      return "Menunggu Jadwal";
    case StatusConstant.STATUS.IN_QUEUE:
      return "Dalam Antrian";
    case StatusConstant.STATUS.FINISHED:
      return "Selesai";
    default:
      return "-";
  }
};

export default formatStatusBlast;
