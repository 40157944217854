import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  OutlinedInput,
  useTheme,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";
import componentStyles from "assets/theme/views/admin/elements";
import componentStylesForm from "assets/theme/components/forms";
import componentStylesAvatar from "assets/theme/components/avatar";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesValidation from "assets/theme/views/admin/validation.js";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const useStyles = makeStyles(componentStyles);
const useStylesForm = makeStyles(componentStylesForm);
const useStylesAvatar = makeStyles(componentStylesAvatar);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesValidation = makeStyles(componentStylesValidation);

const CreateInstructorAssistancePage = () => {
  const [imageSrc, setImageSrc] = useState(null);

  const avatarRef = useRef(null);
  const imageRef = useRef(null);

  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...useStylesForm(),
    ...useStylesAvatar(),
    ...useStylesButtons(),
    ...useStylesValidation(),
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      avatar: "",
      description: "",
    },
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onEditorStateChange = editorState =>
    setValue("description", editorState);
  const descriptionEditor = watch("description");

  const registerAvatarField = register("avatar", {
    required: "Avatar tidak boleh kosong",
  });
  const onUploadAvatar = () => avatarRef.current.click();
  const onAvatarChange = e => {
    if (e.target.files) {
      setValue("avatar", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmit = async values => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("avatar", values.avatar[0]);
      formData.append("description", values.description);

      await http.post(api.v1.admin.instructor_assistance.main, formData);

      Swal.fire(
        "Sukses",
        "Data asisten instruktur berhasil di tambahkan",
        "success"
      );

      history.push("/asisten-instruktur");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(
    () => register("description", { required: "Deskripsi tidak boleh kosong" }),
    [register]
  );

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tambah Asisten Instruktur</title>
      </Helmet>
      <DefaultHeader
        section="Tambah Asisten Instruktur"
        subsection="Data Asisten Instruktur"
        routeBack="/asisten-instruktur"
      />
      <Container maxWidth={false} component={Box}>
        <Grid container component={Box} justifyContent="center">
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {/* Avatar */}
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        {errors.avatar && (
                          <FormHelperText
                            component={Box}
                            color={theme.palette.error.main + "!important"}
                            style={{ textAlign: "center" }}
                          >
                            {errors.avatar.message}
                          </FormHelperText>
                        )}
                        <Avatar
                          innerRef={imageRef}
                          src={imageSrc}
                          className={classes.avatarXl}
                        />
                        <Box
                          component={Button}
                          onClick={() => onUploadAvatar()}
                        >
                          Add photo
                        </Box>
                        <input
                          style={{ display: "none" }}
                          onChange={e => {
                            registerAvatarField.onChange(e);
                            onAvatarChange(e);
                          }}
                          accept="image/*"
                          type="file"
                          ref={avatarRef}
                        />
                      </Box>
                      <FormGroup>
                        <FormLabel>Nama</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          {...register("name", {
                            required: "Nama tidak Boleh Kosong",
                          })}
                        />
                        {errors.name && (
                          <FormHelperText
                            component={Box}
                            color={theme.palette.error.main + "!important"}
                          >
                            {errors.name.message}
                          </FormHelperText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          {...register("email")}
                        />
                        {errors.email && (
                          <FormHelperText
                            component={Box}
                            color={theme.palette.error.main + "!important"}
                          >
                            {errors.email.message}
                          </FormHelperText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Deskripsi</FormLabel>
                        <ReactQuill
                          value={descriptionEditor}
                          onChange={onEditorStateChange}
                        />
                        {errors.description && (
                          <FormHelperText
                            component={Box}
                            color={theme.palette.error.main + "!important"}
                          >
                            {errors.description.message}
                          </FormHelperText>
                        )}
                      </FormGroup>
                      <Grid container className="mt-5">
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Simpan
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateInstructorAssistancePage;
