import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, TabContext, TabList, TabPanel } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import api from "services/api";
import http from "services/http";
import InstructorOfflineParticipantDataTable from "./InstructorOfflineParticipantDataTable";
import InstructorOfflineSessionDataTable from "./InstructorOfflineSessionDataTable";
import { ImportExport, KeyboardArrowDown } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";

export default function InstructorDetailOfflinePage() {
  const dispatch = useDispatch();

  const { watch, setValue } = useForm({
    defaultValues: {
      format: {
        type: "xlsx",
      },
    },
  });

  const formatType = [
    {
      label: "XLSX",
      value: "xlsx",
    },
    {
      label: "CSV",
      value: "csv",
    },
  ];

  const { courseId, scheduleId } = useParams();

  const [valueTab, setValueTab] = useState("1");

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [offlineDetailData, setOfflineDetailData] = useState([]);

  /**
   * Fetch Data
   */
  const getListDetail = async (courseId, scheduleId) => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        `${api.v1.instructor.course.main}/${courseId}/offline-schedule/${scheduleId}`,
        {}
      );

      setError(null);

      setOfflineDetailData(data.data);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const exportData = async (courseId, scheduleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const format = watch("format.type");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Schedule Offline Course.${format}`;

      const params = {
        type: format,
      };

      const res = await http.get(
        `${api.v1.instructor.course.main}/${courseId}/offline-schedule/${scheduleId}/export`,
        {
          params,
          responseType: "blob",
        }
      );

      const type = `text/${format}`;
      link.href = URL.createObjectURL(new Blob([res.data], { type: type }));
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          alert("Tidak ada data");
        }

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getListDetail(courseId, scheduleId);
  }, []);

  /**
   * Handle Data
   */
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Offline</title>
      </Helmet>

      <DefaultHeader
        section="Detail Offline"
        subsection="Data Offline"
        routeBack={`/admin-instructor/my-course/${courseId}/offline`}
      />

      <Container maxWidth={false} component={Box} id="course-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                {!loadingData && (
                  <>
                    <Card>
                      <Grid className="p-3 d-flex justify-content-end">
                        <Grid sm={12}>
                          <Grid className="d-flex align-items-center justify-content-end">
                            <Grid sm={3} className="mr-2">
                              <Autocomplete
                                id="combo-box-category"
                                options={formatType}
                                getOptionLabel={option => option.label}
                                closeIcon={null}
                                onInputChange={(e, val) => {
                                  const { value } = formatType.filter(
                                    data => data.label === val
                                  )[0];

                                  setValue("format.type", value);
                                }}
                                value={
                                  formatType.filter(
                                    data => data.value === watch("format.type")
                                  )[0]
                                }
                                popupIcon={
                                  <Box
                                    component={KeyboardArrowDown}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                  />
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Tipe"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            <Grid className="mr-2">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => exportData(courseId, scheduleId)}
                              >
                                <ImportExport />
                                Export Data
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <CardContent>
                        <Typography variant="h2" className="mb-3">
                          Detail Jadwal Offline
                        </Typography>

                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <Typography variant="h4" className="mb-0">
                                Link Tugas Pengumpulan
                              </Typography>
                              {offlineDetailData?.assignment_submit_url ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    offlineDetailData?.assignment_submit_url
                                  }
                                >
                                  Lihat Tugas Pengumpulan
                                </a>
                              ) : (
                                <p>-</p>
                              )}
                            </Box>

                            <Box className="mt-3">
                              <Typography variant="h4" className="mb-0">
                                Link Hasil Penilaian
                              </Typography>
                              {offlineDetailData?.assignment_result_url ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    offlineDetailData?.assignment_result_url
                                  }
                                >
                                  Lihat Hasil Penilaian
                                </a>
                              ) : (
                                <p>-</p>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Box>
                              <Typography variant="h4" className="mb-0">
                                Tutup Pendaftaran Pada Pukul
                              </Typography>
                              <p>
                                {moment(offlineDetailData?.closed_at).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </p>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container className="mt-3">
                          <Grid item xs={12}>
                            <Box>
                              <TabContext value={valueTab}>
                                <Box>
                                  <TabList
                                    textColor="secondary"
                                    onChange={handleChangeTab}
                                    aria-label="tabs"
                                  >
                                    <Tab label="Daftar Sesi" value="1" />
                                    <Tab label="Daftar Peserta" value="2" />
                                  </TabList>
                                </Box>
                                <TabPanel value="1" className="mx-2">
                                  <InstructorOfflineSessionDataTable />
                                </TabPanel>
                                <TabPanel value="2" className="mx-2">
                                  <InstructorOfflineParticipantDataTable
                                    detailData={offlineDetailData}
                                  />
                                </TabPanel>
                              </TabContext>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
