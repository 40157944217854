import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { Delete, Edit } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import formatRupiah from "helpers/formatRupiah";

export default function CourseBundleDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleDeleteData,
}) {
  const columns = ["#", "Nama", "Harga", "Diskon", "Opsi"];
  const history = useHistory();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minwidth: 650 }}>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((courseBundle, index) => (
              <TableRow key={courseBundle.id}>
                <TableCell align="center">
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>
                <TableCell align="center">{courseBundle.name}</TableCell>
                <TableCell align="center">
                  Rp.{formatRupiah(courseBundle.total_price)}
                </TableCell>
                <TableCell align="center">
                  {parseInt(courseBundle.discount_price)
                    ? `Rp.${formatRupiah(courseBundle.discount_price)}`
                    : "-"}
                </TableCell>
                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="Edit"
                    onClick={() =>
                      history.push(`/bundle-kelas/${courseBundle.id}/detail`)
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDeleteData(courseBundle.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
