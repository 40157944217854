import { Box, Card, Container, Grid, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import SelectPermission from "./SelectPermission";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import localStorage from "helpers/localStorage";

const ListSetPermissionPage = () => {
  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      permissions: [],
    },
  });

  const dispatch = useDispatch();

  const permission = JSON.parse(localStorage.get("permissions"))?.map(
    permission => permission.code
  );

  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async () => {
    try {
      setLoadingData(true);

      const { data } = await http(api.v1.admin.role.permission);
      const { roles, permissions } = data.data;

      // set master data
      setRoles(roles);
      setPermissions(permissions);

      // set assignable data
      const assignablePermissions = permissions.flatMap(permission =>
        permission?.children?.map(sub_permission => {
          return {
            id: sub_permission?.id,
            roles: sub_permission?.roles,
          };
        })
      );

      setValue("permissions", assignablePermissions);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleSelectRole = (subPermissionId, roleId, checked) => {
    const permissionIndex = watch("permissions").findIndex(
      permission => permission.id === subPermissionId
    );

    const permissionItem = watch(`permissions[${permissionIndex}]`);

    const arrRole = permissionItem?.roles;

    if (checked) {
      !arrRole?.includes(roleId) && arrRole?.push(roleId);
    } else {
      const removeItemIndex = arrRole?.indexOf(roleId);

      removeItemIndex !== -1 && arrRole.splice(removeItemIndex, 1);
    }

    setValue(`permissions[${permissionIndex}].roles`, arrRole);
  };

  const onSubmit = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const permissions = watch("permissions");

      await http.post(api.v1.admin.role.permission, { permissions });

      Swal.fire("Sukses", "Atur Akses berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => getList(), []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Akses Permission</title>
      </Helmet>

      <DefaultHeader section="Atur Akses" subsection="Kelola Akses" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid>
                  {permission?.includes("152-1") && (
                    <Card>
                      <SelectPermission
                        loading={loadingData}
                        permissions={permissions}
                        roles={roles}
                        userPermissions={watch("permissions")}
                        handleSelectRole={handleSelectRole}
                      />
                    </Card>
                  )}

                  {permission?.includes("152-4") && (
                    <Grid xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        style={{ marginBottom: "24px" }}
                      >
                        Simpan
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ListSetPermissionPage;
