import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";
import InstructorDataTable from "./InstructorDataTable";

const ListInstructorPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, watch } = useForm();

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [instructors, setInstructors] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { name } = watch("filter");

      const { data } = await http.get(api.v1.admin.instructors.main, {
        params: { limit, page, name },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setInstructors(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.instructors.main}/${id}`);

      getList();

      Swal.fire("Sukses", "Data instruktur berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleDeletData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data instruktur",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) await deleteData(id);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Instruktur Kelas</title>
      </Helmet>

      <DefaultHeader section="Data Instruktur" subsection="Kelola Kelas" />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/instructor/create")}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                {/* List Instruktur */}
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      {/* Search by Name */}
                      <Grid sm={4}>
                        <Grid className="d-flex align-items-center justify-content-end">
                          <OutlinedInput
                            type="text"
                            placeholder="Cari berdasarkan Nama"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                            className="mr-3"
                            onKeyPress={e => e.key === "Enter" && getList()}
                            {...register("filter.name")}
                          />

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => getList()}
                          >
                            <Box
                              component={Search}
                              width="1.25rem!important"
                              height="1.25rem!important"
                              position="relative"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <InstructorDataTable
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      data={instructors}
                      pagination={pagination}
                      handleDeleteData={handleDeletData}
                      loading={loadingData}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ListInstructorPage;
