import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";

import http from "services/http";
import api from "services/api";

export default function PushNotificationEmailPage() {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      push_notification: {
        topic_name: "",
        email_excel: null,
        title: "",
        description: "",
        image_url: "",
        redirect_url: "",
      },
    },
  });

  const dispatch = useDispatch();

  const pushNotification = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { push_notification } = payload;

      const formData = new FormData();

      formData.append("topic_name", push_notification.topic_name);
      formData.append("email_user", push_notification.email_excel[0]);
      formData.append("title", push_notification.title);
      formData.append("description", push_notification.description);
      formData.append("image_url", push_notification.image_url);
      formData.append("redirect_url", push_notification.redirect_url);

      await http.post(api.v1.admin.push_notification_email.main, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      Swal.fire("Sukses", "Notif Berhasil Dikirim", "success");

      reset();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Notifikasi Mobile</title>
      </Helmet>

      <DefaultHeader subsection="Notifikasi Mobile" section="Email" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(pushNotification)}>
                      <Grid container>
                        {/* Topic Name */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="topic-name-label">Topik</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                {...register("push_notification.topic_name")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Email Excel */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormLabel>File Excel</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="file"
                              {...register("push_notification.email_excel")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* Judul */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="title-label">Judul</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                {...register("push_notification.title")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Link Gambar */}
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="url-label">Link Gambar</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="url"
                                {...register("push_notification.image_url")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Url */}
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="url-label">Redirect Url</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="url"
                                {...register("push_notification.redirect_url")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* Deskripsi */}
                        <Grid item xs={12} md={12}>
                          <FormGroup>
                            <FormControl vairant="outline" fullWidth>
                              <FormLabel id="description-label">
                                Deskripsi
                              </FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="text"
                                multiline={true}
                                {...register("push_notification.description")}
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Kirim
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
