import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import UserReminderDataTable from "./UserReminderDataTable";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import http from "services/http";
import api from "services/api";
import moment from "moment";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";

const currentYear = moment().year();

const rangeOption = [
  { label: "0-7", value: "0-7" },
  { label: "8-14", value: "8-14" },
  { label: "15-21", value: "15-21" },
  { label: "22-28", value: "22-28" },
  { label: "29-45", value: "29-45" },
  { label: "46-60", value: "46-60" },
  { label: ">60", value: ">61" },
];

const statusOption = [
  { label: "Not Yet", value: "NOT_YET" },
  { label: "Pending", value: "PENDING" },
  { label: "Delivered", value: "DELIVERED" },
  { label: "Failed", value: "FAILED" },
];
const statusFilterOption = [
  { label: "All", value: null },
  { label: "Not Yet", value: "NOT_YET" },
  { label: "Pending", value: "PENDING" },
  { label: "Delivered", value: "DELIVERED" },
  { label: "Failed", value: "FAILED" },
];

const yearOption = Array.from({ length: 4 }, (_, index) => ({
  label: (currentYear - index).toString(),
  value: (currentYear - index).toString(),
}));

export default function ListUserReminderPage() {
  const dispatch = useDispatch();
  const { watch, setValue, register, handleSubmit } = useForm({
    defaultValues: {
      direct_: {
        email: "",
        password: "",
      },
      filter: {
        search_by: "",
        search: "",
        year: moment().format("YYYY"),
        range: "29-45",
        email: null,
        status: null,
      },
      reminder: {
        status: "NOT_YET",
        note: null,
        id: null,
      },
    },
  });

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [userData, setUserData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { year, range, status, email } = watch("filter");

      const { data } = await http.get(
        api.v1.admin.learning_progress.user_reminder,
        {
          params: { limit, page, year, range, status, email },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setUserData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { reminder } = payload;

      await http.put(
        `${api.v1.admin.learning_progress.user_reminder}/${reminder.id}`,
        reminder
      );

      setShowModal(false);

      getList();

      Swal.fire("Sukses", "Data Reminder berhasil di perbarui", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setValue("reminder.note", null);
    setValue("reminder.status", "NOT_YET");
    setValue("reminder.id", null);
  };

  const handleOpenModal = (note = null, status = "NOT_YET", id) => {
    setShowModal(true);
    setValue("reminder.note", note);
    setValue("reminder.status", status);
    setValue("reminder.id", id);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | User Reminder</title>
      </Helmet>

      <DefaultHeader section="User Reminder" />

      <Container maxWidth={false} component={Box} id="user-reminder">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    <Grid item xs={8} sm={8} className="d-flex">
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={rangeOption}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = rangeOption.filter(
                              data => data.label === val
                            )[0];

                            if (value !== watch("filter.range")) {
                              setValue("filter.range", value);
                              getList();
                            }
                          }}
                          value={
                            rangeOption.filter(
                              data => data.value === watch("filter.range")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Range"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={yearOption}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = yearOption.filter(
                              data => data.label === val
                            )[0];

                            if (value !== watch("filter.year")) {
                              setValue("filter.year", value);
                              getList();
                            }
                          }}
                          value={
                            yearOption.filter(
                              data => data.value === watch("filter.year")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Tahun"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={statusFilterOption}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = statusFilterOption.filter(
                              data => data.label === val
                            )[0];

                            if (value !== watch("filter.status")) {
                              setValue("filter.status", value);
                              getList();
                            }
                          }}
                          value={
                            statusFilterOption.filter(
                              data => data.value === watch("filter.status")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    {/* Search by Email */}
                    <Grid sm={4}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Email"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.email")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <UserReminderDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={userData}
                    pagination={pagination}
                    loading={loadingData}
                    handleOpenModal={handleOpenModal}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Modal  */}
      <Dialog
        open={showModal}
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseModal}
      >
        <Container className="py-4">
          <Box textAlign="Center">
            <h2>Data Reminder</h2>
          </Box>

          <form onSubmit={handleSubmit(putUpdateData)}>
            <Grid container className="py-4 ">
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Catatan</FormLabel>
                  <OutlinedInput
                    fullWidth
                    type="text"
                    {...register("reminder.note")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel id="option-target-platform-label">
                      Status
                    </FormLabel>
                    <Select
                      IconComponent={KeyboardArrowDown}
                      labelId="option-target-platform-label"
                      value={watch("reminder.status") || statusOption[0].value}
                      {...register("reminder.status")}
                    >
                      {statusOption.map((val, key) => (
                        <MenuItem key={key} value={val.value}>
                          {val.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Simpan
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    </>
  );
}
