import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoginPage from "pages/Login/LoginPage";
import AdminLayouts from "layouts/Admin";

export default function Routers() {
  return (
    <Router>
      <Switch>
        <Route path="/login" render={props => <LoginPage {...props} />} />
        <Route path="/" render={props => <AdminLayouts {...props} />} />
        <Route
          path="/admin-instructor"
          render={props => <AdminLayouts {...props} />}
        />

        {/* Page Not Found */}
        <Redirect from="*" to="/login" />
      </Switch>
    </Router>
  );
}
