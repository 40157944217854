import React, { useState } from "react";

import { Box, Button, Card, Grid, Snackbar } from "@material-ui/core";
import { Add, Refresh } from "@material-ui/icons";
import { baseActions } from "redux/actions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import http from "services/http";
import api from "services/api";
import { Alert } from "@material-ui/lab";

import CreateUserDataTable from "./CreateUserDataTable";

export default function CreateUserTab() {
  const dispatch = useDispatch();

  const initUser = {
    name: "",
    email: "",
    phone_number: "",
    password: "",
  };
  const [users, setUsers] = useState([initUser]);
  const [successCopy, setSuccessCopy] = useState(false);

  const CreateAccount = async index => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));
      const arrUser = JSON.parse(JSON.stringify(users));
      const user = arrUser[index];

      const res = await http.post(`${api.v1.admin.sync.user.create}`, user);

      const { password } = res.data.data;

      arrUser[index]["password"] = password;

      setUsers(arrUser);

      Swal.fire("Sukses", "Berhasil Membuat Akun", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */

  const handleChangeData = (e, i) => {
    const name = e?.target?.name || "";
    const value = e?.target?.value || "";
    const arrUser = JSON.parse(JSON.stringify(users));

    arrUser[i][name] = value;

    setUsers(arrUser);
  };

  const handleAddData = () => {
    const arrUser = JSON.parse(JSON.stringify(users));

    arrUser.push(initUser);

    setUsers(arrUser);
  };

  const handleClearData = () => {
    setUsers([initUser]);
  };

  return (
    <Card>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <CreateUserDataTable
                data={users}
                handleChangeData={handleChangeData}
                CreateAccount={CreateAccount}
                setSuccessCopy={setSuccessCopy}
              />
              <Grid className="p-3 d-flex align-items-center justify-content-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddData()}
                  className="ml-2"
                >
                  <Box
                    component={Add}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    position="relative"
                  />
                </Button>
                <Button
                  variant="contained"
                  color=""
                  onClick={() => handleClearData()}
                  className="ml-2"
                >
                  <Box
                    component={Refresh}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    position="relative"
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={successCopy}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSuccessCopy(false)}
      >
        <Alert
          onClose={() => setSuccessCopy(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Berhasil disalin!
        </Alert>
      </Snackbar>
    </Card>
  );
}
