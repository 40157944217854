import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import http from "services/http";
import api from "services/api";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import ReactSelect from "react-select";

import { useForm } from "react-hook-form";
import CourseBonusDataTable from "./CourseBonusDataTable";
import { KeyboardArrowDown } from "@material-ui/icons";

const typeOption = [
  {
    label: "Kelas",
    value: "COURSE",
  },
];

export default function ListCourseBonusPage() {
  const dispatch = useDispatch();

  const { register, setValue, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      bonus: {
        name: "",
        type: typeOption[0].value,
        course: { value: "", label: "" },
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [courseAssessor, setCourseAssessor] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [courseData, setCourseData] = useState([]);

  const [typeModalCourseBonus, setTypeModalCourseBonus] = useState("CREATE");
  const [showModalCourseBonus, setShowModalCourseBonus] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(api.v1.admin.course.bonus, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseAssessor(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getCourse = async () => {
    try {
      setLoadingData(true);

      const getCourses = await http.get(api.v1.admin.course.main, {
        params: { option: "true" },
      });

      setError(null);

      const { records: courses } = getCourses.data.data;

      const options = courses.map(course => ({
        value: course.id,
        label: `${course.name} ${course.code}`,
      }));

      setCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(`${api.v1.admin.course.bonus}/${id}`);

      setValue("bonus", {
        id: data.data.id,
        name: data.data.name,
        type: data.data.type,
        course: courseData.find(d => d.value === data.data.course_id),
      });

      setTypeModalCourseBonus("UPDATE");
      setShowModalCourseBonus(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { bonus } = payload;

      await http.post(api.v1.admin.course.bonus, {
        name: bonus.name,
        type: bonus.type,
        course_id: bonus.course.value,
      });

      handleCloseModal();

      Swal.fire("Sukses", "Asesor berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { bonus } = payload;

      await http.put(`${api.v1.admin.course.bonus}/${bonus.id}`, {
        name: bonus.name,
        type: bonus.type,
        course_id: bonus.course.value,
      });

      handleCloseModal();

      Swal.fire("Sukses", "Asesor berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course.bonus}/${id}`);

      Swal.fire("Sukses", "Asesor berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Asesor",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleOpenModal = async () => {
    setTypeModalCourseBonus("CREATE");
    setShowModalCourseBonus(true);
  };

  const handleCloseModal = () => {
    setShowModalCourseBonus(false);
    reset();
  };

  useEffect(() => {
    getList();
    getCourse();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Bonus Kelas</title>
      </Helmet>

      <DefaultHeader section="Bonus Kelas" />

      <Container maxWidth={false} component={Box} id="course-assessor">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <CourseBonusDataTable
                      loading={loadingData}
                      data={courseAssessor}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Course Assessor */}
                <Dialog
                  open={showModalCourseBonus}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Bonus Kelas</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalCourseBonus === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        {/* name */}
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("bonus.name")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Tipe
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("bonus.type")}
                                {...register("bonus.type")}
                              >
                                {typeOption.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12}>
                          <FormGroup>
                            <FormLabel>Pilih Kelas</FormLabel>

                            <ReactSelect
                              isMulti={false}
                              options={courseData}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("bonus.course", val);
                              }}
                              value={watch("bonus.course")}
                            />
                          </FormGroup>
                        </Grid>

                        {/* button */}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalCourseBonus === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
