import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  SnackbarContent,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import PreviewQuestionAnswer from "../PreviewQuestionAnswer";
import ReactQuill from "react-quill";

const snackbarStyles = makeStyles(snackbarTheme);

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["link", "image", "video", "formula"], // add's image support
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"],
];

export default function CreateQuestionBankPage() {
  const { courseId, moduleId, subModuleId } = useParams();

  const styles = { ...snackbarStyles() };
  const { watch, setValue } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState();

  const [quiz, setQuiz] = useState([]);
  const [edit, setEdit] = useState(null);

  /**
   * Fetch Data
   */
  const postCreateQuestionBank = async (courseId, moduleId, subModuleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = { quiz };

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/question-bank`,
        payload
      );

      Swal.fire("Sukses", "Data Bank Soal berhasil di tambahkan", "success");

      history.push(
        `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/bank-soal`
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleCheckCorrectAnswer = index => {
    for (let x = 0; x < 4; x++)
      setValue(`answers[${x}].correct`, x === index ? 1 : 0);
  };

  const handleAddQuestionAnswer = () => {
    const question = watch("question");
    const answers = watch("answers");
    let correct_check = false;
    let error_check = false;

    setError(null);

    if (!question) {
      setError("Pertanyaan tidak boleh kosong");
      error_check = true;
    }

    answers.forEach(item => {
      const { answer, correct } = item;

      if (correct) correct_check = true;

      if (!answer) {
        setError("Jawaban tidak boleh kosong");
        error_check = true;
      }
    });

    if (!correct_check) setError("Tandai salah satu jawaban benar");

    if (!error_check && correct_check) {
      setQuiz(prevQuiz => [...prevQuiz, { question, answers }]);

      setValue("question", "");
      setValue("answers", []);
    }
  };

  const handleEditQuestionAnswer = key => {
    let quizzes = quiz[key];

    setEdit(key);
    setValue("question", quizzes.question);
    setValue("answers", quizzes.answers);

    document.documentElement.scrollTop = 0;
  };

  const handleUpdateQuestionAnswer = () => {
    let quizzes = [...quiz];
    const question = watch("question");
    const answers = watch("answers");
    let correct_check = false;
    let error_check = false;

    setError(null);

    if (!question) {
      setError("Pertanyaan tidak boleh kosong");
      error_check = true;
    }

    answers.forEach(item => {
      const { answer, correct } = item;

      if (correct) correct_check = true;

      if (!answer) {
        setError("Jawaban tidak boleh kosong");
        error_check = true;
      }
    });

    if (!correct_check) setError("Tandai salah satu jawaban benar");

    if (!error_check && correct_check) {
      quizzes[edit] = {
        question,
        answers,
      };

      setQuiz(quizzes);
      setEdit(null);
      setValue("question", "");
      setValue("answers", []);
    }
  };

  const handleDeleteQuestionAnswer = key => {
    let quizzes = quiz;

    quizzes = quizzes.filter(item => item !== quiz[key]);

    setQuiz(quizzes);
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tambah Bank Soal</title>
      </Helmet>

      <DefaultHeader
        section="Tambah Data"
        subsection="Data Bank Soal"
        routeBack={`/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/bank-soal`}
      />

      <Container
        maxWidth={false}
        component={Box}
        id="course-create-question-bank-page"
      >
        <Grid container>
          <Grid item xs={12}>
            <Card className="p-4">
              {error && (
                <Grid container>
                  <Grid item xs={12} className="mb-2">
                    <SnackbarContent
                      classes={{ root: styles.errorSnackbar }}
                      action={
                        <Box
                          component={IconButton}
                          padding="0!important"
                          onClick={() => setError(null)}
                        >
                          <Box
                            component="span"
                            color={theme.palette.white.main}
                          >
                            ×
                          </Box>
                        </Box>
                      }
                      message={<Box>{error}</Box>}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <h2 className="text-muted">Pertanyaan</h2>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <ReactQuill
                      theme="snow"
                      value={watch("question") || ""}
                      onChange={val => setValue("question", val)}
                      modules={{
                        toolbar: toolbarOptions,
                      }}
                      className="question-field"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <h2 className="text-muted">Jawaban</h2>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <Box className="d-flex">
                      <Checkbox
                        color="primary"
                        onClick={() => handleCheckCorrectAnswer(0)}
                        checked={watch("answers[0].correct") ? true : false}
                      />

                      <ReactQuill
                        theme="snow"
                        value={watch("answers[0].answer") || ""}
                        onChange={val => setValue("answers[0].answer", val)}
                        placeholder="Opsi 1"
                        modules={{
                          toolbar: toolbarOptions,
                        }}
                        className="question-field p-4"
                      />
                    </Box>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} className="mt-4">
                  <FormGroup>
                    <Box className="d-flex">
                      <Checkbox
                        color="primary"
                        onClick={() => handleCheckCorrectAnswer(1)}
                        checked={watch("answers[1].correct") ? true : false}
                      />

                      <ReactQuill
                        theme="snow"
                        value={watch("answers[1].answer") || ""}
                        onChange={val => setValue("answers[1].answer", val)}
                        placeholder="Opsi 2"
                        modules={{
                          toolbar: toolbarOptions,
                        }}
                        className="question-field p-4"
                      />
                    </Box>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} className="mt-4">
                  <FormGroup>
                    <Box className="d-flex">
                      <Checkbox
                        color="primary"
                        onClick={() => handleCheckCorrectAnswer(2)}
                        checked={watch("answers[2].correct") ? true : false}
                      />

                      <ReactQuill
                        theme="snow"
                        value={watch("answers[2].answer") || ""}
                        onChange={val => setValue("answers[2].answer", val)}
                        placeholder="Opsi 3"
                        modules={{
                          toolbar: toolbarOptions,
                        }}
                        className="question-field p-4"
                      />
                    </Box>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} className="mt-4">
                  <FormGroup>
                    <Box className="d-flex">
                      <Checkbox
                        color="primary"
                        onClick={() => handleCheckCorrectAnswer(3)}
                        checked={watch("answers[3].correct") ? true : false}
                      />

                      <ReactQuill
                        theme="snow"
                        value={watch("answers[3].answer") || ""}
                        onChange={val => setValue("answers[3].answer", val)}
                        placeholder="Opsi 4"
                        modules={{
                          toolbar: toolbarOptions,
                        }}
                        className="question-field p-4"
                      />
                    </Box>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} className="mt-4 pt-4">
                  <FormGroup>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (edit === null) {
                          handleAddQuestionAnswer();
                        } else {
                          handleUpdateQuestionAnswer();
                        }
                      }}
                    >
                      {edit === null ? "Tambah Soal" : "Ubah Soal"}
                    </Button>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <Button
                      variant="contained"
                      className="btn btn-success"
                      onClick={() =>
                        postCreateQuestionBank(courseId, moduleId, subModuleId)
                      }
                    >
                      Simpan
                    </Button>
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>

            <PreviewQuestionAnswer
              data={quiz}
              handleEditQuestionAnswer={handleEditQuestionAnswer}
              handleDeleteQuestionAnswer={handleDeleteQuestionAnswer}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
