import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { ImportExport, KeyboardArrowDown, Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { baseActions } from "redux/actions";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import http from "services/http";
import api from "services/api";
import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import UserReportDataTable from "./UserReportDataTable";
import ModalExportUser from "./ModalExportUser";
import { Autocomplete } from "@material-ui/lab";

export default function UserReportPage() {
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      direct_login: {
        email: "",
        password: "",
      },
      filter: {
        search_by: "",
        search: "",
      },
    },
  });

  const filterBy = [
    {
      label: "Nama",
      value: "NAME",
    },
    {
      label: "Email",
      value: "EMAIL",
    },
  ];

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [userData, setUserData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [showModalLogin, setShowModalLogin] = useState(false);
  const [openModalExport, setOpenModalExport] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { search_by, search } = watch("filter");

      const { data } = await http.get(api.v1.admin.report.user, {
        params: { limit, page, search_by, search },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setUserData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  const postDirectLogin = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { direct_login } = payload;

      const { data } = await http.post(
        api.v1.admin.auth.direct_login,
        direct_login
      );

      handleCloseModal();

      window.open(
        `${process.env.REACT_APP_URL_LMS}direct-login?token=${data.data.token}`
      );

      Swal.fire("Sukses", "Berhasil Login User", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleClickDirectLogin = email => {
    setValue("direct_login.email", email);
    setShowModalLogin(true);
  };

  const handleCloseModal = () => {
    reset();
    setShowModalLogin(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Peserta</title>
      </Helmet>

      <DefaultHeader section="Data Peserta" subsection="Laporan Peserta" />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    <Grid sm={6} className="d-flex align-items-center">
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={filterBy}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterBy.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.search_by", value);
                          }}
                          value={
                            filterBy.filter(
                              data => data.value === watch("filter.search_by")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Filter"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <OutlinedInput
                        type="text"
                        placeholder="Cari"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-2 ml-2"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.search")}
                      />
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                    <Grid sm={6}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <Grid>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenModalExport(true)}
                          >
                            <ImportExport className="mr-2" />
                            Export Data
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <UserReportDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={userData}
                    pagination={pagination}
                    loading={loadingData}
                    handleClickDirectLogin={handleClickDirectLogin}
                  />
                </Card>

                {/* Modal Login */}
                <Dialog
                  open={showModalLogin}
                  fullWidth={true}
                  maxWidth="md"
                  onClose={handleCloseModal}
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Direct Login</h2>
                    </Box>

                    <form onSubmit={handleSubmit(postDirectLogin)}>
                      <Grid container className="py-4 ">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Password</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="password"
                              {...register("direct_login.password")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Login
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Export */}
                <ModalExportUser
                  openModal={openModalExport}
                  onClose={() => setOpenModalExport(false)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
