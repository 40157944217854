import React from "react";

import {
  makeStyles,
  useTheme,
  Box,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import Chart from "chart.js";

import { chartOptions, parseOptions } from "variables/charts";
import boxShadows from "assets/theme/box-shadow";
import themeColors from "assets/theme/colors";

import Spinner from "components/Loading/Indicator/Spinner";

import "chartjs-adapter-moment";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  buttonRootUnselected: {
    background: theme.palette.white.main + "!important",
    color: theme.palette.primary.main + "!important",
  },
  buttonSpace: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "49%",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "1rem!important",
    },
  },
}));

export default function CardStatisticTransaction({
  statistics,
  changePeriod,
  period,
  loading,
  fullOption = false,
  refresh,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  if (window.Chart) parseOptions(Chart, chartOptions());

  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing=".0625rem"
                  marginBottom=".25rem!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[400]}>
                    Statistik
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h2"
                  marginBottom="0!important"
                >
                  <Box component="span">Transaksi</Box>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="space-between"
                  display="flex"
                  flexWrap="wrap"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component={Box}
                    // marginRight="1rem!important"
                    onClick={() => changePeriod("DAY")}
                    className={classes.buttonSpace}
                    classes={{
                      root:
                        period === "DAY" ? "" : classes.buttonRootUnselected,
                    }}
                  >
                    24 Hours
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Box}
                    // marginRight="1rem!important"
                    onClick={() => changePeriod("WEEK")}
                    className={classes.buttonSpace}
                    classes={{
                      root:
                        period === "WEEK" ? "" : classes.buttonRootUnselected,
                    }}
                  >
                    7 Days
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Box}
                    // marginRight="1rem!important"
                    onClick={() => changePeriod("MONTH")}
                    className={classes.buttonSpace}
                    classes={{
                      root:
                        period === "MONTH" ? "" : classes.buttonRootUnselected,
                    }}
                  >
                    30 Days
                  </Button>
                  {!fullOption && (
                    <Button
                      variant="contained"
                      color="primary"
                      component={Box}
                      // marginRight="1rem!important"
                      onClick={() =>
                        history.push("/report/transaction/statistic")
                      }
                      className={classes.buttonSpace}
                      classes={{ root: classes.buttonRootUnselected }}
                    >
                      Lainnya
                    </Button>
                  )}
                  {fullOption && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Box}
                        // marginRight="1rem!important"
                        onClick={() => changePeriod("THREE_MONTH")}
                        className={classes.buttonSpace}
                        classes={{
                          root:
                            period === "THREE_MONTH"
                              ? ""
                              : classes.buttonRootUnselected,
                        }}
                      >
                        90 Days
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Box}
                        // marginRight="1rem!important"
                        onClick={() => changePeriod("SIX_MONTH")}
                        className={classes.buttonSpace}
                        classes={{
                          root:
                            period === "SIX_MONTH"
                              ? ""
                              : classes.buttonRootUnselected,
                        }}
                      >
                        6 Months
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Box}
                        // marginRight="1rem!important"
                        onClick={() => changePeriod("YEAR")}
                        className={classes.buttonSpace}
                        classes={{
                          root:
                            period === "YEAR"
                              ? ""
                              : classes.buttonRootUnselected,
                        }}
                      >
                        Years
                      </Button>
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="info"
                    component={Box}
                    // marginRight="1rem!important"
                    onClick={refresh}
                    className={classes.buttonSpace}
                  >
                    Muat Ulang
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        />
        <CardContent position="relative">
          {loading && (
            <Box
              width="100%"
              height="350px"
              position="absolute"
              bgcolor={theme.palette.background.paper}
              style={{ opacity: 0.6 }}
            >
              <Spinner />
            </Box>
          )}
          <Box height="350px">
            <Bar
              data={statistics}
              options={{
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: themeColors.gray[700],
                        zeroLineColor: themeColors.gray[700],
                      },
                    },
                  ],
                },
                layout: {
                  padding: {
                    top: 20,
                    left: 10,
                    right: 10,
                  },
                },
                tooltips: {
                  callbacks: {
                    label: function (item, data) {
                      var label = data.datasets[item.datasetIndex].label || "";
                      var yLabel = item.yLabel;
                      var content = "";

                      if (data.datasets.length > 1) {
                        content += label;
                      }

                      content += ` ${yLabel} Transaksi`;
                      return content;
                    },
                  },
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
