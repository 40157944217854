import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import DefaultHeader from "components/Headers/DefaultHeader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

export default function GenerateCertificatePage() {
  const dispatch = useDispatch();

  const { watch, register, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      certificate_type: "COURSE_V2",
      course_ids: [],
      email: "",
      very_good: true,
    },
  });

  const [courseOptions, setCourseOptions] = useState([]);

  const certificateOptions = [
    {
      label: "Sertifikat Penyelesaian V2",
      value: "COURSE_V2",
    },
    {
      label: "Sertifikat Penyelesaian",
      value: "COURSE",
    },
    {
      label: "Sertifikat Kompetensi",
      value: "COMPETENCY",
    },
  ];

  const gradeOptions = [
    {
      label: "Sangat Baik",
      value: "true",
    },
    {
      label: "Baik",
      value: "false",
    },
  ];

  /**
   * Fetch Data
   */

  const getFirstData = async () => {
    const getCourses = http.get(api.v1.admin.course.main, {
      params: { option: "true" },
    });

    const [courses] = await Promise.all([getCourses]);

    const { data: courseResponse } = courses.data;
    const { records: courseRecords } = courseResponse;

    setCourseOptions(courseRecords);
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const course_ids = val.course_ids.map(data => data.id);

      val.course_ids = course_ids;
      val.very_good = val.very_good === "true" ? true : false;

      const res = await http.post(
        api.v1.admin.sync.user.generate_certificate,
        val,
        {
          responseType: "blob",
        }
      );

      reset();

      Swal.fire("Sukses", "Berhasil Generate Sertifikat", "success");

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Sertifikat.xlsx`;

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );

      link.click();
    } catch (error) {
      Swal.fire("Gagal", "Email Tidak Ditemukan", "error");
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getFirstData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Generate Sertifikat</title>
      </Helmet>

      <DefaultHeader
        section="Generate Sertifikat"
        subsection="Kelola Peserta"
      />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item sm={12}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Grid container>
                            <Grid item xs={12}>
                              <FormGroup>
                                <FormLabel>Email Peserta</FormLabel>
                                <OutlinedInput
                                  fullWidth
                                  type="text"
                                  {...register("email")}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item sm={12}>
                              <FormGroup>
                                <FormLabel>Pilih Kelas</FormLabel>
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  name="autocomplete"
                                  id="combo-box-category"
                                  disableClearable={false}
                                  options={courseOptions}
                                  value={watch("course_ids")}
                                  getOptionLabel={option => option.name}
                                  closeIcon={null}
                                  onChange={(e, val) => {
                                    if (courseOptions.length)
                                      setValue("course_ids", val);
                                  }}
                                  popupIcon={
                                    <Box
                                      component={KeyboardArrowDown}
                                      width="1.25rem!important"
                                      height="1.25rem!important"
                                    />
                                  }
                                  renderInput={params => (
                                    <TextField {...params} variant="filled" />
                                  )}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item sm={12}>
                              <FormGroup>
                                <FormControl variant="outlined" fullWidth>
                                  <FormLabel id="option-competence-aspect-label">
                                    Tipe Sertifikat
                                  </FormLabel>
                                  <Select
                                    IconComponent={KeyboardArrowDown}
                                    labelId="option-competence-aspect-label"
                                    value={
                                      watch("certificate_type") || "COURSE_V2"
                                    }
                                    {...register("certificate_type")}
                                  >
                                    {certificateOptions.map((val, key) => (
                                      <MenuItem key={key} value={val.value}>
                                        {val.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </FormGroup>
                            </Grid>

                            {watch("certificate_type") === "COURSE_V2" ? (
                              <Grid item sm={12}>
                                <FormGroup>
                                  <FormControl variant="outlined" fullWidth>
                                    <FormLabel id="option-competence-aspect-label">
                                      Nilai
                                    </FormLabel>
                                    <Select
                                      IconComponent={KeyboardArrowDown}
                                      labelId="option-competence-aspect-label"
                                      value={watch("very_good") || "true"}
                                      {...register("very_good")}
                                    >
                                      {gradeOptions.map((val, key) => (
                                        <MenuItem key={key} value={val.value}>
                                          {val.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </FormGroup>
                              </Grid>
                            ) : (
                              false
                            )}

                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                              >
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
