import React, { useEffect, useState } from "react";

import { Box, Card, Container, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import http from "services/http";
import api from "services/api";

import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import ListTransactionCourseDataTable from "./ListTransactionCourseDataTable";

export default function ListTransactionCoursePage() {
  const { instructorId, courseId } = useParams();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [transactionData, setParticipantData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.report.instructor}/${instructorId}/course/${courseId}/transaction`,
        { params: { limit, page } }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setParticipantData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Detail Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Data Transaksi Kelas"
        subsection="Data Kelas Instruktur"
        routeBack={`/report/instructor/${instructorId}/course`}
      />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Card>
                <ListTransactionCourseDataTable
                  loading={loadingData}
                  data={transactionData}
                  pagination={pagination}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPage={handleChangeRowsPage}
                />
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
