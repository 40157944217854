import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { useForm } from "react-hook-form";

import themeColors from "assets/theme/colors";

import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";
import DetailCourseReportDataTable from "./DetailCourseReportDataTable";
import formatRupiah from "helpers/formatRupiah";
import { Autocomplete } from "@material-ui/lab";

const DetailCourseReportPage = () => {
  const { courseId } = useParams();
  const { register, watch, setValue } = useForm();

  const [loadingParticipant, setLoadingParticipant] = useState(false);
  const [loadingDetailCourse, setLoadingDetailCourse] = useState(false);

  const [error, setError] = useState(null);
  const [detailCourse, setDetailCourse] = useState();

  const [courseParticipants, setCourseParticipants] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [modalAttendance, setModalAttendance] = useState({
    active: false,
    data: null,
  });

  const status = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Progress",
      value: "progress",
    },
    {
      label: "Finished",
      value: "finished",
    },
  ];

  /**
   * Fetch Data
   */
  const getCourseParticipants = async (course_id, page = 1) => {
    try {
      setLoadingParticipant(true);

      const { limit } = pagination;
      const { filterSearch, filterProgress } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.report.course.main}/${course_id}/participant`,
        {
          params: { limit, page, filterSearch, filterProgress },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseParticipants(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  const getDetailCourse = async course_id => {
    try {
      setLoadingDetailCourse(true);
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${course_id}`
      );

      const { data: records } = data;

      setDetailCourse(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingDetailCourse(false);
    }
  };

  const postAttendance = async (course_id, user_id) => {
    try {
      setLoadingParticipant(true);

      const check_attendance = await http.get(
        `${api.v1.admin.report.course.main}/${course_id}/participant/${user_id}/attendance`
      );

      if (
        check_attendance?.data?.data?.user_learning_offline_attendances.length >
        0
      )
        return Swal.fire("Gagal", "User Telah Absen", "error");

      await http.post(
        `${api.v1.admin.report.course.main}/${course_id}/participant/${user_id}/attendance`
      );

      getCourseParticipants(courseId, pagination?.current_page);
      setModalAttendance({ active: false, data: null });

      Swal.fire("Sukses", "User berhasil absen", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) =>
    getCourseParticipants(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getCourseParticipants(courseId);
  };

  const handleAttendance = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengabsenkan user ini",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        postAttendance(courseId, id);
      }
    });
  };

  useEffect(() => {
    getCourseParticipants(courseId);
    getDetailCourse(courseId);
  }, []);
  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Detail Kelas</title>
      </Helmet>
      <DefaultHeader
        section="Detail Data Kelas"
        subsection="Data Kelas"
        routeBack={"/report/list-course"}
      />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {!loadingDetailCourse && (
              <Card>
                <CardContent>
                  <Typography variant="h2" className="mb-3">
                    Detail Kelas
                  </Typography>

                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Nama Kelas
                        </Typography>
                        <p>{detailCourse?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Kategori
                        </Typography>
                        <p>{detailCourse?.course_category?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tipe Kelas
                        </Typography>
                        <p>{detailCourse?.course_type?.name}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Metode
                        </Typography>
                        <p>{detailCourse?.course_teach_method?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Level
                        </Typography>
                        <p>{detailCourse?.course_level?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Status
                        </Typography>
                        <p>{detailCourse?.status}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Harga
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Diskon
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.discount_price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Total
                        </Typography>
                        <p>
                          Rp{" "}
                          {formatRupiah(
                            detailCourse?.price - detailCourse?.discount_price
                          )}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-end">
                    <Grid sm={12}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <Grid sm={2} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={status}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = status.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.filterProgress", value);
                              getCourseParticipants(courseId);
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Status"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>

                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Nama"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e =>
                            e.key === "Enter" && getCourseParticipants(courseId)
                          }
                          {...register("filter.filterSearch")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getCourseParticipants(courseId)}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <DetailCourseReportDataTable
                    loading={loadingParticipant}
                    data={courseParticipants}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    detailCourse={detailCourse}
                    handleAttendance={setModalAttendance}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Modal Attendance */}
        <Dialog
          open={modalAttendance.active}
          onClose={() => setModalAttendance({ active: false, data: null })}
          fullWidth={true}
          maxWidth="md"
        >
          <Container className="py-4">
            <Box textAlign="Center">
              <h2>Absen User</h2>
            </Box>

            <Grid container className="py-4">
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nama
                  </Typography>
                  <p>{modalAttendance?.data?.name}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Email
                  </Typography>
                  <p>{modalAttendance?.data?.email}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nomor
                  </Typography>
                  <p>{modalAttendance?.data?.phone_number}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Waktu Absen
                  </Typography>
                  <p>
                    {modalAttendance?.data?.attendance
                      ?.user_learning_offline_attendances.length
                      ? moment(
                          modalAttendance?.data?.attendance
                            ?.user_learning_offline_attendances[0].submit_at
                        ).format("DD/MM/yyyy HH:mm")
                      : "-"}
                  </p>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} className="mb-2">
                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nam Tempat
                  </Typography>
                  <p>
                    {modalAttendance?.data?.attendance?.event_place?.name ||
                      "Belum memilih jadwal"}
                  </p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Alamat Tempat
                  </Typography>
                  <p>
                    {modalAttendance?.data?.attendance?.event_place?.address ||
                      "Belum memilih jadwal"}
                  </p>
                </Box>
              </Grid>

              {!modalAttendance?.data?.attendance
                ?.user_learning_offline_attendances.length &&
                modalAttendance?.data?.attendance?.event_place?.name && (
                  <Grid item sm={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      onClick={() =>
                        handleAttendance(modalAttendance?.data?.id)
                      }
                    >
                      Absen
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Container>
        </Dialog>
      </Container>
    </>
  );
};

export default DetailCourseReportPage;
