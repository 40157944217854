import {
  Box,
  Card,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Button,
  IconButton,
  makeStyles,
  SnackbarContent,
} from "@material-ui/core";

import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";
import ReactQuill from "react-quill";
import { KeyboardArrowDown } from "@material-ui/icons";
import api from "services/api";
import http from "services/http";
import { baseActions } from "redux/actions";

import Swal from "sweetalert2";
import Spinner from "components/Loading/Indicator/Spinner";

const snackbarStyles = makeStyles(snackbarTheme);

export default function DetailDigitalProductPage() {
  const { id } = useParams();

  const styles = { ...snackbarStyles() };
  const history = useHistory();
  const dispatch = useDispatch();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      dp: {
        name: "",
        slug: "",
      },
    },
  });

  const typeOptions = ["EBOOK", "EVENT", "PRODUCT_DIGITAL"];
  const statusOptions = ["UNLISTED", "DRAFT", "PUBLISH"];
  const uploadTypeOptions = ["FILE", "URL"];

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetch Data
   */
  const getDetailDigitalProduct = async id => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        `${api.v1.admin.digital_product.main}/${id}`
      );

      setValue("preview_cover", data.data.cover);

      data.data.cover = null;

      setValue("dp", data.data);

      setError(null);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const putUpdateDigitalProduct = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("dp");

      if (payload.upload_product_type === "URL") delete payload.product;
      if (payload.upload_product_type === "FILE") delete payload.link_product;

      const formData = new FormData();

      const files = ["cover", "product"];

      for (const key in payload) {
        if (!Array.isArray(payload[key]) && !files.includes(key)) {
          formData.append(key, payload[key]);
        }

        if (files.includes(key) && payload[key]) {
          formData.append(key, payload[key][0]);
        }
      }

      await http.put(`${api.v1.admin.digital_product.main}/${id}`, formData);

      Swal.fire(
        "Sukses",
        "Data produk digital berhasil di perbarui",
        "success"
      );

      history.push("/digital-product");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handlechangeInput = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (name === "name") {
      setValue("dp.name", value);
      setValue("dp.slug", value.replace(/ /g, "-").toLowerCase());
    } else if (name === "slug") {
      setValue("dp.slug", value.replace(/ /g, "-").toLowerCase());
    } else {
      setValue(`dp.${name}`, value);
    }
  };

  useEffect(() => {
    getDetailDigitalProduct(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Produk Digital</title>
      </Helmet>

      <DefaultHeader
        section="Detail Produk Digital"
        subsection="Data Produk Digital"
        routeBack="/digital-product"
      />

      <Container maxWidth={false} component={Box} id="create-course-page">
        <Grid container>
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <>
                {error && (
                  <Grid container className="my-2">
                    <Grid item xs={12}>
                      <SnackbarContent
                        classes={{ root: styles.errorSnackbar }}
                        action={
                          <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => setError(null)}
                          >
                            <Box
                              component="span"
                              color={theme.palette.white.main}
                            >
                              ×
                            </Box>
                          </Box>
                        }
                        message={<Box>{error}</Box>}
                      />
                    </Grid>
                  </Grid>
                )}

                <Card className="p-4">
                  {/* Master Data */}
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Nama</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          name="name"
                          onChange={handlechangeInput}
                          value={watch("dp.name")}
                          // {...register("course.name")}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Slug</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          name="slug"
                          onChange={handlechangeInput}
                          value={watch("dp.slug")}
                        />
                      </FormGroup>
                    </Grid>

                    {/* Cover Preview */}
                    {watch("preview_cover") && (
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormLabel>Preview Cover</FormLabel>
                          <img
                            src={watch("preview_cover")}
                            alt="cover"
                            width="400px"
                          />
                        </FormGroup>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Gambar</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="file"
                          placeholder="Default input"
                          {...register("dp.cover")}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Deskripsi</FormLabel>
                        <ReactQuill
                          theme="snow"
                          value={watch("dp.description")}
                          onChange={val => setValue("dp.description", val)}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormGroup>
                        <FormControl variant="outlined" fullWidth>
                          <FormLabel id="option-status-label">Status</FormLabel>
                          <Select
                            IconComponent={KeyboardArrowDown}
                            labelId="option-status-label"
                            value={watch("dp.status") || ""}
                            {...register("dp.status")}
                          >
                            {statusOptions.map((val, key) => (
                              <MenuItem key={key} value={val}>
                                {val}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormGroup>
                        <FormControl variant="outlined" fullWidth>
                          <FormLabel id="option-type-label">Tipe</FormLabel>
                          <Select
                            IconComponent={KeyboardArrowDown}
                            labelId="option-type-label"
                            value={watch("dp.type") || ""}
                            {...register("dp.type")}
                          >
                            {typeOptions.map((val, key) => (
                              <MenuItem key={key} value={val}>
                                {val}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormLabel>Harga</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          {...register("dp.price")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormLabel>Diskon</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          {...register("dp.discount_price")}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Catatan</FormLabel>
                        <ReactQuill
                          theme="snow"
                          value={watch("dp.notes")}
                          onChange={val => setValue("dp.notes", val)}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControl variant="outlined" fullWidth>
                          <FormLabel id="option-type-upload-label">
                            Tipe Upload
                          </FormLabel>
                          <Select
                            IconComponent={KeyboardArrowDown}
                            labelId="option-type-upload-label"
                            value={watch("dp.upload_product_type") || ""}
                            {...register("dp.upload_product_type", {
                              onChange: () => {
                                setValue("dp.link_product", "");
                                setValue("dp.product", "");
                              },
                            })}
                          >
                            {uploadTypeOptions.map((val, key) => (
                              <MenuItem key={key} value={val}>
                                {val}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    {watch("dp.upload_product_type") === "URL" ? (
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormLabel>Link Produk</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("dp.link_product")}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null}

                    {watch("dp.upload_product_type") === "FILE" ? (
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormLabel>Produk</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="file"
                            placeholder="Default input"
                            inputProps={{
                              accept:
                                watch("dp.type") === "EBOOK"
                                  ? "application/pdf"
                                  : "",
                            }}
                            {...register("dp.product")}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Maksimum Penjualan</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          {...register("dp.max_sales_limit")}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container className="mt-4">
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => putUpdateDigitalProduct(id)}
                      >
                        Perbarui
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
