import React from "react";

import { Redirect, Route } from "react-router-dom";
import cookies from "helpers/cookies";
import jwtDecode from "jwt-decode";
import localStorage from "helpers/localStorage";

export default function ProtectedRoute({
  component: Component,
  roles,
  ...rest
}) {
  const token = cookies.get("token");
  const user = cookies.get("user");
  const permissions = JSON.parse(localStorage.get("permissions"))?.map(
    permission => permission.code
  );

  const role_check = arr => {
    if (arr) {
      const { role } = jwtDecode(token);

      return arr.includes(role);
    }

    return true;
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (token && user && role_check(roles) && !!permissions) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
}
