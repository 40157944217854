import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  useTheme,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import ReactSelect from "components/ReactSelect/ReactSelect";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

const DetailUserReportPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    reset,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
      gender: "",
      birth: "",
      province: "",
      city: "",
      address: "",
      profession_id: null,
      user_ewallets: [],
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [optionProvince, setOptionProvince] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  const [optionEwallet, setOptionEwallet] = useState([]);
  const [optionProfession, setOptionProfession] = useState([]);

  const optionGender = [
    {
      label: "Laki Laki",
      value: "M",
    },
    {
      label: "Perempuan",
      value: "F",
    },
  ];

  const getUserResource = async () => {
    setLoadingData(true);

    const getUser = http.get(`${api.v1.admin.report.user}/${id}`);
    const getProvince = http.get(api.v1.province.main);
    const getEwallet = http.get(api.v1.ewallet.main);
    const getProfession = http.get(api.v1.profession.main);

    const [user, provinces, ewallets, professions] = await Promise.allSettled([
      getUser,
      getProvince,
      getEwallet,
      getProfession,
    ]);

    const { data: userReponse } = user.value.data;
    const { data: profesionResponse } = professions.value.data;
    const { data: provinceResponse } = provinces.value.data;
    const { data: ewalletResponse } = ewallets.value.data;

    await getCity(
      provinceResponse.records.find(
        province => province.name === userReponse.province
      )?.id
    );

    setOptionProvince(
      provinceResponse.records.map(province => ({
        label: province.name,
        id: province.id,
        value: province.name,
      }))
    );

    setOptionProfession(
      profesionResponse.records.map(profesion => ({
        label: profesion.name,
        value: profesion.id,
      }))
    );

    setOptionEwallet(
      ewalletResponse.records.map(ewallet => ({
        label: ewallet.name,
        id: ewallet.id,
        value: ewallet.name,
      }))
    );

    reset({
      name: userReponse.name,
      email: userReponse.email,
      phone_number: userReponse.phone_number,
      gender: userReponse.gender,
      birth: userReponse.birth,
      province: userReponse.province,
      city: userReponse.city,
      address: userReponse.address,
      profession_id: userReponse.profession?.id,
      user_ewallets: userReponse.ewallets.map(ewallet => ewallet.id),
    });

    setLoadingData(false);
  };

  const getCity = async province_id => {
    const { data } = await http.get(`${api.v1.city.main}`, {
      params: { province_id },
    });

    const { records } = data.data;

    const options = records.map(item => {
      return { label: item.name, value: item.name };
    });

    setOptionCity(options);
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.put(`${api.v1.admin.report.user}/${id}`, val);

      Swal.fire("Sukses", "Data Peserta berhasil diperbarui", "success");

      history.push("/report/user");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getUserResource();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Peserta</title>
      </Helmet>

      <DefaultHeader
        section="Detail Peserta"
        subsection="Laporan Peserta"
        routeBack="/report/user"
      />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                          <Grid item xs={6}>
                            <FormGroup>
                              <FormLabel>Nama</FormLabel>
                              <OutlinedInput
                                {...register("name", {
                                  required: "Tidak boleh kosong",
                                })}
                              />
                              {errors.name && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.name.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Email</FormLabel>
                              <OutlinedInput
                                autoComplete="off"
                                {...register("email", {
                                  required: "Tidak boleh kosong",
                                  pattern: {
                                    value:
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Email tidak valid",
                                  },
                                })}
                              />
                              {errors.email && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.email.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Nomor Telepon</FormLabel>
                              <OutlinedInput
                                {...register("phone_number", {
                                  required: "Tidak boleh kosong",
                                })}
                              />
                              {errors.phone_number && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.phone_number.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Jenis Kelamin</FormLabel>
                              <ReactSelect
                                showLabel={false}
                                showRequired={true}
                                options={optionGender}
                                name={"gender"}
                                control={control}
                                value={
                                  optionGender.find(
                                    gender => gender.value === watch("gender")
                                  )
                                    ? optionGender.find(
                                        gender =>
                                          gender.value === watch("gender")
                                      )
                                    : [
                                        {
                                          value: "",
                                          label: "Pilih Jenis Kelamin",
                                        },
                                      ]
                                }
                                onChange={item => {
                                  setValue("gender", item.value);
                                }}
                              />

                              {errors.role && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.role.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Tanggal Lahir</FormLabel>
                              <OutlinedInput
                                type="date"
                                {...register("birth", {
                                  required: "Tidak boleh kosong",
                                })}
                              />
                              {errors.birth && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.birth.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          <Grid item xs={6}>
                            <FormGroup>
                              <FormLabel>Provinsi</FormLabel>
                              <ReactSelect
                                showLabel={false}
                                showRequired={true}
                                options={optionProvince}
                                name={"province"}
                                control={control}
                                value={
                                  optionProvince.find(
                                    province =>
                                      province.value === watch("province")
                                  )
                                    ? optionProvince.find(
                                        province =>
                                          province.value === watch("province")
                                      )
                                    : [{ value: "", label: "Pilih Provinsi" }]
                                }
                                onChange={item => {
                                  setValue("province", item.value);
                                  setValue("city", null);
                                  getCity(item.id);
                                }}
                              />

                              {errors.province && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.province.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Kota</FormLabel>
                              <ReactSelect
                                showLabel={false}
                                showRequired={true}
                                options={optionCity}
                                name={"city"}
                                control={control}
                                isDisabled={!watch("province")}
                                value={
                                  optionCity.find(
                                    city => city.value === watch("city")
                                  )
                                    ? optionCity.find(
                                        city => city.value === watch("city")
                                      )
                                    : [{ value: "", label: "Pilih Kota" }]
                                }
                                onChange={item => {
                                  setValue("city", item.value);
                                }}
                              />

                              {errors.city && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.city.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Alamat</FormLabel>
                              <OutlinedInput
                                {...register("address", {
                                  required: "Tidak boleh kosong",
                                })}
                              />
                              {errors.address && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.address.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>Profesi</FormLabel>
                              <ReactSelect
                                showLabel={false}
                                showRequired={true}
                                options={optionProfession}
                                name={"profession_id"}
                                control={control}
                                value={
                                  optionProfession.find(
                                    profession =>
                                      profession.value ===
                                      watch("profession_id")
                                  )
                                    ? optionProfession.find(
                                        profession =>
                                          profession.value ===
                                          watch("profession_id")
                                      )
                                    : [{ value: "", label: "Pilih Profesi" }]
                                }
                                onChange={item => {
                                  setValue("profession_id", item.value);
                                }}
                              />

                              {errors.role && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.role.message}
                                </FormHelperText>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <FormLabel>E-Wallet</FormLabel>
                              <ReactSelect
                                name="user_ewallets"
                                control={control}
                                options={optionEwallet}
                                onChange={options => {
                                  setValue(
                                    "user_ewallets",
                                    options.map(op => op.id),
                                    { shouldValidate: true, shouldDirty: true }
                                  );
                                }}
                                value={optionEwallet.filter(
                                  ewallet =>
                                    watch("user_ewallets").indexOf(
                                      ewallet.id
                                    ) !== -1
                                )}
                                isMulti
                              />

                              {errors.user_ewallets && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.user_ewallets.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} className="mt-5">
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Perbarui Peserta
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DetailUserReportPage;
