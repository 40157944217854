import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  OutlinedInput,
  Typography,
  useTheme,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";
import componentStyles from "assets/theme/views/admin/elements";
import componentStylesForm from "assets/theme/components/forms";
import componentStylesAvatar from "assets/theme/components/avatar";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesValidation from "assets/theme/views/admin/validation.js";
import { useFieldArray, useForm } from "react-hook-form";
import { Add, Delete } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import localStorage from "helpers/localStorage";

const useStyles = makeStyles(componentStyles);
const useStylesForm = makeStyles(componentStylesForm);
const useStylesAvatar = makeStyles(componentStylesAvatar);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesValidation = makeStyles(componentStylesValidation);

const DetailPermissionPage = () => {
  const [loadingData, setLoadingData] = useState(false);

  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...useStylesForm(),
    ...useStylesAvatar(),
    ...useStylesButtons(),
    ...useStylesValidation(),
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
      name: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "children",
    keyName: "key",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const permissions = JSON.parse(localStorage.get("permissions"))?.map(
    permission => permission.code
  );

  const getPermission = async id => {
    try {
      setLoadingData(true);

      const { data } = await http.get(`${api.v1.admin.permission.main}/${id}`);

      const { data: permissionData } = data;

      reset({
        code: permissionData?.code,
        name: permissionData?.name,
      });

      permissionData?.children?.forEach(item => {
        append({ code: item.code, name: item.name });
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      setLoadingData(false);
    }
  };

  const onSubmit = async values => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { code, name, children } = values;

      await http.put(`${api.v1.admin.permission.main}/${id}`, {
        name,
        code,
        children,
      });

      Swal.fire("Sukses", "Kelola Akses berhasil di perbarui", "success");

      history.push("/permission");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getPermission(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Permission</title>
      </Helmet>
      <DefaultHeader
        section="Detail Permission"
        subsection="Kelola Akses"
        routeBack="/permission"
      />
      <Container maxWidth={false} component={Box}>
        <Grid container component={Box} justifyContent="center">
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                          <FormLabel>Kode</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("code", {
                              required: "Kode tidak Boleh Kosong",
                            })}
                          />
                          {errors.name && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.name.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Nama</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("name", {
                              required: "Nama tidak Boleh Kosong",
                            })}
                          />
                          {errors.name && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.name.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <Box display="flex">
                          <Box component={Typography} variant="h3">
                            Sub Permission
                          </Box>
                          <Box marginLeft="5px!important">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                append({
                                  code: "",
                                  name: "",
                                });
                              }}
                            >
                              <Add size="large" />
                            </Button>
                          </Box>
                        </Box>
                        {fields.map((field, index) => (
                          <Grid container key={field.key}>
                            <Grid item xs={12} md={3}>
                              <FormGroup>
                                <FormLabel>Kode</FormLabel>
                                <Box display="flex">
                                  <OutlinedInput
                                    fullWidth
                                    type="text"
                                    {...register(`children.${index}.code`)}
                                  />
                                </Box>
                              </FormGroup>
                            </Grid>
                            <Grid item direction="row" xs={12} md={9}>
                              <FormGroup>
                                <FormLabel>Nama</FormLabel>
                                <Box display="flex">
                                  <OutlinedInput
                                    fullWidth
                                    type="text"
                                    {...register(`children.${index}.name`)}
                                  />
                                  {index !== 0 && (
                                    <Box
                                      marginLeft="5px"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Button
                                        variant="contained"
                                        size="small"
                                        classes={{
                                          root: classes.buttonContainedError,
                                        }}
                                        onClick={() => remove(index)}
                                      >
                                        <Delete size="large" />
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              </FormGroup>
                            </Grid>
                          </Grid>
                        ))}

                        {permissions?.includes("151-4") && (
                          <Grid container className="mt-5">
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                              >
                                Perbarui
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DetailPermissionPage;
