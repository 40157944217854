import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";

export default function UpdateScheduleVoucherPage() {
  const [loadingData, setLoadingData] = useState(false);
  const [courses, setCourses] = useState([]);
  const [scheduleOptions, setScheduleOptions] = useState([]);

  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      course: "",
      voucher_excel: null,
      schedule: "",
    },
  });

  /**
   * Fetch Data
   */
  const getVoucherNeeds = async () => {
    setLoadingData(true);

    const getCourses = http.get(api.v1.admin.course.main, {
      params: { option: "true", teach_method_id: "1,4" },
    });

    const [courses] = await Promise.all([getCourses]);

    const { data: courseResponse } = courses.data;
    const { records: courseRecords } = courseResponse;

    setCourses(courseRecords);

    setLoadingData(false);
  };

  const getWebinarSchedule = async courseId => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${courseId}/webinar`,
      {
        params: { limit: 100, page: 1 },
      }
    );

    const scheduleOptions = [];
    data.data.records.forEach(el => {
      if (el.sessions.length) {
        const label = `${moment(el?.sessions[0].date).format(
          "DD/MM/YYYY"
        )} - ${moment(el?.sessions[el?.sessions.length - 1].date).format(
          "DD/MM/YYYY"
        )} - ${el.code}`;

        scheduleOptions.push({
          id: el.id,
          label,
        });
      }
    });

    setScheduleOptions(scheduleOptions);
  };

  const getOfflineSchedule = async courseId => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${courseId}/offline-schedule`,
      {
        params: { limit: 100, page: 1 },
      }
    );

    const scheduleOptions = [];

    data.data.records.forEach(el => {
      if (el?.course_offline_schedule_sessions.length) {
        const label = `${moment(
          el?.course_offline_schedule_sessions[0].date
        ).format("DD/MM/YYYY")} - ${moment(
          el?.course_offline_schedule_sessions[
            el?.course_offline_schedule_sessions?.length - 1
          ].date
        ).format("DD/MM/YYYY")} - ${el.code}`;

        scheduleOptions.push({
          id: el.id,
          label,
        });
      }
    });

    setScheduleOptions(scheduleOptions);
  };

  const getFormatExcel = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = "Format Update Voucher Schedule.xlsx";

      const res = await http.get(
        `${api.v1.admin.voucher.main}/template-update-schedule`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { course, voucher_excel, schedule } = val;

      const courseWebinarScheduleId =
        course?.course_teach_method_id === 1 ? schedule.id : null;
      const courseOfflineScheduleId =
        course?.course_teach_method_id === 4 ? schedule.id : null;

      const formData = new FormData();

      formData.append("course_id", course?.id);
      formData.append("voucher_excel", voucher_excel[0]);
      formData.append("course_webinar_id", courseWebinarScheduleId);
      formData.append("course_offline_schedule_id", courseOfflineScheduleId);

      await http.post(
        `${api.v1.admin.voucher.main}/update-schedule`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      Swal.fire("Sukses", "Jadwal Voucher Berhasil Diubah", "success");

      reset();
      setScheduleOptions([]);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => getVoucherNeeds(), []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Ubah Jadwal Voucher</title>
      </Helmet>

      <DefaultHeader
        section="Ubah Jadwal Voucher"
        subsection="Kelola Voucher"
      />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Grid className="mb-4">
              <Button
                variant="contained"
                color="default"
                onClick={() => {
                  getFormatExcel();
                }}
                className="ml-2"
              >
                Unduh Format
              </Button>
            </Grid>

            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Pilih Kelas</FormLabel>
                              <Controller
                                control={control}
                                name="course"
                                rules={{
                                  required: "Tidak boleh kosong",
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    options={courses}
                                    getOptionLabel={option =>
                                      option?.name
                                        ? `${option?.name} - ${option?.code}`
                                        : ""
                                    }
                                    getOptionSelected={(option, val) =>
                                      val === undefined ||
                                      val === "" ||
                                      option.id === val.id
                                    }
                                    closeIcon={null}
                                    value={value}
                                    onChange={(e, val) => {
                                      onChange(val);

                                      setValue("schedule", "");

                                      if (val?.course_teach_method_id === 1)
                                        getWebinarSchedule(val.id);
                                      if (val?.course_teach_method_id === 4)
                                        getOfflineSchedule(val.id);
                                    }}
                                    popupIcon={
                                      <Box
                                        component={KeyboardArrowDown}
                                        width="1.25rem!important"
                                        height="1.25rem!important"
                                      />
                                    }
                                    renderInput={params => (
                                      <TextField {...params} variant="filled" />
                                    )}
                                  />
                                )}
                              />
                              {errors.course && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.course.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Pilih Jadwal</FormLabel>

                              <Controller
                                control={control}
                                name="schedule"
                                rules={{
                                  required: "Tidak boleh kosong",
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    options={scheduleOptions}
                                    getOptionLabel={option =>
                                      option ? option.label : ""
                                    }
                                    getOptionSelected={(option, val) =>
                                      val === undefined ||
                                      val === "" ||
                                      option.id === val.id
                                    }
                                    closeIcon={null}
                                    value={value}
                                    onChange={(e, val) => {
                                      onChange(val);
                                    }}
                                    popupIcon={
                                      <Box
                                        component={KeyboardArrowDown}
                                        width="1.25rem!important"
                                        height="1.25rem!important"
                                      />
                                    }
                                    renderInput={params => (
                                      <TextField {...params} variant="filled" />
                                    )}
                                  />
                                )}
                              />

                              {errors.course && (
                                <FormHelperText
                                  component={Box}
                                  color={
                                    theme.palette.error.main + "!important"
                                  }
                                >
                                  {errors.course.message}
                                </FormHelperText>
                              )}
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup>
                              <FormLabel>File Voucher</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="file"
                                {...register("voucher_excel")}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Simpan
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
