import React from "react";

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

export default function InstructorListCourseWebinarDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const { id } = useParams();

  const history = useHistory();

  const columns = [
    "#",
    "Sesi",
    "Jumlah Peserta Redeem",
    "Jumlah Peserta Selesai",
    "Opsi",
  ];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && data.length ? (
            data.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>

                <TableCell component="td" align="center">
                  {moment(`${course?.sessions[0]?.date}`).format("DD/MM/YYYY")}{" "}
                  {moment(course.sessions[0].start_time, "HH:mm:ss").format(
                    "HH:mm"
                  )}{" "}
                  -{" "}
                  {moment(
                    `${course?.sessions[course?.sessions?.length - 1]?.date}`
                  ).format("DD/MM/YYYY")}{" "}
                  {moment(
                    course.sessions[course.sessions.length - 1].end_time,
                    "HH:mm:ss"
                  ).format("HH:mm")}
                </TableCell>
                <TableCell component="td" align="center">
                  {Number(course.total_participant)}
                </TableCell>
                <TableCell component="td" align="center">
                  {Number(course.total_participant_complete)}
                </TableCell>
                <TableCell component="td" align="center">
                  <Button
                    variant="contained"
                    size="xl"
                    color="default"
                    onClick={() =>
                      history.push(
                        `/admin-instructor/my-course/${id}/webinar/${course?.id}`
                      )
                    }
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={8}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={8}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
