import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Add,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import React, { useState } from "react";

export default function CertificateStandardDataTable({
  loading,
  data,
  pagination,
  getDetailData,
  handleDeleteData,
  handleChangePage,
  handleChangeRowsPage,
  handleCreateCertificateCompetency,
  handleDetailCertificateCompetency,
  handleDeleteCertificateCompetency,
}) {
  const [open, setOpen] = useState(false);
  const columns = ["", "#", "Judul", "Opsi"];

  const columnsChild = ["#", "Deskripsi", "Opsi"];

  const childTable = (data = []) => {
    return (
      <TableContainer component={Paper}>
        <Box component={Table} alignItems="center">
          <TableHead>
            <TableRow>
              {columnsChild.map((item, key) => (
                <TableCell
                  key={key}
                  style={{ fontWeight: 700 }}
                  align="center"
                  width={key === 0 ? "100px" : null}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && data.length ? (
              data.map((item, index) => (
                <>
                  <TableRow key={item.id}>
                    <TableCell
                      component="td"
                      scope="row"
                      align="center"
                      style={{ wordWrap: "break-word" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      align="center"
                      style={{ whiteSpace: "normal" }}
                    >
                      {item.title}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      align="center"
                      style={{ wordWrap: "break-word" }}
                    >
                      <IconButton
                        aria-label="Edit"
                        onClick={() =>
                          handleDetailCertificateCompetency(
                            item.id,
                            item.course_certificate_standard_id
                          )
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() =>
                          handleDeleteCertificateCompetency(
                            item.id,
                            item.course_certificate_standard_id
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            component={Box}
                            className="pl-4 pt-2"
                          >
                            {childTable(item.certificate_competencies)}
                          </Grid>
                        </Grid>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell component="td" colSpan={8}>
                  {loading ? (
                    <Box textAlign="center">Harap Tunggu...</Box>
                  ) : (
                    <Box textAlign="center">Tidak ada data</Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Box>
      </TableContainer>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((item, index) => (
              <>
                <TableRow key={item.id}>
                  <TableCell align="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen(prev => ({
                          ...prev,
                          [item.id]: !prev[item.id],
                        }))
                      }
                    >
                      {open[item.id] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ whiteSpace: "normal" }}
                  >
                    {item.title}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <IconButton
                      aria-label="Create"
                      onClick={() =>
                        handleCreateCertificateCompetency("CREATE", item.id)
                      }
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => getDetailData(item.id)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDeleteData(item.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                      {childTable(item.certificate_competencies)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={8}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
