import React from "react";

import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LibraryBooks } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import ChipData from "components/Chip/ChipData";

export default function InstructorReportDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const history = useHistory();

  const columns = ["#", "Nama", "Total Kelas", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((instructor, index) => (
              <TableRow key={instructor?.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell component="td" style={{ whiteSpace: "normal" }}>
                  <Box alignItems="center" display="flex">
                    <Box
                      component={Avatar}
                      marginRight="1rem"
                      alt={instructor?.name}
                      src={instructor?.avatar}
                    />
                    <Box display="flex" alignItems="flex-start">
                      <Box fontSize=".875rem" component="span">
                        {instructor?.name}
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  <ChipData text={instructor.total_courses} />
                </TableCell>
                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="List Course"
                    onClick={() =>
                      history.push(`/report/instructor/${instructor.id}/course`)
                    }
                  >
                    <LibraryBooks />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
