import { useState } from "react";

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import moment from "moment";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

const columns = ["#", "Nama Kelas", "Peserta", "Status", "Total"];

const useRowStyles = makeStyles({
  rowRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useHeadRowStyles = makeStyles({
  root: {
    backgroundColor: themeColors.primary.main,
  },
});

export default function TransactionReportDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const [open, setOpen] = useState(false);

  const classes = { ...useRowStyles(), ...useHeadRowStyles() };

  return (
    <>
      <TableContainer component={Box}>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow
              classes={{
                root: classes.root,
              }}
            >
              {columns.map((column, key) => (
                <TableCell key={key} style={{ fontWeight: 700 }} align="center">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && data.length > 0 ? (
              data.map(transaction => (
                <>
                  <TableRow key={transaction.id} className={classes.rowRoot}>
                    <TableCell align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          setOpen(prev => ({
                            ...prev,
                            [transaction.id]: !prev[transaction.id],
                          }))
                        }
                      >
                        {open[transaction.id] ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {transaction?.course?.name}
                    </TableCell>
                    <TableCell align="center">
                      {transaction.user?.name}
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="h5"
                        style={{
                          color:
                            transaction.status === "SUCCESS"
                              ? themeColors.success.main
                              : themeColors.error.main,
                          fontWeight: 700,
                        }}
                      >
                        {transaction.status}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      Rp{" "}
                      {transaction.total_payment
                        ? new Intl.NumberFormat("id-ID", {
                            currency: "IDR",
                          }).format(transaction.total_payment)
                        : 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open[transaction.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            component={Box}
                            className="pl-4 pt-2"
                          >
                            <Typography
                              variant="h3"
                              gutterBottom
                              component="div"
                            >
                              Detail Transaksi
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={6} component={Box}>
                            <List dense disablePadding={true}>
                              <ListItem>
                                <ListItemText
                                  primary="Kode Pembayaran"
                                  secondary={transaction.code}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemText
                                  primary="Tanggal Pembayaran"
                                  secondary={
                                    transaction.date_completed
                                      ? moment(
                                          transaction.date_completed
                                        ).format("D MMM YYYY")
                                      : "-"
                                  }
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemText
                                  primary="Metode Pembayaran"
                                  secondary={`${transaction.payment_method}${
                                    transaction.voucher
                                      ? ` (${transaction.voucher})`
                                      : ""
                                  }`}
                                />
                              </ListItem>
                            </List>
                          </Grid>

                          <Grid item xs={12} md={6} component={Box}>
                            <Grid>
                              <h4>Email Peserta</h4>
                              <h4 className="font-weight-normal">
                                {transaction.user?.email}
                              </h4>
                            </Grid>

                            {transaction.selected_webinar && (
                              <Grid>
                                <h4>Jadwal Webinar Terpilih</h4>
                                {transaction.selected_webinar?.sessions.map(
                                  (session, key) => (
                                    <div key={key}>
                                      <h4 className="font-weight-normal">
                                        Sesi {key + 1}
                                      </h4>
                                      <h4 className="font-weight-normal">
                                        {session.date} {session.start_time}-
                                        {session.end_time}
                                      </h4>
                                    </div>
                                  )
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {loading ? (
                    <Box textAlign="center">Harap Tunggu...</Box>
                  ) : (
                    <Box textAlign="center">Tidak ada data</Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <Pagination
                pagination={pagination}
                colSpan={6}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </TableContainer>
    </>
  );
}
