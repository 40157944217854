import {
  Box,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  Delete,
  Edit,
  GetApp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import formatPeriod from "helpers/formatPeriod";
import formatStatusBlast from "helpers/formatStatusBlast";
import { useState } from "react";

export default function ReminderCourseDataTable({
  loading,
  data,
  getDetailData,
  handleDeleteData,
  handleExportData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const [open, setOpen] = useState(false);

  const columns = ["", "#", "Nama Kelas", "Periode", "Status", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((reminder, index) => (
              <>
                <TableRow key={reminder.id}>
                  <TableCell align="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen(prev => ({
                          ...prev,
                          [reminder.id]: !prev[reminder.id],
                        }))
                      }
                    >
                      {open[reminder.id] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell component="td" scope="row" align="center">
                    {pagination.current_page > 1
                      ? (pagination.current_page - 1) * pagination.limit +
                        (index + 1)
                      : index + 1}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {reminder.course.name}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {formatPeriod(reminder.period)}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {formatStatusBlast(reminder.status)}
                  </TableCell>
                  <TableCell component="td" align="center">
                    <IconButton
                      aria-label="Edit"
                      onClick={() => getDetailData(reminder.id)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDeleteData(reminder.id)}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      aria-label="Export"
                      onClick={() => handleExportData(reminder.id)}
                    >
                      <GetApp />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={open[reminder.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container className="mb-2 px-2">
                        <Grid
                          item
                          xs={12}
                          md={12}
                          component={Box}
                          className="pt-2"
                        >
                          <Typography variant="h3" gutterBottom component="div">
                            Detail Data
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary="Dalam Antrian"
                                secondary={reminder?.total_queue ?? "0"}
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary="Diterima"
                                secondary={reminder?.total_delivered ?? "0"}
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary="Tidak Terkirim"
                                secondary={reminder?.total_undelivered ?? "0"}
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary="Dibaca"
                                secondary={reminder?.total_read ?? "0"}
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary="Gagal Terkirim"
                                secondary={reminder?.total_failed ?? "0"}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
