import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";
import JobOpeningDataTable from "./JobOpeningDataTable";
import ReactSelect from "react-select";
import moment from "moment";
import ReactQuill from "react-quill";

const optionType = [
  { label: "Internship", value: "Internship" },
  { label: "Part Time", value: "Part Time" },
  { label: "Full Time", value: "Full Time" },
];

const optionPayType = [
  { label: "Tidak dipilih", value: null },
  { label: "Paid", value: "Paid" },
  { label: "Unpaid", value: "Unpaid" },
];

const optionWorkArrangement = [
  { label: "Tidak dipilih", value: null },
  { label: "WFH", value: "WFH" },
  { label: "On Site", value: "On Site" },
  { label: "Hybrid", value: "Hybrid" },
];

export default function ListJobOPening() {
  const { brandId } = useParams();
  const dispatch = useDispatch();

  const { setValue, handleSubmit, register, watch, reset } = useForm({
    defaultValues: {
      job_opening: {
        title: "",
        description: null,
        requirements: null,
        register_link: "",
        end_date: moment()
          .endOf("month")
          .set({ D: "25" })
          .format("yyyy-MM-DDThh:mm"),
        city: { label: "", value: null },
        type: { label: "", value: null },
        pay_type: { label: "Tidak dipilih", value: null },
        work_arrangement: { label: "Tidak dipilih", value: null },
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [jobOpeningData, setJobOpeningData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [optionCity, setOptionCity] = useState([]);

  const [typeModalJobOpening, setTypeModalJobOpening] = useState("CREATE");
  const [showModalJobOpening, setShowModalJobOpening] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (brandId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.brand.main}/${brandId}/job-opening`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setJobOpeningData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getOptionCity = async () => {
    try {
      const { data } = await http.get(`${api.v1.city.main}`);

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCity(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.brand.main}/${brandId}/job-opening/${id}`
      );

      setValue("job_opening", {
        id: data.data.id,
        title: data.data.title,
        register_link: data.data.register_link,
        city: optionCity.find(city => city.value === data.data.city_id),
        type: optionType.find(type => type.value === data.data.type),
        pay_type: optionPayType.find(
          option => option.value === data.data.pay_type
        ),
        work_arrangement: optionWorkArrangement.find(
          option => option.value === data.data.work_arrangement
        ),
        end_date: data.data.end_date,
        requirements: data.data.requirements,
        description: data.data.description,
      });

      setTypeModalJobOpening("UPDATE");
      setShowModalJobOpening(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const {
        title,
        description,
        requirements,
        register_link,
        end_date,
        city,
        type,
        work_arrangement,
        pay_type,
      } = payload.job_opening;

      const data = {
        title,
        description,
        requirements,
        register_link,
        end_date,
        city_id: city?.value || null,
        type: type?.value || null,
        pay_type: pay_type?.value || null,
        work_arrangement: work_arrangement?.value || null,
      };

      await http.post(
        `${api.v1.admin.brand.main}/${brandId}/job-opening`,
        data
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Lowongan Kerja berhasil ditambahkan",
        "success"
      );

      getList(brandId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const {
        id,
        title,
        description,
        requirements,
        register_link,
        end_date,
        city,
        type,
        work_arrangement,
        pay_type,
      } = payload.job_opening;

      const data = {
        title,
        description,
        requirements,
        register_link,
        end_date,
        city_id: city?.value || null,
        type: type?.value || null,
        pay_type: pay_type?.value || null,
        work_arrangement: work_arrangement?.value || null,
      };

      await http.put(
        `${api.v1.admin.brand.main}/${brandId}/job-opening/${id}`,
        data
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Lowongan Kerja berhasil diperbaharui",
        "success"
      );

      getList(brandId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async (brandId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.brand.main}/${brandId}/job-opening/${id}`
      );

      getList(brandId);

      Swal.fire("Sukses", "Data Lowongan Kerja berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const setCoverData = async (brandId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.brand.main}/${brandId}/job-opening/${id}/set-cover`,
        {
          job_opening_id: brandId,
          id,
        }
      );

      getList(brandId);

      Swal.fire("Sukses", "Cover Berhasil Diubah", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(brandId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(brandId);
  };

  const handleOpenModal = async () => {
    setTypeModalJobOpening("CREATE");
    setShowModalJobOpening(true);
  };

  const handleCloseModal = () => {
    setShowModalJobOpening(false);

    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Lowongan Kerja",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(brandId, id);
      }
    });
  };

  const handleSetCover = id => {
    Swal.fire({
      icon: "question",
      title: "Apakah anda yakin?",
      text: "Anda akan menajadikan gambar ini menjadi cover",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        setCoverData(brandId, id);
      }
    });
  };

  useEffect(() => {
    getList(brandId);
    getOptionCity();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Lowongan Kerja</title>
      </Helmet>

      <DefaultHeader
        section="Data Lowongan Kerja"
        subsection="Data Brand"
        routeBack="/brand"
      />

      <Container maxWidth={false} component={Box} id="brand-job-opening">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <JobOpeningDataTable
                      loading={loadingData}
                      data={jobOpeningData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      handleSetCover={handleSetCover}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Job Opening */}
                <Dialog
                  open={showModalJobOpening}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Data Lowongan</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalJobOpening === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Judul Lowongan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("job_opening.title")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Link Pendaftaran</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("job_opening.register_link")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel id="option-type-label">
                              Pilih Tipe
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={optionType}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("job_opening.type", val);
                              }}
                              value={watch("job_opening.type")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel id="option-type-label">
                              Pilih Tipe Pembayaran
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={optionPayType}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("job_opening.pay_type", val);
                              }}
                              value={watch("job_opening.pay_type")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel id="option-type-label">
                              Pilih Metode Kerja
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={optionWorkArrangement}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("job_opening.work_arrangement", val);
                              }}
                              value={watch("job_opening.work_arrangement")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel id="option-city-label">
                              Pilih Kota
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={optionCity}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("job_opening.city", val);
                              }}
                              value={watch("job_opening.city")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal Berakhir</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("job_opening.end_date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Persyaratan</FormLabel>
                            <ReactQuill
                              theme="snow"
                              value={watch("job_opening.requirements")}
                              onChange={val =>
                                setValue("job_opening.requirements", val)
                              }
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Deskripsi</FormLabel>
                            <ReactQuill
                              theme="snow"
                              value={watch("job_opening.description")}
                              onChange={val =>
                                setValue("job_opening.description", val)
                              }
                            />
                          </FormGroup>
                        </Grid>

                        <Grid sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalJobOpening === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
