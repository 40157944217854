import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ImportExport, KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import formatRupiah from "helpers/formatRupiah";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { baseActions } from "redux/actions";

import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import UserScheduleDataTable from "../Schedule/UserScheduleDataTable";
import CourseSessionReportDataTable from "../CourseSessionReportDataTable";
import WebinarScheduleReportDataTable from "./WebinarScheduleReportDatatable";
import ReactSelect from "react-select";
import ModalExportShcedule from "./ModalExportSchedule";
import ModalExportParticipant from "./ModalExportParticipant";

function WebinarScheduleReportPage() {
  const dispatch = useDispatch();
  const { watch, reset, setValue, register, handleSubmit } = useForm({
    defaultValues: {
      filter: {
        filterSearch: "",
        filterProgress: "",
      },
      format: {
        type: "xlsx",
      },
      changeSchedule: {
        type: "ALL",
        webinar_id: null,
        user: [],
      },
    },
  });
  const { courseId } = useParams();

  const [loadingData, setLoadingData] = useState(false);
  const [webinarSchedules, setWebinarSchedules] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [loadingSessionData, setLoadingSessionData] = useState(false);
  const [webinarScheduleId, setWebinarScheduleId] = useState();
  const [webinarSessionId, setWebinarSessionId] = useState();
  const [webinarScheduleSessions, setWebinarScheduleSessions] = useState([]);
  const [paginationScheduleSession, setPaginationScheduleSession] = useState({
    limit: 10,
  });

  const [showDataParticipant, setShowDataParticipant] = useState(false);

  const [loadingDetailCourse, setLoadingDetailCourse] = useState(false);
  const [error, setError] = useState(null);

  const [detailCourse, setDetailCourse] = useState();

  const [openModalSession, setOpenModalSession] = useState(false);
  const [webinar, setWebinar] = useState({
    id: null,
    session_id: null,
  });
  const [modalAttendance, setModalAttendance] = useState({
    active: false,
    data: null,
  });

  const [modalChangeSchedule, setModalChangeSchedule] = useState(false);
  const [modalExportSchedule, setModalExportSchedule] = useState(false);
  const [modalExportParticipant, setModalExportParticipant] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [scheduleOptions, setScheduleOptions] = useState([]);

  const [courseParticipants, setCourseParticipants] = useState([]);
  const [sessionAttendanceCode, setSessionAttendanceCode] = useState("");
  const [paginationParticipant, setPaginationParticipant] = useState({
    limit: 10,
  });
  const [loadingParticipant, setLoadingParticipant] = useState(false);

  const status = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Progress",
      value: "progress",
    },
    {
      label: "Finished",
      value: "finished",
    },
  ];

  const formatType = [
    {
      label: "XLSX",
      value: "xlsx",
    },
    {
      label: "CSV",
      value: "csv",
    },
  ];

  const targetUser = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Pilih User",
      value: "SELECTED",
    },
  ];

  /**
   * Fetch Data
   */
  const getList = async (courseId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        api.v1.admin.report.course.webinar_schedule(courseId),
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setWebinarSchedules(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailCourse = async courseId => {
    try {
      setLoadingDetailCourse(true);
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}`
      );

      const { data: records } = data;

      setDetailCourse(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingDetailCourse(false);
    }
  };

  const getListSessions = async (courseId, scheduleId, page = 1) => {
    try {
      setLoadingSessionData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.report.course.webinar_schedule(
          courseId
        )}/${scheduleId}/webinar-session`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setWebinarScheduleSessions(records);

      setPaginationScheduleSession({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingSessionData(false);
    }
  };

  const getCourseParticipants = async (
    courseId,
    scheduleId,
    sessionId,
    page = 1
  ) => {
    try {
      setLoadingParticipant(true);

      const { limit } = paginationParticipant;
      const { filterSearch, filterProgress } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.report.course.main}/${courseId}/participant`,
        {
          params: {
            limit,
            page,
            filter_search: filterSearch,
            filter_progress: filterProgress,
            webinar_id: scheduleId,
            webinar_session_id: sessionId,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseParticipants(records);

      setPaginationParticipant({
        ...paginationParticipant,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  const getParticipantOption = async (courseId, scheduleId = 0, sessionId) => {
    try {
      setLoadingParticipant(true);

      const { data } = await http.get(
        `${api.v1.admin.report.course.main}/${courseId}/participant`,
        {
          params: {
            limit: 1000,
            page: 1,
            webinar_id: scheduleId,
            webinar_session_id: sessionId,
          },
        }
      );

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        value: item.user.id,
        label: `${item.user.email}`,
      }));

      setUserOptions(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  const getScheduleOption = async courseId => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        api.v1.admin.report.course.webinar_schedule(courseId),
        {
          params: { limit: 1000, page: 1 },
        }
      );

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        value: item.id,
        label: `${moment(item.first_date_schedule).format(
          "DD/MM/YYYY"
        )} - ${moment(item.last_date_schedule).format("DD/MM/YYYY")}`,
      }));

      setScheduleOptions(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const postAttendance = async (course_id, user_id, webinar_session_id) => {
    try {
      setLoadingParticipant(true);

      await http.post(
        api.v1.admin.report.course.submit_webinar_attendance(
          course_id,
          webinar_session_id,
          user_id
        )
      );

      getCourseParticipants(
        courseId,
        webinar.id,
        webinar.session_id,
        pagination?.current_page
      );
      setModalAttendance({ active: false, data: null });

      Swal.fire("Sukses", "User berhasil absen", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  const postChangeWebinarSchedule = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { changeSchedule } = payload;

      const user_id = changeSchedule.user.map(user => user.value);

      await http.post(
        api.v1.admin.report.course.change_webinar_schedule(courseId),
        {
          ...changeSchedule,
          webinar_id: changeSchedule.webinar_id.value,
          from_webinar_id: webinarScheduleId,
          user_id,
        }
      );

      setValue("changeSchedule", { type: "ALL", webinar_id: null, user: [] });
      setModalChangeSchedule(false);
      handleCloseModalSession();
      Swal.fire("Sukses", "Jadwal Peserta Berhasil Dipindahkan", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const exportDataWebinar = async (
    course_id,
    webinar_id,
    webinar_session_id
  ) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const webinarSession = webinarScheduleSessions.find(
        ({ id }) => id === webinar_session_id
      );

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - ${detailCourse.name} (${moment(
        webinarSession.date
      ).format("DD/MM/YYYY")}).xlsx`;

      const params = {
        webinar_id,
        webinar_session_id,
      };

      const res = await http.get(
        `${api.v1.admin.report.course.main}/${course_id}/export/webinar-session`,
        {
          params,
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const exportDataWebinarSession = async (courseId, scheduleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const format = watch("format.type");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - ${detailCourse.name}.${format}`;

      const params = {
        type: format,
      };

      const res = await http.get(
        `${api.v1.admin.report.course.main}/${courseId}/webinar-schedule/${scheduleId}/export`,
        {
          params,
          responseType: "blob",
        }
      );

      const type = `text/${format}`;
      link.href = URL.createObjectURL(new Blob([res.data], { type: type }));
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId);
  };

  const handleChangePageSession = (_, page) =>
    getListSessions(courseId, webinarScheduleId, page + 1);

  const handleChangeRowsPageSession = e => {
    setPaginationScheduleSession({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getListSessions(courseId, webinarScheduleId);
  };

  const handleShowParticipant = (sessionId, attendanceCode) => {
    setWebinarSessionId(sessionId);
    setSessionAttendanceCode(attendanceCode);
    getCourseParticipants(courseId, webinarScheduleId, sessionId);
    setWebinar({ id: webinarScheduleId, session_id: sessionId });
    setShowDataParticipant(true);
  };

  const handleChangePageParticipant = (_, page) =>
    getCourseParticipants(
      courseId,
      webinarScheduleId,
      webinarSessionId,
      page + 1
    );

  const handleChangeRowsPageParticipant = e => {
    setPaginationParticipant({
      ...paginationParticipant,
      limit: e.target.value,
    });
    paginationParticipant.limit = e.target.value;

    getCourseParticipants(courseId, webinarScheduleId, webinarSessionId);
  };

  const handleOpanModalSession = scheduleId => {
    setWebinarScheduleId(scheduleId);
    getListSessions(courseId, scheduleId);
    setOpenModalSession(true);
  };

  const handleCloseModalSession = () => {
    reset();
    setWebinarScheduleId();
    setSessionAttendanceCode("");
    setOpenModalSession(false);
    setShowDataParticipant(false);
    setWebinar({ id: null, session_id: null });
    modalAttendance;
  };

  const handleAttendance = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengabsenkan user ini",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        postAttendance(courseId, id, webinarSessionId);
      }
    });
  };

  useEffect(() => {
    getList(courseId);
    getDetailCourse(courseId);
    getScheduleOption(courseId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Jadwal Kelas Webinar</title>
      </Helmet>

      <DefaultHeader
        section="Data Jadwal Kelas Webinar"
        subsection="Data Kelas"
        routeBack={"/report/list-course"}
      />

      <Container maxWidth={false} component={Box}>
        {/* Detail Course */}
        <Grid container>
          <Grid item xs={12}>
            {!loadingDetailCourse && (
              <Card>
                <CardContent>
                  <Typography variant="h2" className="mb-3">
                    Detail Kelas
                  </Typography>

                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Nama Kelas
                        </Typography>
                        <p>{detailCourse?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Kategori
                        </Typography>
                        <p>{detailCourse?.course_category?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tipe Kelas
                        </Typography>
                        <p>{detailCourse?.course_type?.name}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Metode
                        </Typography>
                        <p>{detailCourse?.course_teach_method?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Instruktur
                        </Typography>
                        <p>{detailCourse?.instructors[0]?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Status
                        </Typography>
                        <p>{detailCourse?.status}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Harga
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Diskon
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.discount_price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Total
                        </Typography>
                        <p>
                          Rp{" "}
                          {formatRupiah(
                            detailCourse?.price - detailCourse?.discount_price
                          )}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setModalExportSchedule(true)}
                      >
                        <ImportExport className="mr-2" />
                        Export Data
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="ml-3"
                        onClick={() => setModalExportParticipant(true)}
                      >
                        <ImportExport className="mr-2" />
                        Export Peserta
                      </Button>
                    </Grid>

                    <WebinarScheduleReportDataTable
                      loading={loadingData}
                      data={webinarSchedules}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleOpanModalSession={handleOpanModalSession}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Modal User */}
        <Dialog
          open={openModalSession}
          onClose={() => handleCloseModalSession()}
          fullWidth={true}
          maxWidth={false}
        >
          <Container className="py-4" maxWidth={false}>
            <Grid container className="py-4">
              <Grid item xs={12} lg={6}>
                <Grid xs={12} className="py-3">
                  <Box textAlign="Center">
                    <h2>Daftar Sesi</h2>
                  </Box>
                </Grid>
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      <Grid sm={12}>
                        <Grid className="d-flex align-items-center justify-content-end">
                          <Grid sm={3} className="mr-2">
                            <Autocomplete
                              id="combo-box-category"
                              options={formatType}
                              getOptionLabel={option => option.label}
                              closeIcon={null}
                              onInputChange={(e, val) => {
                                const { value } = formatType.filter(
                                  data => data.label === val
                                )[0];

                                setValue("format.type", value);
                              }}
                              value={
                                formatType.filter(
                                  data => data.value === watch("format.type")
                                )[0]
                              }
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Tipe"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid className="mr-2">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                exportDataWebinarSession(
                                  courseId,
                                  webinarScheduleId
                                )
                              }
                            >
                              <ImportExport />
                              Export Data
                            </Button>
                          </Grid>

                          <Grid>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setModalChangeSchedule(true);

                                getParticipantOption(
                                  courseId,
                                  webinarScheduleId,
                                  webinarScheduleSessions[0].id
                                );
                              }}
                            >
                              <ImportExport />
                              Pindah Jadwal
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <CourseSessionReportDataTable
                      loading={loadingSessionData}
                      data={webinarScheduleSessions}
                      pagination={paginationScheduleSession}
                      handleChangePage={handleChangePageSession}
                      handleChangeRowsPage={handleChangeRowsPageSession}
                      detailCourse={detailCourse}
                      handleShowParticipant={handleShowParticipant}
                      webinar={true}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid xs={12} className="py-3">
                  <Box textAlign="Center">
                    <h2>Daftar Peserta {sessionAttendanceCode}</h2>
                  </Box>
                </Grid>
                <Grid>
                  {showDataParticipant ? (
                    <Card>
                      <Grid className="p-3 d-flex justify-content-end">
                        <Grid sm={12}>
                          <Grid className="d-flex align-items-center justify-content-end">
                            <Grid sm={2} className="mr-2">
                              <Autocomplete
                                id="combo-box-category"
                                options={status}
                                getOptionLabel={option => option.label}
                                closeIcon={null}
                                onInputChange={(e, val) => {
                                  const { value } = status.filter(
                                    data => data.label === val
                                  )[0];

                                  setValue("filter.filterProgress", value);
                                  getCourseParticipants(
                                    courseId,
                                    webinarScheduleId,
                                    webinarSessionId
                                  );
                                }}
                                popupIcon={
                                  <Box
                                    component={KeyboardArrowDown}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                  />
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Status"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            <OutlinedInput
                              type="text"
                              placeholder="Cari berdasarkan Nama"
                              startAdornment={
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              }
                              className="mr-3"
                              onKeyPress={e =>
                                e.key === "Enter" &&
                                getCourseParticipants(
                                  courseId,
                                  webinarScheduleId,
                                  webinarSessionId
                                )
                              }
                              {...register("filter.filterSearch")}
                            />

                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-2"
                              onClick={() =>
                                getCourseParticipants(
                                  courseId,
                                  webinarScheduleId,
                                  webinarSessionId
                                )
                              }
                            >
                              <Box
                                component={Search}
                                width="1.25rem!important"
                                height="1.25rem!important"
                                position="relative"
                              />
                            </Button>

                            <Grid>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  exportDataWebinar(
                                    courseId,
                                    webinarScheduleId,
                                    webinarSessionId
                                  )
                                }
                              >
                                <ImportExport />
                                Export Data
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <UserScheduleDataTable
                        loading={loadingParticipant}
                        data={courseParticipants}
                        pagination={paginationParticipant}
                        handleChangePage={handleChangePageParticipant}
                        handleChangeRowsPage={handleChangeRowsPageParticipant}
                        detailCourse={detailCourse}
                        handleAttendance={setModalAttendance}
                        webinar={true}
                      />
                    </Card>
                  ) : (
                    <Box textAlign="Center">
                      Pilih sesi terlebih dahulu untuk menampilkan data peserta.
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Dialog>

        {/* Modal Attendance */}
        <Dialog
          open={modalAttendance.active}
          onClose={() => setModalAttendance({ active: false, data: null })}
          fullWidth={true}
          maxWidth="md"
        >
          <Container className="py-4">
            <Box textAlign="Center">
              <h2>Absen User</h2>
            </Box>

            <Grid container className="py-4">
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nama
                  </Typography>
                  <p>{modalAttendance?.data?.name}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Email
                  </Typography>
                  <p>{modalAttendance?.data?.email}</p>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} className="mb-2">
                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nomor
                  </Typography>
                  <p>{modalAttendance?.data?.phone_number}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Waktu Absen
                  </Typography>
                  <p>
                    {modalAttendance?.data?.attendance
                      ?.user_learning_webinar_attendances.length
                      ? moment(
                          modalAttendance?.data?.attendance
                            ?.user_learning_webinar_attendances[0].submit_at
                        ).format("DD/MM/yyyy HH:mm")
                      : "-"}
                  </p>
                </Box>
              </Grid>

              {!modalAttendance?.data?.attendance
                ?.user_learning_webinar_attendances.length && (
                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    onClick={() => handleAttendance(modalAttendance?.data?.id)}
                  >
                    Absen
                  </Button>
                </Grid>
              )}
            </Grid>
          </Container>
        </Dialog>

        {/* Modal Pindah Jadwal */}
        <Dialog
          open={modalChangeSchedule}
          onClose={() => {
            setModalChangeSchedule(false);
            setValue("changeSchedule", {
              type: "ALL",
              webinar_id: null,
              user: [],
            });
          }}
          fullWidth={true}
          maxWidth="lg"
        >
          <Container className="py-4" maxWidth={false}>
            <Box textAlign="center">
              <h2>Pindah Jadwal</h2>
            </Box>

            <form onSubmit={handleSubmit(postChangeWebinarSchedule)}>
              <Grid container className="py-4">
                <Grid item sm={12}>
                  <FormGroup>
                    <FormLabel id="option-city-label">Pilih Jadwal</FormLabel>
                    <ReactSelect
                      isMulti={false}
                      options={scheduleOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={val => {
                        setValue("changeSchedule.webinar_id", val);
                      }}
                      value={watch("changeSchedule.webinar_id")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item sm={12}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-target-platform-label">
                        Target Peserta
                      </FormLabel>
                      <Select
                        IconComponent={KeyboardArrowDown}
                        labelId="option-target-platform-label"
                        value={
                          watch("changeSchedule.type") || targetUser[0].value
                        }
                        {...register("changeSchedule.type")}
                      >
                        {targetUser.map((val, key) => (
                          <MenuItem key={key} value={val.value}>
                            {val.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                </Grid>

                {watch("changeSchedule.type") === "SELECTED" && (
                  <Grid item sm={12}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-status-promo-code-label">
                          Pilih Peserta
                        </FormLabel>
                        <ReactSelect
                          isMulti
                          options={userOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={val => setValue("changeSchedule.user", val)}
                          value={watch("changeSchedule.user")}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                )}

                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Pindah Jadwal
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Dialog>

        {/* Modal Export Schedule */}
        <ModalExportShcedule
          show={modalExportSchedule}
          onClose={() => setModalExportSchedule(false)}
        />

        {/* Modal Export Participant */}
        <ModalExportParticipant
          show={modalExportParticipant}
          onClose={() => setModalExportParticipant(false)}
        />
      </Container>
    </>
  );
}

export default WebinarScheduleReportPage;
