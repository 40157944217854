import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  SnackbarContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Email, Lock } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import buttonTheme from "assets/theme/components/button";
import snackbarTheme from "assets/theme/components/snackbar.js";

import http from "../../services/http";
import api from "../../services/api";
import { baseActions } from "../../redux/actions";
import cookies from "../../helpers/cookies";

import bgCariilmu from "assets/img/background/bg-cariilmu.jfif";
import mainLogo from "assets/img/brand/main-logo.png";
import jwtDecode from "jwt-decode";
import localStorage from "helpers/localStorage";

const buttonStyles = makeStyles(buttonTheme);
const snackbarStyles = makeStyles(snackbarTheme);

export default function LoginPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const styles = { ...buttonStyles(), ...snackbarStyles() };

  const [error, setError] = useState(null);

  const postLogin = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.post(api.v1.admin.auth.login, payload);

      setError(null);

      const { token, user } = data.data;

      const permissions = user?.admin_role?.permissions;
      delete user?.admin_role?.permissions;

      localStorage.set("permissions", JSON.stringify(permissions));

      cookies.set("token", token);
      cookies.set("user", user);

      dispatch(baseActions.SET_USER(user));

      const { role } = jwtDecode(token);

      history.push(
        role === "INSTRUCTOR"
          ? "/admin-instructor/dashboard"
          : role === "ADMIN_OPERATION"
          ? "/learning-progress/course"
          : "/dashboard"
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 401) setError(response.data.meta.message);
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    const token = cookies.get("token");
    const user = cookies.get("user");
    const permissions = JSON.parse(localStorage.get("permissions"))?.map(
      permission => permission.code
    );

    if (token && user && !!permissions) {
      const { role } = jwtDecode(token);

      history.push(
        role === "INSTRUCTOR"
          ? "/admin-instructor/dashboard"
          : role === "ADMIN_OPERATION"
          ? "/learning-progress/course"
          : "/dashboard"
      );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Masuk</title>
      </Helmet>

      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          background: `linear-gradient(
            rgba(0, 0, 0, 0.06), 
            rgba(0, 0, 0, 0.06)
          ), url(${bgCariilmu})`,
          backgroundSize: "cover",
        }}
      >
        <Container
          component={Box}
          minHeight="100vh"
          className="d-flex justify-content-center align-items-center"
        >
          <Box item component={Grid} justifyContent="center" md={6}>
            <Card>
              <CardContent>
                <Box textAlign="center" marginBottom={4}>
                  <img src={mainLogo} alt="logo-cariilmu" width={200} />
                </Box>

                {error && (
                  <SnackbarContent
                    classes={{ root: styles.errorSnackbar }}
                    action={
                      <Box
                        component={IconButton}
                        padding="0!important"
                        onClick={() => setError(null)}
                      >
                        <Box component="span" color={theme.palette.white.main}>
                          ×
                        </Box>
                      </Box>
                    }
                    message={<Box>{error}</Box>}
                  />
                )}

                <form onSubmit={handleSubmit(postLogin)}>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem !important"
                  >
                    <FilledInput
                      autoComplete="off"
                      type="email"
                      placeholder="Alamat Email"
                      {...register("email")}
                      startAdornment={
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem !important"
                  >
                    <FilledInput
                      autoComplete="off"
                      type="password"
                      placeholder="Kata Sandi"
                      {...register("password")}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <Box
                    textAlign="center"
                    marginTop="1.5rem"
                    marginBottom="1.5rem"
                    width="100%"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="w-100"
                      classes={{ root: styles.buttonContainedPrimary }}
                    >
                      Masuk
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
}
