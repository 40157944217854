import React from "react";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

export default function WebinarScheduleDataTable({
  data,
  handleDeleteData = () => {},
  handleEditData = () => {},
}) {
  const columns = [
    "Jadwal",
    "Tanggal",
    "Waktu Mulai",
    "Waktu Selesai",
    "Link Webinar",
    "Link Telegram",
    "Opsi",
  ];

  return (
    <TableContainer component={Paper} width="100%">
      <Table width="100%">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns?.map((item, key) => {
              if (handleDeleteData === null && key + 1 === columns.length)
                return null;

              return (
                <TableCell key={key} align="center">
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody width="100%">
          {!data?.length ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                Tidak Ada Data
              </TableCell>
            </TableRow>
          ) : (
            data?.map((schedules, scheduleKey) => {
              const rowspan = schedules?.length;

              return schedules?.map((item, key) => (
                <TableRow key={key}>
                  {key === 0 && (
                    <TableCell rowSpan={rowspan} align="center">
                      {scheduleKey + 1}
                    </TableCell>
                  )}
                  <TableCell align="center">{item?.date}</TableCell>
                  <TableCell align="center">{item?.start_time}</TableCell>
                  <TableCell align="center">{item?.end_time}</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {item?.webinar_url}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {item?.telegram_url}
                  </TableCell>
                  {key === 0 ? (
                    <TableCell rowSpan={rowspan} align="center" width="10px">
                      <IconButton onClick={() => handleEditData(scheduleKey)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteData(scheduleKey)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  ) : null}
                </TableRow>
              ));
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
