import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  SnackbarContent,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import { KeyboardArrowDown } from "@material-ui/icons";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";

import http from "services/http";
import api from "services/api";
import Spinner from "components/Loading/Indicator/Spinner";

const snackbarStyles = makeStyles(snackbarTheme);

export default function DetailBundleDigitalProductPage() {
  const { id } = useParams();
  const styles = { ...snackbarStyles() };

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      bundle: {
        name: "",
        slug: "",
        description: "",
        discount_price: 0,
      },
      avatar: "",
    },
  });

  const statusOptions = ["UNLISTED", "DRAFT", "PUBLISH"];

  const [imageSrc, setImageSrc] = useState(null);

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [optionDigitalProductData, setOptionDigitalProduct] = useState([]);
  const [coursePrice, setCoursePrice] = useState([]);

  /**
   * Fetch Data
   */
  const getOptionDigitalProduct = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.digital_product.main}`, {
        params: { option: true, price: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        label: item.name,
        value: item.id,
        price: Number(item.price),
        discount_price: Number(item.discount_price),
      }));

      setOptionDigitalProduct(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getDetailBundleDigitalProduct = async () => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        `${api.v1.admin.digital_product_bundle.main}/${id}`
      );

      const dataBundle = {
        name: data.data.name,
        slug: data.data.slug,
        description: data.data.description,
        discount_price: Number(data.data.discount_price),
        status: data.data.status,
        target_digital_products: data.data.digital_products.map(({ id }) => id),
      };

      const selectedBundle = data.data.digital_products.map(course => ({
        label: course.name,
        value: course.id,
        price: Number(course.price),
        discount_price: Number(course.discount_price),
      }));

      setCoursePrice(
        selectedBundle.reduce((a, b) => a + b.price - b.discount_price, 0)
      );

      //set img
      setImageSrc(data.data?.cover);
      fetch(data.data?.cover + `?cachebuster=${new Date().getTime()}`).then(
        async res => {
          const blob = await res.blob();
          const file = new File([blob], "avatar.jpg", { type: blob.type });
          setValue("avatar", [file]);
        }
      );

      setValue("target_digital_products", selectedBundle);

      setValue("bundle", dataBundle);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const putBundleDigitalProduct = async () => {
    try {
      if (coursePrice < watch("bundle_course.discount_price")) {
        document.documentElement.scrollTop = 0;
        return setError("Diskon tidak bisa lebih dari harga kelas");
      }

      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const {
        name,
        slug,
        description,
        discount_price,
        status,
        target_digital_products,
      } = watch("bundle");
      const avatar = watch("avatar");

      const formData = new FormData();
      formData.append("name", name);
      formData.append("slug", slug);
      formData.append("description", description);
      formData.append("discount_price", discount_price);
      formData.append("status", status);
      formData.append("cover", avatar[0]);

      target_digital_products.forEach((bundle, i) => {
        formData.append(`target_digital_products[${i}]`, bundle);
      });

      await http.put(
        `${api.v1.admin.digital_product_bundle.main}/${id}`,
        formData
      );

      Swal.fire(
        "Sukses",
        "Data Paket Produk Digital berhasil di perbarui",
        "success"
      );

      history.push("/bundle-digital-product");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Event
   */
  const registerAvatarField = register("avatar", {
    required: "Cover tidak boleh kosong",
  });

  const onAvatarChange = e => {
    if (e.target.files) {
      setValue("avatar", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handlechangeInput = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (name === "name") {
      setValue("bundle.name", value);
      setValue("bundle.slug", value.replace(/ /g, "-").toLowerCase());
    } else if (name === "slug") {
      setValue("bundle.slug", value.replace(/ /g, "-").toLowerCase());
    } else {
      setValue(`bundle.${name}`, value);
    }
  };

  useEffect(() => {
    getOptionDigitalProduct();
    getDetailBundleDigitalProduct();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Paket Produk Digital</title>
      </Helmet>

      <DefaultHeader
        section="Detail Paket Produk Digital"
        subsection="Paket Produk Digital"
        routeBack="/bundle-digital-product"
      />

      <Container maxWidth={false} component={Box}>
        <Grid container component={Box} justifyContent="center">
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <Card className="p-4">
                {error && (
                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <SnackbarContent
                        classes={{ root: styles.errorSnackbar }}
                        action={
                          <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => setError(null)}
                          >
                            <Box
                              component="span"
                              color={theme.palette.white.main}
                            >
                              ×
                            </Box>
                          </Box>
                        }
                        message={<Box>{error}</Box>}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Nama</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        name="name"
                        onChange={handlechangeInput}
                        value={watch("bundle.name")}
                        // {...register("course.name")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Slug</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        name="slug"
                        onChange={handlechangeInput}
                        value={watch("bundle.slug")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Deskripsi</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("bundle.description")}
                        onChange={val => setValue("bundle.description", val)}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-status-label">Status</FormLabel>
                        <Select
                          IconComponent={KeyboardArrowDown}
                          labelId="option-status-label"
                          value={watch("bundle.status") || ""}
                          {...register("bundle.status")}
                        >
                          {statusOptions.map((val, key) => (
                            <MenuItem key={key} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-status-promo-code-label">
                          Product yang dipilih
                        </FormLabel>
                        <ReactSelect
                          isMulti
                          options={optionDigitalProductData}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={val => {
                            setCoursePrice(
                              val.reduce(
                                (a, b) => a + b.price - b.discount_price,
                                0
                              )
                            );
                            setValue("target_digital_products", val);
                            setValue(
                              "bundle.target_digital_products",
                              val.map(({ value }) => value)
                            );
                          }}
                          value={watch("target_digital_products")}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Harga Paket</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        disabled
                        value={coursePrice}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Diskon</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        {...register("bundle.discount_price")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Total</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        disabled
                        value={coursePrice - watch("bundle.discount_price")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Gambar Paket Produk Digital</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="file"
                        placeholder="Default input"
                        inputProps={{ accept: "image/*" }}
                        onChange={e => {
                          registerAvatarField.onChange(e);
                          onAvatarChange(e);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={9}></Grid>
                  <Grid item xs={12} md={6}>
                    {imageSrc && (
                      <Box
                        component="img"
                        sx={{
                          height: 200,
                          width: 350,
                          maxHeight: { xs: 200, md: 200 },
                          maxWidth: { xs: 350, md: 350 },
                          borderRadius: "16px",
                        }}
                        alt="Cover."
                        src={imageSrc}
                      />
                    )}
                  </Grid>
                  <Grid item md={6}></Grid>
                  <Grid item xs={12} className="mt-5">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      onClick={putBundleDigitalProduct}
                    >
                      Perbarui
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
