import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";

import moment from "moment";
import api from "services/api";
import http from "services/http";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";

import themeColors from "assets/theme/colors";
import { useParams } from "react-router-dom";

export default function ModalExportShcedule({ show, onClose }) {
  const dispatch = useDispatch();

  const { courseId } = useParams();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      start_date: moment(),
      end_date: moment(),
    },
  });

  const [error, setError] = useState(null);

  /**
   * Fetch Data
   */
  const exportData = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Data Jadwal Webinar.xlsx`;

      const params = val;

      const res = await http.get(
        `${api.v1.admin.report.course.webinar_schedule(courseId)}/export`,
        {
          params,
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      open={show}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="sm"
    >
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Export Data Jadwal</h2>
        </Box>

        <form onSubmit={handleSubmit(exportData)}>
          <Grid container className="py-4">
            {error && (
              <Grid item xs={12}>
                <Box textAlign="center" color={themeColors.error.main}>
                  {error}
                </Box>
              </Grid>
            )}

            <Grid item xs={6}>
              <FormGroup>
                <FormLabel>Tanggal Awal</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="date"
                  {...register("start_date")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                <FormLabel>Tanggal Akhir</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="date"
                  {...register("end_date")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
}
