import React from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import theme from "assets/theme/theme";
import boxShadows from "assets/theme/box-shadow";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  buttonRootUnselected: {
    background: theme.palette.white.main + "!important",
    color: theme.palette.primary.main + "!important",
  },
  buttonSpace: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "49%",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "1rem!important",
    },
  },
}));

export default function CardUserDevice({ userDevices }) {
  const classes = useStyles();
  return (
    <div>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing=".0625rem"
                  marginBottom=".25rem!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[400]}>
                    Statistik
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h2"
                  marginBottom="0!important"
                >
                  <Box component="span">Pengguna Device</Box>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        />
        <CardContent position="relative">
          <Box height="350px">
            <Pie
              data={userDevices}
              options={{
                legend: { display: true },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
