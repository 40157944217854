import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Container,
  Grid,
  Button,
  OutlinedInput,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import Swal from "sweetalert2";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import CourseDataTable from "./CourseDataTable";

export default function ListCoursePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, watch, setValue } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [courseData, setCourseData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [teachMethods, setTeachMethods] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { name, type_id, category_id, level_id, teach_method_id } =
        watch("filter");

      const { data } = await http.get(api.v1.admin.course.main, {
        params: {
          limit,
          page,
          name,
          type_id,
          category_id,
          level_id,
          teach_method_id,
        },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getListCategories = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.category, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        name: "Semua",
      });

      setCategories(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListTypes = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.type, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        name: "Semua",
      });

      setTypes(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListLevels = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.level, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        name: "Semua",
      });

      setLevels(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListTeachMethods = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.teach_method, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        name: "Semua",
      });

      setTeachMethods(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const postDuplicateCourse = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const course_id = id;

      await http.post(`${api.v1.admin.course.main}/duplicate`, {
        course_id,
      });

      Swal.fire("Sukses", "Berhasil duplikat kelas", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course.main}/${id}`);

      getList();

      Swal.fire("Sukses", "Data kelas berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) await deleteData(id);
    });
  };

  const handleDuplicateCourse = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menduplikat data kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) await postDuplicateCourse(id);
    });
  };

  useEffect(() => {
    getList();
    getListCategories();
    getListTypes();
    getListLevels();
    getListTeachMethods();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Kelas</title>
      </Helmet>

      <DefaultHeader section="Data Kelas" subsection="Kelola Kelas" />

      <Container maxWidth={false} component={Box} id="course-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <>
                <Grid>
                  <Grid
                    className="mb-4"
                    container
                    component={Box}
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("/kelas/tambah")}
                      >
                        Tambah Data
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid>
                    <Card>
                      <Grid className="p-3 d-flex justify-content-between">
                        <Grid sm={8} className="d-flex">
                          {/* Filter by Type Course */}
                          <Grid sm={2} className="mr-2">
                            <Autocomplete
                              id="combo-box-category"
                              options={types}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onInputChange={(e, val) => {
                                const { id } = types.filter(
                                  data => data.name === val
                                )[0];

                                setValue("filter.type_id", id);
                                getList();
                              }}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Tipe"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          {/* Filter by Category Course */}
                          <Grid sm={3} className="mr-2">
                            <Autocomplete
                              id="combo-box-category"
                              options={categories}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onInputChange={(e, val) => {
                                const { id } = categories.filter(
                                  data => data.name === val
                                )[0];

                                setValue("filter.category_id", id);
                                getList();
                              }}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="kategori"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          {/* Filter by Level Course */}
                          <Grid sm={2} className="mr-2">
                            <Autocomplete
                              id="combo-box-category"
                              options={levels}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onInputChange={(e, val) => {
                                const { id } = levels.filter(
                                  data => data.name === val
                                )[0];

                                setValue("filter.level_id", id);
                                getList();
                              }}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Level"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          {/* Filter by Teach Method Course */}
                          <Grid sm={3} className="mr-2">
                            <Autocomplete
                              id="combo-box-category"
                              options={teachMethods}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onInputChange={(e, val) => {
                                const { id } = teachMethods.filter(
                                  data => data.name === val
                                )[0];

                                setValue("filter.teach_method_id", id);
                                getList();
                              }}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Metode Belajar"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        {/* Search by Name */}
                        <Grid sm={4}>
                          <Grid className="d-flex align-items-center justify-content-end">
                            <OutlinedInput
                              type="text"
                              placeholder="Cari berdasarkan Nama"
                              startAdornment={
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              }
                              className="mr-3"
                              onKeyPress={e => e.key === "Enter" && getList()}
                              {...register("filter.name")}
                            />

                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => getList()}
                            >
                              <Box
                                component={Search}
                                width="1.25rem!important"
                                height="1.25rem!important"
                                position="relative"
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <CourseDataTable
                        loading={loadingData}
                        data={courseData}
                        pagination={pagination}
                        handleDeleteData={handleDeleteData}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPage={handleChangeRowsPage}
                        handleDuplicateCourse={handleDuplicateCourse}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
