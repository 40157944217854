import {
  Box,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

import themeColors from "assets/theme/colors";
import ChipData from "components/Chip/ChipData";

import Pagination from "components/Pagination";
import moment from "moment";
import { useState } from "react";

export default function DetailCourseLogDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const [open, setOpen] = useState(false);

  const columns = [
    "",
    "#",
    "Tipe Tugas",
    "Status Tugas",
    "Email Peserta",
    "Tanggal Pengumpulan",
    "Tugas",
  ];

  const statusText = {
    ASSESSED: "Dinilai",
    REASSESSED: "Dinilai Ulang",
  };

  const typeColor = {
    UK: themeColors.success.main,
    TPM: themeColors.info.main,
  };

  const statusColor = {
    ASSESSED: themeColors.facebook.main,
    REASSESSED: themeColors.warning.main,
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((log, index) => (
              <>
                <TableRow key={log?.id}>
                  <TableCell align="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen(prev => ({
                          ...prev,
                          [log.id]: !prev[log.id],
                        }))
                      }
                    >
                      {open[log.id] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {pagination.current_page > 1
                      ? (pagination.current_page - 1) * pagination.limit +
                        (index + 1)
                      : index + 1}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <ChipData
                      color="secondary"
                      text={log?.type}
                      style={{
                        color: themeColors.gray[100],
                        backgroundColor: typeColor[log?.type],
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <ChipData
                      color="secondary"
                      text={statusText[log?.status]}
                      style={{
                        color: themeColors.gray[100],
                        backgroundColor: statusColor[log?.status],
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {log.type === "TPM"
                      ? log?.user_learning_practice_assignment?.user?.email
                      : log?.user_learning_assignment?.user?.email}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {moment(log?.created_at).format("DD/MM/YYYY, HH:mm")}
                  </TableCell>
                  <TableCell component="td" align="center">
                    <a
                      target="_blank"
                      href={
                        log.type === "TPM"
                          ? log?.user_learning_practice_assignment
                              ?.submission_url
                          : log?.user_learning_assignment?.submission_url
                      }
                      rel="noreferrer"
                      download
                    >
                      Unduh Tugas
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open[log.id]} timeout="auto" unmountOnExit>
                      <Grid container className="mb-2">
                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem>
                              <ListItemText
                                primary="Nama"
                                secondary={
                                  log.type === "TPM"
                                    ? log?.user_learning_practice_assignment
                                        ?.user?.name
                                    : log?.user_learning_assignment?.user?.name
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                primary="Email"
                                secondary={
                                  log.type === "TPM"
                                    ? log?.user_learning_practice_assignment
                                        ?.user?.email
                                    : log?.user_learning_assignment?.user?.email
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <Grid>
                            <List dense disablePadding={true}>
                              <ListItem>
                                <ListItemText
                                  primary="Nilai"
                                  secondary={log?.score}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemText
                                  primary="Komentar"
                                  secondary={log?.comment}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
