import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import { useState } from "react";
import ModalScore from "./ModalScore";

export default function ParticipantDataTable({
  refetch,
  loading,
  data,
  detailData,
  assignmentCourse,
  courseId,
  scheduleId,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const [openModalScore, setOpenModalScore] = useState(false);

  const [userData, setUserData] = useState(null);

  const columns = [
    "#",
    "Nama",
    "Progres",
    ...(assignmentCourse ? ["Tugas", "Nilai", "Opsi"] : []),
  ];

  return (
    <>
      <TableContainer component={Paper} width="100%">
        <Box component={Table} alignItems="center">
          <TableHead>
            <TableRow
              style={{
                backgroundColor: themeColors.primary.main,
              }}
            >
              {columns.map((item, key) => (
                <TableCell
                  key={key}
                  style={{ fontWeight: 700 }}
                  align="center"
                  width={key === 0 ? "100px" : null}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && data?.length ? (
              data.map((participant, index) => (
                <TableRow key={participant.id}>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {participant?.user?.name}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {participant?.progress_percentage}%
                  </TableCell>
                  {assignmentCourse ? (
                    <>
                      <TableCell component="td" align="center">
                        {participant?.user?.user_learning_progresses?.length ? (
                          participant?.user?.user_learning_progresses[0]
                            ?.learning_assignment?.submission_url === null ? (
                            "Belum Upload Tugas"
                          ) : (
                            <a
                              target="_blank"
                              href={
                                participant?.user?.user_learning_progresses[0]
                                  ?.learning_assignment?.submission_url
                              }
                              rel="noreferrer"
                              download
                            >
                              Unduh Tugas
                            </a>
                          )
                        ) : (
                          "Belum Upload Tugas"
                        )}
                      </TableCell>
                      <TableCell component="td" align="center">
                        {participant?.user?.user_learning_progresses?.length
                          ? participant?.user?.user_learning_progresses[0]
                              ?.learning_assignment?.score === null
                            ? "Belum Dinilai"
                            : participant?.user?.user_learning_progresses[0]
                                ?.learning_assignment?.score
                          : "Belum Bisa Dinilai"}
                      </TableCell>
                      <TableCell component="td" align="center">
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          className="ml-1"
                          disabled={
                            !participant?.user?.user_learning_progresses
                              .length ||
                            participant?.user?.user_learning_progresses[0]
                              ?.learning_assignment?.submission_url === null
                          }
                          onClick={() => {
                            setUserData({
                              id: participant?.user?.id,
                              name: participant?.user?.name,
                            });

                            setOpenModalScore(true);
                          }}
                        >
                          Beri Nilai
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    false
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell component="td" colSpan={8}>
                  {loading ? (
                    <Box textAlign="center">Harap Tunggu...</Box>
                  ) : (
                    <Box textAlign="center">Tidak ada data</Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <Pagination
                pagination={pagination}
                colSpan={2}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </TableContainer>

      {/* Modal Score */}
      <ModalScore
        openModal={openModalScore}
        refetch={refetch}
        onClose={() => setOpenModalScore(false)}
        courseId={courseId}
        scheduleId={scheduleId}
        userId={userData?.id}
        userName={userData?.name}
        detailData={detailData}
      />
    </>
  );
}
