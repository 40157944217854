import React, { useEffect, useState } from "react";

import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import componentStyles from "assets/theme/views";
import { Helmet } from "react-helmet";
import api from "services/api";
import http from "services/http";
import CardTopCourse from "./CardTopCourse";
import DashboardStatsHeader from "./DashboardStatsHeader";
import CardWebinarSchedule from "./CardWebinarSchedule";
import CardOfflineSchedule from "./CardOfflineSchedule";
import CardParticipantRated from "./CardParticipantRated";

const useStyles = makeStyles(componentStyles);

export default function InstructorDashboardPage() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalCourse: 0,
    totalParticipant: 0,
    totalAllParticipant: 0,
    topCourseParticipant: [],
    participantNotYetRated: [],
    webinarScheduleUpcomings: [],
    offlineScheduleUpcomings: [],
  });

  /**
   * Fetch Data
   */
  const getDashboardData = async () => {
    try {
      setLoading(true);

      const { data } = await http.get(api.v1.instructor.dashboard.main);

      const {
        total_course,
        total_participant,
        total_participant_unique,
        top_course_participants,
        participant_not_yet_rateds,
        webinar_schedule_upcomings,
        offline_schedule_upcomings,
      } = data.data;

      setData({
        totalCourse: total_course,
        totalParticipant: total_participant_unique,
        totalAllParticipant: total_participant,
        topCourseParticipant: top_course_participants,
        participantNotYetRated: participant_not_yet_rateds,
        webinarScheduleUpcomings: webinar_schedule_upcomings,
        offlineScheduleUpcomings: offline_schedule_upcomings,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Dashboard</title>
      </Helmet>

      <DashboardStatsHeader
        totalCourse={data.totalCourse}
        totalUser={data.totalParticipant}
        totalAllParticipant={data.totalAllParticipant}
        loading={loading}
      />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading ? (
              <CardTopCourse topCourses={data.topCourseParticipant} />
            ) : (
              <Skeleton
                variant="rect"
                height={419}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>

          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading ? (
              <CardParticipantRated data={data.participantNotYetRated} />
            ) : (
              <Skeleton
                variant="rect"
                height={419}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>

          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading ? (
              <CardWebinarSchedule
                webinarScheduleUpcomings={data.webinarScheduleUpcomings}
              />
            ) : (
              <Skeleton
                variant="rect"
                height={419}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>

          <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
            {!loading ? (
              <CardOfflineSchedule
                offlineScheduleUpcomings={data.offlineScheduleUpcomings}
              />
            ) : (
              <Skeleton
                variant="rect"
                height={419}
                style={{ borderRadius: "0.375rem", marginBottom: "30px" }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
