export default {
  set: (name, value) => {
    localStorage.setItem(name, value);
  },

  get: name => {
    const data = localStorage.getItem(name);

    return data;
  },

  destroy: name => {
    return localStorage.removeItem(name);
  },
};
