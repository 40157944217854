import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import http from "services/http";
import api from "services/api";
import Swal from "sweetalert2";
import { baseActions } from "redux/actions";
import { useForm } from "react-hook-form";
import DemonstrationSkillsDataTable from "./DemonstrationSkillsDataTable";

export default function DemonstrationSkillsTab() {
  const { id: courseId } = useParams();
  const dispatch = useDispatch();

  const { handleSubmit, setValue, reset, register } = useForm({
    defaultValues: {
      id: null,
      skills: "",
      percentage: 0,
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [certificateStandardData, setEvaluationMethodData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalEvaluationMethod, setTypeModalEvaluationMethod] =
    useState("CREATE");
  const [showModalEvaluationMethod, setShowModalEvaluationMethod] =
    useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        api.v1.admin.course.demonstration_skills(courseId),
        {
          params: {
            limit,
            page,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setEvaluationMethodData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.course.demonstration_skills(courseId)}/${id}`
      );

      setValue("id", data.data.id);
      setValue("skills", data.data.skills);

      handleOpenModal("UPDATE");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreatedata = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        api.v1.admin.course.demonstration_skills(courseId),
        payload
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data unjuk keterampilan berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.put(
        `${api.v1.admin.course.demonstration_skills(courseId)}/${payload.id}`,
        payload
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data unjuk keterampilan berhasil di perbarui",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.demonstration_skills(courseId)}/${id}`
      );

      Swal.fire(
        "Sukses",
        "Data unjuk keterampilan berhasil di hapus",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = type => {
    setTypeModalEvaluationMethod(type);
    setShowModalEvaluationMethod(true);
  };

  const handleCloseModal = () => {
    setShowModalEvaluationMethod(false);
    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data standar sertifikat",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) await deleteData(id);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Card className="p-4">
      <Grid container>
        <Grid item xs={12}>
          {error ? (
            <Box textAlign="center" color={themeColors.error.main}>
              {error}
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={12} className="mb-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModal("CREATE")}
                >
                  Tambah Data
                </Button>
              </Grid>

              <Grid item xs={12}>
                <DemonstrationSkillsDataTable
                  loading={loadingData}
                  data={certificateStandardData}
                  pagination={pagination}
                  getDetailData={getDetailData}
                  handleDeleteData={handleDeleteData}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPage={handleChangeRowsPage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Modal Create & Update Standard Certificate */}
      <Dialog
        open={showModalEvaluationMethod}
        onClose={() => handleCloseModal()}
        fullWidth={true}
        maxWidth="md"
      >
        <Container className="py-4">
          <Box textAlign="center">
            <h2>Data Unjuk Keterampilan</h2>
          </Box>

          <form
            onSubmit={
              typeModalEvaluationMethod === "CREATE"
                ? handleSubmit(postCreatedata)
                : handleSubmit(putUpdateData)
            }
          >
            <Grid container className="py-4">
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Kemampuan</FormLabel>
                  <OutlinedInput
                    fullWidth
                    type="text"
                    {...register("skills")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Persentase</FormLabel>
                  <OutlinedInput
                    fullWidth
                    type="number"
                    {...register("percentage")}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  {typeModalEvaluationMethod === "CREATE"
                    ? "Tambah"
                    : "Perbarui"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    </Card>
  );
}
