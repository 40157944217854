import { createSlice } from "@reduxjs/toolkit";

import cookies from "helpers/cookies";

const user = cookies.get("user");

const baseSlice = createSlice({
  name: "base",

  initialState: {
    loading: {
      screen: false,
    },

    user: user || {},
  },

  reducers: {
    SET_LOADING_SCREEN: (state, { payload }) => {
      state.loading.screen = payload;

      return;
    },

    SET_USER: (state, { payload }) => {
      state.user = payload;

      return;
    },
  },
});

export const baseActions = baseSlice.actions;

export const baseReducer = baseSlice.reducer;
