import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { baseActions } from "redux/actions";

import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import QuizMixAndMatchDataTable from "./QuizMixAndMatchDataTable";

export default function ListQuizMixAndMatchPage() {
  const dispatch = useDispatch();

  const { courseId, moduleId, subModuleId, videoQuizId } = useParams();

  const { setValue, handleSubmit, register } = useForm({
    defaultValues: {
      quiz: {
        question: "",
        answer: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [quizData, setQuizData] = useState([]);

  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalQuiz, setTypeModalQuiz] = useState("CREATE");
  const [showModalQuiz, setShowModalQuiz] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (
    courseId,
    moduleId,
    subModuleId,
    videoQuizId,
    page = 1
  ) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${videoQuizId}/mix-and-match`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setQuizData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async (
    courseId,
    moduleId,
    subModuleId,
    videoQuizId,
    id
  ) => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${videoQuizId}/mix-and-match/${id}`
      );

      setValue("quiz", {
        id: id,
        question: data.data.question,
        answer: data.data.answer.answer,
      });

      setTypeModalQuiz("UPDATE");
      setShowModalQuiz(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { quiz } = payload;

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${videoQuizId}/mix-and-match`,
        {
          ...quiz,
        }
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data pertanyaan dan jawaban berhasil di tambahkan",
        "success"
      );

      getList(courseId, moduleId, subModuleId, videoQuizId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { quiz } = payload;

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${videoQuizId}/mix-and-match/${quiz.id}`,
        {
          ...quiz,
        }
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data pertanyaan dan jawaban berhasil di Perbarui",
        "success"
      );

      getList(courseId, moduleId, subModuleId, videoQuizId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async (
    courseId,
    moduleId,
    subModuleId,
    videoQuizId,
    id
  ) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/video-quiz/${videoQuizId}/mix-and-match/${id}`
      );

      getList(courseId, moduleId, subModuleId, videoQuizId);

      Swal.fire(
        "Sukses",
        "Pertanyaan dan jawaban berhasil di hapus",
        "success"
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) =>
    getList(courseId, moduleId, subModuleId, videoQuizId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId, moduleId, subModuleId, videoQuizId);
  };

  const handleOpenModal = async () => {
    setTypeModalQuiz("CREATE");
    setShowModalQuiz(true);
  };

  const handleCloseModal = () => {
    setShowModalQuiz(false);
    setValue("quiz", {
      question: "",
      answer: "",
    });
  };

  const handleDeleteData = (
    courseId,
    moduleId,
    subModuleId,
    videoQuizId,
    id
  ) => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus pertanyaan dan jawaban",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(courseId, moduleId, subModuleId, videoQuizId, id);
      }
    });
  };

  useEffect(() => {
    getList(courseId, moduleId, subModuleId, videoQuizId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Kuis Video</title>
      </Helmet>

      <DefaultHeader
        section="Kuis Mix and Match"
        subsection="Kuis Video"
        routeBack={`/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/video-quiz`}
      />

      <Container maxWidth={false} component={Box} id="course-video-quiz-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <QuizMixAndMatchDataTable
                      loading={loadingData}
                      data={quizData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Quiz Mix and Match */}
                <Dialog
                  open={showModalQuiz}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Kuis Mix and Match</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalQuiz === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Pertanyaan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("quiz.question")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Jawaban</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("quiz.answer")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalQuiz === "CREATE" ? "Tambah" : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
