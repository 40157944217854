import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";
import ChipData from "components/Chip/ChipData";

import Pagination from "components/Pagination";
import formatStatusLogWhatsapp from "helpers/formatStatusLogWhatsapp";

export default function LogWhatsappDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const columns = ["#", "Dari", "Tujuan", "Status", "Kelas"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((column, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && data.length ? (
            data.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  style={{ whiteSpace: "normal" }}
                >
                  {item.from}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  style={{ whiteSpace: "normal" }}
                >
                  {item.to}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  style={{ whiteSpace: "normal" }}
                >
                  <ChipData
                    color="primary"
                    text={formatStatusLogWhatsapp(item.status.toUpperCase())}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  style={{ whiteSpace: "normal" }}
                >
                  {item?.whatsapp_reminder_course
                    ? `${item?.whatsapp_reminder_course?.course?.name} - ${item?.whatsapp_reminder_course?.course?.code}`
                    : "-"}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
