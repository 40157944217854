import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";

import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import CustomSectionDataTable from "./CustomSectionDataTable";
import { KeyboardArrowDown } from "@material-ui/icons";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const platformOptions = [
  { value: "WEB", label: "Web" },
  { value: "MOBILE", label: "Mobile" },
];

const layoutTypeOptions = [
  { value: "VERTICAL", label: "Menurun" },
  { value: "HORIZONTAL", label: "Mendatar" },
];

const statusOptions = [
  { value: 1, label: "Aktif" },
  { value: 2, label: "Tidak Aktif" },
];

export default function ListCustomSectionPage() {
  const dispatch = useDispatch();

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      custom_section: {
        name: "",
        code: "",
        platform: "WEB",
        active: 1,
        layout_type: "VERTICAL",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [customSectionData, setCustomSectionData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalCustomSection, setTypeModalCustomSection] =
    useState("CREATE");
  const [showModalCustomSection, setShowModalCustomSection] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.custom_section.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCustomSectionData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.custom_section.main}/${id}`
      );

      setValue("custom_section", {
        id,
        name: data.data.name,
        code: data.data.code,
        platform: data.data.platform,
        active: data.data.active,
        layout_type: data.data.layout_type,
      });

      setTypeModalCustomSection("UPDATE");
      setShowModalCustomSection(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { custom_section } = payload;

      await http.post(api.v1.admin.custom_section.main, custom_section);

      handleCloseModal();
      setShowModalCustomSection(false);

      Swal.fire(
        "Sukses",
        "Data sorotan bagian khusus berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { custom_section } = payload;

      await http.put(
        `${api.v1.admin.custom_section.main}/${custom_section.id}`,
        custom_section
      );

      handleCloseModal();
      setShowModalCustomSection(false);

      Swal.fire(
        "Sukses",
        "Data sorotan bagian khsusus berhasil di perbarui",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.custom_section.main}/${id}`);

      Swal.fire(
        "Sukses",
        "Data sorotan bagian khusus berhasil di hapus",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postChangeOrder = async (to, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.custom_section.main}/${id}/change-order`,
        { to }
      );

      Swal.fire(
        "Sukses",
        "Urutan sorotan bagian khusus berhasil di ubah",
        "success"
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleOpenModal = async () => {
    setTypeModalCustomSection("CREATE");
    setShowModalCustomSection(true);
  };

  const handleCloseModal = () => {
    setShowModalCustomSection(false);
    setValue("custom_section", {
      name: "",
      code: "",
      platform: "WEB",
      active: 1,
      layout_type: "VERTICAL",
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Sorotan Bagian Khusus",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleChangeOrder = result => {
    if (!result.destination) return;

    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah urutan bagian khusus",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        const items = reorder(
          customSectionData,
          result.source.index,
          result.destination.index
        );

        postChangeOrder(result.destination.index, Number(result.draggableId));
        setCustomSectionData(items);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Sorotan Bagian Khusus</title>
      </Helmet>

      <DefaultHeader section="Data Sorotan Bagian Khusus" />

      <Container maxWidth={false} component={Box} id="custom-section-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <CustomSectionDataTable
                      loading={loadingData}
                      data={customSectionData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleChangeOrder={handleChangeOrder}
                    />
                  </Card>
                </Grid>

                <Dialog
                  open={showModalCustomSection}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Sorotan Bagian Khusus</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalCustomSection === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              value={watch("custom_section.name")}
                              {...register("custom_section.name")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormLabel>Kode</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              value={watch("custom_section.code")}
                              {...register("custom_section.code")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-platform-label">
                                Platform
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-platform-label"
                                value={
                                  watch("custom_section.platform") ||
                                  platformOptions[0].value
                                }
                                {...register("custom_section.platform")}
                              >
                                {platformOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-layout-type-label">
                                Tipe Tampilan
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-layout-type-label"
                                value={
                                  watch("custom_section.layout_type") ||
                                  layoutTypeOptions[0].value
                                }
                                {...register("custom_section.layout_type")}
                              >
                                {layoutTypeOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-label">
                                Status
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-label"
                                value={
                                  watch("custom_section.active") ||
                                  statusOptions[0].value
                                }
                                {...register("custom_section.active")}
                              >
                                {statusOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalCustomSection === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
