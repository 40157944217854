import React from "react";

import {
  Box,
  Card,
  CardHeader,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import componentStyles from "assets/theme/components/cards/dashboard/card-light-table.js";

const useStyles = makeStyles(componentStyles);

export default function CardWebinarSchedule({ webinarScheduleUpcomings }) {
  const classes = useStyles();

  const columns = ["Nama Kelas", "Sesi Jadwal"];

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          className={classes.cardHeader}
          title="Jadwal Webinar Akan Datang"
          titleTypographyProps={{
            component: Box,
            marginBottom: "0!important",
            variant: "h3",
          }}
        />
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {columns.map((column, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                    className="text-center"
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {webinarScheduleUpcomings.length > 0 ? (
                webinarScheduleUpcomings.map(schedule => (
                  <TableRow key={schedule.code}>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                      className="text-center"
                    >
                      {schedule.course.name}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      className="text-center"
                    >
                      {schedule.sessions[0]?.date} -{" "}
                      {schedule.sessions?.slice(-1)[0]?.date}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell component="td" colSpan={6}>
                    <Box textAlign="center">Tidak Ada Data</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}
