import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import { Autocomplete } from "@material-ui/lab";

import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import api from "services/api";
import http from "services/http";
import formatStatusLogExportCourse from "helpers/formatStatusLogExportCourse";
import LogExportCourseDataTable from "./LogExportCourseDataTable";
import formatReportCourseType from "helpers/formatReportCourseType";

const filterStatus = [
  {
    value: "",
    label: "Semua",
  },
  {
    value: "PENDING",
    label: formatStatusLogExportCourse("PENDING"),
  },
  {
    value: "PROCESS",
    label: formatStatusLogExportCourse("PROCESS"),
  },
  {
    value: "FAILED",
    label: formatStatusLogExportCourse("FAILED"),
  },
  {
    value: "FINISHED",
    label: formatStatusLogExportCourse("FINISHED"),
  },
  {
    value: "DELETED",
    label: formatStatusLogExportCourse("DELETED"),
  },
];

const filterType = [
  {
    value: "",
    label: "Semua",
  },
  {
    value: "PURCHASE",
    label: formatReportCourseType("PURCHASE"),
  },
  {
    value: "COMPLETED",
    label: formatReportCourseType("COMPLETED"),
  },
];

export default function ListExportCourseLogPage() {
  const { register, setValue, watch } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [logEmails, setLogEmails] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { email, status, type } = watch("filter");

      const { data } = await http.get(api.v1.admin.log.export.course, {
        params: {
          limit,
          page,
          email,
          status,
          type,
        },
      });

      const { current_page, records, total_page, total_records } = data.data;

      setLogEmails(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Log Export Kelas</title>
      </Helmet>
      <DefaultHeader section="Data Export Kelas" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    {/* select option */}
                    <Grid sm={8} className="d-flex">
                      {/* Filter by Status */}
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={filterStatus}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterStatus.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.status", value);
                            getList();
                          }}
                          value={
                            filterStatus.filter(
                              data => data.value === watch("filter.status")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* Filter by type */}
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={filterType}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterType.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.type", value);
                            getList();
                          }}
                          value={
                            filterType.filter(
                              data => data.value === watch("filter.type")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Tipe"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    {/* Search by email */}
                    <Grid sm={4}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan email"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.email")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <LogExportCourseDataTable
                    loading={loadingData}
                    data={logEmails}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
