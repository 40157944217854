import React, { useEffect, useState } from "react";

import {
  Grid,
  Container,
  Box,
  Card,
  Button,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import api from "services/api";
import http from "services/http";

import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import TransactionReportDataTable from "./TransactionReportDataTable";
import { Search } from "@material-ui/icons";

export default function TransactionReportPage() {
  const { register, watch } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [transactionData, setTransactionData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { voucher } = watch("filter");

      const { data } = await http.get(api.v1.admin.report.transaction, {
        params: { limit, page, voucher },
      });

      setError(null);

      const { records, current_page, total_page, total_records } = data.data;

      setTransactionData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Transaksi</title>
      </Helmet>

      <DefaultHeader section="Data Transaksi" subsection="Laporan Transaksi" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Card>
                <Grid>
                  <Grid className="p-3 d-flex justify-content-end">
                    <Grid
                      sm={5}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <OutlinedInput
                        type="text"
                        placeholder="Cari berdasarkan Voucher"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-3"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.voucher")}
                        style={{ width: "220px" }}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid>
                    <TransactionReportDataTable
                      loading={loadingData}
                      data={transactionData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
