import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import http from "services/http";
import api from "services/api";
import TrainingGoalDataTable from "./TrainingGoalDataTable";
import Swal from "sweetalert2";
import { baseActions } from "redux/actions";
import { useForm } from "react-hook-form";
import { KeyboardArrowDown } from "@material-ui/icons";

export default function TrainingGoalTab() {
  const { id: courseId } = useParams();
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      id: null,
      type: "",
      description: "",
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [trainingGoalData, setTrainingGoalData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalTrainingGoal, setTypeModalTrainingGoal] = useState("CREATE");
  const [showModalTrainingGoal, setShowModalTrainingGoal] = useState(false);

  const typeOptions = ["UMUM", "KHUSUS"];

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        api.v1.admin.course.training_goal(courseId),
        {
          params: {
            limit,
            page,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setTrainingGoalData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.course.training_goal(courseId)}/${id}`
      );

      setValue("id", data.data.id);
      setValue("type", data.data.type);
      setValue("description", data.data.description);

      handleOpenModal("UPDATE");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreatedata = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(api.v1.admin.course.training_goal(courseId), payload);

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data tujuan pelatihan berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.put(
        `${api.v1.admin.course.training_goal(courseId)}/${payload.id}`,
        payload
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data tujuan pelatihan berhasil di perbarui",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course.training_goal(courseId)}/${id}`);

      Swal.fire("Sukses", "Data tujuan pelatihan berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = type => {
    setTypeModalTrainingGoal(type);
    setShowModalTrainingGoal(true);
  };

  const handleCloseModal = () => {
    setShowModalTrainingGoal(false);
    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data tujuan pelatihan",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) await deleteData(id);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Card className="p-4">
      <Grid container>
        <Grid item xs={12}>
          {error ? (
            <Box textAlign="center" color={themeColors.error.main}>
              {error}
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={12} className="mb-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModal("CREATE")}
                >
                  Tambah Data
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TrainingGoalDataTable
                  loading={loadingData}
                  data={trainingGoalData}
                  pagination={pagination}
                  getDetailData={getDetailData}
                  handleDeleteData={handleDeleteData}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPage={handleChangeRowsPage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Modal Create & Update Training Goal */}
      <Dialog
        open={showModalTrainingGoal}
        onClose={() => handleCloseModal()}
        fullWidth={true}
        maxWidth="md"
      >
        <Container className="py-4">
          <Box textAlign="center">
            <h2>Data Tujuan Pelatihan</h2>
          </Box>

          <form
            onSubmit={
              typeModalTrainingGoal === "CREATE"
                ? handleSubmit(postCreatedata)
                : handleSubmit(putUpdateData)
            }
          >
            <Grid container className="py-4">
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Tipe</FormLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="option-training-goal-label"
                      IconComponent={KeyboardArrowDown}
                      value={watch("type")}
                      {...register("type")}
                    >
                      {typeOptions.map((val, key) => (
                        <MenuItem key={key} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Deskripsi</FormLabel>
                  <OutlinedInput
                    fullWidth
                    type="text"
                    {...register("description")}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  {typeModalTrainingGoal === "CREATE" ? "Tambah" : "Perbarui"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    </Card>
  );
}
