import React from "react";

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  AssignmentOutlined,
  AssignmentIndOutlined,
  GroupOutlined,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import componentStyles from "assets/theme/components/headers/stats-header.js";
import theme from "assets/theme/theme";

import CardStats from "components/Cards/Dashboard/CardStats";

const useStylesStats = makeStyles(componentStyles);
const useStyles = makeStyles({
  headerRoot: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    paddingBottom: "8rem",
  },
});

export default function DashboardStatsHeader({
  totalCourse,
  totalUser,
  totalInstructor,
  loading,
}) {
  const classes = {
    ...useStyles(),
    ...useStylesStats(),
  };

  return (
    <>
      <div className={classes.headerRoot}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={12} classes={classes.gridItem}>
              <Typography
                variant="h2"
                component="h6"
                className={classes.textWhite}
              >
                Dashboard
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xl={4} lg={6} xs={12}>
              {!loading ? (
                <CardStats
                  subtitle="Total Kelas"
                  title={new Intl.NumberFormat().format(totalCourse)}
                  icon={AssignmentOutlined}
                  color="bgError"
                />
              ) : (
                <Skeleton
                  variant="rect"
                  height={81.5}
                  style={{ borderRadius: "0.375rem" }}
                />
              )}
            </Grid>
            <Grid item xl={4} lg={6} xs={12}>
              {!loading ? (
                <CardStats
                  subtitle="Total Instruktur"
                  title={new Intl.NumberFormat().format(totalInstructor)}
                  icon={AssignmentIndOutlined}
                  color="bgError"
                />
              ) : (
                <Skeleton
                  variant="rect"
                  height={81.5}
                  style={{ borderRadius: "0.375rem" }}
                />
              )}
            </Grid>
            <Grid item xl={4} lg={6} xs={12}>
              {!loading ? (
                <CardStats
                  subtitle="Total Peserta"
                  title={new Intl.NumberFormat().format(totalUser)}
                  icon={GroupOutlined}
                  color="bgError"
                />
              ) : (
                <Skeleton
                  variant="rect"
                  height={81.5}
                  style={{ borderRadius: "0.375rem" }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
