import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  OutlinedInput,
  SnackbarContent,
  Switch,
  TextField,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";

import theme from "assets/theme/theme-rtl";
import snackbarTheme from "assets/theme/components/snackbar";
import GenerateVoucherPage from "../Generate/GenerateVoucherPage";

const snackbarStyles = makeStyles(snackbarTheme);

export default function CreateVoucherPage() {
  const styles = { ...snackbarStyles() };
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      course: "",
      withSchedule: false,
    },
  });

  const [error, setError] = useState(null);

  const [loadingData, setLoadingData] = useState(false);

  const [vouchers, setVouchers] = useState([]);
  const [courseData, setCourseData] = useState(false);
  const [voucherTypeData, setVoucherTypeData] = useState(false);
  const [toggleRedeemption, setToggleRedeemption] = useState(false);
  const [scheduleOptions, setScheduleOptions] = useState([]);

  const [activeTab, setActiveTab] = useState(1);

  const buttonTabs = [
    {
      key: 1,
      value: "Satuan",
    },
    {
      key: 2,
      value: "Kelompok",
    },
  ];

  const contentTabs = () => {
    switch (activeTab) {
      case 1:
        return createVoucher();
      case 2:
        return <GenerateVoucherPage />;
      default:
        return createVoucher();
    }
  };

  /**
   * Fetch Data
   */
  const getVoucherReq = async () => {
    try {
      setLoadingData(true);

      const getCourses = await http.get(api.v1.admin.course.main, {
        params: { option: "true" },
      });

      const getVoucherTypes = await http.get(api.v1.admin.voucher.type, {
        params: { option: "true" },
      });

      setError(null);

      const { records: courses } = getCourses.data.data;
      const { records: voucherTypes } = getVoucherTypes.data.data;

      setCourseData(courses);
      setVoucherTypeData(voucherTypes);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const postCreateVoucher = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const {
        course,
        schedule,
        course_voucher_type_id,
        expired_date,
        has_redeemption_code,
      } = payload;

      const courseWebinarId =
        watch("withSchedule") && course?.course_teach_method_id === 1
          ? schedule.id
          : null;
      const courseOfflineScheduleId =
        watch("withSchedule") && course?.course_teach_method_id === 4
          ? schedule.id
          : null;

      await http.post(api.v1.admin.voucher.main, {
        expired_date,
        course_id: course.id,
        course_voucher_type_id,
        has_redeemption_code: has_redeemption_code ? 1 : 0,
        vouchers,
        course_webinar_id: courseWebinarId,
        course_offline_schedule_id: courseOfflineScheduleId,
      });

      Swal.fire("Sukses", "Data Voucher berhasil di tambahkan", "success");

      setValue("course_voucher_type_id", "");
      setValue(
        "expired_date",
        moment().endOf("year").set({ D: "25" }).format("yyyy-MM-DDThh:mm:ss")
      );
      setValue("course_id", "");
      setValue("voucher", "");

      setVouchers([]);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const getWebinarSchedule = async courseId => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${courseId}/webinar`,
      {
        params: { limit: 100, page: 1 },
      }
    );

    const scheduleOptions = [];
    data.data.records.forEach(el => {
      if (el.sessions.length) {
        const label = `${moment(el?.sessions[0].date).format(
          "DD/MM/YYYY"
        )} - ${moment(el?.sessions[el?.sessions.length - 1].date).format(
          "DD/MM/YYYY"
        )} - ${el.code}`;

        scheduleOptions.push({
          id: el.id,
          label,
        });
      }
    });

    setScheduleOptions(scheduleOptions);
  };

  const getOfflineSchedule = async courseId => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${courseId}/offline-schedule`,
      {
        params: { limit: 100, page: 1 },
      }
    );

    const scheduleOptions = [];

    data.data.records.forEach(el => {
      if (el?.course_offline_schedule_sessions.length) {
        const label = `${moment(
          el?.course_offline_schedule_sessions[0].date
        ).format("DD/MM/YYYY")} - ${moment(
          el?.course_offline_schedule_sessions[
            el?.course_offline_schedule_sessions?.length - 1
          ].date
        ).format("DD/MM/YYYY")} - ${el.code}`;

        scheduleOptions.push({
          id: el.id,
          label,
        });
      }
    });

    setScheduleOptions(scheduleOptions);
  };

  /**
   * Handling Events
   */
  const handleAddVoucher = () => {
    const voucher = watch("voucher");

    voucher !== "" && setVouchers(prev => [...prev, voucher]);

    setValue("voucher", "");
  };

  const handleDeleteVoucher = index => {
    const tempVouchers = [...vouchers];

    tempVouchers.splice(index, 1);

    setVouchers(tempVouchers);
  };

  const handleButtonTab = key => {
    setActiveTab(key);
  };

  useEffect(() => {
    getVoucherReq();
  }, []);

  /**
   * Component
   */

  const createVoucher = () => {
    return (
      <>
        <Container maxWidth={false} component={Box}>
          <Grid container>
            {!loadingData ? (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid>
                      {error && (
                        <Grid container>
                          <Grid item xs={12} className="mb-2">
                            <SnackbarContent
                              classes={{ root: styles.errorSnackbar }}
                              action={
                                <Box
                                  component={IconButton}
                                  padding="0!important"
                                  onClick={() => setError(null)}
                                >
                                  <Box
                                    component="span"
                                    color={theme.palette.white.main}
                                  >
                                    ×
                                  </Box>
                                </Box>
                              }
                              message={<Box>{error}</Box>}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <form onSubmit={handleSubmit(postCreateVoucher)}>
                        <Grid container xs={12}>
                          <Grid item sm={6}>
                            <FormGroup>
                              <FormLabel>Tipe Voucher</FormLabel>
                              <Autocomplete
                                options={voucherTypeData}
                                getOptionLabel={option => option.name}
                                closeIcon={null}
                                onInputChange={(e, val) => {
                                  const { id } = voucherTypeData.find(
                                    data => data.name === val
                                  );

                                  setValue("course_voucher_type_id", id);
                                }}
                                popupIcon={
                                  <Box
                                    component={KeyboardArrowDown}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                  />
                                }
                                renderInput={params => (
                                  <TextField {...params} variant="filled" />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item sm={6}>
                            <FormGroup>
                              <FormLabel>Tanggal Kadaluarsa</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="datetime-local"
                                defaultValue={moment()
                                  .endOf("year")
                                  .set({ D: "25" })
                                  .format("yyyy-MM-DDThh:mm:ss")}
                                {...register("expired_date")}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Pilih Kelas</FormLabel>
                              <Controller
                                control={control}
                                name="course_id"
                                rules={{
                                  required: "Tidak boleh kosong",
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    options={courseData}
                                    getOptionLabel={option =>
                                      option?.name
                                        ? `${option?.name} - ${option?.code}`
                                        : ""
                                    }
                                    getOptionSelected={(option, val) =>
                                      val === undefined ||
                                      val === "" ||
                                      option.id === val.id
                                    }
                                    closeIcon={null}
                                    value={value}
                                    onChange={(e, val) => {
                                      onChange(val.id);
                                      setValue("course", val);

                                      if (val?.course_teach_method_id === 1)
                                        getWebinarSchedule(val.id);
                                      if (val?.course_teach_method_id === 4)
                                        getOfflineSchedule(val.id);
                                    }}
                                    popupIcon={
                                      <Box
                                        component={KeyboardArrowDown}
                                        width="1.25rem!important"
                                        height="1.25rem!important"
                                      />
                                    }
                                    renderInput={params => (
                                      <TextField {...params} variant="filled" />
                                    )}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          {[1, 4].includes(
                            watch("course")?.course_teach_method_id
                          ) && (
                            <Grid item sm={12}>
                              <FormGroup>
                                <FormLabel>
                                  <Checkbox
                                    checked={watch("withSchedule")}
                                    onChange={() =>
                                      setValue(
                                        "withSchedule",
                                        !watch("withSchedule")
                                      )
                                    }
                                    color="primary"
                                  />
                                  Pilih Jadwal
                                </FormLabel>
                                {watch("withSchedule") === true ? (
                                  <Controller
                                    control={control}
                                    name="schedule"
                                    rules={{
                                      required: "Tidak boleh kosong",
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Autocomplete
                                        options={scheduleOptions}
                                        getOptionLabel={option =>
                                          option ? option.label : ""
                                        }
                                        getOptionSelected={(option, val) =>
                                          val === undefined ||
                                          val === "" ||
                                          option.id === val.id
                                        }
                                        closeIcon={null}
                                        value={value}
                                        onChange={(e, val) => {
                                          onChange(val);
                                        }}
                                        popupIcon={
                                          <Box
                                            component={KeyboardArrowDown}
                                            width="1.25rem!important"
                                            height="1.25rem!important"
                                          />
                                        }
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                ) : null}

                                {errors.course && (
                                  <FormHelperText
                                    component={Box}
                                    color={
                                      theme.palette.error.main + "!important"
                                    }
                                  >
                                    {errors.course.message}
                                  </FormHelperText>
                                )}
                              </FormGroup>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={10}>
                            <FormGroup>
                              <OutlinedInput
                                placeholder="Masukan Voucher"
                                {...register("voucher")}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <FormGroup>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddVoucher()}
                              >
                                Tambah
                              </Button>
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <Box className="mb-2">
                              {vouchers.map((item, key) => (
                                <Chip
                                  key={key}
                                  onDelete={() => handleDeleteVoucher(key)}
                                  label={item}
                                  className="mr-2 mb-2"
                                />
                              ))}
                            </Box>
                          </Grid>

                          <Grid item sm={12}>
                            <FormGroup>
                              <FormLabel>Kode Redeem</FormLabel>
                              <Switch
                                color="primary"
                                size="medium"
                                checked={toggleRedeemption}
                                {...register("has_redeemption_code", {
                                  onChange: e =>
                                    setToggleRedeemption(e.target.checked),
                                })}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Simpan Voucher
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid sm={12}>
                <Box
                  component="div"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="1rem"
                  marginBottom="1rem"
                >
                  <CircularProgress size={32} color="primary" />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tambah Voucher</title>
      </Helmet>

      <DefaultHeader section="Tambah Voucher" subsection="Kelola Voucher" />
      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2} className="mb-3 overflow-scroll">
              {buttonTabs.map((tab, index) => (
                <Grid item key={index} xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={activeTab === tab.key ? "primary" : ""}
                    onClick={() => handleButtonTab(tab.key)}
                  >
                    {tab.value}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {contentTabs()}
    </>
  );
}
