import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Group, Receipt } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import ChipData from "components/Chip/ChipData";

export default function ListCourseInstructorDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const { instructorId } = useParams();

  const history = useHistory();

  const columns = ["#", "Nama", "Tipe", "Metode", "Status", "Opsi"];
  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  component="td"
                  align="center"
                  style={{ whiteSpace: "normal" }}
                >
                  {course.name}
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.course_type.name} />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData
                    color="primary"
                    text={course.course_teach_method.name}
                  />
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData color="default" text={course.status} />
                </TableCell>
                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="Transaksi"
                    onClick={() =>
                      history.push(
                        `/report/instructor/${instructorId}/course/${course.id}/transaction`
                      )
                    }
                  >
                    <Receipt />
                  </IconButton>

                  <IconButton
                    aria-label="Participant"
                    onClick={() =>
                      history.push(
                        `/report/instructor/${instructorId}/course/${course.id}/participant`
                      )
                    }
                  >
                    <Group />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
