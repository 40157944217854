import axios from "axios";
import cookies from "../helpers/cookies";
import localStorage from "helpers/localStorage";

import { Redirect } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

const http = axios.create({ baseURL });

http.interceptors.request.use(config => {
  const token = cookies.get("token");
  config.headers["Cariilmu-Platform"] = "WEB";

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

http.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
    const from = window.location.href;

    if (status === 401) {
      cookies.destroy("token");
      cookies.destroy("user");
      localStorage.destroy("permissions");

      if (!from.includes("/login")) <Redirect to="/login" />;

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
