import PeriodConstant from "constant/PeriodConstant";

const formatPeriod = string => {
  switch (string) {
    case PeriodConstant.PERIODS.ONE_DAY_BEFORE:
      return "1 Hari Sebelum";
    case PeriodConstant.PERIODS.ONE_DAY_AFTER:
      return "1 Hari Sesudah";
    case PeriodConstant.PERIODS.ONE_HOUR_BEFORE:
      return "1 Jam Sebelum";
    case PeriodConstant.PERIODS.THREE_DAY_AFTER:
      return "3 Hari Sesudah";
    case PeriodConstant.PERIODS.SEVEN_DAY_AFTER:
      return "7 Hari Sesudah";
    case PeriodConstant.PERIODS.EIGHT_DAY_AFTER:
      return "8 Hari Sesudah";
    case PeriodConstant.PERIODS.TEN_DAY_AFTER:
      return "10 Hari Sesudah";
    case PeriodConstant.PERIODS.TWELVE_DAY_AFTER:
      return "12 Hari Sesudah";
    case PeriodConstant.PERIODS.LAST_DAY:
      return "Hari Terakhir";
    default:
      return "-";
  }
};

export default formatPeriod;
