import cookies from "react-cookies";

export default {
  set: (name, value) => {
    cookies.save(name, value, {
      path: "/",
      // domain: "https://dashboard.cariilmu.co.id",
      secure: true,
      // httpOnly: true,
    });
  },

  get: name => {
    const data = cookies.load(name);

    return data;
  },

  destroy: name => {
    return cookies.remove(name, {
      path: "/",
      // domain: "https://dashboard.cariilmu.co.id",
      secure: true,
      // httpOnly: true,
    });
  },
};
