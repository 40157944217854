import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import themeColors from "assets/theme/colors";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";

import VoucherAttemptDataTable from "./VoucherAttemptDataTable";
import moment from "moment";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import cookies from "helpers/cookies";
import ModalVertification from "components/Modal/ModalVertification";
import AutocompleteDebounce from "components/AutocompleteDebounce";

export default function ListVoucherAttempt() {
  const dispatch = useDispatch();
  const { watch, reset, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      filter: {
        course_code: null,
        name: "",
      },
      export_data: {
        start_date: moment().startOf("week").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        status: "ALL",
        course: {
          code: "",
        },
      },
      delete_data: {
        start_date: moment().startOf("week").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        status: "ALL",
        password: "",
      },
    },
  });

  const statusOptions = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Berhasil",
      value: "SUCCESS",
    },
    {
      label: "Gagal",
      value: "ERROR",
    },
  ];

  const [courses, setCourses] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [redeemVoucherAttempData, setRedeemVoucherAttempData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [refresh, setRefresh] = useState(true);

  const [showExportData, setShowExportData] = useState(false);
  const [showDeleteDataModal, setshowDeleteDataModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { course_code, name } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.sync.voucher.redeem_attempt}`,
        {
          params: { limit, page, course_code, name },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setRedeemVoucherAttempData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getCourseOption = async (searech_name = "") => {
    const { data } = await http.get(api.v1.admin.course.main, {
      params: { page: 1, limit: 5, name: searech_name },
    });

    setCourses(data.data.records);

    data.data.records = data.data.records.unshift({
      name: "Semua",
      code: "",
    });
  };

  const exportData = async val => {
    try {
      let res;

      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { start_date, end_date, status, course } = val.export_data;

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Laporan Redeem Voucher.xlsx`;

      const params = {
        start_date,
        end_date,
        status,
        course_code: course.code,
      };

      if (!course.code) delete params.course_code;
      if (status === "ALL") delete params.status;

      res = await http.get(
        `${api.v1.admin.sync.voucher.main}/export-redeem-voucher-attemp`,
        {
          params,
          responseType: "blob",
        }
      );

      reset();

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));
      const user = cookies.get("user");

      const { start_date, end_date, status, password } = val.delete_data;

      const payload = {
        email: user.email,
        password,
      };

      await http.post(api.v1.admin.auth.login, payload);

      const params = { start_date, end_date, status };

      if (status === "ALL") delete params.status;

      await http.delete(
        `${api.v1.admin.sync.voucher.main}/delete-redeem-voucher-attemp`,
        { params }
      );

      Swal.fire("Sukses", "Data berhasil dihapus", "success");

      handleCloseModal();
      reset();
      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleCloseModal = () => {
    setShowExportData(false);
    setshowDeleteDataModal(false);
    setShowVerificationModal(false);
    reset();
  };

  useEffect(() => {
    getList();
    getCourseOption();
  }, []);

  useEffect(() => {
    if (refresh) {
      const interval = setInterval(() => {
        getList();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [refresh]);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Log Redeem Voucher</title>
      </Helmet>

      <DefaultHeader section="Log Redeem Voucher" subsection="Voucher" />

      <Container maxWidth={false} component={Box} id="event-place-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid>
                  <Grid className="d-flex justify-content-end mb-3">
                    <Grid alignItems="center">
                      <FormLabel className="mr-2">Auto Refresh</FormLabel>
                      <Switch
                        color="primary"
                        checked={refresh}
                        onChange={e => setRefresh(e.target.checked)}
                      />
                    </Grid>
                  </Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-between">
                      <Grid sm={6} className="d-flex mr-2">
                        <Grid sm={6} className="mr-2">
                          <AutocompleteDebounce
                            options={courses}
                            getOption={getCourseOption}
                            label={option =>
                              option?.code
                                ? `${option.name} - ${option.code}`
                                : option.name
                            }
                            onChange={(e, val) => {
                              setValue("filter.course_code", val.code);

                              getList();
                            }}
                            labelOption="Kelas"
                          />
                        </Grid>

                        <Grid sm={6}>
                          <OutlinedInput
                            type="text"
                            placeholder="Cari berdasarkan Nama"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                            className="mr-3"
                            onKeyPress={e => e.key === "Enter" && getList()}
                            {...register("filter.name")}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        sm={6}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setShowExportData(true)}
                        >
                          Export Log
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="ml-2"
                          onClick={() => setshowDeleteDataModal(true)}
                        >
                          Hapus Log
                        </Button>
                        <Button
                          variant="contained"
                          color="info"
                          component={Box}
                          // marginRight="1rem!important"
                          className="ml-2"
                          onClick={() => getList()}
                        >
                          Muat Ulang
                        </Button>
                      </Grid>
                    </Grid>

                    <VoucherAttemptDataTable
                      loading={loadingData}
                      data={redeemVoucherAttempData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Download Redeem Voucher Attempt */}
                <Dialog
                  open={showExportData}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Export Log</h2>
                    </Box>

                    <form onSubmit={handleSubmit(exportData)}>
                      <Grid container className="py-4">
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal Awal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("export_data.start_date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal Akhir</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("export_data.end_date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Status
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("export_data.status")}
                                {...register("export_data.status")}
                              >
                                {statusOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Pilih Kelas</FormLabel>

                            <AutocompleteDebounce
                              options={courses}
                              getOption={getCourseOption}
                              label={option =>
                                `${option.name} - ${option.code}`
                              }
                              onChange={(e, val) => {
                                setValue("export_data.course", val);
                              }}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Export
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Delete Redeem Voucher Attempt */}
                <Dialog
                  open={showDeleteDataModal}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Hapus Log</h2>
                    </Box>

                    <form>
                      <Grid container className="py-4">
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal Awal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("delete_data.start_date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Tanggal Akhir</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("delete_data.end_date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-promo-code-label">
                                Status
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-promo-code-label"
                                value={watch("delete_data.status")}
                                {...register("delete_data.status")}
                              >
                                {statusOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setShowVerificationModal(true)}
                            type="button"
                          >
                            Hapus
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Verification */}
                <ModalVertification
                  openModal={showVerificationModal}
                  onClose={() => setShowVerificationModal(false)}
                  handleSubmit={handleSubmit(deleteData)}
                  passwordProps={{ ...register("delete_data.password") }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
