import { Box, TextField } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";

export default function AutocompleteDebounce({
  options = [],
  label = () => {},
  onChange = () => {},
  getOption = () => {},
  labelOption = "",
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      getOption(query);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  return (
    <Autocomplete
      id="combo-box-category"
      options={options}
      getOptionLabel={label}
      closeIcon={null}
      onInputChange={(e, val, reason) => {
        setLoading(true);
        if (val === "") setQuery(val);

        if (reason == "input") setQuery(val);

        setLoading(true);
      }}
      onChange={onChange}
      loading={loading}
      popupIcon={
        <Box
          component={KeyboardArrowDown}
          width="1.25rem!important"
          height="1.25rem!important"
        />
      }
      renderInput={params => (
        <TextField {...params} label={labelOption} variant="outlined" />
      )}
      {...props}
    />
  );
}
