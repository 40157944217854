import RoleConstant from "constant/RoleConstant";

const formatRole = string => {
  switch (string) {
    case RoleConstant.ROLES.ADMIN:
      return "Admin";
    case RoleConstant.ROLES.SUPERADMIN:
      return "Superadmin";
    case RoleConstant.ROLES.INSTRUCTOR:
      return "Instruktur";
    case RoleConstant.ROLES.DATA_MANAGER:
      return "Data Manager";
    case RoleConstant.ROLES.USER:
      return "User";
    default:
      return "-";
  }
};

export default formatRole;
