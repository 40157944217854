import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DateRange, Group } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

import moment from "moment";
import { useHistory } from "react-router-dom";

export default function WebinarScheduleReportDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleOpanModalSession,
}) {
  const history = useHistory();

  const columns = [
    "#",
    "Kode Jadwal",
    "Tanggal Mulai",
    "Tanggal Terakhir",
    "Opsi",
  ];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((schedule, index) => (
              <TableRow key={schedule.id}>
                <TableCell component="td" scope="row" align="center">
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>

                <TableCell component="td" align="center">
                  {schedule.code}
                </TableCell>

                <TableCell component="td" align="center">
                  {moment(schedule.first_date_schedule).format("DD/MM/YYYY")}{" "}
                  {moment(schedule.sessions[0].start_time, "HH:mm:ss").format(
                    "HH:mm"
                  )}
                </TableCell>

                <TableCell component="td" align="center">
                  {moment(schedule.last_date_schedule).format("DD/MM/YYYY")}{" "}
                  {moment(
                    schedule.sessions[schedule.sessions.length - 1].end_time,
                    "HH:mm:ss"
                  ).format("HH:mm")}
                </TableCell>

                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="detail"
                    onClick={() => handleOpanModalSession(schedule.id)}
                  >
                    <DateRange />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/report/list-course/${schedule.course_id}/webinar-schedule/${schedule.id}/participant`
                      )
                    }
                  >
                    <Group />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
