import React from "react";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

export default function ListParticipantCourseDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const columns = ["#", "Nama Peserta", "Email", "Nomor Handphone"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((column, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading.list_participant && data.length ? (
            data.map((course, index) => (
              <TableRow key={index}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  component="td"
                  style={{ whiteSpace: "normal" }}
                  align="center"
                >
                  {course.name}
                </TableCell>
                <TableCell
                  component="td"
                  style={{ whiteSpace: "normal" }}
                  align="center"
                >
                  {course.email}
                </TableCell>
                <TableCell
                  component="td"
                  style={{ whiteSpace: "normal" }}
                  align="center"
                >
                  {course.phone_number}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading.list_participant ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
