import { Box, Card, Container, Grid } from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import api from "services/api";
import http from "services/http";
import InstructorListCoursePracticeAssignmentDataTable from "./InstructorListCoursePracticeAssignmentDataTable";

export default function InstructorListCoursePracticeAssignmentPage() {
  const { id } = useParams();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [practiceAssignmentData, setPracticeAssignmentData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (id, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.instructor.course.main}/${id}/practice-assignment`,
        {
          params: {
            limit,
            page,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setPracticeAssignmentData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(id, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(id);
  };

  useEffect(() => {
    getList(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Tugas Praktik Mandiri</title>
      </Helmet>

      <DefaultHeader
        section="Data Praktik Mandiri"
        subsection="Data Kelas"
        routeBack="/admin-instructor/my-course"
      />

      <Container maxWidth={false} component={Box} id="course-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <InstructorListCoursePracticeAssignmentDataTable
                    loading={loadingData}
                    data={practiceAssignmentData}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
