import React from "react";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import ChipData from "components/Chip/ChipData";
import moment from "moment";

export default function VoucherAttemptDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const columns = [
    "tanggal",
    "Status",
    "Pesan",
    "Email",
    "Nama",
    "Nomor",
    "Voucher",
    "Kode Redeem",
  ];

  const status = {
    SUCCESS: "Berhasil",
    ERROR: "Gagal",
    ON_PROGRESS: "Dalam Proses",
  };

  const statusColor = {
    SUCCESS: themeColors.success.main,
    ERROR: themeColors.error.main,
    ON_PROGRESS: themeColors.warning.main,
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.secondary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{
                  fontWeight: 700,
                  color: themeColors.dark.main,
                }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map(data => (
              <TableRow key={data.id}>
                <TableCell
                  component="td"
                  align="center"
                  style={{
                    color: statusColor[data.status],
                  }}
                >
                  {moment(data.attempt_date).format("DD MM YYYY HH:mm")}
                </TableCell>
                <TableCell component="td" align="center">
                  <ChipData
                    color="secondary"
                    text={status[data.status]}
                    style={{
                      color: themeColors.gray[100],
                      backgroundColor: statusColor[data.status],
                    }}
                  />
                </TableCell>
                <TableCell
                  component="td"
                  align="center"
                  style={{
                    color: statusColor[data.status],
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    fontWeight: "Bold",
                  }}
                >
                  {data.message}
                </TableCell>
                <TableCell component="td" align="center">
                  {data.email}
                </TableCell>
                <TableCell component="td" align="center">
                  {data.name}
                </TableCell>
                <TableCell component="td" align="center">
                  {data.phone_number || "-"}
                </TableCell>
                <TableCell
                  component="td"
                  align="center"
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    maxWidth: "240px",
                  }}
                >
                  {data.voucher}
                </TableCell>
                <TableCell
                  component="td"
                  align="center"
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    maxWidth: "240px",
                  }}
                >
                  {data.redeemption_code}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
