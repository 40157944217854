import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import http from "services/http";
import api from "services/api";
import AutocompleteDebounce from "components/AutocompleteDebounce";
import { useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";

import moment from "moment";
import themeColors from "assets/theme/colors";
import LearningProgressCourseParticipantDataTable from "./LearningProgressCourseParticipantDataTable";
import ModalCourseParticipant from "./ModalCourseParticipant";
import ModalRateParticipant from "./ModalRateParticipant";

const typeOptions = [
  { value: "USER", label: "Peserta" },
  { value: "FR", label: "Verifikasi Wajah" },
  {
    value: "TPM",
    label: "Tugas Praktik Mandiri",
  },
  {
    value: "UK",
    label: "Unjuk Keterampilan",
  },
];

export default function ListLearningProgressCoursePage() {
  const dispatch = useDispatch();

  const { watch, setValue } = useForm({
    defaultValues: {
      course_id: null,
      course_webinar_id: null,
      type: null,
    },
  });

  const [permissions, setPermissions] = useState([]);

  const [courses, setCourses] = useState([]);
  const [courseSchedules, setCourseSchedules] = useState([]);

  // course data
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [courseDatas, setCourseData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [detailData, setDetailData] = useState({
    course_id: null,
    module_id: null,
    sub_module_id: null,
    sub_module_type: null,
    course_webinar_id: null,
  });

  const [disableSchedule, setDisableSchedule] = useState(true);

  const [selectedTypeParticipant, setSelectedTypeParticipant] = useState("");
  const [selectedWebinarId, setSelectedWebinarId] = useState(null);

  const [openModalParticipant, setOpenModalParticipant] = useState(false);
  const [openModalRateParticipant, setOpenModalRateParticipant] =
    useState(false);

  const styleReactSelect = {
    control: base => ({
      ...base,
      height: 43,
    }),
  };

  /**
   * Fetch Data
   */
  const getCourseOption = async (search_name = "") => {
    const { data } = await http.get(api.v1.admin.course.main, {
      params: { page: 1, limit: 5, name: search_name },
    });

    setCourses(data.data.records);
  };

  const getCourseScheduleOption = async course_id => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${course_id}/webinar`,
        {
          params: { page: 1, limit: 999 },
        }
      );

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return {
          label: `${item.code} - ${item.sessions[0]?.date}`,
          value: item.id,
        };
      });

      setCourseSchedules(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getCourseData = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      setSelectedTypeParticipant(watch("type").value);

      setSelectedWebinarId(watch("course_webinar_id")?.value);

      const { data } = await http.get(api.v1.admin.learning_progress.main, {
        params: {
          limit,
          page,
          course_id: watch("course_id"),
          type: watch("type").value,
          course_webinar_id: watch("course_webinar_id")?.value || null,
        },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async (
    course_id,
    module_id,
    sub_module_id,
    sub_module_type,
    course_webinar_id,
    particant
  ) => {
    setDetailData({
      course_id,
      module_id,
      sub_module_id,
      sub_module_type,
      course_webinar_id,
    });

    if (particant) setOpenModalParticipant(true);
    else setOpenModalRateParticipant(true);
  };

  const exportData = async () => {
    try {
      let res;

      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Progress Belajar.xlsx`;

      const params = {
        course_id: watch("course_id"),
        type: watch("type").value,
        course_webinar_id: watch("course_webinar_id")?.value || null,
      };

      res = await http.get(`${api.v1.admin.learning_progress.main}/export`, {
        params,
        responseType: "blob",
      });

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleCloseModalParticipant = () => {
    setOpenModalParticipant(false);

    setDetailData({
      course_id: null,
      module_id: null,
      sub_module_id: null,
      sub_module_type: null,
      course_webinar_id: null,
    });
  };

  const handleChangePage = (_, page) => getCourseData(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getCourseData();
  };

  useEffect(() => {
    setPermissions(
      JSON.parse(localStorage.getItem("permissions"))?.map(
        permission => permission.code
      )
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Progres Belajar Kelas</title>
      </Helmet>

      <DefaultHeader section="Progres Peserta" />

      <Container maxWidth={false} component={Box} id="learning-progress-course">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <FormGroup>
                            <FormLabel>Pilih Kelas</FormLabel>
                            <AutocompleteDebounce
                              options={courses}
                              getOption={getCourseOption}
                              label={option =>
                                option?.code
                                  ? `${option.name} - ${option.code}`
                                  : option.name
                              }
                              onChange={(e, val) => {
                                setValue("course_id", val.id);
                                setValue("course_webinar_id", null);

                                setCourseSchedules([]);

                                if (val?.course_teach_method?.id === 1) {
                                  getCourseScheduleOption(val.id);
                                  setDisableSchedule(false);
                                } else {
                                  setDisableSchedule(true);
                                }
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormGroup>
                          <FormControl variant="outlined">
                            <FormLabel id="option-schedule">
                              Pilih Jadwal
                            </FormLabel>
                            <ReactSelect
                              options={courseSchedules}
                              isDisabled={disableSchedule}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("course_webinar_id", val);
                              }}
                              value={watch("course_webinar_id")}
                              styles={styleReactSelect}
                            />
                          </FormControl>
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormGroup>
                          <FormControl variant="outlined">
                            <FormLabel id="option-schedule">
                              Pilih Tipe
                            </FormLabel>
                            <ReactSelect
                              options={typeOptions}
                              required
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("type", val);
                              }}
                              value={watch("type")}
                              styles={styleReactSelect}
                            />
                          </FormControl>
                        </FormGroup>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={permissions?.includes("281-2") ? 6 : 12}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getCourseData()}
                          fullWidth
                          disabled={
                            watch("type") == null || watch("course_id") == null
                          }
                        >
                          Lihat Data
                        </Button>
                      </Grid>

                      {permissions?.includes("281-2") && (
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => exportData()}
                            fullWidth
                            disabled={
                              watch("type") == null ||
                              watch("course_id") == null
                            }
                          >
                            Export
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>

                {courseDatas.length ? (
                  <>
                    <Card>
                      <LearningProgressCourseParticipantDataTable
                        loading={loadingData}
                        courseWebinarId={selectedWebinarId}
                        type={selectedTypeParticipant}
                        getDetailData={getDetailData}
                        data={courseDatas}
                        permissions={permissions}
                        pagination={pagination}
                        handleOpenModalRateParticipant={() =>
                          setOpenModalRateParticipant(true)
                        }
                        handleChangePage={handleChangePage}
                        handleChangeRowsPage={handleChangeRowsPage}
                      />
                    </Card>

                    <ModalCourseParticipant
                      detailData={detailData}
                      permissions={permissions}
                      openModal={openModalParticipant}
                      onClose={handleCloseModalParticipant}
                    />

                    <ModalRateParticipant
                      permissions={permissions}
                      openModal={openModalRateParticipant}
                      detailData={detailData}
                      onClose={() => setOpenModalRateParticipant(false)}
                    />
                  </>
                ) : null}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
