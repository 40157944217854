import { useEffect, useState } from "react";

import { Box, Card, Container, Grid, TextField } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

import http from "services/http";
import api from "services/api";
import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import { Autocomplete } from "@material-ui/lab";
import DetailCourseLogDataTable from "./DetailCourseLogDataTable";

export default function DetailCourseLogPage() {
  const { administratorId, courseId } = useParams();

  const { setValue, watch } = useForm({
    defaultValues: {
      filter: {
        sort_by: "",
        type: "",
        status: "",
      },
    },
  });

  const sortBy = [
    {
      label: "Tanggal Penilaian - Terlama",
      value: "created_at_asc",
    },
    {
      label: "Tanggal Penilaian - Terbaru",
      value: "created_at_dsc",
    },
  ];

  const typeFilter = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "TPM",
      value: "TPM",
    },
    {
      label: "UK",
      value: "UK",
    },
  ];

  const statusFilter = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Dinilai",
      value: "ASSESSED",
    },
    {
      label: "Dinilai Ulang",
      value: "REASSESSED",
    },
  ];

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [logData, setLogData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { sort_by, type, status } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.report.assessment}/${administratorId}/course/${courseId}/log`,
        {
          params: { limit, page, sort_by, type, status },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setLogData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Log Penilaian Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Log Penilaian Kelas"
        subsection="Detail Penilaian"
        routeBack={`/report/penilaian/${administratorId}/kelas`}
      />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    <Grid sm={8} className="d-flex align-items-center">
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={sortBy}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = sortBy.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.sort_by", value);

                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Urut Berdasarkan"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={typeFilter}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = typeFilter.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.type", value);

                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Tipe Tugas"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={statusFilter}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = statusFilter.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.status", value);

                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status Tugas"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <DetailCourseLogDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={logData}
                    pagination={pagination}
                    loading={loadingData}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
