import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

const ModalRateParticipant = ({ detailData, openModal, onClose }) => {
  const dispatch = useDispatch();

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      user_assignment_excel: null,
    },
  });

  /**
   * Fetch Data
   */
  const exportData = async () => {
    try {
      let res;

      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - List Penilaian.xlsx`;

      const params = {
        course_id: detailData.course_id,
        course_module_id: detailData.module_id,
        course_sub_module_id: detailData.sub_module_id,
      };

      res = await http.get(
        `${api.v1.admin.learning_progress.main}/export-user-assignment`,
        {
          params,
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { user_assignment_excel } = val;

      const formData = new FormData();

      formData.append("user_assignment_excel", user_assignment_excel[0]);
      formData.append("course_id", detailData.course_id);
      formData.append("course_module_id", detailData.module_id);
      formData.append("course_sub_module_id", detailData.sub_module_id);

      await http.post(
        `${api.v1.admin.learning_progress.main}/import-rated-user-assignment`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          responseType: "blob",
        }
      );

      Swal.fire("Sukses", "Berhasil Melakukan Penilaian", "success");

      reset();
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => onClose()}
      fullWidth={true}
      maxWidth="lg"
    >
      <Container className="py-4">
        <Box textAlign="Center">
          <h2>Bulk Penilaian Peserta</h2>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container className="py-4">
            <Grid item xs={12} lg={2} className="mb-4">
              <Button
                variant="contained"
                color="default"
                type="button"
                onClick={() => {
                  exportData();
                }}
              >
                Export Data
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>File List Tugas</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="file"
                  {...register("user_assignment_excel")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

export default ModalRateParticipant;
