import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit, Menu } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const DroppableComponent = onDragEnd => props => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {provided => (
          <TableBody
            innerRef={provided.innerRef}
            {...provided.droppableProps}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default function QuestionBankDataTable({
  loading,
  data,
  handleDeleteData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleChangeOrder,
}) {
  const { courseId, moduleId, subModuleId } = useParams();

  const history = useHistory();

  const columns = ["#", "Jumlah Soal", "Opsi", ""];

  const onDragEnd = result => {
    handleChangeOrder(result);
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={
                  key === 0
                    ? "100px"
                    : item === "Opsi"
                    ? "48px"
                    : item === ""
                    ? "100px"
                    : null
                }
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody component={DroppableComponent(onDragEnd)}>
          {!loading && data.length ? (
            data.map((questionBank, index) => (
              <Draggable
                draggableId={questionBank.id.toString()}
                index={index}
                key={questionBank.id.toString()}
              >
                {provided => (
                  <TableRow
                    key={questionBank.id}
                    innerRef={provided.innerRef}
                    {...provided.draggableProps}
                    style={provided.draggableProps.style}
                  >
                    <TableCell component="td" scoped="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {questionBank.total_questions}
                    </TableCell>
                    <TableCell component="td" align="center">
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/bank-soal/${questionBank.id}/detail`
                          )
                        }
                      >
                        <Edit />
                      </IconButton>

                      <IconButton
                        onClick={() =>
                          handleDeleteData(
                            courseId,
                            moduleId,
                            subModuleId,
                            questionBank.id
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton {...provided.dragHandleProps}>
                        <Menu />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </Draggable>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={4}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
