import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Container,
  Grid,
  TextField,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import themeColors from "assets/theme/colors";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";

import FaceRecognitionLogDataTable from "./FaceRecognitionLogDataTable";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import AutocompleteDebounce from "components/AutocompleteDebounce";

export default function ListFaceRecognitonLogPage() {
  const { watch, setValue, register } = useForm({
    defaultValues: {
      filter: {
        course_code: null,
        sequence: "",
      },
    },
  });

  const statusOptions = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Berhasil",
      value: "SUCCESS",
    },
    {
      label: "Gagal",
      value: "ERROR",
    },
    {
      label: "Stuck",
      value: "STUCK",
    },
  ];

  const verificationTypeOptions = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Redeem",
      value: "REDEEMPTION",
    },
    {
      label: "Kehadiran",
      value: "ATTENDANCE",
    },
  ];

  const [courses, setCourses] = useState([]);
  const [teachMethods, setTeachMethods] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [redeemVoucherAttempData, setRedeemVoucherAttempData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const {
        course_code,
        sequence,
        teach_method_id,
        status,
        verification_type,
      } = watch("filter");

      let status_prakerja = null;
      if (status === "ON_PROGRESS") {
        // status_prakerja = 1;
      }

      const { data } = await http.get(`${api.v1.admin.log.face_recognition}`, {
        params: {
          limit,
          page,
          course_code,
          sequence,
          teach_method_id,
          status,
          verification_type,
          status_prakerja,
        },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setRedeemVoucherAttempData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getCourseOption = async (searech_name = "") => {
    const { data } = await http.get(api.v1.admin.course.main, {
      params: { page: 1, limit: 5, name: searech_name },
    });

    setCourses(data.data.records);

    data.data.records = data.data.records.unshift({
      name: "Semua",
      code: "",
    });
  };

  const getListTeachMethods = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.teach_method, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        name: "Semua",
      });

      setTeachMethods(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
    getCourseOption();
    getListTeachMethods();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Log Verifikasi Wajah</title>
      </Helmet>

      <DefaultHeader section="Log Verifikasi Wajah" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    <Grid sm={12} className="d-flex">
                      <Grid sm={3} className="mr-2">
                        <AutocompleteDebounce
                          options={courses}
                          getOption={getCourseOption}
                          label={option =>
                            option?.code
                              ? `${option.name} - ${option.code}`
                              : option.name
                          }
                          onChange={(e, val) => {
                            setValue("filter.course_code", val.code);

                            getList();
                          }}
                          labelOption="Kelas"
                        />
                      </Grid>

                      {/* Filter by Teach Method Course */}
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={teachMethods}
                          getOptionLabel={option => option.name}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { id } = teachMethods.filter(
                              data => data.name === val
                            )[0];

                            setValue("filter.teach_method_id", id);
                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Metode Belajar"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* filter status */}
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={statusOptions}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = statusOptions.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.status", value);
                            getList();
                          }}
                          value={
                            statusOptions.filter(
                              data => data.value === watch("filter.status")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* filter verification type */}
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={verificationTypeOptions}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = verificationTypeOptions.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.verification_type", value);
                            getList();
                          }}
                          value={
                            verificationTypeOptions.filter(
                              data =>
                                data.value === watch("filter.verification_type")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Tipe Verifikasi"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* search sequence */}
                      <Grid sm={3} className="mr-2">
                        <OutlinedInput
                          type="number"
                          placeholder="Cari sesi (1-999)"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.sequence")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <FaceRecognitionLogDataTable
                    loading={loadingData}
                    data={redeemVoucherAttempData}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
