import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import api from "services/api";
import http from "services/http";
import ParticipantDataTable from "./ParticipantDataTable";

const filterScoreOption = [
  {
    label: "Semua",
    value: "ALL",
  },
  {
    label: "Sudah Dinilai",
    value: "RATED",
  },
  {
    label: "Belum Dinilai",
    value: "NOT_YET_RATED",
  },
  {
    label: "Belum Bisa Dinilai",
    value: "CANNOT_BE_RATTED",
  },
];

export default function ListParticipantPage() {
  const { register, watch, setValue } = useForm();

  const { courseId, webinarScheduleId } = useParams();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [sessionData, setSessionData] = useState([]);
  const [webinarDetailData, setWebinarDetailData] = useState({});
  const [pagination, setPagination] = useState({ limit: 10 });
  const [assignmentCourse, setAssignmentCourse] = useState(true);

  const getList = async (courseId, scheduleId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { filter_search, filter_score = "ALL" } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.report.course.webinar_schedule(
          courseId
        )}/${scheduleId}/participant`,
        {
          params: {
            limit,
            page,
            filter_search,
            filter_score,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setSessionData(records);

      const assignment = records[0]?.assignment ?? true;

      setAssignmentCourse(assignment);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getListDetail = async (courseId, scheduleId) => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        `${api.v1.admin.report.course.webinar_schedule(
          courseId
        )}/${scheduleId}`,
        {}
      );

      setError(null);

      setWebinarDetailData(data.data);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) =>
    getList(courseId, webinarScheduleId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId, webinarScheduleId);
  };

  useEffect(() => {
    getList(courseId, webinarScheduleId);
    getListDetail(courseId, webinarScheduleId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Peserta Kelas Webinar</title>
      </Helmet>

      <DefaultHeader
        section="Data Peserta Kelas Webinar"
        subsection="Data Jadwal Kelas Webinar"
        routeBack={`/report/list-course/${courseId}/webinar-schedule`}
      />

      <Container maxWidth={false} component={Box} id="promo-code-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-end">
                    <Grid sm={6}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        {/* Filter by Level Course */}
                        <Grid sm={4} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={filterScoreOption}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = filterScoreOption.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.filter_score", value);
                              getList(courseId, webinarScheduleId);
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Filter Nilai"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Nama"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e =>
                            e.key === "Enter" &&
                            getList(courseId, webinarScheduleId)
                          }
                          {...register("filter.filter_search")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList(courseId, webinarScheduleId)}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <ParticipantDataTable
                    refetch={getList}
                    loading={loadingData}
                    data={sessionData}
                    detailData={webinarDetailData}
                    assignmentCourse={assignmentCourse}
                    courseId={courseId}
                    scheduleId={webinarScheduleId}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
