import React, { useEffect, useState } from "react";

import { Box, Button, Card, Container, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import QuestionBankDataTable from "./QuestionBankDataTable";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ListQuestionBankPage() {
  const { courseId, moduleId, subModuleId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [questionBankData, setQuestionBankData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (courseId, moduleId, subModuleId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/question-bank`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setQuestionBankData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const deleteData = async (courseId, moduleId, subModuleId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/question-bank/${id}`
      );

      getList(courseId, moduleId, subModuleId);

      Swal.fire("Sukses", "Data Bank Soal berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postChangeOrder = async (to, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module/${moduleId}/sub-module/${subModuleId}/question-bank/${id}/change-order`,
        { to }
      );

      Swal.fire("Sukses", "Urutan Bank Soal berhasil di ubah", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) =>
    getList(courseId, moduleId, subModuleId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId, moduleId, subModuleId);
  };

  const handleDeleteData = (courseId, moduleId, subModuleId, id) => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data bank soal",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(courseId, moduleId, subModuleId, id);
      }
    });
  };

  const handleChangeOrder = result => {
    if (!result.destination) return;

    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah urutan Bank Soal",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        const items = reorder(
          questionBankData,
          result.source.index,
          result.destination.index
        );

        postChangeOrder(result.destination.index, Number(result.draggableId));
        setQuestionBankData(items);
      }
    });
  };

  useEffect(() => {
    getList(courseId, moduleId, subModuleId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Bank Soal Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Data Bank Soal"
        subsection="Data Sub Modul"
        routeBack={`/kelas/${courseId}/modul/${moduleId}/sub-modul`}
      />

      <Container
        maxWidth={false}
        component={Box}
        id="course-question-bank-page"
      >
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(
                        `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/bank-soal/tambah`
                      )
                    }
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <QuestionBankDataTable
                      loading={loadingData}
                      data={questionBankData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleChangeOrder={handleChangeOrder}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
