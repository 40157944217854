import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { baseActions } from "redux/actions";
import cookies from "helpers/cookies";

import Routers from "./routes";
import LoadingScreen from "components/Loading/LoadingScreen";

function App() {
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.base);

  useEffect(() => {
    const user = cookies.get("user");

    dispatch(baseActions.SET_USER(user));
  }, []);

  return (
    <div className="App">
      {loading.screen && <LoadingScreen />}

      <Routers />
    </div>
  );
}

export default App;
