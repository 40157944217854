const course_types = {
  COURSE: "COURSE",
  COURSE_SELECTED: "COURSE_SELECTED",
};

const CourseConstant = {
  ALL_COURSE_TYPES: Object.keys(course_types),
  COURSE_TYPES: course_types,
};

export default CourseConstant;
