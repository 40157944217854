import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import React from "react";

const ModalVertification = ({
  openModal,
  onClose,
  handleSubmit,
  passwordProps,
}) => {
  return (
    <Dialog open={openModal} fullWidth={true} maxWidth="md" onClose={onClose}>
      <Container className="py-4">
        <Box textAlign="Center">
          <h2>Verifikasi</h2>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container className="py-4 ">
            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>Password</FormLabel>
                <OutlinedInput fullWidth type="password" {...passwordProps} />
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

export default ModalVertification;
