import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

export default function CourseParticipantDataTable({
  loading,
  type,
  data,
  permissions,
  pagination,
  getUserStatusData,
  handleChangePage,
  handleChangeRowsPage,
  handleSetUser = () => {},
  handleOpenScore = () => {},
  handleOpenSubmission = () => {},
}) {
  const columns = {
    FR: ["#", "Nama", "Email", "Nomor Telepon", "Progres Belajar"],
    TPM: ["#", "Nama", "Email", "Nomor Telepon", "Nilai", "Tugas", "Opsi"],
    UK: ["#", "Nama", "Email", "Nomor Telepon", "Nilai", "Tugas", "Opsi"],
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns[type]?.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data?.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell component="td" scope="row" align="center">
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>
                <TableCell component="td" align="center">
                  {item?.user?.name || "-"}
                </TableCell>
                <TableCell component="td" align="center">
                  {item?.user?.email || "-"}
                </TableCell>
                <TableCell component="td" align="center">
                  {item?.user?.phone_number || "-"}
                </TableCell>

                {/* FR */}
                {type === "FR" && (
                  <TableCell component="td" align="center">
                    {item?.progress_percentage || 0}%
                  </TableCell>
                )}

                {/* TPM */}
                {type === "TPM" && (
                  <>
                    <TableCell component="td" align="center">
                      {item?.user?.user_learning_progresses[0]
                        ?.learning_practice_assignment?.score || "-"}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {item?.user?.user_learning_progresses[0]
                        ?.learning_practice_assignment === null ? (
                        "Belum Upload Tugas"
                      ) : (
                        <a
                          target="_blank"
                          href={
                            item?.user?.user_learning_progresses[0]
                              ?.learning_practice_assignment?.submission_url
                          }
                          rel="noreferrer"
                          download
                        >
                          Unduh Tugas
                        </a>
                      )}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {permissions?.includes("281-8") && (
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          className="mr-1"
                          onClick={() =>
                            getUserStatusData(
                              item?.course_id,
                              item?.user?.user_learning_progresses[0]
                                ?.course_sub_module_id,
                              item?.user?.id
                            )
                          }
                        >
                          Cek Status
                        </Button>
                      )}

                      {permissions?.includes("281-5") && (
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          className="mr-1"
                          onClick={() => {
                            handleSetUser({
                              id: item?.user?.id,
                              name: item?.user?.name,
                              score:
                                item?.user?.user_learning_progresses[0]
                                  ?.learning_practice_assignment?.score ?? 0,
                              comment:
                                item?.user?.user_learning_progresses[0]
                                  ?.learning_practice_assignment?.comment ?? "",
                            });
                            handleOpenScore(true);
                          }}
                        >
                          Nilai
                        </Button>
                      )}

                      {permissions?.includes("281-4") && (
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          onClick={() => {
                            handleSetUser({
                              id: item?.user?.id,
                              name: item?.user?.name,
                            });
                            handleOpenSubmission(true);
                          }}
                        >
                          Submit Tugas
                        </Button>
                      )}
                    </TableCell>
                  </>
                )}

                {/* UK */}
                {type === "UK" && (
                  <>
                    <TableCell component="td" align="center">
                      {item?.user?.user_learning_progresses[0]
                        ?.learning_assignment?.score || "-"}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {item?.user?.user_learning_progresses[0]
                        ?.learning_assignment === null ? (
                        "Belum Upload Tugas"
                      ) : (
                        <a
                          target="_blank"
                          href={
                            item?.user?.user_learning_progresses[0]
                              ?.learning_assignment?.submission_url
                          }
                          rel="noreferrer"
                          download
                        >
                          Unduh Tugas
                        </a>
                      )}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {permissions?.includes("281-8") && (
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          className="mr-1"
                          onClick={() =>
                            getUserStatusData(
                              item?.course_id,
                              item?.user?.user_learning_progresses[0]
                                ?.course_sub_module_id,
                              item?.user?.id
                            )
                          }
                        >
                          Cek Status
                        </Button>
                      )}

                      {permissions?.includes("281-7") && (
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          className="mr-1"
                          onClick={() => {
                            handleSetUser({
                              id: item?.user?.id,
                              name: item?.user?.name,
                              score:
                                item?.user?.user_learning_progresses[0]
                                  ?.learning_assignment?.score ?? 0,
                              comment:
                                item?.user?.user_learning_progresses[0]
                                  ?.learning_assignment?.comment ?? "",
                            });
                            handleOpenScore(true);
                          }}
                        >
                          Nilai
                        </Button>
                      )}

                      {permissions?.includes("281-6") && (
                        <Button
                          variant="contained"
                          size="xl"
                          color="default"
                          onClick={() => {
                            handleSetUser({
                              id: item?.user?.id,
                              name: item?.user?.name,
                            });
                            handleOpenSubmission(true);
                          }}
                        >
                          Submit Tugas
                        </Button>
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
