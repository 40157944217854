import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";

import Swal from "sweetalert2";

import api from "services/api";
import http from "services/http";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown } from "@material-ui/icons";

const ModalExportUserAssessment = ({ openModal, onClose }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      start_date: "",
      end_date: "",
    },
  });

  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);

  /**
   * Fetch Data
   */
  const getListAdmins = async () => {
    try {
      const { data } = await http.get(api.v1.admin.administrator.main, {
        params: { option: "true", role_id: 6 },
      });

      const { records } = data.data;

      setAdmins(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      } else {
        console.error(error);
      }
    }
  };

  const exportData = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${val.start_date}-${val.end_date}_Data Penilaian.xlsx`;

      const administrator_id = selectedAdmin?.join(",");

      const params = {
        ...val,
        administrator_id,
      };

      const res = await http.get(`${api.v1.admin.report.assessment}/export`, {
        params,
        responseType: "blob",
      });

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response?.data?.errors
            ? response?.data?.errors[0]
            : response?.data?.meta?.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleClose = () => {
    reset();

    setAdmins([]);

    setSelectedAdmin([]);

    onClose();
  };

  useEffect(() => {
    if (openModal) {
      getListAdmins();
    }
  }, [openModal]);

  return (
    <Dialog
      open={openModal}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="md"
    >
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Export Data Penilaian</h2>
        </Box>

        <form onSubmit={handleSubmit(exportData)}>
          <Grid container className="py-4">
            <Grid item xs={6}>
              <FormGroup>
                <FormLabel>Tanggal Awal</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="date"
                  {...register("start_date")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                <FormLabel>Tanggal Akhir</FormLabel>
                <OutlinedInput
                  fullWidth
                  type="date"
                  {...register("end_date")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>Pilih Admin</FormLabel>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="combo-box-category"
                  options={admins}
                  getOptionLabel={option => option.name}
                  closeIcon={null}
                  onChange={(e, val) => {
                    if (admins.length)
                      setSelectedAdmin(val.map(el => parseInt(el.id)));
                  }}
                  popupIcon={
                    <Box
                      component={KeyboardArrowDown}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  }
                  renderInput={params => (
                    <TextField {...params} variant="filled" />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Export Data
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

export default ModalExportUserAssessment;
