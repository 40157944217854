const report_course_types = {
  PURCHASE: "PURCHASE",
  COMPLETED: "COMPLETED",
};

const ReportCourseTypeConstant = {
  ALL_REPORT_COURSE_TYPES: Object.keys(report_course_types),
  REPORT_COURSE_TYPES: report_course_types,
};

export default ReportCourseTypeConstant;
