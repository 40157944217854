import React, { useState } from "react";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import ChipData from "components/Chip/ChipData";
import moment from "moment";

export default function FaceRecognitionLogDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const [open, setOpen] = useState(false);

  const columns = [
    "",
    "tanggal",
    "Status",
    "Pesan",
    "Email",
    "Nomor",
    "Sesi",
    "Schedule Code",
    "Tipe",
  ];

  const status = {
    SUCCESS: "Berhasil",
    ERROR: "Gagal",
    ON_PROGRESS: "Dalam Proses",
  };

  const verificationType = {
    REDEEMPTION: "Redeem",
    ATTENDANCE: "Kehadiran",
  };

  const statusColor = {
    SUCCESS: themeColors.success.main,
    ERROR: themeColors.error.main,
    ON_PROGRESS: themeColors.warning.main,
    REDEEMPTION: themeColors.warning.main,
    ATTENDANCE: themeColors.facebook.main,
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.secondary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{
                  fontWeight: 700,
                  color: themeColors.dark.main,
                }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map(data => (
              <>
                <TableRow key={data.id}>
                  <TableCell align="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen(prev => ({
                          ...prev,
                          [data.id]: !prev[data.id],
                        }))
                      }
                    >
                      {open[data.id] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="td"
                    align="center"
                    style={{
                      color: statusColor[data.status],
                    }}
                  >
                    {moment(data.created_at).format("DD MM YYYY HH:mm")}
                  </TableCell>
                  <TableCell component="td" align="center">
                    <ChipData
                      color="secondary"
                      text={status[data.status]}
                      style={{
                        color: themeColors.gray[100],
                        backgroundColor: statusColor[data.status],
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="td"
                    align="center"
                    style={{
                      color: statusColor[data.status],
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      fontWeight: "Bold",
                    }}
                  >
                    {data.message}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {data?.prakerja_session_record?.user?.email}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {data?.prakerja_session_record?.user?.phone_number || "-"}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {data?.prakerja_session_record?.sequence !== 999
                      ? `Sesi ${data?.prakerja_session_record?.sequence}`
                      : "Post Test"}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {data?.prakerja_session_record?.schedule_code || "-"}
                  </TableCell>
                  <TableCell
                    component="td"
                    align="center"
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      maxWidth: "240px",
                    }}
                  >
                    <ChipData
                      color="secondary"
                      text={
                        verificationType[data?.prakerja_session_record?.type]
                      }
                      style={{
                        color: themeColors.gray[100],
                        backgroundColor:
                          statusColor[data?.prakerja_session_record?.type],
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open[data.id]} timeout="auto" unmountOnExit>
                      <Grid container className="mb-2">
                        <Grid item xs={12} md={6} component={Box}>
                          <List dense disablePadding={true}>
                            <ListItem>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { fontWeight: "bold" },
                                }}
                                primary="Kode Kelas"
                                secondary={
                                  data?.prakerja_session_record?.course?.code
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { fontWeight: "bold" },
                                }}
                                primary="Nama Kelas"
                                secondary={
                                  data?.prakerja_session_record?.course?.name
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <Grid>
                            <List dense disablePadding={true}>
                              <ListItem>
                                <ListItemText
                                  primaryTypographyProps={{
                                    style: { fontWeight: "bold" },
                                  }}
                                  primary="Kode Voucher"
                                  secondary={
                                    data?.prakerja_session_record?.voucher
                                  }
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemText
                                  primaryTypographyProps={{
                                    style: { fontWeight: "bold" },
                                  }}
                                  primary="Kode Redeem"
                                  secondary={
                                    data?.prakerja_session_record?.redeem_code
                                  }
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} component={Box}>
                          <Grid>
                            <List dense disablePadding={true}>
                              <ListItem>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    window.open(
                                      `${process.env.REACT_APP_URL_LMS}status-redeem?state=${data.state}`
                                    )
                                  }
                                >
                                  Cek Status
                                </Button>
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
