import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";

import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";
import moment from "moment";
import CreateUserTab from "components/User/CreateUser/CreateUserTab";

export default function CreateUserPage() {
  const dispatch = useDispatch();

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      user_excel: null,
    },
  });

  const [activeTab, setActiveTab] = useState(1);

  const buttonTabs = [
    {
      key: 1,
      value: "Bulk Create User",
    },
    {
      key: 2,
      value: "Create User Account",
    },
  ];

  const contentTabs = () => {
    switch (activeTab) {
      case 1:
        return bulkCreateUser();
      case 2:
        return <CreateUserTab />;
      default:
        return bulkCreateUser();
    }
  };

  /**
   * Fetch Data
   */
  const getFormatExcel = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = "Format Tambah User.xlsx";

      const res = await http.get(
        `${api.v1.admin.sync.user.main}/template-bulk-create-user`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { user_excel } = val;

      const formData = new FormData();

      formData.append("user_excel", user_excel[0]);

      const res = await http.post(
        `${api.v1.admin.sync.user.bulk_create}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          responseType: "blob",
        }
      );

      Swal.fire("Sukses", "Berhasil Menambahkan User", "success");

      reset();

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Result Create User.xlsx`;

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );

      link.click();
    } catch (error) {
      Swal.fire("Gagal", "Terjadi Kesalahan", "error");
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   *
   * Handle Change
   */
  const handleButtonTab = key => {
    setActiveTab(key);
  };

  /* component */
  const bulkCreateUser = () => {
    return (
      <>
        <Grid className="mb-4">
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              getFormatExcel();
            }}
            className="ml-2"
          >
            Unduh Format
          </Button>
        </Grid>

        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>File Peserta</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="file"
                          {...register("user_excel")}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        Simpan
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Create User</title>
      </Helmet>

      <DefaultHeader section="Tambah Peserta" subsection="Kelola Peserta" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2} className="mb-3 overflow-scroll">
              {buttonTabs.map((tab, index) => (
                <Grid item key={index} xs={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={activeTab === tab.key ? "primary" : ""}
                    onClick={() => handleButtonTab(tab.key)}
                  >
                    {tab.value}
                  </Button>
                </Grid>
              ))}
            </Grid>
            {contentTabs()}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
