import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import PermissionDataTable from "./PermissionDataTable";
import localStorage from "helpers/localStorage";

const ListPermissionPage = () => {
  const [permissions, setPermissions] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });
  const [loadingData, setLoadingData] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const permission = JSON.parse(localStorage.get("permissions"))?.map(
    permission => permission.code
  );

  const { register, watch, setValue } = useForm({
    defaultValues: {
      filter: {
        search_by: "",
        search: "",
      },
    },
  });

  const filterBy = [
    {
      label: "Nama",
      value: "NAME",
    },
    {
      label: "Kode",
      value: "CODE",
    },
  ];

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { search_by, search } = watch("filter");

      const { data } = await http(api.v1.admin.permission.main, {
        params: { limit, page, search_by, search },
      });

      const { records, current_page, total_page, total_records } = data.data;

      setPermissions(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      setLoadingData(false);
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.permission.main}/${id}`);

      getList();

      Swal.fire("Sukses", "Permission berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const handleDeletData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data permission",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) await deleteData(id);
    });
  };

  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => getList(), []);
  return (
    <>
      <Helmet>
        <title>Cariilmu | Permission</title>
      </Helmet>

      <DefaultHeader section="Data Akses" subsection="Kelola Akses" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Grid>
              {permission?.includes("151-3") && (
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/permission/tambah")}
                  >
                    Tambah Data
                  </Button>
                </Grid>
              )}

              {/* List Permission */}
              {permission?.includes("151-1") && (
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex align-items-center justify-content-end">
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={filterBy}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterBy.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.search_by", value);
                          }}
                          value={
                            filterBy.filter(
                              data => data.value === watch("filter.search_by")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Filter"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <OutlinedInput
                        type="text"
                        placeholder="Cari"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-2"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.search")}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                    <PermissionDataTable
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      data={permissions}
                      pagination={pagination}
                      handleDeleteData={handleDeletData}
                      loading={loadingData}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ListPermissionPage;
