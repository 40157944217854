import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import themeColors from "assets/theme/colors";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import EventPlaceDataTable from "./EventPlaceDataTable";
import ReactSelect from "react-select";
import { Search } from "@material-ui/icons";

export default function ListEventPlace() {
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      event_place: {
        name: "",
        address: "",
        city: { label: "", value: "" },
        max_participant: 0,
        lat: "",
        long: "",
      },
      filter: {
        name: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [eventPlaceData, setEventPlaceData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [optionCity, setOptionCity] = useState([]);

  const [typeModalEventPlace, setTypeModalEventPlace] = useState("CREATE");
  const [showModalEventPlace, setShowModalEventPlace] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { name } = watch("filter");

      const { data } = await http.get(`${api.v1.admin.event_place.main}`, {
        params: { limit, page, name },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setEventPlaceData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getOptionCity = async () => {
    try {
      const { data } = await http.get(`${api.v1.city.main}`);

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.name };
      });

      setOptionCity(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(`${api.v1.admin.event_place.main}/${id}`);

      setValue("event_place", {
        id,
        name: data.data.name,
        address: data.data.address,
        city: optionCity.find(city => city.value === data.data.city),
        max_participant: data.data.max_participant,
        lat: data.data.lat,
        long: data.data.long,
      });

      setTypeModalEventPlace("UPDATE");
      setShowModalEventPlace(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { event_place } = payload;

      await http.post(api.v1.admin.event_place.main, {
        ...event_place,
        city: event_place.city.value,
      });

      handleCloseModal();

      Swal.fire("Sukses", "Data Tempat Acara berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { event_place } = payload;

      await http.put(`${api.v1.admin.event_place.main}/${event_place.id}`, {
        ...event_place,
        city: event_place.city.value,
      });

      handleCloseModal();

      Swal.fire("Sukses", "Data Tempat Acara berhasil di Perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.event_place.main}/${id}`);

      Swal.fire("Sukses", "Data Tempat Acara berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalEventPlace("CREATE");
    setShowModalEventPlace(true);
  };

  const handleCloseModal = () => {
    setShowModalEventPlace(false);
    setValue("event_place", {
      name: "",
      address: "",
      max_participant: 0,
      lat: "",
      long: "",
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Tempat Acara",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList();
    getOptionCity();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tempat Acara</title>
      </Helmet>

      <DefaultHeader section="Data Tempat Acara" />

      <Container maxWidth={false} component={Box} id="event-place-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex align-items-center justify-content-end">
                      <OutlinedInput
                        type="text"
                        placeholder="Cari berdasarkan nama"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-3"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.name")}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                    <EventPlaceDataTable
                      loading={loadingData}
                      data={eventPlaceData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Event Place */}
                <Dialog
                  open={showModalEventPlace}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Data Tempat Acara</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalEventPlace === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("event_place.name")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Alamat</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("event_place.address")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel id="option-city-label">
                              Pilih Kota
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={optionCity}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("event_place.city", val);
                              }}
                              value={watch("event_place.city")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Maksimal Peserta</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("event_place.max_participant")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Latitude</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("event_place.lat")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>Longitude</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("event_place.long")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalEventPlace === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
