import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  SnackbarContent,
  Switch,
  TextField,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import ReactQuill from "react-quill";
import ReactSelect from "react-select";
import Swal from "sweetalert2";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import Spinner from "components/Loading/Indicator/Spinner";
import CompetencyAspectTab from "components/Course/CompetencyAspect/CompetencyAspectTab";
import TrainingGoalTab from "components/Course/TrainingGoal/TrainingGoalTab";
import TrainingMaterialTab from "components/Course/TrainingMaterial/TrainingMaterialTab";
import CertificateStandardTab from "components/Course/CertificateStandard/CertificateStandardTab";
import EvaluationMethodTab from "components/Course/EvaluationMethod/EvaluationMethodTab";
import DemonstrationSkillsTab from "components/Course/DemonstrationSkills/DemonstrationSkillsTab";

const snackbarStyles = makeStyles(snackbarTheme);

export default function DetailCoursePage() {
  const { id } = useParams();

  const styles = { ...snackbarStyles() };
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, watch, setValue } = useForm({
    defaultValues: {
      course: {
        name: "",
        slug: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [teachMethods, setTeachMethods] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [applePrice, setApplePrice] = useState([]);
  const [assessors, setAssessors] = useState([]);
  const [instructorAssistances, setInstructorAssistances] = useState([]);
  const [partners, setPartners] = useState([]);
  const [optionBonusCourse, setOptionBonusCourse] = useState([]);

  const [activeTab, setActiveTab] = useState(1);

  const coverRef = useRef(null);

  const buttonTabs = [
    {
      key: 1,
      value: "Master Data",
    },
    {
      key: 2,
      value: "Aspek Kompetensi",
    },
    {
      key: 3,
      value: "Tujuan Pelatihan",
    },
    {
      key: 4,
      value: "Materi Pelatihan",
    },
    {
      key: 5,
      value: "Standar Sertifikat",
    },
    {
      key: 6,
      value: "Metode Evaluasi",
    },
    {
      key: 7,
      value: "Unjuk Keterampilan",
    },
  ];
  const statusCourseOptions = ["DRAFT", "QC", "PUBLISH"];
  // const competenceAspectOptions = [
  //   "PENGETAHUAN",
  //   "KETERAMPILAN",
  //   "SIKAP KERJA",
  // ];

  /**
   * Fetch Data
   */
  const getDetailCourse = async id => {
    try {
      setLoadingData(true);

      const { data } = await http.get(`${api.v1.admin.course.main}/${id}`);

      setError(null);

      setValue("preview_cover", data.data.cover);
      if (data.data.certificate) {
        setValue("preview_certificate_sample", data.data.certificate_sample);
        setValue("course.certificate_sample", null);
      }

      data.data.cover = null;
      data.data.certificate_sample = null;

      setValue("course", data.data);

      setValue(
        "course.certificate",
        data.data.certificate === 1 ? true : false
      );
      setValue(
        "course.external_purchase",
        data.data.external_purchase === 1 ? true : false
      );
      setValue(
        "course.publish_at",
        moment(data.data.publish_at).format("yyyy-MM-DDTHH:mm:ss")
      );
      setValue("course.partner_id", data?.data?.partner?.id);

      const selected_bonus = data.data.bonuses.map(item => ({
        label: `(${item.type}) ${item.name}`,
        value: item.id,
      }));

      setValue("course.selected_course_bonuses", selected_bonus);
      setValue(
        "course.course_bonuses",
        selected_bonus.map(item => item)
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getListCategories = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.category, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setCategories(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListTypes = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.type, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setTypes(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListLevels = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.level, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setLevels(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListTeachMethods = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.teach_method, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setTeachMethods(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListInstructors = async () => {
    try {
      const { data } = await http.get(api.v1.admin.instructors.main, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setInstructors(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListApplePrice = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.apple_price, {
        params: { option: "true", all: true },
      });

      setError(null);

      let { records } = data.data;

      setApplePrice(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListInstrucotrAssistances = async () => {
    try {
      const { data } = await http.get(api.v1.admin.instructor_assistance.main, {
        params: { option: true, all: true },
      });

      setError(null);

      let { records } = data.data;

      setInstructorAssistances(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListAssessor = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.assessor, {
        params: { limit: 999 },
      });

      setError(null);

      let { records } = data.data;

      setAssessors(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListPartners = async () => {
    try {
      const { data } = await http.get(api.v1.admin.partner.main, {
        params: { limit: 999, option: true, all: true },
      });

      setError(null);

      let { records } = data.data;

      setPartners(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.bonus}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        label: `(${item.type}) ${item.name}`,
        value: item.id,
      }));

      setOptionBonusCourse(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const putUpdateCourse = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("course");

      payload.publish_at = moment(payload.publish_at).toISOString();
      payload.certificate = payload.certificate ? 1 : 0;
      payload.external_purchase = payload.external_purchase ? 1 : 0;

      // if (!payload.preview_video) delete payload.preview_video;
      if (!payload.certificate) delete payload.course_assessor;
      if (!payload.partner) delete payload.partner;
      if (!payload.external_purchase) delete payload.external_purchase_url;

      const formData = new FormData();
      const files = ["cover", "certificate_sample"];

      for (const key in payload) {
        if (!Array.isArray(payload[key]) && !files.includes(key)) {
          formData.append(key, payload[key]);
        }

        if (files.includes(key) && payload[key]) {
          formData.append(key, payload[key][0]);
        }
      }

      const instructorsList = watch("course.instructors");
      const courseType = watch("course.course_type");
      const courseTeachMethod = watch("course.course_teach_method");
      const courseCategory = watch("course.course_category");
      const courseLevel = watch("course.course_level");
      const courseApplePrice = watch("course.course_apple_price");
      const courseAssessor = watch("course.course_assessor");
      const coursePartner = watch("course.partner");
      const instructorAssistanceList = watch("course.instructor_assistances");

      formData.append(
        "course_type_id",
        courseType?.id ? parseInt(courseType?.id) : null
      );
      formData.append(
        "course_teach_method_id",
        parseInt(courseTeachMethod?.id)
      );
      formData.append(
        "course_category_id",
        courseCategory?.id ? parseInt(courseCategory?.id) : null
      );
      formData.append(
        "course_level_id",
        courseLevel?.id ? parseInt(courseLevel?.id) : null
      );
      formData.append(
        "course_apple_price_id",
        courseApplePrice?.id ? parseInt(courseApplePrice?.id) : null
      );

      if (payload.certificate && courseAssessor) {
        formData.append(
          "course_assessor_id",
          courseAssessor?.id ? parseInt(courseAssessor?.id) : null
        );
      }

      formData.delete("partner_id");
      formData.append(
        "partner_id",
        coursePartner?.id ? parseInt(coursePartner?.id) : null
      );

      if (instructorsList.length)
        instructorsList.forEach((instList, key) => {
          formData.append(`instructors[${key}]`, instList.id);
        });

      if (instructorAssistanceList.length)
        instructorAssistanceList.forEach((instList, key) => {
          formData.append(`instructor_assistances[${key}]`, instList.id);
        });

      if (payload.course_bonuses?.length)
        payload.course_bonuses.forEach((instList, key) => {
          formData.append(`course_bonuses[${key}]`, instList);
        });

      await http.put(`${api.v1.admin.course.main}/${id}`, formData);

      Swal.fire("Sukses", "Data kelas berhasil di perbarui", "success");

      history.push("/kelas");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */

  const handleButtonTab = key => {
    setActiveTab(key);
  };

  const handlechangeInput = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (name === "name") {
      setValue("course.name", value);
      setValue("course.slug", value.replace(/ /g, "-").toLowerCase());
    } else if (name === "slug") {
      setValue("course.slug", value.replace(/ /g, "-").toLowerCase());
    } else {
      setValue(`course.${name}`, value);
    }
  };

  useEffect(() => {
    getDetailCourse(id);
    getListCategories();
    getListTypes();
    getListLevels();
    getListTeachMethods();
    getListInstructors();
    getListApplePrice();
    getListAssessor();
    getListPartners();
    getOptionCourses();
    getListInstrucotrAssistances();
  }, []);

  const contentTabs = () => {
    switch (activeTab) {
      case 1:
        return detailCourseTab();
      case 2:
        return <CompetencyAspectTab />;
      case 3:
        return <TrainingGoalTab />;
      case 4:
        return <TrainingMaterialTab />;
      case 5:
        return <CertificateStandardTab />;
      case 6:
        return <EvaluationMethodTab />;
      case 7:
        return <DemonstrationSkillsTab />;
      default:
        return detailCourseTab();
    }
  };

  const detailCourseTab = () => {
    return (
      <Card className="p-4">
        {/* Master Data */}
        <Grid container>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Kode</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                {...register("course.code")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormGroup>
              <FormLabel>Judul</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                name="name"
                onChange={handlechangeInput}
                value={watch("course.name")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormLabel>Slug</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                name="slug"
                onChange={handlechangeInput}
                value={watch("course.slug")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormLabel>Deskripsi</FormLabel>
              <ReactQuill
                theme="snow"
                value={watch("course.description")}
                onChange={val => setValue("course.description", val)}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Kategori</FormLabel>
              <Autocomplete
                id="combo-box-category"
                options={categories}
                getOptionLabel={option => option.name}
                closeIcon={null}
                defaultValue={watch("course.course_category")}
                getOptionSelected={(option, val) => option.id === val.id}
                onChange={(e, val) => {
                  setValue("course.course_category", val);
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Tipe</FormLabel>
              <Autocomplete
                id="combo-box-category"
                multiple={false}
                options={types}
                getOptionLabel={option => option.name}
                closeIcon={null}
                defaultValue={watch("course.course_type")}
                getOptionSelected={(option, val) => option.id === val.id}
                onChange={(e, val) => {
                  setValue("course.course_type", val);
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Level</FormLabel>
              <Autocomplete
                id="combo-box-category"
                options={levels}
                getOptionLabel={option => option.name}
                closeIcon={null}
                defaultValue={watch("course.course_level")}
                getOptionSelected={(option, val) => option.id === val.id}
                onChange={(e, val) => {
                  setValue("course.course_level", val);
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Metode Ajar</FormLabel>
              <Autocomplete
                id="combo-box-category"
                options={teachMethods}
                getOptionLabel={option => option.name}
                closeIcon={null}
                defaultValue={watch("course.course_teach_method")}
                getOptionSelected={(option, val) => option.id === val.id}
                onChange={(e, val) => {
                  setValue("course.course_teach_method", val);
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormLabel>Gambar Kelas</FormLabel>
              <OutlinedInput
                fullWidth
                type="file"
                inputRef={coverRef}
                placeholder="Default input"
                {...register("course.cover")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormLabel>Instruktur</FormLabel>
              <Autocomplete
                multiple
                id="combo-box-category"
                options={instructors}
                getOptionLabel={option => option.name}
                closeIcon={null}
                defaultValue={watch("course.instructors")}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, val) => {
                  if (instructors.length) {
                    setValue("course.instructors", val);
                  }
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormLabel>Asisten Instruktur</FormLabel>
              <Autocomplete
                multiple
                id="combo-box-instructor-assistance"
                options={instructorAssistances}
                getOptionLabel={option => option.name}
                closeIcon={null}
                defaultValue={watch("course.instructor_assistances")}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, val) => {
                  if (instructorAssistances.length) {
                    setValue("course.instructor_assistances", val);
                  }
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>

          {/* Cover Preview */}
          {watch("preview_cover") && (
            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>Preview Cover</FormLabel>
                <img src={watch("preview_cover")} alt="cover" width="400px" />
              </FormGroup>
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Durasi</FormLabel>
              <OutlinedInput
                fullWidth
                type="number"
                {...register("course.duration")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Bahasa</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                {...register("course.language")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Harga</FormLabel>
              <OutlinedInput
                fullWidth
                type="number"
                {...register("course.price")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Mayar Produk Id</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                {...register("course.mayar_product_id")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Harga Diskon</FormLabel>
              <OutlinedInput
                fullWidth
                type="number"
                {...register("course.discount_price")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Apple Pay</FormLabel>
              <Autocomplete
                id="combo-box-category"
                options={applePrice}
                getOptionLabel={option => option.price}
                closeIcon={null}
                defaultValue={watch("course.course_apple_price")}
                getOptionSelected={(option, val) => option.id === val.id}
                onChange={(e, val) => {
                  setValue("course.course_apple_price", val);
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Pratinjau Video</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                {...register("course.preview_video")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormGroup>
              <FormControl variant="outlined" fullWidth>
                <FormLabel id="option-competence-aspect-label">
                  Status
                </FormLabel>
                <Select
                  IconComponent={KeyboardArrowDown}
                  labelId="option-competence-aspect-label"
                  value={watch("course.status") || ""}
                  {...register("course.status")}
                >
                  {statusCourseOptions.map((val, key) => (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Tanggal Publish</FormLabel>
              <OutlinedInput
                fullWidth
                type="datetime-local"
                defaultValue={moment(watch("course.publish_at")).format(
                  "yyyy-MM-DDTHH:mm:ss"
                )}
                {...register("course.publish_at")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Total Peserta</FormLabel>
              <OutlinedInput
                fullWidth
                type="number"
                {...register("course.total_participant")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Kode Prakerja</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                {...register("course.prakerja_code")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Link Grup Diskusi</FormLabel>
              <OutlinedInput
                fullWidth
                type="text"
                {...register("course.group_url")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormLabel>Kelompok Sasaran</FormLabel>
              <ReactQuill
                theme="snow"
                value={watch("course.target_group")}
                onChange={val => setValue("course.target_group", val)}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControl variant="outlined" fullWidth>
                <FormLabel id="option-status-promo-code-label">
                  Bonus Penyelesaian
                </FormLabel>
                <ReactSelect
                  isMulti
                  options={optionBonusCourse}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={val => {
                    setValue("course.selected_course_bonuses", val);
                    setValue(
                      "course.course_bonuses",
                      val.map(({ value }) => value)
                    );
                  }}
                  value={watch("course.selected_course_bonuses")}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Partner</FormLabel>
              <Autocomplete
                id="combo-box-partner"
                options={partners}
                getOptionLabel={option => option.name}
                defaultValue={watch("course.partner")}
                getOptionSelected={(option, val) => option.id === val.id}
                onChange={(e, val) => {
                  setValue("course.partner", val);
                }}
                popupIcon={
                  <Box
                    component={KeyboardArrowDown}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                }
                renderInput={params => (
                  <TextField {...params} variant="filled" />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={2}>
            <FormGroup>
              <FormLabel>Sertifikat</FormLabel>
              <Switch
                color="primary"
                size="medium"
                defaultChecked={watch("course.certificate")}
                onChange={e => setValue("course.certificate", e.target.checked)}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          {watch("course.certificate") ? (
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Asesor</FormLabel>
                <Autocomplete
                  id="combo-box-assessor"
                  options={assessors}
                  getOptionLabel={option => option.name}
                  defaultValue={watch("course.course_assessor")}
                  getOptionSelected={(option, val) => option.id === val.id}
                  onChange={(e, val) => {
                    setValue("course.course_assessor", val);
                  }}
                  popupIcon={
                    <Box
                      component={KeyboardArrowDown}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  }
                  renderInput={params => (
                    <TextField {...params} variant="filled" />
                  )}
                />
              </FormGroup>
            </Grid>
          ) : null}
        </Grid>

        {/* External purcahse */}
        <Grid container className="mt-4">
          <Grid item xs={12} className="mb-2">
            <h2 className="text-muted">
              Pembelian Eksternal
              <Switch
                color="primary"
                size="medium"
                defaultChecked={watch("course.external_purchase")}
                onChange={e =>
                  setValue("course.external_purchase", e.target.checked)
                }
                className="ml-2"
              />
            </h2>
          </Grid>

          {watch("course.external_purchase") ? (
            <Grid item xs={12}>
              <FormGroup>
                <OutlinedInput
                  fullWidth
                  type="text"
                  placeholder="Link Pembelian Eksternal"
                  {...register("course.external_purchase_url")}
                />
              </FormGroup>
            </Grid>
          ) : null}
        </Grid>

        <Grid container className="mt-5">
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => putUpdateCourse(id)}
            >
              Perbarui
            </Button>
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Detail Kelas"
        subsection="Data Kelas"
        routeBack="/kelas"
      />

      <Container maxWidth={false} component={Box} id="create-course-page">
        <Grid container>
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <>
                <Grid container spacing={2} className="mb-3 overflow-scroll">
                  {buttonTabs.map((tab, index) => (
                    <Grid item key={index} xs={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color={activeTab === tab.key ? "primary" : ""}
                        onClick={() => handleButtonTab(tab.key)}
                      >
                        {tab.value}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                {error && (
                  <Grid container className="my-2">
                    <Grid item xs={12}>
                      <SnackbarContent
                        classes={{ root: styles.errorSnackbar }}
                        action={
                          <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => setError(null)}
                          >
                            <Box
                              component="span"
                              color={theme.palette.white.main}
                            >
                              ×
                            </Box>
                          </Box>
                        }
                        message={<Box>{error}</Box>}
                      />
                    </Grid>
                  </Grid>
                )}

                {contentTabs()}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
