import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import http from "services/http";
import api from "services/api";
import { baseActions } from "redux/actions";
import { useForm } from "react-hook-form";
import ReminderCourseDataTable from "./ReminderCourseDataTable";
import Swal from "sweetalert2";
import { KeyboardArrowDown } from "@material-ui/icons";
import ReactSelect from "react-select";
import moment from "moment";

export default function ListReminderCoursePage() {
  const dispatch = useDispatch();

  const { setValue, handleSubmit, register, watch } = useForm({
    defaultValues: {
      reminder_course: {
        period: "",
        status: "",
        course_id: null,
        email_template_message_id: null,
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [reminderCourses, setReminderCourses] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [optionCourseData, setOptionCourseData] = useState([]);
  const [webinarSchedules, setWebinarSchedules] = useState([]);
  const [optionTemplateMessages, setOptionTemplateMessages] = useState([]);
  const [disableWebinar, setDisableWebinar] = useState(true);

  const [typeModalReminderCourse, setTypeModalReminderCourse] =
    useState("CREATE");
  const [showModalReminderCourse, setShowModalReminderCourse] = useState(false);

  const periodOptions = [
    {
      label: "7 Hari Sesudah",
      value: "SEVEN_DAY_AFTER",
    },
    {
      label: "12 Hari Sesudah",
      value: "TWELVE_DAY_AFTER",
    },
  ];

  const statusOptions = [
    {
      label: "Menunggu Jadwal",
      value: "WAITING_PERIOD",
    },
    {
      label: "Dalam Antrian",
      value: "IN_QUEUE",
    },
    {
      label: "Selesai",
      value: "FINISHED",
    },
  ];

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.email.reminder.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setReminderCourses(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: `${item.name} - ${item.code}`, value: item.id };
      });

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getTemplateMessages = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.email.template.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionTemplateMessages(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getCourseWebinarOption = async () => {
    const { data } = await http.get(
      `${api.v1.admin.course.main}/${watch(
        "reminder_course.course_id"
      )}/webinar`,
      {
        params: { page: 1, limit: 999 },
      }
    );

    setWebinarSchedules(data.data.records);
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.email.reminder.main}/${id}`
      );

      setValue("reminder_course", {
        id,
        period: data.data.period,
        status: data.data.status,
        course_id: data.data.course?.id,
        course_webinar_id: data.data.course_webinar?.id,
        email_template_message_id: data.data.email_template_message?.id,
      });

      setTypeModalReminderCourse("UPDATE");
      setShowModalReminderCourse(true);

      getCourseWebinarOption();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { reminder_course } = payload;

      await http.post(api.v1.admin.email.reminder.main, {
        ...reminder_course,
      });

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Pengingat Jadwal Kelas berhasil di tambahkan",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { reminder_course } = payload;

      await http.put(
        `${api.v1.admin.email.reminder.main}/${reminder_course.id}`,
        {
          ...reminder_course,
        }
      );

      handleCloseModal();

      Swal.fire(
        "Sukses",
        "Data Pengingat Jadwal Kelas berhasil di Perbarui",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.email.reminder.main}/${id}`);

      Swal.fire(
        "Sukses",
        "Data Pengingat Jadwal Kelas berhasil di hapus",
        "success"
      );

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const exportData = async id => {
    try {
      let res;

      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - Laporan Pengingat Jadwal Kelas (Email).xlsx`;

      res = await http.get(`${api.v1.admin.email.reminder.main}/${id}/export`, {
        responseType: "blob",
      });

      Swal.fire(
        "Sukses",
        "Laporan Pengingat Jadwal Kelas (Email) berhasil di unduh",
        "success"
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      let message;
      const { response } = error;

      if (response) {
        const { status } = response;

        if (status === 404) {
          message = "Tidak ditemukan";
        } else {
          message = response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;
        }

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalReminderCourse("CREATE");
    setShowModalReminderCourse(true);
  };

  const handleCloseModal = () => {
    setShowModalReminderCourse(false);
    setValue("reminder_course", {
      period: "",
      status: "",
      course_id: null,
      course_webinar_id: null,
      email_template_message_id: null,
    });
    setDisableWebinar(true);
    setWebinarSchedules([]);
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data pengingat kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleExportData = id => {
    Swal.fire({
      icon: "question",
      title: "Apakah anda yakin?",
      text: "Anda akan mengunduh data pengingat kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        exportData(id);
      }
    });
  };

  useEffect(() => {
    getList();
    getOptionCourses();
    getTemplateMessages();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Pengingat Jadwal Kelas</title>
      </Helmet>

      <DefaultHeader section="Data Pengingat Jadwal Kelas" />

      <Container maxWidth={false} component={Box} id="reminder-course">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <ReminderCourseDataTable
                      loading={loadingData}
                      data={reminderCourses}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      handleExportData={handleExportData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Reminder Course */}
                <Dialog
                  open={showModalReminderCourse}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Data Pengingat Jadwal Kelas</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalReminderCourse === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                              <FormLabel>Pilih Kelas</FormLabel>
                              <ReactSelect
                                options={optionCourseData}
                                className="basic-multi-select"
                                classNamePrefix="select-course"
                                onChange={val => {
                                  setValue(
                                    "reminder_course.course_id",
                                    val.value
                                  );

                                  setDisableWebinar(false);
                                  getCourseWebinarOption();
                                }}
                                defaultValue={optionCourseData.find(
                                  ({ value }) =>
                                    value === watch("reminder_course.course_id")
                                )}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                              <FormLabel>Pilih Jadwal</FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-webinar"
                                disabled={
                                  typeModalReminderCourse === "UPDATE"
                                    ? false
                                    : disableWebinar
                                }
                                value={watch(
                                  "reminder_course.course_webinar_id"
                                )}
                                {...register(
                                  "reminder_course.course_webinar_id"
                                )}
                              >
                                {webinarSchedules.map((val, key) => (
                                  <MenuItem key={key} value={val.id}>
                                    {val.code} - {val.sessions[0].date}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                              <FormLabel>Pilih Template Email</FormLabel>
                              <ReactSelect
                                options={optionTemplateMessages}
                                className="basic-multi-select"
                                classNamePrefix="select-course"
                                onChange={val => {
                                  setValue(
                                    "reminder_course.email_template_message_id",
                                    val.value
                                  );
                                }}
                                defaultValue={optionTemplateMessages.find(
                                  ({ value }) =>
                                    value ===
                                    watch(
                                      "reminder_course.email_template_message_id"
                                    )
                                )}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                              <FormLabel>Periode</FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-period"
                                value={watch("reminder_course.period")}
                                {...register("reminder_course.period")}
                              >
                                {periodOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <FormGroup>
                              <FormLabel>Status</FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status"
                                value={watch("reminder_course.status")}
                                {...register("reminder_course.status")}
                              >
                                {statusOptions.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalReminderCourse === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
