import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { ImportExport, Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import http from "services/http";
import api from "services/api";
import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import CandidateDataTable from "./CandidateDataTable";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";

export default function ListCandidatePage() {
  const dispatch = useDispatch();

  const { register, setValue, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      filter: {
        stage: "",
        email: "",
      },
      import_candidate: {
        brand: { label: "", value: null },
        job_opening: { label: "", value: null },
        participant_excel: "",
      },
      participant: {
        name: "",
        email: "",
        phone_number: "",
        stage: null,
        brand: { label: "", value: null },
        job_opening: { label: "", value: null },
      },
    },
  });

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [brandOption, setBrandOption] = useState([]);
  const [jobOpeningOption, setJobOpeningOption] = useState([]);

  const [candidateData, setCandidateData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [openModalImport, setOpenModalImport] = useState(false);
  const [showModalParticipant, setShowModalParticipant] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { stage, email } = watch("filter");

      const { data } = await http.get(
        api.v1.admin.report.massive_hiring_candidate,
        {
          params: { limit, page, stage, email },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCandidateData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.report.massive_hiring_candidate}/${id}`
      );

      await getJobOpening(data.data.job_opening?.brand_id);

      const brand = brandOption.find(
        item => item.value === data.data.job_opening?.brand_id
      );

      const job_opening = {
        label: data.data.job_opening?.title,
        value: data.data.job_opening?.id,
      };

      setValue("participant", {
        id,
        name: data.data.name,
        email: data.data.email,
        phone_number: data.data.phone_number,
        stage: data.data.stage,
        brand,
        job_opening,
      });

      setShowModalParticipant(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const getBrand = async () => {
    try {
      const { data } = await http.get(api.v1.admin.brand.main, {
        params: { option: true },
      });

      const options = data.data.records.map(item => ({
        label: item.name,
        value: item.id,
      }));

      setBrandOption(options);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobOpening = async id => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.brand.main}/${id}/job-opening`,
        { params: { option: true } }
      );

      const options = data.data.records.map(item => ({
        label: item.title,
        value: item.id,
      }));

      setJobOpeningOption(options);
    } catch (error) {
      console.log(error);
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { id, name, email, phone_number, stage, job_opening } =
        payload.participant;

      const data = {
        name,
        email,
        phone_number,
        stage,
        brand_job_opening_id: job_opening?.value || null,
      };

      await http.put(
        `${api.v1.admin.report.massive_hiring_candidate}/${id}`,
        data
      );

      setShowModalParticipant(false);
      reset();

      Swal.fire("Sukses", "Data Kandidat berhasil diperbaharui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const importParticipant = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { participant_excel, job_opening } = val.import_candidate;

      const formData = new FormData();

      formData.append("participant_excel", participant_excel[0]);
      formData.append("brand_job_opening_id", job_opening?.value || null);

      await http.post(
        `${api.v1.admin.report.massive_hiring_candidate}/import`,
        formData
      );

      Swal.fire("Sukses", "Berhasil Import Kandidat", "success");

      setOpenModalImport(false);
      getList();
      reset();
    } catch (error) {
      const { response } = error;
      if (response) {
        const { status } = response;
        const message =
          status === 422 && response?.data?.errors
            ? response?.data?.errors[0]
            : response?.data?.meta?.message;

        Swal.fire("Gagal", message, "error");
      } else {
        Swal.fire("Gagal", "Terjadi Kesalahan", "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.report.massive_hiring_candidate}/${id}`
      );

      Swal.fire("Sukses", "Data kandidat dihapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan kandidat",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList();
    getBrand();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Kandidat</title>
      </Helmet>

      <DefaultHeader section="Data Kandidat" subsection="Laporan Perekrutan" />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <>
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-between">
                      <Grid sm={8} className="d-flex align-items-center">
                        <Grid sm={3} className="mr-2">
                          <OutlinedInput
                            type="number"
                            placeholder="Cari Tahapan (1-999)"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                            onKeyPress={e => e.key === "Enter" && getList()}
                            {...register("filter.stage")}
                          />
                        </Grid>
                        <OutlinedInput
                          type="text"
                          placeholder="Cari email"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-2 ml-2"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.email")}
                        />
                        <Button
                          className="mr-2"
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                      <Grid sm={6}>
                        <Grid className="d-flex align-items-center justify-content-end">
                          <Grid>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setOpenModalImport(true)}
                            >
                              <ImportExport className="mr-2" />
                              Import Data
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <CandidateDataTable
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      data={candidateData}
                      pagination={pagination}
                      loading={loadingData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                    />
                  </Card>
                </Grid>

                {/* Modal Import */}
                <Dialog
                  open={openModalImport}
                  onClose={() => {
                    setOpenModalImport(false);
                    reset();
                  }}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Import Data Kandidat</h2>
                    </Box>

                    <form onSubmit={handleSubmit(importParticipant)}>
                      <Grid container className="py-4">
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel id="option-type-label">
                              Pilih Brand
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={brandOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setJobOpeningOption([]);
                                setValue("import_candidate.job_opening", {
                                  label: "",
                                  value: null,
                                });
                                getJobOpening(val.value);
                                setValue("import_candidate.brand", val);
                              }}
                              value={watch("import_candidate.brand")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel id="option-city-label">
                              Pilih Lowongan
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={jobOpeningOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("import_candidate.job_opening", val);
                              }}
                              value={watch("import_candidate.job_opening")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>File Excel</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="file"
                              {...register(
                                "import_candidate.participant_excel"
                              )}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Import
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Update User */}
                <Dialog
                  open={showModalParticipant}
                  onClose={() => {
                    setShowModalParticipant(false);
                    reset();
                  }}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Perbaharui Data Kandidat</h2>
                    </Box>

                    <form onSubmit={handleSubmit(putUpdateData)}>
                      <Grid container className="py-4">
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("participant.name")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Email</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("participant.email")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel id="option-type-label">
                              Pilih Brand
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={brandOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setJobOpeningOption([]);
                                setValue("participant.job_opening", {
                                  label: "",
                                  value: null,
                                });
                                getJobOpening(val.value);
                                setValue("participant.brand", val);
                              }}
                              value={watch("participant.brand")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel id="option-city-label">
                              Pilih Lowongan
                            </FormLabel>
                            <ReactSelect
                              isMulti={false}
                              options={jobOpeningOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={val => {
                                setValue("participant.job_opening", val);
                              }}
                              value={watch("participant.job_opening")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Tahapan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="number"
                              {...register("participant.stage")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Simpan
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
