import StatusConstant from "constant/StatusConstant";

const formatStatusLogEmail = string => {
  switch (string) {
    case StatusConstant.STATUS_LOG_EMAIL.SEND:
      return "Terkirim";
    case StatusConstant.STATUS_LOG_EMAIL.DELIVERED:
      return "Diterima";
    case StatusConstant.STATUS_LOG_EMAIL.OPEN:
      return "Dibuka";
    case StatusConstant.STATUS_LOG_EMAIL.NOT_FOUND:
      return "Tidak Ditemukan";
    default:
      return "-";
  }
};

export default formatStatusLogEmail;
