import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import api from "services/api";
import http from "services/http";

import { baseActions } from "redux/actions";
import { useDispatch } from "react-redux";

import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import DefaultHeader from "components/Headers/DefaultHeader";

export default function BulkVoidVoucherPage() {
  const dispatch = useDispatch();

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      voucher_excel: null,
    },
  });

  /**
   * Fetch Data
   */
  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { voucher_excel } = val;

      const formData = new FormData();

      formData.append("voucher_excel", voucher_excel[0]);

      await http.post(`${api.v1.admin.voucher.main}/bulk-void`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      });

      Swal.fire("Sukses", "Berhasil Void Voucher", "success");

      reset();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response?.data?.errors
            ? response?.data?.errors[0]
            : response?.data?.meta?.message;

        Swal.fire("Gagal", message, "error");
      }

      Swal.fire("Gagal", "Terjadi Kesalahan", "error");
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Void Voucher</title>
      </Helmet>

      <DefaultHeader section="Void Voucher" subsection="Kelola Voucher" />

      <Container maxWidth={false} component={Box}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>File Voucher</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="file"
                          {...register("voucher_excel")}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
