import StatusConstant from "constant/StatusConstant";

const formatStatusLogWhatsapp = string => {
  switch (string) {
    case StatusConstant.STATUS_LOG_WHATSAPP.QUEUED:
      return "Dalam Antrian";
    case StatusConstant.STATUS_LOG_WHATSAPP.FAILED:
      return "Gagal";
    case StatusConstant.STATUS_LOG_WHATSAPP.SENT:
      return "Terkirim";
    case StatusConstant.STATUS_LOG_WHATSAPP.DELIVERED:
      return "Sudah Diterima";
    case StatusConstant.STATUS_LOG_WHATSAPP.UNDELIVERED:
      return "Belum Diterima";
    case StatusConstant.STATUS_LOG_WHATSAPP.READ:
      return "Sudah Dibaca";
    default:
      return "-";
  }
};

export default formatStatusLogWhatsapp;
