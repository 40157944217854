import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";

import api from "services/api";
import http from "services/http";
import CourseBundleDataTable from "./CourseBundleDataTable";
import { useForm } from "react-hook-form";
import { Search } from "@material-ui/icons";

export default function ListCourseBundlePage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [courseBundleData, setCourseBundleData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const { register, watch } = useForm({
    defaultValues: {
      filter: {
        name: "",
      },
    },
  });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { name } = watch("filter");

      const { data } = await http.get(api.v1.admin.course_bundle.main, {
        params: { limit, page, name },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseBundleData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course_bundle.main}/${id}`);

      Swal.fire("Sukses", "Data Bundle Kelas berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data bundle kelas",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Bundle Kelas</title>
      </Helmet>

      <DefaultHeader section="Data Bundle Kelas" subsection="Kelola Kelas" />

      <Container maxWidth={false} component={Box} id="bundle-course-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/bundle-kelas/tambah")}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex align-items-center justify-content-end">
                      <OutlinedInput
                        type="text"
                        placeholder="Cari berdasarkan Nama"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-3"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.name")}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                    <CourseBundleDataTable
                      loading={loadingData}
                      data={courseBundleData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleDeleteData={handleDeleteData}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
