import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

import http from "services/http";
import api from "services/api";
import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import { Autocomplete } from "@material-ui/lab";
import DetailAssessmentDataTable from "./DetailAssessmentDataTable";

export default function DetailAssessmentPage() {
  const { administratorId } = useParams();

  const { register, setValue, watch } = useForm({
    defaultValues: {
      filter: {
        sort_by: "",
        name: "",
      },
    },
  });

  const sortBy = [
    {
      label: "Total Penilaian - Terendah",
      value: "total_asc",
    },
    {
      label: "Total Penilaian - Terbanyak",
      value: "total_dsc",
    },
  ];

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [courseData, setCourseData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { sort_by, name } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.report.assessment}/${administratorId}/course`,
        {
          params: { limit, page, sort_by, name },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Penilaian</title>
      </Helmet>

      <DefaultHeader
        section="Detail Penilaian"
        subsection="Data Penilaian"
        routeBack={"/report/penilaian"}
      />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    <Grid sm={8} className="d-flex align-items-center">
                      <Grid sm={4} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={sortBy}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = sortBy.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.sort_by", value);

                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Urut Berdasarkan"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <OutlinedInput
                        type="text"
                        placeholder="Cari nama"
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                        className="mr-2 ml-2"
                        onKeyPress={e => e.key === "Enter" && getList()}
                        {...register("filter.name")}
                      />
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => getList()}
                      >
                        <Box
                          component={Search}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          position="relative"
                        />
                      </Button>
                    </Grid>
                  </Grid>

                  <DetailAssessmentDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={courseData}
                    administratorId={administratorId}
                    pagination={pagination}
                    loading={loadingData}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
