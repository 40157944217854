import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

const DetailAdminPage = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [roles, setRoles] = useState([]);

  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      role: "",
      password: "",
    },
  });

  const getAdminResources = async () => {
    setLoadingData(true);

    const getAdmin = http.get(`${api.v1.admin.administrator.main}/${id}`);
    const getRoles = http.get(api.v1.admin.administrator.role);

    const [admin, roleList] = await Promise.allSettled([getAdmin, getRoles]);

    const { data: adminResponse } = admin.value;
    const { data: rolesResponse } = roleList.value;

    // set default for role options field
    const defaultValueRoleAdmin = rolesResponse.data.records.filter(
      option => option.id === adminResponse.data.admin_role_id
    );

    // Set default value to fields
    reset({
      name: adminResponse.data.name,
      email: adminResponse.data.email,
      role: {
        id: defaultValueRoleAdmin[0].id,
        name: defaultValueRoleAdmin[0].name,
      },
    });

    setRoles(rolesResponse.data.records);

    setLoadingData(false);
  };

  const onSubmit = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { name, email, password, role } = val;

      await http.put(`${api.v1.admin.administrator.main}/${id}`, {
        name,
        email,
        password,
        admin_role_id: role.id,
      });

      Swal.fire("Sukses", "Data admin berhasil diperbarui", "success");

      history.push("/administrator");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => getAdminResources(), []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Admin</title>
      </Helmet>

      <DefaultHeader
        section="Detail Admin"
        subsection="Kelola Admin"
        routeBack="/administrator"
      />

      {/* Edit Admin */}
      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {loadingData ? (
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1rem"
                        marginBottom="1rem"
                      >
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                          <FormLabel>Nama</FormLabel>
                          <OutlinedInput
                            {...register("name", {
                              required: "Tidak boleh kosong",
                            })}
                          />
                          {errors.name && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.name.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <OutlinedInput
                            autoComplete="off"
                            {...register("email", {
                              required: "Tidak boleh kosong",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Email tidak valid",
                              },
                            })}
                          />
                          {errors.email && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.email.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Password</FormLabel>
                          <OutlinedInput
                            autoComplete="off"
                            type="password"
                            {...register("password", {
                              minLength: {
                                value: 6,
                                message: "Minimal 6 karakter",
                              },
                            })}
                          />
                          {errors.password && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.password.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Role Admin</FormLabel>
                          <Controller
                            control={control}
                            name="role"
                            rules={{
                              required: "Tidak boleh kosong",
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                options={roles}
                                getOptionLabel={option => option.name || ""}
                                getOptionSelected={(option, val) =>
                                  option.id === val.id
                                }
                                closeIcon={null}
                                value={value}
                                onChange={(_, val) => {
                                  onChange(val);
                                }}
                                popupIcon={
                                  <Box
                                    component={KeyboardArrowDown}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                  />
                                }
                                renderInput={params => (
                                  <TextField {...params} variant="filled" />
                                )}
                              />
                            )}
                          />
                          {errors.role && (
                            <FormHelperText
                              component={Box}
                              color={theme.palette.error.main + "!important"}
                            >
                              {errors.role.message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                        <Grid container className="mt-5">
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Perbarui Admin
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DetailAdminPage;
