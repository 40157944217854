import StatusConstant from "constant/StatusConstant";

const formatStatusLogExportCourse = string => {
  switch (string) {
    case StatusConstant.STATUS_LOG_EXPORT_COURSE.PENDING:
      return "Tertunda";
    case StatusConstant.STATUS_LOG_EXPORT_COURSE.PROCESS:
      return "Diproses";
    case StatusConstant.STATUS_LOG_EXPORT_COURSE.FAILED:
      return "Gagal";
    case StatusConstant.STATUS_LOG_EXPORT_COURSE.FINISHED:
      return "Selesai";
    case StatusConstant.STATUS_LOG_EXPORT_COURSE.DELETED:
      return "Terhapus";
    default:
      return "-";
  }
};

export default formatStatusLogExportCourse;
