import React, { useEffect, useState } from "react";
import DefaultHeader from "components/Headers/DefaultHeader";
import { Helmet } from "react-helmet";
import http from "services/http";
import api from "services/api";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  Switch,
  TextField,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import WebinarDataTable from "./WebinarDataTable";
import moment from "moment";
import WebinarTimeListDataTable from "components/Course/WebinarTimeListDataTable";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import ReactSelect from "react-select";

function ListWebinarPage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();

  const { register, setValue, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      webinar: {
        code: "",
        closed_at: moment().format("YYYY-MM-DDTHH:mm"),
        instructor_id: null,
        sync_participant: 0,
      },
      session: {
        id: null,
        date: moment().format("YYYY-MM-DD"),
        start_time: moment().format("HH:mm"),
        end_time: moment().format("HH:mm"),
        webinar_url: "",
        telegram_url: "",
        notes: "",
      },
      schedule: {
        file_excel: null,
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [instructorOption, setInstructorOption] = useState([]);
  const [moduleOption, setModuleOption] = useState([]);
  const [subModuleOption, setSubModuleOption] = useState([]);

  const [showModalWebinarSchedule, setShowModalWebinarSchedule] =
    useState(false);
  const [statusModal, setStatusModal] = useState("CREATE");
  const [showModalImport, setShowModalImport] = useState(false);

  const [syncParticipantToggle, setSyncParticipantToggle] = useState(false);
  const [selectedModule, setSelectedModule] = useState(false);

  const [pagination, setPagination] = useState({ limit: 10 });
  const [webinarData, setWebinarData] = useState([]);

  const [webinarSessionData, setWebinarSessionData] = useState([]);
  const [indexWebinarSessionEdit, setIndexWebinarSessionEdit] = useState(null);

  /**
   * Fetch Data
   */
  const getList = async (courseId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/webinar`,
        {
          params: { limit, page },
        }
      );

      const { current_page, records, total_page, total_records } = data.data;

      setWebinarData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/webinar/${id}`
      );

      const webinarSessions = data.data.sessions.map(session => ({
        id: session.id,
        date: moment(session.date).format("YYYY-MM-DD"),
        start_time: moment(session.start_time, "HH:mm").format("HH:mm"),
        end_time: moment(session.end_time, "HH:mm").format("HH:mm"),
        webinar_url: session.webinar_url,
        telegram_url: session.telegram_url,
        notes: session.notes,
        course_sub_module: session.course_sub_module,
      }));

      setWebinarSessionData(webinarSessions);

      setValue("webinar", {
        id,
        code: data.data.code,
        closed_at: moment(data.data.closed_at).format("YYYY-MM-DDTHH:mm"),
        sync_participant: data.data.sync_participant,
        instructor_id: data.data.instructor_id,
        max_participant: data.data.max_participant,
        // assignment_submit_url: data.data.assignment_submit_url,
        // assignment_result_url: data.data.assignment_result_url,
      });

      setSyncParticipantToggle(data.data.sync_participant ? true : false);
      setStatusModal("UPDATE");
      setShowModalWebinarSchedule(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const getInstructor = async courseId => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}`
      );

      setInstructorOption(data.data.instructors);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getModule = async courseId => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.module(courseId)}`,
        {
          params: {
            option: true,
          },
        }
      );

      const { records } = data.data;
      const options = records.map(item => {
        return { label: item.title, value: item.id };
      });

      setModuleOption(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getSubModule = async (courseId, courseModuleId) => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.course.sub_module(courseId, courseModuleId)}`,
        {
          params: {
            option: true,
          },
        }
      );

      const { records } = data.data;
      const options = records.map(item => {
        return { label: item.title, value: item.id };
      });

      setSubModuleOption(options);

      return options;
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getFormatExcel = async courseId => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = "Format Import Webinar Schedule.xlsx";

      const res = await http.get(
        `${api.v1.admin.course.main}/${courseId}/webinar/template-import`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { webinar } = payload;

      webinar.sync_participant = syncParticipantToggle ? 1 : 0;
      webinar.sessions = webinarSessionData;
      webinar.closed_at = moment(webinar.closed_at);

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/webinar`,
        webinar
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Webinar berhasil di tambahkan", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const importSchedule = async payload => {
    try {
      const { schedule } = payload;

      const formData = new FormData();

      formData.append("file_excel", schedule?.file_excel[0]);

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/webinar/import`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      handleCloseModalImport();

      Swal.fire("Sukses", "Import jadwal webinar berhasil", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { webinar } = payload;

      webinar.sync_participant = syncParticipantToggle ? 1 : 0;
      webinar.sessions = webinarSessionData;
      webinar.closed_at = moment(webinar.closed_at);

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/webinar/${webinar.id}`,
        webinar
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Webinar berhasil di tambahkan", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(
        `${api.v1.admin.course.main}/${courseId}/webinar/${id}`
      );

      Swal.fire("Sukses", "Data Jadwal berhasil dihapus", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId);
  };

  const handleAddSession = () => {
    const {
      date,
      start_time,
      end_time,
      webinar_url,
      telegram_url,
      notes,
      id,
      course_webinar_id,
      course_module_id,
      course_sub_module_id,
    } = watch("session");

    if (date && start_time && end_time && webinar_url)
      setWebinarSessionData(prev => [
        ...prev,
        {
          date,
          start_time,
          end_time,
          webinar_url,
          telegram_url,
          notes,
          id,
          course_webinar_id,
          course_module_id,
          course_sub_module_id,
        },
      ]);
  };

  const handleDeleteSession = key => {
    let webinarSessions = webinarSessionData;

    webinarSessions = webinarSessions.filter(
      item => item !== webinarSessions[key]
    );

    setWebinarSessionData(webinarSessions);
  };

  const handleEditSession = async key => {
    const webinarSession = { ...webinarSessionData[key] };

    setIndexWebinarSessionEdit(key);

    if (webinarSession.course_module_id) {
      const sub_moudules = await getSubModule(
        courseId,
        webinarSession.course_module_id
      );

      setSelectedModule(true);

      const selected_module_temp = moduleOption.find(
        item => item.value === webinarSession.course_module_id
      );
      setValue("selected_module_temp", selected_module_temp);

      const selected_sub_module_temp = sub_moudules.find(
        item => item.value === webinarSession.course_sub_module_id
      );

      setValue("selected_sub_module_temp", selected_sub_module_temp);
    } else if (webinarSession.course_sub_module) {
      getSubModule(courseId, webinarSession.course_sub_module.module.id);
      setSelectedModule(true);

      const selected_module_temp = {
        label: webinarSession.course_sub_module?.module?.title,
        value: webinarSession.course_sub_module?.module?.id,
      };
      setValue("selected_module_temp", selected_module_temp);

      const selected_sub_module_temp = {
        label: webinarSession.course_sub_module?.title,
        value: webinarSession.course_sub_module?.id,
      };
      setValue("selected_sub_module_temp", selected_sub_module_temp);

      webinarSession.course_module_id =
        webinarSession.course_sub_module?.module?.id;
      webinarSession.course_sub_module_id =
        webinarSession.course_sub_module?.id;
    } else handleResetModule();

    setValue("session", { ...webinarSession });
  };

  const handleUpdateSession = () => {
    let webinarSessions = [...webinarSessionData];

    webinarSessions[indexWebinarSessionEdit] = {
      ...watch("session"),
    };

    setWebinarSessionData(webinarSessions);
    setIndexWebinarSessionEdit(null);
    setValue("session.id", null);
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Jadwal Webinar",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleCloseModal = () => {
    reset();
    setWebinarSessionData([]);
    setShowModalWebinarSchedule(false);
    setSyncParticipantToggle(false);
    setIndexWebinarSessionEdit(null);
    setSelectedModule(false);
  };

  const handleCloseModalImport = () => {
    reset();
    setShowModalImport(false);
  };

  const handleResetModule = () => {
    setSelectedModule(false);
    setValue("selected_module_temp", null);
    setValue("selected_sub_module_temp", null);
    setValue("session.course_module_id", null);
    setValue("session.course_sub_module_id", null);
  };

  useEffect(() => {
    getList(courseId);
    getInstructor(courseId);
    getModule(courseId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Webinar</title>
      </Helmet>

      <DefaultHeader
        section="Data Webinar"
        subsection="Data Kelas"
        routeBack="/kelas"
      />

      <Container maxWidth={false} component={Box} id="course-webinar">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowModalWebinarSchedule(true);
                      setStatusModal("CREATE");
                    }}
                  >
                    Tambah Data
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowModalImport(true);
                    }}
                    className="ml-2"
                  >
                    Import Jadwal
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      getFormatExcel(courseId);
                    }}
                    className="ml-2"
                  >
                    Unduh Format
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <WebinarDataTable
                      loading={loadingData}
                      data={webinarData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Add Webinar Schedule */}
                <Dialog
                  open={showModalWebinarSchedule}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="md"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Webinar</h2>
                    </Box>
                    <form
                      onSubmit={
                        statusModal === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="pt-4 pb-2">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Kode</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("webinar.code")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Tanggal Tutup Jadwal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="datetime-local"
                              {...register("webinar.closed_at")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Pengajar</FormLabel>

                            <Autocomplete
                              id="combo-box-category"
                              options={instructorOption}
                              getOptionLabel={option => option.name}
                              closeIcon={null}
                              onChange={(e, val) => {
                                setValue("webinar.instructor_id", val.id);
                              }}
                              value={instructorOption.find(
                                data =>
                                  data.id === watch("webinar.instructor_id")
                              )}
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel id="option-competence-aspect-label">
                              Sinkronisasi Peserta
                            </FormLabel>
                            <FormControl variant="outlined" fullWidth>
                              <Switch
                                color="primary"
                                checked={syncParticipantToggle}
                                onChange={e =>
                                  setSyncParticipantToggle(e.target.checked)
                                }
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Link Pengumpulan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("webinar.assignment_submit_url")}
                            />
                          </FormGroup>

                          <FormGroup>
                            <FormLabel>Link Spreadsheet</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("webinar.assignment_result_url")}
                            />
                          </FormGroup>
                        </Grid> */}
                      </Grid>

                      <Box textAlign="">
                        <h3>Data Waktu Webinar</h3>
                      </Box>

                      <Grid container className="pt-4">
                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Tanggal</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="date"
                              {...register("session.date")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Waktu Mulai</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="time"
                              {...register("session.start_time")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Waktu Selesai</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="time"
                              {...register("session.end_time")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Link Webinar</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("session.webinar_url")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Link Telegram</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("session.telegram_url")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Catatan</FormLabel>
                            <OutlinedInput
                              fullWidth
                              multiline
                              rows={3}
                              {...register("session.notes")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <Grid container>
                              <Grid item xs={12}>
                                <FormLabel>Pilih Modul</FormLabel>
                              </Grid>

                              <Grid item xs={10}>
                                <ReactSelect
                                  isMulti={false}
                                  options={moduleOption}
                                  className="basic-multi-select"
                                  value={watch("selected_module_temp")}
                                  onChange={item => {
                                    setValue("selected_module_temp", item);
                                    setValue(
                                      "session.course_module_id",
                                      item.value
                                    );
                                    getSubModule(courseId, item.value);
                                    setSelectedModule(true);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={2}>
                                <Button
                                  variant="contained"
                                  color="default"
                                  size="medium"
                                  onClick={() => handleResetModule()}
                                >
                                  Reset
                                </Button>
                              </Grid>
                            </Grid>
                          </FormGroup>
                        </Grid>

                        {selectedModule ? (
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormLabel>Pilih Sub Modul</FormLabel>

                              <ReactSelect
                                isMulti={false}
                                options={subModuleOption}
                                className="basic-multi-select"
                                value={watch("selected_sub_module_temp")}
                                onChange={item => {
                                  setValue(
                                    "session.course_sub_module_id",
                                    item.value
                                  );
                                  setValue("selected_sub_module_temp", item);
                                }}
                              />
                            </FormGroup>
                          </Grid>
                        ) : null}

                        <Grid item xs={12}>
                          <FormGroup>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                indexWebinarSessionEdit === null
                                  ? handleAddSession()
                                  : handleUpdateSession();
                              }}
                            >
                              {indexWebinarSessionEdit === null
                                ? "Tambah Waktu"
                                : "Ubah Waktu"}
                            </Button>
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <WebinarTimeListDataTable
                            data={webinarSessionData}
                            handleEditData={handleEditSession}
                            handleDeleteData={handleDeleteSession}
                          />
                        </Grid>

                        <Grid item xs={12} className="mt-4">
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!webinarSessionData.length}
                          >
                            {statusModal === "CREATE" ? "Tambah" : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>

                {/* Modal Import */}
                <Dialog
                  open={showModalImport}
                  onClose={() => handleCloseModalImport()}
                  fullWidth={true}
                  maxWidth="md"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Import Jadwal</h2>
                    </Box>
                    <form onSubmit={handleSubmit(importSchedule)}>
                      <Grid container className="pt-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>File Excel</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="file"
                              {...register("schedule.file_excel")}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} className="mt-4">
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Import
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ListWebinarPage;
