import React from "react";

import {
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

export default function PreviewQuizAnswer({
  data,
  handleDeleteQuestionAnswer,
  handleEditQuestionAnswer,
}) {
  return (
    <Card className="p-4">
      <Grid container>
        <Grid item xs={12} className="mb-2">
          <h2 className="text-muted">Preview Soal dan Jawaban</h2>
        </Grid>

        {!data.length && (
          <Grid item xs={12}>
            <h3 className="text-muted text-center">
              Tidak Ada Soal dan Jawaban
            </h3>
          </Grid>
        )}

        {data.map((quiz, key) => (
          <Grid key={key} item xs={12} className="mb-2">
            <div className="d-flex justify-content-between">
              <div
                className="questions"
                dangerouslySetInnerHTML={{ __html: quiz.question }}
              />

              <div>
                <IconButton onClick={() => handleEditQuestionAnswer(key)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteQuestionAnswer(key)}>
                  <Delete />
                </IconButton>
              </div>
            </div>
            <RadioGroup className="mb-3" defaultValue="">
              {quiz?.answers.map((item, key_answer) => (
                <FormControlLabel
                  key={key_answer}
                  value={key_answer}
                  control={<Radio color="primary" />}
                  checked={item?.correct ? true : false}
                  label={`${item?.answer}`}
                />
              ))}
            </RadioGroup>
            <div className="mb-5">
              <span>Ringkasan : </span>
              {quiz?.summary}
            </div>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
