const api = {
  v1: {
    admin: {
      auth: {
        login: "/v1/admin/auth/login",
        direct_login: "/v1/admin/auth/direct-login-user",
      },
      dashboard: {
        main: "/v1/admin/dashboard",
      },
      instructors: {
        main: "/v1/admin/manage-instructor",
      },
      instructor_assistance: {
        main: "/v1/admin/manage-instructor-assistance",
      },
      administrator: {
        main: "/v1/admin/manage-admin",
        role: "/v1/admin/role",
      },
      course: {
        main: "/v1/admin/course",
        category: "/v1/admin/course/category",
        level: "/v1/admin/course/level",
        type: "/v1/admin/course/type",
        teach_method: "/v1/admin/course/teach-method",
        assessor: "/v1/admin/course/assessor",
        bonus: "/v1/admin/course/bonus",
        apple_price: "/v1/admin/course/apple-price",
        competency_aspect: courseId =>
          `/v1/admin/course/${courseId}/competency-aspect`,
        training_goal: course_id =>
          `/v1/admin/course/${course_id}/training-goal`,
        training_material: course_id =>
          `/v1/admin/course/${course_id}/training-material`,
        evaluation_method: course_id =>
          `/v1/admin/course/${course_id}/evaluation-method`,
        demonstration_skills: course_id =>
          `/v1/admin/course/${course_id}/demonstration-skills`,
        certificate_standard: course_id =>
          `/v1/admin/course/${course_id}/certificate-standard`,
        certificate_competency: (course_id, certificate_standard_id) =>
          `/v1/admin/course/${course_id}/certificate-standard/${certificate_standard_id}/certificate-competency`,
        module: courseId => `/v1/admin/course/${courseId}/module`,
        sub_module: (courseId, courseModuleId) =>
          `/v1/admin/course/${courseId}/module/${courseModuleId}/sub-module`,

        user_learning_progress: (courseId, userId) =>
          `/v1/admin/course/${courseId}/user/${userId}/learning-progress`,
      },
      course_bundle: {
        main: "/v1/admin/course-bundle",
      },
      partner: {
        main: "/v1/admin/manage-partner",
      },
      digital_product: {
        main: "/v1/admin/digital-product",
      },
      digital_product_bundle: {
        main: "/v1/admin/digital-product-bundle",
      },
      voucher: {
        main: "/v1/admin/course/voucher",
        type: "/v1/admin/course/voucher/type",
      },
      promo_code: {
        main: "/v1/admin/course/promo-code",
      },
      membership: {
        main: "/v1/admin/membership",
      },
      event_place: {
        main: "/v1/admin/event-place",
      },
      main_slideshow: {
        main: "/v1/admin/cms/main-slideshow",
      },
      course_section: {
        main: "/v1/admin/cms/course-section",
      },
      pop_up_ads: {
        main: "/v1/admin/cms/pop-up-ads",
      },
      custom_section: {
        main: "/v1/admin/cms/custom-section",
      },
      payment_method: {
        main: "/v1/admin/transaction/payment-method",
      },
      brand: {
        main: "/v1/admin/brand",
      },
      topic_notification: {
        main: "/v1/admin/mobile/topic-notification",
      },
      push_notification_topic: {
        main: "/v1/admin/mobile/notification/topic",
      },
      push_notification_email: {
        main: "/v1/admin/mobile/notification/email",
      },
      statistic: {
        main: "/v1/admin/statistic/transaction",
      },
      report: {
        main: "/v1/admin/report",
        course: {
          main: "/v1/admin/report/course",
          webinar_schedule: courseId =>
            `/v1/admin/report/course/${courseId}/webinar-schedule`,
          change_webinar_schedule: courseId =>
            `/v1/admin/report/course/${courseId}/change-user-webinar-schedule`,
          submit_webinar_attendance: (courseId, webinarSessionId, userId) =>
            `/v1/admin/report/course/${courseId}/webinar-session/${webinarSessionId}/participant/${userId}/attendance`,
          offline_schedule: courseId =>
            `/v1/admin/report/course/${courseId}/offline-schedule`,
        },
        transaction: "/v1/admin/report/transaction",
        instructor: "/v1/admin/report/instructor",
        user: "/v1/admin/report/user",
        notification: "/v1/admin/report/history-notification",
        notification_whatsapp: "/v1/admin/report/history-whatsapp-blast",
        notification_email: "/v1/admin/report/history-email-blast",
        assessment: "/v1/admin/report/assessment",
        massive_hiring_candidate: "/v1/admin/massive-hiring-candidate",
      },

      sync: {
        voucher: {
          main: "/v1/admin/sync/voucher",
          redeem_attempt: "/v1/admin/sync/voucher/redeem-voucher-attemp",
        },
        user: {
          main: "/v1/admin/sync/user",
          generate_certificate: "/v1/admin/sync/user/generate-certificate",
          bulk_create: "/v1/admin/sync/user/bulk-create-user",
          create: "/v1/admin/sync/user/create-user",
        },
      },

      learning_progress: {
        main: "/v1/admin/learning-progress",
        user_reminder: "/v1/admin/learning-progress/user-reminder",
      },

      notification: {
        template: {
          main: "/v1/admin/notification/template",
        },
        reminder: {
          main: "/v1/admin/notification/reminder",
        },
      },

      whatsapp: {
        template: {
          main: "/v1/admin/whatsapp/template",
        },
        reminder: {
          main: "/v1/admin/whatsapp/reminder",
        },
        blast: {
          send_multiple: "/v1/admin/twillio/whatsapp-message/send-multiple",
        },
      },

      email: {
        template: {
          main: "/v1/admin/email/template",
        },
        reminder: {
          main: "/v1/admin/email/reminder",
        },
        blast: {
          send_multiple: "/v1/admin/email/multiple-send",
        },
      },

      role: {
        main: "/v1/admin/role",
        permission: "/v1/admin/role/permissions",
      },

      permission: {
        main: "/v1/admin/permission",
      },

      log: {
        whatsapp: {
          sender_activity: "/v1/admin/log/twilio/whatsapp/sender-activity",
        },
        login: "/v1/admin/log/login-activity",
        email: "/v1/admin/log/email/sender-activity",
        export: {
          course: "/v1/admin/log/report/course",
        },
        face_recognition: "/v1/admin/log/face-recognition",
      },
    },

    instructor: {
      dashboard: {
        main: "/v1/admin-instructor/dashboard",
      },
      course: {
        main: "/v1/admin-instructor/course",
      },
      report: {
        course: "/v1/admin-instructor/report/course",
      },
    },

    city: {
      main: "/v1/city",
    },
    province: {
      main: "/v1/province",
    },
    profession: {
      main: "/v1/profession",
    },
    ewallet: {
      main: "/v1/e-wallet",
    },
  },
};

export default api;
