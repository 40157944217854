import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit, Menu } from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const DroppableComponent = onDragEnd => props => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {provided => (
          <TableBody
            innerRef={provided.innerRef}
            {...provided.droppableProps}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default function CustomSectionDataTable({
  loading,
  data,
  getDetailData,
  handleDeleteData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleChangeOrder,
}) {
  const columns = ["#", "Nama", "Platform", "Status", "Opsi", ""];

  const onDragEnd = result => {
    handleChangeOrder(result);
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody component={DroppableComponent(onDragEnd)}>
          {!loading && data.length ? (
            data.map((item, index) => (
              <Draggable
                draggableId={item.id.toString()}
                index={index}
                key={item.id.toString()}
              >
                {provided => (
                  <TableRow
                    innerRef={provided.innerRef}
                    {...provided.draggableProps}
                    style={provided.draggableProps.style}
                  >
                    <TableCell component="td" scope="row" align="center">
                      {pagination.current_page > 1
                        ? (pagination.current_page - 1) * pagination.limit +
                          (index + 1)
                        : index + 1}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {item.name}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {item.platform}
                    </TableCell>
                    <TableCell component="td" align="center">
                      {item.active === 1 ? (
                        <Chip label={"Aktif"} color="primary" size="small" />
                      ) : (
                        <Chip
                          label={"Tidak Aktif"}
                          color="default"
                          size="small"
                        />
                      )}
                    </TableCell>
                    <TableCell component="td" align="center">
                      <IconButton
                        aria-label="Edit"
                        onClick={() => getDetailData(item.id)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteData(item.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton {...provided.dragHandleProps}>
                        <Menu />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </Draggable>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
