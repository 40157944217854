import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  SnackbarContent,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import DefaultHeader from "components/Headers/DefaultHeader";
import BenefitDataTable from "components/Membership/BenefitDataTable";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "components/Loading/Indicator/Spinner";

const snackbarStyles = makeStyles(snackbarTheme);

export default function DetailMembershipPage() {
  const { id } = useParams();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      membership: {
        name: "",
        price: null,
        discount_price: null,
        max_selected_course: null,
        month_period: null,
        type: "ALL_COURSE",
        course_apple_price_id: null,
        course_category_id: null,
      },
      benefit: {
        tooltip: "",
        benefit: "",
      },
    },
  });
  const styles = { ...snackbarStyles() };
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [applePrice, setApplePrice] = useState([]);

  const [benefits, setBenefits] = useState([]);
  const [optionCourseData, setOptionCourseData] = useState([]);
  const [optionCategoryData, setOptionCategoryData] = useState([]);

  const [exceptCourses, setExceptCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const typeOptions = [
    { label: "Semua Kelas", value: "ALL_COURSE" },
    { label: "Semua Kelas, Kecuali", value: "ALL_COURSE_EXCEPT" },
    { label: "Kelas Umum", value: "GENERAL_COURSE" },
    { label: "Kelas Umum, Kecuali", value: "GENERAL_COURSE_EXCEPT" },
    { label: "Kelas Prakerja", value: "PRAKERJA_COURSE" },
    { label: "Kelas Prakerja, Kecuali", value: "PRAKERJA_COURSE_EXCEPT" },
    { label: "Kategori", value: "BY_COURSE_CATEGORY" },
    { label: "Kategori, Kecuali", value: "BY_COURSE_CATEGORY_EXCEPT" },
    { label: "Pilih Kategori", value: "BY_MULTIPLE_COURSE_CATEGORY" },
    { label: "Pilih Kelas", value: "ONLY_COURSE" },
  ];

  /**
   * Fetch Data
   */
  const getDetailMembership = async id => {
    try {
      setLoadingData(true);
      const { data } = await http.get(`${api.v1.admin.membership.main}/${id}`);

      const {
        name,
        price,
        discount_price,
        type,
        course_apple_price_id,
        month_period,
        max_selected_course,
        course_apple_price,
        course_categories,
        benefit,
        courses,
      } = data.data;
      let course_category_id = null;

      if (type === "BY_COURSE_CATEGORY") {
        setValue("membership.course_category", course_categories[0]);
      }
      const benefitData = benefit.map(({ benefit, tooltip }) => ({
        benefit,
        tooltip,
      }));

      setBenefits(benefitData);

      setValue("membership", {
        name,
        price,
        discount_price,
        type,
        course_apple_price_id,
        course_category_id,
        month_period,
        max_selected_course,
        course_apple_price,
      });

      if (type === "BY_COURSE_CATEGORY") {
        setValue("membership.course_category", {
          label: course_categories[0].name,
          value: course_categories[0].id,
        });
      }

      if (
        [
          "ALL_COURSE_EXCEPT",
          "GENERAL_COURSE_EXCEPT",
          "PRAKERJA_COURSE_EXCEPT",
          "BY_COURSE_CATEGORY_EXCEPT",
        ].includes(watch("membership.type"))
      ) {
        const courseData = courses.map(item => ({
          label: item.name,
          value: item.id,
        }));

        setExceptCourses(courseData);
      }

      if (["ONLY_COURSE"].includes(watch("membership.type"))) {
        const courseData = courses.map(item => ({
          label: item.name,
          value: item.id,
        }));

        setSelectedCourses(courseData);
      }

      const selectedCategoryData = course_categories.map(item => ({
        label: item.name,
        value: item.id,
      }));

      setSelectedCategories(selectedCategoryData);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getListApplePrice = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.apple_price, {
        params: { option: "true", all: true },
      });

      setError(null);

      let { records } = data.data;

      setApplePrice(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCategories = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.category, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCategoryData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const putCreateCourse = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      setError(null);

      let selected_course = [];

      if (
        [
          "ALL_COURSE_EXCEPT",
          "GENERAL_COURSE_EXCEPT",
          "PRAKERJA_COURSE_EXCEPT",
          "BY_COURSE_CATEGORY_EXCEPT",
        ].includes(watch("membership.type"))
      ) {
        selected_course = exceptCourses;
      } else if (["ONLY_COURSE"].includes(watch("membership.type"))) {
        selected_course = selectedCourses;
      }

      selected_course = selected_course.map(course => course?.value);
      const selected_course_category = selectedCategories.map(
        item => item?.value
      );

      const payload = {
        ...watch("membership"),
        benefits,
        selected_course,
        selected_course_category,
        course_category_id: watch("membership")?.course_category?.value,
        course_apple_price_id: watch("membership")?.course_apple_price?.id,
      };

      await http.put(`${api.v1.admin.membership.main}/${id}`, payload);

      Swal.fire("Sukses", "Data Membership berhasil di tambahkan", "success");

      history.push("/membership");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */

  const handleAddBenefit = () => {
    const benefit = watch("benefit.benefit");
    const tooltip = watch("benefit.tooltip");

    if (benefit) setBenefits(prev => [...prev, { benefit, tooltip }]);

    setValue("benefit", {
      tooltip: "",
      benefit: "",
    });
  };

  const handleDeleteBenefit = key => {
    let benefits_delete = benefits;

    benefits_delete = benefits_delete.filter(item => item !== benefits[key]);

    setBenefits(benefits_delete);
  };

  useEffect(() => {
    getDetailMembership(id);
    getListApplePrice();
    getOptionCourses();
    getOptionCategories();
  }, []);

  const SelectCourse = () => {
    if (
      [
        "ALL_COURSE_EXCEPT",
        "GENERAL_COURSE_EXCEPT",
        "PRAKERJA_COURSE_EXCEPT",
        "BY_COURSE_CATEGORY_EXCEPT",
      ].includes(watch("membership.type"))
    ) {
      return (
        <Grid item sm={12} className="">
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <FormLabel id="option-status-promo-code-label">
                Pilih Kelas Yang Dikecualikan
              </FormLabel>
              <ReactSelect
                isMulti
                options={optionCourseData}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={val => setExceptCourses(val)}
                value={exceptCourses}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      );
    } else if (["ONLY_COURSE"].includes(watch("membership.type"))) {
      return (
        <Grid item sm={12} className="">
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <FormLabel id="option-status-promo-code-label">
                Pilih Kelas
              </FormLabel>
              <ReactSelect
                isMulti
                options={optionCourseData}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={val => setSelectedCourses(val)}
                value={selectedCourses}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      );
    }
  };

  const SelectCategory = () => {
    if (
      ["BY_COURSE_CATEGORY", "BY_COURSE_CATEGORY_EXCEPT"].includes(
        watch("membership.type")
      )
    ) {
      return (
        <Grid item xs={12}>
          <FormGroup>
            <FormLabel>Kategori</FormLabel>
            <Autocomplete
              id="combo-box-category"
              options={optionCategoryData}
              getOptionLabel={option => option.label}
              closeIcon={null}
              defaultValue={watch("membership.course_category")}
              getOptionSelected={(option, val) => option.value === val.val}
              onChange={(e, val) => {
                setValue("membership.course_category", val);
              }}
              popupIcon={
                <Box
                  component={KeyboardArrowDown}
                  width="1.25rem!important"
                  height="1.25rem!important"
                />
              }
              renderInput={params => <TextField {...params} variant="filled" />}
            />
          </FormGroup>
        </Grid>
      );
    } else if (
      ["BY_MULTIPLE_COURSE_CATEGORY"].includes(watch("membership.type"))
    ) {
      return (
        <Grid item sm={12} className="">
          <FormGroup>
            <FormControl variant="outlined" fullWidth>
              <FormLabel id="option-status-promo-code-label">
                Pilih Kategori
              </FormLabel>
              <ReactSelect
                isMulti
                options={optionCategoryData}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={val => setSelectedCategories(val)}
                value={selectedCategories}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Membership</title>
      </Helmet>

      <DefaultHeader
        section="Tambah Membership"
        subsection="Data Membership"
        routeBack="/membership"
      />

      <Container maxWidth={false} component={Box} id="membership-page">
        <Grid container>
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <Card className="p-4">
                {error && (
                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <SnackbarContent
                        classes={{ root: styles.errorSnackbar }}
                        action={
                          <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => setError(null)}
                          >
                            <Box
                              component="span"
                              color={theme.palette.white.main}
                            >
                              ×
                            </Box>
                          </Box>
                        }
                        message={<Box>{error}</Box>}
                      />
                    </Grid>
                  </Grid>
                )}
                <>
                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Master Data</h2>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormGroup>
                        <FormLabel>Judul</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          {...register("membership.name")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormGroup>
                        <FormLabel>Maksimal Pilih Kelas</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          {...register("membership.max_selected_course")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormGroup>
                        <FormControl variant="outlined" fullWidth>
                          <FormLabel id="option-status-promo-code-label">
                            Tipe Membership
                          </FormLabel>
                          <Select
                            IconComponent={KeyboardArrowDown}
                            labelId="option-status-promo-code-label"
                            value={watch("membership.type")}
                            {...register("membership.type")}
                          >
                            {typeOptions.map((val, key) => (
                              <MenuItem key={key} value={val.value}>
                                {val.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormLabel>Kadaluarsa</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          endAdornment={
                            <InputAdornment position="end">
                              Bulan
                            </InputAdornment>
                          }
                          {...register("membership.month_period")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormLabel>Harga</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          {...register("membership.price")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormLabel>Harga Diskon</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          {...register("membership.discount_price")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormLabel>Harga di Apple</FormLabel>
                        <Autocomplete
                          id="combo-box-category"
                          options={applePrice}
                          getOptionLabel={option => option.price}
                          closeIcon={null}
                          defaultValue={watch("membership.course_apple_price")}
                          getOptionSelected={(option, val) =>
                            option.id === val.id
                          }
                          onChange={(e, val) => {
                            setValue("membership.course_apple_price", val);
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField {...params} variant="filled" />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {SelectCategory()}
                    {SelectCourse()}
                  </Grid>

                  <Grid container className="mt-4">
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Benefits</h2>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          placeholder="Deskripsi"
                          {...register("benefit.benefit")}
                          onKeyUp={e => e.key === "Enter" && handleAddBenefit()}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormGroup>
                        <OutlinedInput
                          fullWidth
                          placeholder="Tooltip"
                          type="text"
                          {...register("benefit.tooltip")}
                          onKeyUp={e => e.key === "Enter" && handleAddBenefit()}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <FormGroup>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddBenefit()}
                        >
                          Tambah
                        </Button>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <BenefitDataTable
                        data={benefits}
                        handleDeleteData={handleDeleteBenefit}
                      />
                    </Grid>
                  </Grid>
                </>

                <Grid container className="mt-5">
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => putCreateCourse(id)}
                    >
                      Simpan
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
