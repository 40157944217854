import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import themeColors from "assets/theme/colors";
import { Autocomplete } from "@material-ui/lab";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import api from "services/api";
import http from "services/http";
import formatStatusLogEmail from "helpers/formatStatusLogEmail";
import LogEmailDataTable from "./LogEmailDataTable";

const filterStatus = [
  {
    value: "",
    label: "Semua",
  },
  {
    value: "SEND",
    label: formatStatusLogEmail("SEND"),
  },
  {
    value: "DELIVERED",
    label: formatStatusLogEmail("DELIVERED"),
  },
  {
    value: "OPEN",
    label: formatStatusLogEmail("OPEN"),
  },
  {
    value: "NOT_FOUND",
    label: formatStatusLogEmail("NOT_FOUND"),
  },
];

export default function ListEmailLogPage() {
  const { register, setValue, watch } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [logEmails, setLogEmails] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { email, status, start_date, end_date } = watch("filter");

      const { data } = await http.get(api.v1.admin.log.email, {
        params: {
          limit,
          page,
          email,
          status,
          start_date,
          end_date,
        },
      });

      const { current_page, records, total_page, total_records } = data.data;

      setLogEmails(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Log Email</title>
      </Helmet>
      <DefaultHeader section="Data Log Email" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    {/* select option */}
                    <Grid sm={8} className="d-flex">
                      {/* Filter by Status */}
                      <Grid sm={2} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={filterStatus}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterStatus.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.status", value);
                            getList();
                          }}
                          value={
                            filterStatus.filter(
                              data => data.value === watch("filter.status")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* filter start date */}
                      <Grid sm={3} className="mr-2">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            label="Tanggal Mulai"
                            value={startDate}
                            onChange={val => {
                              setStartDate(val);
                              setValue(
                                "filter.start_date",
                                val?.format("YYYY-MM-DD") ?? ""
                              );

                              getList();
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      {/* filter start date */}
                      <Grid sm={3} className="mr-2">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            label="Tanggal Selesai"
                            value={endDate}
                            onChange={val => {
                              setEndDate(val);

                              setValue(
                                "filter.end_date",
                                val?.format("YYYY-MM-DD") ?? ""
                              );

                              getList();
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    {/* Search by email */}
                    <Grid sm={4}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan email"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.email")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <LogEmailDataTable
                    loading={loadingData}
                    data={logEmails}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
