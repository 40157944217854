import {
  Box,
  Card,
  Grid,
  TableBody,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Button,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import api from "services/api";
import http from "services/http";
import ModalScore from "./ModalScore";

export default function InstructorOfflineParticipantDataTable({ detailData }) {
  const { courseId, scheduleId } = useParams();
  const { register, watch, setValue } = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [sessionData, setSessionData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [userData, setUserData] = useState(null);

  const [openModalScore, setOpenModalScore] = useState(false);

  const filterScoreOption = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Sudah Dinilai",
      value: "RATED",
    },
    {
      label: "Belum Dinilai",
      value: "NOT_YET_RATED",
    },
    {
      label: "Belum Bisa Dinilai",
      value: "CANNOT_BE_RATTED",
    },
  ];

  /**
   * Fetch Data
   */
  const getList = async (courseId, scheduleId, page = 1) => {
    try {
      setLoading(true);

      const { limit } = pagination;
      const { filter_search, filter_score = "ALL" } = watch("filter");

      const { data } = await http.get(
        `${api.v1.instructor.course.main}/${courseId}/offline-schedule/${scheduleId}/participant`,
        {
          params: {
            limit,
            page,
            filter_search,
            filter_score,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setSessionData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, scheduleId, page + 1);

  useEffect(() => {
    getList(courseId, scheduleId);
  }, []);

  /**
   * Table Data
   */
  const columns = ["#", "Nama", "Progres", "Nilai", "Opsi"];

  return (
    <Grid container>
      <Grid item xs={12}>
        {error ? (
          <Box textAlign="center" color={themeColors.error.main}>
            {error}
          </Box>
        ) : (
          <Grid>
            <Card>
              <Grid className="p-3 d-flex justify-content-end">
                <Grid sm={6}>
                  <Grid className="d-flex align-items-center justify-content-end">
                    {/* Filter by Level Course */}
                    <Grid sm={4} className="mr-2">
                      <Autocomplete
                        id="combo-box-category"
                        options={filterScoreOption}
                        getOptionLabel={option => option.label}
                        closeIcon={null}
                        onInputChange={(e, val) => {
                          const { value } = filterScoreOption.filter(
                            data => data.label === val
                          )[0];

                          setValue("filter.filter_score", value);

                          getList(courseId, scheduleId);
                        }}
                        popupIcon={
                          <Box
                            component={KeyboardArrowDown}
                            width="1.25rem!important"
                            height="1.25rem!important"
                          />
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Filter Nilai"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <OutlinedInput
                      type="text"
                      placeholder="Cari berdasarkan Nama"
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                      className="mr-3"
                      onKeyPress={e =>
                        e.key === "Enter" && getList(courseId, scheduleId)
                      }
                      {...register("filter.filter_search")}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => getList(courseId, scheduleId)}
                    >
                      <Box
                        component={Search}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        position="relative"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer component={Paper} width="100%">
                <Box component={Table} alignItems="center">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: themeColors.primary.main,
                      }}
                    >
                      {columns.map((item, key) => (
                        <TableCell
                          key={key}
                          style={{ fontWeight: 700 }}
                          align="center"
                          width={key === 0 ? "100px" : null}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading && sessionData.length ? (
                      sessionData.map((participant, index) => (
                        <TableRow key={participant.id}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="center"
                            style={{ wordWrap: "break-word" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell component="td" align="center">
                            {participant?.user?.name}
                          </TableCell>
                          <TableCell component="td" align="center">
                            {participant?.progress_percentage}%
                          </TableCell>
                          <TableCell component="td" align="center">
                            {participant?.user?.user_learning_progresses.length
                              ? participant?.user?.user_learning_progresses[0]
                                  ?.learning_assignment?.score === null
                                ? "Belum Dinilai"
                                : participant?.user?.user_learning_progresses[0]
                                    ?.learning_assignment?.score
                              : "Belum Bisa Dinilai"}
                          </TableCell>
                          <TableCell component="td" align="center">
                            <Button
                              variant="contained"
                              size="xl"
                              color="default"
                              disabled={
                                !participant?.user?.user_learning_progresses
                                  .length
                              }
                              onClick={() => {
                                setUserData({
                                  id: participant?.user?.id,
                                  name: participant?.user?.name,
                                });
                                setOpenModalScore(true);
                              }}
                            >
                              Beri Nilai
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell component="td" colSpan={8}>
                          {loading ? (
                            <Box textAlign="center">Harap Tunggu...</Box>
                          ) : (
                            <Box textAlign="center">Tidak ada data</Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <Pagination
                        pagination={pagination}
                        colSpan={8}
                        onChangePage={handleChangePage}
                      />
                    </TableRow>
                  </TableFooter>
                </Box>
              </TableContainer>
              {/* Modal Score */}
              <ModalScore
                openModal={openModalScore}
                refetch={getList}
                onClose={() => setOpenModalScore(false)}
                courseId={courseId}
                scheduleId={scheduleId}
                userId={userData?.id}
                userName={userData?.name}
                detailData={detailData}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
