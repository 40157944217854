import {
  Box,
  Container,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";
import ChipData from "components/Chip/ChipData";

const ModalCheckStatus = ({ data, error, openModal, onClose }) => {
  const status = {
    0: "Belum Submit",
    1: "Pending",
    2: "Sukses",
    3: "Gagal",
  };

  const statusColor = {
    0: themeColors.dark.main,
    1: themeColors.warning.main,
    2: themeColors.success.main,
    3: themeColors.error.main,
  };

  return (
    <Dialog open={openModal} onClose={onClose} fullWidth={true} maxWidth="md">
      <Container className="py-4">
        <Box textAlign="center">
          <h2>Status Tugas</h2>
        </Box>

        {error ? (
          <Box
            textAlign="center"
            className="mt-4"
            color={themeColors.error.main}
          >
            {error}
          </Box>
        ) : (
          <Grid container className="mt-4">
            <Grid item xs={12} md={6} component={Box}>
              <List dense disablePadding={true}>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    primary="Status Tugas"
                  />
                  <ChipData
                    color="secondary"
                    text={status[data?.assignment?.status]}
                    style={{
                      color: themeColors.gray[100],
                      backgroundColor: statusColor[data?.assignment?.status],
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6} component={Box}>
              <List dense disablePadding={true}>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    primary="Status Feedback"
                  />
                  <ChipData
                    color="secondary"
                    text={status[data?.feedback?.status]}
                    style={{
                      color: themeColors.gray[100],
                      backgroundColor: statusColor[data?.feedback?.status],
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        )}
      </Container>
    </Dialog>
  );
};

export default ModalCheckStatus;
