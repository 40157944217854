import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import api from "services/api";
import http from "services/http";
import InstructorListParticipantAssignmentDataTable from "./InstructorListParticipantAssignmentDataTable";
import ModalScore from "./ModalScore";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import formatRupiah from "helpers/formatRupiah";

const filterOrderOption = [
  {
    label: "Tanggal Submit - Terlama",
    value: { order_by: "submit_at", sort_by: "ASC" },
  },
  {
    label: "Tanggal Submit - Terbaru",
    value: { order_by: "submit_at", sort_by: "DESC" },
  },
];

export default function InstructorListParticipantAssignmentPage() {
  const { id, course_sub_module_id } = useParams();
  const { register, watch, setValue } = useForm({
    defaultValues: {
      filter: {
        filter_order: filterOrderOption[1].value,
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [participantData, setParticipantData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [detailCourse, setDetailCourse] = useState();
  const [loadingDetailCourse, setLoadingDetailCourse] = useState(true);

  const [userData, setUserData] = useState(null);
  const [openModalScore, setOpenModalScore] = useState(false);

  const filterScoreOption = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Sudah Dinilai",
      value: "RATED",
    },
    {
      label: "Belum Dinilai",
      value: "NOT_YET_RATED",
    },
  ];

  /**
   * Fetch Data
   */
  const getList = async (id, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const {
        filter_search,
        filter_score = "ALL",
        filter_order,
      } = watch("filter");

      const { data } = await http.get(
        `${api.v1.instructor.course.main}/${id}/assignment/${course_sub_module_id}/participant`,
        {
          params: {
            limit,
            page,
            filter_search,
            filter_score,
            order_by: filter_order.order_by,
            sort_by: filter_order.sort_by,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setParticipantData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailCourse = async id => {
    try {
      setLoadingDetailCourse(true);

      const { data } = await http.get(`${api.v1.instructor.course.main}/${id}`);

      setDetailCourse(data.data);
    } catch (err) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingDetailCourse(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(id, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(id);
  };

  useEffect(() => {
    getList(id);
    getDetailCourse(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Tugas Uji Keterampilan</title>
      </Helmet>

      <DefaultHeader
        section="Peserta Uji Keterampilan"
        subsection="Data Uji Keterampilan"
        routeBack={`/admin-instructor/my-course/${id}/uji-keterampilan`}
      />

      <Container maxWidth={false} component={Box} id="course-page">
        {/* Detail Course */}
        <Grid container>
          <Grid item xs={12}>
            {!loadingDetailCourse && (
              <Card>
                <CardContent>
                  <Typography variant="h2" className="mb-3">
                    Detail Kelas
                  </Typography>

                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Nama Kelas
                        </Typography>
                        <p>{detailCourse?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Kategori
                        </Typography>
                        <p>{detailCourse?.course_category?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tipe Kelas
                        </Typography>
                        <p>{detailCourse?.course_type?.name}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Metode
                        </Typography>
                        <p>{detailCourse?.course_teach_method?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Instruktur
                        </Typography>
                        <p>{detailCourse?.instructors[0]?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Status
                        </Typography>
                        <p>{detailCourse?.status}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Harga
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Diskon
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.discount_price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Total
                        </Typography>
                        <p>
                          Rp{" "}
                          {formatRupiah(
                            detailCourse?.price - detailCourse?.discount_price
                          )}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-end">
                    <Grid sm={6}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        {/* Filter by Level Course */}
                        <Grid sm={4} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={filterScoreOption}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = filterScoreOption.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.filter_score", value);
                              getList(id);
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Filter Nilai"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid sm={5} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={filterOrderOption}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = filterOrderOption.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.filter_order", value);
                              getList(id);
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Urut Berdasarkan"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Nama"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList(id)}
                          {...register("filter.filter_search")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList(id)}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <InstructorListParticipantAssignmentDataTable
                    loading={loadingData}
                    data={participantData}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    handleSetUser={setUserData}
                    handleOpenScore={setOpenModalScore}
                  />

                  {/* Modal Score */}
                  <ModalScore
                    openModal={openModalScore}
                    refetch={getList}
                    onClose={() => setOpenModalScore(false)}
                    courseId={id}
                    courseSubmoduleId={course_sub_module_id}
                    userId={userData?.id}
                    userName={userData?.name}
                    userScore={userData?.score}
                    userComment={userData?.comment}
                    detailData={detailCourse}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
