import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  OutlinedInput,
  SnackbarContent,
  TextField,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import http from "services/http";
import api from "services/api";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown } from "@material-ui/icons";

const snackbarStyles = makeStyles(snackbarTheme);

export default function CreateCourseBundle() {
  const styles = { ...snackbarStyles() };

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      bundle_course: {
        description: "",
        discount_price: 0,
        course_apple_price_id: null,
      },
      avatar: "",
    },
  });

  const [imageSrc, setImageSrc] = useState(null);

  const [error, setError] = useState(null);

  const [optionCourseData, setOptionCourseData] = useState([]);
  const [coursePrice, setCoursePrice] = useState([]);

  const [applePrice, setApplePrice] = useState([]);

  /**
   * Fetch Data
   */
  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true, price: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        label: item.name,
        value: item.id,
        price: Number(item.price),
        discount_price: Number(item.discount_price),
      }));

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getListApplePrice = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.apple_price, {
        params: { option: "true", all: true },
      });

      setError(null);

      let { records } = data.data;

      setApplePrice(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const postCreateBundleCourse = async () => {
    try {
      if (coursePrice < watch("bundle_course.discount_price")) {
        document.documentElement.scrollTop = 0;
        return setError("Diskon tidak bisa lebih dari harga kelas");
      }

      dispatch(baseActions.SET_LOADING_SCREEN(true));
      const {
        name,
        description,
        discount_price,
        target_courses,
        course_apple_price_id,
      } = watch("bundle_course");
      const avatar = watch("avatar");

      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("discount_price", discount_price);
      formData.append("course_apple_price_id", course_apple_price_id);
      formData.append("cover", avatar[0]);

      target_courses.forEach((course, i) => {
        formData.append(`target_courses[${i}]`, course);
      });

      await http.post(api.v1.admin.course_bundle.main, formData);

      Swal.fire("Sukses", "Data Bundle Kelas berhasil di tambahkan", "success");

      history.push("/bundle-kelas");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Event
   */
  const registerAvatarField = register("avatar", {
    required: "Cover tidak boleh kosong",
  });

  const onAvatarChange = e => {
    if (e.target.files) {
      setValue("avatar", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getOptionCourses();
    getListApplePrice();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tambah Bundle Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Tambah Bundle Kelas"
        subsection="Kelola Kelas"
        routeBack="/bundle-kelas"
      />

      <Container maxWidth={false} component={Box}>
        <Grid container component={Box} justifyContent="center">
          <Grid item xs={12}>
            <Card className="p-4">
              {error && (
                <Grid container>
                  <Grid item xs={12} className="mb-2">
                    <SnackbarContent
                      classes={{ root: styles.errorSnackbar }}
                      action={
                        <Box
                          component={IconButton}
                          padding="0!important"
                          onClick={() => setError(null)}
                        >
                          <Box
                            component="span"
                            color={theme.palette.white.main}
                          >
                            ×
                          </Box>
                        </Box>
                      }
                      message={<Box>{error}</Box>}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Nama</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("bundle_course.name")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Deskripsi</FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={val =>
                        setValue("bundle_course.description", val)
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-status-promo-code-label">
                        Kelas yang dipilih
                      </FormLabel>
                      <ReactSelect
                        isMulti
                        options={optionCourseData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={val => {
                          setCoursePrice(
                            val.reduce(
                              (a, b) => a + b.price - b.discount_price,
                              0
                            )
                          );
                          setValue("selected_courses", val);
                          setValue(
                            "bundle_course.target_courses",
                            val.map(({ value }) => value)
                          );
                        }}
                        value={watch("selected_courses")}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Harga Kelas</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      disabled
                      value={coursePrice}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Diskon</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      {...register("bundle_course.discount_price")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Total</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      disabled
                      value={
                        coursePrice - watch("bundle_course.discount_price")
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Apple Pay</FormLabel>
                    <Autocomplete
                      id="combo-box-apple-price"
                      options={applePrice}
                      getOptionLabel={option => option.price}
                      closeIcon={null}
                      onInputChange={(e, val) => {
                        const { id } = applePrice.filter(
                          data => data.price === val
                        )[0];

                        setValue("course_apple_price", val);
                        setValue("bundle_course.course_apple_price_id", id);
                      }}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={params => (
                        <TextField {...params} variant="filled" />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Gambar Bundle Kelas</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="file"
                      placeholder="Default input"
                      inputProps={{ accept: "image/*" }}
                      onChange={e => {
                        registerAvatarField.onChange(e);
                        onAvatarChange(e);
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={9}></Grid>
                <Grid item xs={12} md={6}>
                  {imageSrc && (
                    <Box
                      component="img"
                      sx={{
                        height: 200,
                        width: 350,
                        maxHeight: { xs: 200, md: 200 },
                        maxWidth: { xs: 350, md: 350 },
                        borderRadius: "16px",
                      }}
                      alt="Cover."
                      src={imageSrc}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item xs={12} className="mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    onClick={postCreateBundleCourse}
                  >
                    Simpan
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
