import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Helmet } from "react-helmet";

import themeColors from "assets/theme/colors";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import InstructorListCourseDataTable from "./InstructorListCourseDataTable";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardArrowDown, Search } from "@material-ui/icons";

export default function ListCoursePage() {
  const { register, watch, setValue } = useForm();

  const filterStatus = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Publish",
      value: "PUBLISH",
    },
    {
      label: "QC",
      value: "QC",
    },
    {
      label: "Draft",
      value: "DRAFT",
    },
  ];

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [teachMethods, setTeachMethods] = useState([]);

  const [courseData, setCourseData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { name, teach_method_id, status } = watch("filter");

      const { data } = await http.get(api.v1.instructor.course.main, {
        params: {
          limit,
          page,
          name,
          teach_method_id,
          status,
        },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getListTeachMethods = async () => {
    try {
      const { data } = await http.get(api.v1.admin.course.teach_method, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        name: "Semua",
      });

      setTeachMethods(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getListTeachMethods();
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Kelas</title>
      </Helmet>

      <DefaultHeader section="Kelas Saya" />

      <Container maxWidth={false} component={Box} id="course-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    {/* Filter by Type Course */}
                    <Grid sm={8} className="d-flex">
                      {/* Filter by Status */}
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={filterStatus}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterStatus.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.status", value);
                            getList();
                          }}
                          value={
                            filterStatus.filter(
                              data => data.value === watch("filter.status")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* Filter by Teach Method Course */}
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={teachMethods}
                          getOptionLabel={option => option.name}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { id } = teachMethods.filter(
                              data => data.name === val
                            )[0];

                            setValue("filter.teach_method_id", id);
                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Metode Belajar"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    {/* Search by Name */}
                    <Grid sm={4}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Nama"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.name")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <InstructorListCourseDataTable
                    loading={loadingData}
                    data={courseData}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
