import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CheckCircle, Delete, Edit, GetApp } from "@material-ui/icons";
import moment from "moment";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

export default function PromoCodeDataTable({
  loading,
  data,
  getDetailData,
  handleDeleteData,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleShowOnHomePage,
  handleExport,
}) {
  const columns = [
    "#",
    "Nama",
    "Kode",
    "Limit",
    "Batas Waktu",
    "Sudah Digunakan",
    "Opsi",
  ];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell component="td" scope="row" align="center">
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>
                <TableCell component="td" align="center">
                  {item.name}
                </TableCell>
                <TableCell component="td" align="center">
                  {item.code}
                </TableCell>
                <TableCell component="td" align="center">
                  {item.limit}
                </TableCell>
                <TableCell component="td" align="center">
                  {moment(item.expired_at).format("D MMM YYYY HH:mm:ss")}
                </TableCell>
                <TableCell component="td" align="center">
                  {item.code_used_transaction}
                </TableCell>
                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="Edit"
                    onClick={() => handleShowOnHomePage(item.id)}
                    disabled={item.show_on_homepage === 1 ? true : false}
                  >
                    <CheckCircle />
                  </IconButton>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => getDetailData(item.id)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDeleteData(item.id)}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    onClick={() => handleExport(item.id, item.code)}
                  >
                    <GetApp />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
