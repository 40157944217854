import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Group } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

import moment from "moment";

export default function CourseSessionReportDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleShowParticipant,
}) {
  const columns = ["#", "Tanggal", "Waktu Mulai", "Kode Absen", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((session, index) => (
              <TableRow key={session.id}>
                <TableCell component="td" scope="row" align="center">
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>

                <TableCell component="td" align="center">
                  {moment(session.date).format("DD/MM/YYYY")}
                </TableCell>

                <TableCell component="td" align="center">
                  {moment(session.start_time, "HH:mm:ss").format("HH:mm")}
                </TableCell>

                <TableCell component="td" align="center">
                  {session.attendance_code ? session.attendance_code : "-"}
                </TableCell>

                <TableCell component="td" align="center">
                  <IconButton
                    aria-label="detail"
                    onClick={() =>
                      handleShowParticipant(session.id, session.attendance_code)
                    }
                  >
                    <Group />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
