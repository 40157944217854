import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { ImportExport, KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import formatRupiah from "helpers/formatRupiah";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { baseActions } from "redux/actions";

import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import CourseSessionReportDataTable from "../CourseSessionReportDataTable";
import ModalExport from "./ModalExport";
import ModalExportShcedule from "./ModalExportSchedule";

import ScheduleCourseReportDataTable from "./ScheduleCourseReportDataTable";
import UserScheduleDataTable from "./UserScheduleDataTable";

function ScheduleCourseReportPage() {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const { register, watch, setValue, reset } = useForm({
    defaultValues: {
      filter: {
        filterSearch: "",
        filterProgress: "",
      },
      format: {
        type: "xlsx",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [loadingParticipant, setLoadingParticipant] = useState(false);
  const [loadingDetailCourse, setLoadingDetailCourse] = useState(false);
  const [error, setError] = useState(null);

  const [detailCourse, setDetailCourse] = useState();

  const [OfflineScheduleData, setOfflineScheduleData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [offlineSessionId, setOfflineSessionId] = useState();

  const [loadingSessionData, setLoadingSessionData] = useState(false);
  const [offlineScheduleSessions, setOfflineScheduleSessions] = useState([]);
  const [paginationScheduleSession, setPaginationScheduleSession] = useState({
    limit: 10,
  });

  const [showDataParticipant, setShowDataParticipant] = useState(false);

  const [courseParticipants, setCourseParticipants] = useState([]);
  const [paginationParticipant, setPaginationParticipant] = useState({
    limit: 10,
  });

  const [openModalSession, setOpenModalSession] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [modalAttendance, setModalAttendance] = useState({
    active: false,
    data: null,
  });
  const [modalExportSchedule, setModalExportSchedule] = useState(false);

  const [openModalExport, setOpenModalExport] = useState(false);

  const status = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Progress",
      value: "progress",
    },
    {
      label: "Finished",
      value: "finished",
    },
  ];

  const formatType = [
    {
      label: "XLSX",
      value: "xlsx",
    },
    {
      label: "CSV",
      value: "csv",
    },
  ];

  /**
   * Fetch Data
   */
  const getList = async (courseId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.report.course.offline_schedule(courseId)}`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setOfflineScheduleData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailCourse = async course_id => {
    try {
      setLoadingDetailCourse(true);
      const { data } = await http.get(
        `${api.v1.admin.course.main}/${course_id}`
      );

      const { data: records } = data;

      setDetailCourse(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingDetailCourse(false);
    }
  };

  const getListSessions = async (courseId, scheduleId, page = 1) => {
    try {
      setLoadingSessionData(true);

      const { limit } = pagination;

      const { data } = await http.get(
        `${api.v1.admin.report.course.offline_schedule(
          courseId
        )}/${scheduleId}/offline-session`,
        {
          params: { limit, page },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setOfflineScheduleSessions(records);

      setPaginationScheduleSession({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingSessionData(false);
    }
  };

  const getCourseParticipants = async (
    course_id,
    offlineScheduleId,
    offlineSessionId,
    page = 1
  ) => {
    try {
      setLoadingParticipant(true);

      const { limit } = paginationParticipant;
      const { filterSearch, filterProgress } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.report.course.main}/${course_id}/participant`,
        {
          params: {
            limit,
            page,
            filter_search: filterSearch,
            filter_progress: filterProgress,
            offline_schedule_id: offlineScheduleId,
            ofline_session_id: offlineSessionId,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseParticipants(records);
      setPaginationParticipant({
        ...paginationParticipant,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  const postAttendance = async (course_id, user_id) => {
    try {
      setLoadingParticipant(true);

      const check_attendance = await http.get(
        `${api.v1.admin.report.course.main}/${course_id}/participant/${user_id}/attendance`
      );

      if (
        check_attendance?.data?.data?.user_learning_offline_attendances.length >
        0
      )
        return Swal.fire("Gagal", "User Telah Absen", "error");

      await http.post(
        `${api.v1.admin.report.course.main}/${course_id}/participant/${user_id}/attendance`
      );

      getCourseParticipants(
        courseId,
        scheduleId,
        offlineSessionId,
        pagination?.current_page
      );
      setModalAttendance({ active: false, data: null });

      Swal.fire("Sukses", "User berhasil absen", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingParticipant(false);
    }
  };

  const exportDataOfflineSession = async (courseId, scheduleId) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const exportDate = moment().format("D MMMM YYYY HH:mm:ss");

      const format = watch("format.type");

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = `${exportDate} - ${detailCourse.name}.${format}`;

      const params = {
        type: format,
      };

      const res = await http.get(
        `${api.v1.admin.report.course.main}/${courseId}/offline-schedule/${scheduleId}/export`,
        {
          params,
          responseType: "blob",
        }
      );

      const type = `text/${format}`;
      link.href = URL.createObjectURL(new Blob([res.data], { type: type }));
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId);
  };

  const handleChangePageSession = (_, page) =>
    getListSessions(courseId, scheduleId, page + 1);

  const handleChangeRowsPageSession = e => {
    setPaginationScheduleSession({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getListSessions(courseId, scheduleId);
  };

  const handleChangePageParticipant = (_, page) =>
    getCourseParticipants(courseId, scheduleId, offlineSessionId, page + 1);

  const handleChangeRowsPageParticipant = e => {
    setPaginationParticipant({
      ...paginationParticipant,
      limit: e.target.value,
    });
    paginationParticipant.limit = e.target.value;

    getCourseParticipants(courseId, scheduleId);
  };

  const handleShowParticipant = sessionId => {
    setOfflineSessionId(sessionId);
    getCourseParticipants(courseId, scheduleId, sessionId);
    setShowDataParticipant(true);
  };

  const handleOpenModalSession = id => {
    getListSessions(courseId, id);
    setScheduleId(id);
    setOpenModalSession(true);
  };

  const handleAttendance = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengabsenkan user ini",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        postAttendance(courseId, id);
      }
    });
  };

  const handleClose = () => {
    reset();
    setOpenModalSession(false);
    setScheduleId(null);
    setShowDataParticipant(false);
  };

  useEffect(() => {
    getList(courseId);
    getDetailCourse(courseId);
  }, []);
  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Jadwal Kelas Offline</title>
      </Helmet>

      <DefaultHeader
        section="Data Jadwal Kelas Offline"
        subsection="Data Kelas"
        routeBack={"/report/list-course"}
      />

      <Container maxWidth={false} component={Box} id="course-offline-schedule">
        <Grid container>
          <Grid item xs={12}>
            {!loadingDetailCourse && (
              <Card>
                <CardContent>
                  <Typography variant="h2" className="mb-3">
                    Detail Kelas
                  </Typography>

                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Nama Kelas
                        </Typography>
                        <p>{detailCourse?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Kategori
                        </Typography>
                        <p>{detailCourse?.course_category?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tipe Kelas
                        </Typography>
                        <p>{detailCourse?.course_type?.name}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Metode
                        </Typography>
                        <p>{detailCourse?.course_teach_method?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Instruktur
                        </Typography>
                        <p>{detailCourse?.instructors[0]?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Status
                        </Typography>
                        <p>{detailCourse?.status}</p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Harga
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Diskon
                        </Typography>
                        <p>Rp {formatRupiah(detailCourse?.discount_price)}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Total
                        </Typography>
                        <p>
                          Rp{" "}
                          {formatRupiah(
                            detailCourse?.price - detailCourse?.discount_price
                          )}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setModalExportSchedule(true)}
                      >
                        <ImportExport className="mr-2" />
                        Export Data
                      </Button>
                    </Grid>

                    <ScheduleCourseReportDataTable
                      loading={loadingData}
                      data={OfflineScheduleData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleOpenModalSession={handleOpenModalSession}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Modal User */}
        <Dialog
          open={openModalSession}
          onClose={() => handleClose()}
          fullWidth={true}
          maxWidth={false}
        >
          <Container className="py-4" maxWidth={false}>
            <Box textAlign="Center">
              <h2>Data Peserta</h2>
            </Box>

            <Grid container className="py-4">
              <Grid item xs={12} lg={6}>
                <Grid xs={12} className="py-3">
                  <Box textAlign="Center">
                    <h2>Daftar Sesi</h2>
                  </Box>
                </Grid>
                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      <Grid sm={12}>
                        <Grid className="d-flex align-items-center justify-content-end">
                          <Grid sm={3} className="mr-2">
                            <Autocomplete
                              id="combo-box-category"
                              options={formatType}
                              getOptionLabel={option => option.label}
                              closeIcon={null}
                              onInputChange={(e, val) => {
                                const { value } = formatType.filter(
                                  data => data.label === val
                                )[0];

                                setValue("format.type", value);
                              }}
                              value={
                                formatType.filter(
                                  data => data.value === watch("format.type")
                                )[0]
                              }
                              popupIcon={
                                <Box
                                  component={KeyboardArrowDown}
                                  width="1.25rem!important"
                                  height="1.25rem!important"
                                />
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Tipe"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                exportDataOfflineSession(courseId, scheduleId)
                              }
                            >
                              <ImportExport />
                              Export Data
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <CourseSessionReportDataTable
                      loading={loadingSessionData}
                      data={offlineScheduleSessions}
                      pagination={paginationScheduleSession}
                      handleChangePage={handleChangePageSession}
                      handleChangeRowsPage={handleChangeRowsPageSession}
                      detailCourse={detailCourse}
                      handleShowParticipant={handleShowParticipant}
                      webinar={true}
                    />
                  </Card>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid xs={12} className="py-3">
                  <Box textAlign="Center">
                    <h2>Daftar Peserta</h2>
                  </Box>
                </Grid>
                <Grid>
                  {showDataParticipant ? (
                    <Card>
                      <Grid className="p-3 d-flex justify-content-end">
                        <Grid sm={12}>
                          <Grid className="d-flex align-items-center justify-content-end">
                            <Grid sm={2} className="mr-2">
                              <Autocomplete
                                id="combo-box-category"
                                options={status}
                                getOptionLabel={option => option.label}
                                closeIcon={null}
                                onInputChange={(e, val) => {
                                  const { value } = status.filter(
                                    data => data.label === val
                                  )[0];

                                  setValue("filter.filterProgress", value);
                                  getCourseParticipants(courseId, scheduleId);
                                }}
                                popupIcon={
                                  <Box
                                    component={KeyboardArrowDown}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                  />
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Status"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            <OutlinedInput
                              type="text"
                              placeholder="Cari berdasarkan Nama"
                              startAdornment={
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              }
                              className="mr-3"
                              onKeyPress={e =>
                                e.key === "Enter" &&
                                getCourseParticipants(courseId, scheduleId)
                              }
                              {...register("filter.filterSearch")}
                            />

                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-2"
                              onClick={() =>
                                getCourseParticipants(courseId, scheduleId)
                              }
                            >
                              <Box
                                component={Search}
                                width="1.25rem!important"
                                height="1.25rem!important"
                                position="relative"
                              />
                            </Button>

                            <Grid>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenModalExport(true)}
                              >
                                <ImportExport />
                                Export Data
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <UserScheduleDataTable
                        loading={loadingParticipant}
                        data={courseParticipants}
                        pagination={paginationParticipant}
                        handleChangePage={handleChangePageParticipant}
                        handleChangeRowsPage={handleChangeRowsPageParticipant}
                        detailCourse={detailCourse}
                        handleAttendance={setModalAttendance}
                      />
                    </Card>
                  ) : (
                    <Box textAlign="Center">
                      Pilih sesi terlebih dahulu untuk menampilkan data peserta.
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Dialog>

        {/* Modal Attendance */}
        <Dialog
          open={modalAttendance.active}
          onClose={() => setModalAttendance({ active: false, data: null })}
          fullWidth={true}
          maxWidth="md"
        >
          <Container className="py-4">
            <Box textAlign="Center">
              <h2>Absen User</h2>
            </Box>

            <Grid container className="py-4">
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nama
                  </Typography>
                  <p>{modalAttendance?.data?.name}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Email
                  </Typography>
                  <p>{modalAttendance?.data?.email}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nomor
                  </Typography>
                  <p>{modalAttendance?.data?.phone_number}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Waktu Absen
                  </Typography>
                  <p>
                    {modalAttendance?.data?.attendance
                      ?.user_learning_offline_attendances.length
                      ? moment(
                          modalAttendance?.data?.attendance
                            ?.user_learning_offline_attendances[0].submit_at
                        ).format("DD/MM/yyyy HH:mm")
                      : "-"}
                  </p>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} className="mb-2">
                <Box>
                  <Typography variant="h4" className="mb-0">
                    Nama Tempat
                  </Typography>
                  <p>{modalAttendance?.data?.attendance?.event_place?.name}</p>
                </Box>

                <Box>
                  <Typography variant="h4" className="mb-0">
                    Alamat Tempat
                  </Typography>
                  <p>
                    {modalAttendance?.data?.attendance?.event_place?.address}
                  </p>
                </Box>
              </Grid>

              {!modalAttendance?.data?.attendance
                ?.user_learning_offline_attendances.length && (
                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    onClick={() => handleAttendance(modalAttendance?.data?.id)}
                  >
                    Absen
                  </Button>
                </Grid>
              )}
            </Grid>
          </Container>
        </Dialog>

        {/* Modal Export */}
        <ModalExport
          openModal={openModalExport}
          onClose={() => setOpenModalExport(false)}
          course_id={courseId}
          schedule_id={scheduleId}
        />

        {/* Modal Schedule Export */}
        <ModalExportShcedule
          show={modalExportSchedule}
          onClose={() => setModalExportSchedule(false)}
        />
      </Container>
    </>
  );
}

export default ScheduleCourseReportPage;
