import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import { Edit, Phone, WhatsApp } from "@material-ui/icons";
import ChipData from "components/Chip/ChipData";

export default function UserReminderDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleOpenModal,
}) {
  const columns = [
    "#",
    "Nama",
    "Email",
    "Nomor Telepon",
    "Progres Belajar",
    "Waktu Pembelajaran",
    "Status",
    "Catatan",
    "Action",
  ];

  const statusConstant = {
    NOT_YET: "Not Yet",
    PENDING: "Pending",
    DELIVERED: "Delivered",
    FAILED: "Failed",
  };

  const statusColor = {
    DELIVERED: themeColors.success.main,
    FAILED: themeColors.error.main,
    PENDING: themeColors.warning.main,
    NOT_YET: themeColors.info.main,
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map(({ user, ...data }, index) => (
              <>
                <TableRow key={user?.id}>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {pagination.current_page > 1
                      ? (pagination.current_page - 1) * pagination.limit +
                        (index + 1)
                      : index + 1}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {user.name}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {user.phone_number}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {data.progress_percentage}%
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {data?.day_difference} Hari
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <ChipData
                      color="secondary"
                      text={
                        data.reminder?.status
                          ? statusConstant[data.reminder?.status]
                          : statusConstant.NOT_YET
                      }
                      style={{
                        color: themeColors.gray[100],
                        backgroundColor: data.reminder?.status
                          ? statusColor[data.reminder?.status]
                          : statusColor.NOT_YET,
                      }}
                    />
                    {}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {data.reminder?.note || "-"}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        handleOpenModal(
                          data.reminder?.note || null,
                          data.reminder?.status || null,
                          data.id
                        )
                      }
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        window.open(
                          `https://wa.me/${user.phone_number}`,
                          "_blank"
                        )
                      }
                    >
                      <WhatsApp />
                    </IconButton>
                    <IconButton
                      aria-label="Edit"
                      onClick={() =>
                        window.open(`tel:${user.phone_number}`, "_blank")
                      }
                    >
                      <Phone />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={12}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
