import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Search } from "@material-ui/icons";
import Swal from "sweetalert2";

import themeColors from "assets/theme/colors";

import http from "services/http";
import api from "services/api";
import { baseActions } from "redux/actions";

import DefaultHeader from "components/Headers/DefaultHeader";
import ModuleDataTable from "./ModuleDataTable";

export default function ListModulePage() {
  const { courseId } = useParams();

  const dispatch = useDispatch();
  const { register, watch, setValue } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [moduleData, setModuleData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 50 });

  const [typeModalModule, setTypeModalModule] = useState("CREATE");
  const [showModalModule, setShowModalModule] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (courseId, page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { title } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module`,
        {
          params: { limit, page, title },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setModuleData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async (courseId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.course.main}/${courseId}/module/${id}`
      );

      setTypeModalModule("UPDATE");
      setShowModalModule(true);

      setValue("module", data.data);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async courseId => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("module");

      await http.post(
        `${api.v1.admin.course.main}/${courseId}/module`,
        payload
      );

      setShowModalModule(false);
      setValue("course.title", "");

      Swal.fire("Sukses", "Data Modul berhasil di tambahkan", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async courseId => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("module");

      await http.put(
        `${api.v1.admin.course.main}/${courseId}/module/${payload.id}`,
        payload
      );

      setShowModalModule(false);
      setValue("course.title", "");

      Swal.fire("Sukses", "Data Modul berhasil di perbarui", "success");

      getList(courseId);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async (courseId, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.course.main}/${courseId}/module/${id}`);

      getList(courseId);

      Swal.fire("Sukses", "Data kelas berhasil di hapus", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(courseId, page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList(courseId);
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data modul",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(courseId, id);
      }
    });
  };

  useEffect(() => {
    getList(courseId);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Modul Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Data Modul"
        subsection="Data Kelas"
        routeBack="/kelas"
      />

      <Container maxWidth={false} component={Box} id="course-module-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setValue("module", {});
                      setTypeModalModule("CREATE");
                      setShowModalModule(true);
                    }}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-end">
                      {/* Search by Title */}
                      <Grid sm={4}>
                        <Grid className="d-flex align-items-center justify-content-end">
                          <OutlinedInput
                            type="text"
                            placeholder="Cari berdasarkan Judul"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                            className="mr-3"
                            onKeyPress={e =>
                              e.key === "Enter" && getList(courseId)
                            }
                            {...register("filter.title")}
                          />

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => getList(courseId)}
                          >
                            <Box
                              component={Search}
                              width="1.25rem!important"
                              height="1.25rem!important"
                              position="relative"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <ModuleDataTable
                      loading={loadingData}
                      data={moduleData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Module */}
                <Dialog
                  open={showModalModule}
                  onClose={() => setShowModalModule(false)}
                  fullWidth={true}
                  maxWidth="sm"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Modul</h2>
                    </Box>

                    <Grid container className="pt-4">
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormLabel>Judul</FormLabel>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            {...register("module.title")}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormGroup>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              typeModalModule === "CREATE"
                                ? postCreateData(courseId)
                                : putUpdateData(courseId)
                            }
                          >
                            {typeModalModule === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
