import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LogWhatsappDataTable from "./LogWhatsappDataTable";

import api from "services/api";
import http from "services/http";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";

const filterStatus = [
  {
    label: "Semua",
    value: "",
  },
  {
    label: "Dalam Antrian",
    value: "QUEUED",
  },
  {
    label: "Gagal",
    value: "FAILED",
  },
  {
    label: "Sudah Diterima",
    value: "DELIVERED",
  },
  {
    label: "Belum Diterima",
    value: "UNDELIVERED",
  },
  {
    label: "Sudah Dibaca",
    value: "READ",
  },
];

export default function ListLogWhatsappPage() {
  const { register, watch, setValue } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [reminderCourses, setReminderCourses] = useState([]);

  const [logWhatsapps, setLogWhatsapps] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { search, status, reminder_course_id } = watch("filter");

      const { data } = await http.get(
        api.v1.admin.log.whatsapp.sender_activity,
        {
          params: {
            limit,
            page,
            search: encodeURIComponent(search),
            status,
            whatsapp_reminder_course_id: reminder_course_id,
          },
        }
      );

      const { current_page, records, total_page, total_records } = data.data;

      setLogWhatsapps(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getReminderCourses = async () => {
    try {
      const { data } = await http.get(api.v1.admin.whatsapp.reminder.main, {
        params: { limit: 999, page: 1 },
      });

      setError(null);

      const { records } = data.data;
      records.unshift({
        id: "",
        course: { name: "Semua", code: "" },
      });

      setReminderCourses(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getReminderCourses();
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Log Whatsapp</title>
      </Helmet>
      <DefaultHeader section="Data Log Whatsapp" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-between">
                    {/* select option */}
                    <Grid sm={8} className="d-flex">
                      {/* Filter by Status */}
                      <Grid sm={3} className="mr-2">
                        <Autocomplete
                          id="combo-box-status"
                          options={filterStatus}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          onInputChange={(e, val) => {
                            const { value } = filterStatus.filter(
                              data => data.label === val
                            )[0];

                            setValue("filter.status", value);
                            getList();
                          }}
                          value={
                            filterStatus.filter(
                              data => data.value === watch("filter.status")
                            )[0]
                          }
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      {/* Filter by reminder course */}
                      <Grid sm={5} className="mr-2">
                        <Autocomplete
                          id="combo-box-category"
                          options={reminderCourses}
                          getOptionLabel={option => {
                            if (option.course.code) {
                              return `${option.course.name} - ${option.course.code}`;
                            }
                            return option.course.name;
                          }}
                          closeIcon={null}
                          onChange={(e, option) => {
                            const { id } = reminderCourses.filter(
                              data => data.id === option.id
                            )[0];

                            setValue("filter.reminder_course_id", id);
                            getList();
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Reminder Kelas"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    {/* Search by */}
                    <Grid sm={4}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan No. Dari, Tujuan, atau Id Pesan"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.search")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <LogWhatsappDataTable
                    loading={loadingData}
                    data={logWhatsapps}
                    pagination={pagination}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
