import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";
import DefaultHeader from "components/Headers/DefaultHeader";
import themeColors from "assets/theme/colors";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import http from "services/http";
import api from "services/api";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import RoleDataTable from "./RoleDataTable";
import localStorage from "helpers/localStorage";

export default function ListRolePage() {
  const dispatch = useDispatch();

  const { setValue, handleSubmit, register } = useForm({
    defaultValues: {
      role: {
        name: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.get("permissions"))?.map(
    permission => permission.code
  );

  const [roles, setRoles] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalRole, setTypeModalRole] = useState("CREATE");
  const [showModalRole, setShowModalRole] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.role.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setRoles(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    dispatch(baseActions.SET_LOADING_SCREEN(true));
    try {
      const { data } = await http.get(`${api.v1.admin.role.main}/${id}`);

      setValue("role", {
        id,
        name: data.data.name,
      });

      setTypeModalRole("UPDATE");
      setShowModalRole(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { role } = payload;

      await http.post(api.v1.admin.role.main, {
        ...role,
      });

      handleCloseModal();

      Swal.fire("Sukses", "Data Role berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { role } = payload;

      await http.put(`${api.v1.admin.role.main}/${role.id}`, {
        ...role,
      });

      handleCloseModal();

      Swal.fire("Sukses", "Data Role berhasil di Perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.role.main}/${id}`);

      Swal.fire("Sukses", "Data Role berhasil di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = async () => {
    setTypeModalRole("CREATE");
    setShowModalRole(true);
  };

  const handleCloseModal = () => {
    setShowModalRole(false);
    setValue("role", {
      name: "",
    });
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data Role",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Role</title>
      </Helmet>

      <DefaultHeader section="Data Role" subsection="Kelola Role" />

      <Container maxWidth={false} component={Box} id="role">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                {permissions?.includes("141-3") && (
                  <Grid className="mb-4">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenModal(true)}
                    >
                      Tambah Data
                    </Button>
                  </Grid>
                )}

                {permissions?.includes("141-1") && (
                  <Grid>
                    <Card>
                      <RoleDataTable
                        loading={loadingData}
                        data={roles}
                        getDetailData={getDetailData}
                        handleDeleteData={handleDeleteData}
                        pagination={pagination}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPage={handleChangeRowsPage}
                      />
                    </Card>
                  </Grid>
                )}

                {/* Modal Create & Update Template Message */}
                <Dialog
                  open={showModalRole}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="Center">
                      <h2>Role</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalRole === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Nama</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("role.name", {
                                onChange: e =>
                                  setValue(
                                    "role.name",
                                    e.target.value.toUpperCase()
                                  ),
                              })}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </FormGroup>
                        </Grid>

                        {/* permission create button */}
                        {permissions?.includes("141-3") &&
                          typeModalRole === "CREATE" && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                              >
                                Tambah
                              </Button>
                            </Grid>
                          )}

                        {/* permission update button */}
                        {permissions?.includes("141-4") &&
                          typeModalRole !== "CREATE" && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                              >
                                Perbarui
                              </Button>
                            </Grid>
                          )}
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
