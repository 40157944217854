import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { FileCopy, Search } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import { baseActions } from "redux/actions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  buttonSpace: {
    marginBottom: "1rem",
    [theme.breakpoints.up("lg")]: {
      marginBottom: 0,
    },
  },
}));

export default function SearchVoucherPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit } = useForm();

  const [voucher, setVoucher] = useState(null);

  const [successCopy, setSuccessCopy] = useState(false);

  /**
   * Fetch Data
   */
  const getSearchVoucher = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(`${api.v1.admin.voucher.main}/search`, {
        params: payload,
      });

      setVoucher(data.data);

      setValue("voucher", "");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        const message =
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message;

        Swal.fire("Gagal", message, "error");
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  return (
    <>
      <Helmet>
        <title>Cariilmu | Cari Voucher</title>
      </Helmet>

      <DefaultHeader section="Pencarian" subsection="Kelola Voucher" />

      <Container maxWidth={false} component={Box}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit(getSearchVoucher)}>
                  <Grid container>
                    <Grid item xs={12} lg={11}>
                      <OutlinedInput
                        fullWidth
                        className={classes.buttonSpace}
                        placeholder="Pencarian"
                        {...register("voucher")}
                      />
                    </Grid>

                    <Grid item xs={12} lg={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className=""
                      >
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>

          {voucher ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="d-flex justify-content-between">
                        <Typography variant="h2" className="mb-3">
                          Hasil Pencarian Terakhir :
                        </Typography>

                        {voucher?.user?.id && (
                          <IconButton
                            className="mb-3"
                            aria-label="Edit"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `Voucher sudah digunakan ${voucher?.user?.name} (${voucher?.user?.email})\nTanggal digunakan\n${voucher.used_at}`
                              );
                              setSuccessCopy(true);
                            }}
                          >
                            <FileCopy />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Voucher
                        </Typography>
                        <p>{voucher.voucher}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tanggal Kadaluarsa
                        </Typography>
                        <p>{voucher.expired_date}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tanggal Digunakan
                        </Typography>
                        <p>
                          {voucher.used_at
                            ? voucher.used_at
                            : "Belum Digunakan"}
                        </p>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Tipe Voucher
                        </Typography>
                        <p>{voucher?.voucher_type?.name}</p>
                      </Box>

                      <Box>
                        <Typography variant="h4" className="mb-0">
                          Kelas
                        </Typography>
                        <p>{voucher?.course?.name}</p>
                      </Box>
                    </Grid>

                    {voucher?.user?.id && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Typography variant="h4" className="mb-0">
                              Nama
                            </Typography>
                            <p>{voucher?.user?.name}</p>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Typography variant="h4" className="mb-0">
                              Email
                            </Typography>
                            <p>{voucher?.user?.email}</p>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Container>

      <Snackbar
        open={successCopy}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setSuccessCopy(false)}
      >
        <Alert
          onClose={() => setSuccessCopy(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Berhasil disalin!
        </Alert>
      </Snackbar>
    </>
  );
}
