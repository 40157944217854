import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  SnackbarContent,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReactQuill from "react-quill";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import http from "services/http";
import api from "services/api";

import DefaultHeader from "components/Headers/DefaultHeader";
import Spinner from "components/Loading/Indicator/Spinner";
import CertificateCompetenceDataTable from "components/Course/CertificateCompetenceDataTable";
import CompetenceAspectDataTable from "components/Course/CompetenceAspectDataTable";
import TrainingGoalDataTable from "components/Course/TrainingGoalDataTable";
import TrainingMaterialDataTable from "components/Course/TrainingMaterialDataTable";
import WebinarScheduleDataTable from "components/Course/WebinarScheduleDataTable";

const snackbarStyles = makeStyles(snackbarTheme);

export default function InstructorDetailCoursePage() {
  const { id } = useParams();

  const styles = { ...snackbarStyles() };
  const { register, setValue, watch } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [toggleCertificateCompetence, setToggleCertificateCompetence] =
    useState(false);
  const [toggleTrainingGoal, setToggleTrainingGoal] = useState(false);
  const [toggleTrainingMaterial, setToggleTrainingMaterial] = useState(false);

  const [showModalAddWebinarSchedule, setShowModalAddWebinarSchedule] =
    useState(false);

  const statusCourseOptions = ["DRAFT", "QC", "PUBLISH"];

  /**
   * Fetch Data
   */
  const getDetailCourse = async id => {
    try {
      setLoadingData(true);

      const { data } = await http.get(`${api.v1.instructor.course.main}/${id}`);

      setError(null);

      const webinars = [];

      if (data?.data?.webinars) {
        data.data.webinars.forEach(webinar => {
          const { sessions } = webinar;

          const session_data = [];
          sessions.forEach(session => {
            session_data.push(session);
          });

          webinars.push(session_data);
        });

        data.data.webinars = webinars;
      }

      setValue("preview_cover", data.data.cover);
      if (data.data.certificate) {
        setValue("preview_certificate_sample", data.data.certificate_sample);
        setValue("course.certificate_sample", null);
      }

      data.data.cover = null;
      data.data.certificate_sample = null;

      setValue("course", data.data);
      setValue(
        "course.certificate",
        data.data.certificate === 1 ? true : false
      );
      setValue(
        "course.publish_at",
        moment(data.data.publish_at).format("yyyy-MM-DDTHH:mm:ss")
      );

      setToggleCertificateCompetence(
        data.data.certificate_competencies.length ? true : false
      );
      setToggleTrainingGoal(data.data.training_goals.length ? true : false);
      setToggleTrainingMaterial(
        data.data.training_materials.length ? true : false
      );
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleDeleteWebinarSchedule = key => {
    let webinarSchedules = watch("course.webinars");

    webinarSchedules = webinarSchedules.filter(
      item => item !== webinarSchedules[key]
    );

    setValue("course.webinars", webinarSchedules);
  };

  useEffect(() => {
    getDetailCourse(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Kelas</title>
      </Helmet>

      <DefaultHeader
        section="Detail Kelas"
        subsection="Data Kelas"
        routeBack="/admin-instructor/my-course"
      />

      <Container maxWidth={false} component={Box} id="create-course-page">
        <Grid container>
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <Card className="p-4">
                {error && (
                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <SnackbarContent
                        classes={{ root: styles.errorSnackbar }}
                        action={
                          <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => setError(null)}
                          >
                            <Box
                              component="span"
                              color={theme.palette.white.main}
                            >
                              ×
                            </Box>
                          </Box>
                        }
                        message={<Box>{error}</Box>}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Master Data */}
                <Grid container>
                  <Grid item xs={12} className="mb-2">
                    <h2 className="text-muted">Master Data</h2>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Kode</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.code")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <FormGroup>
                      <FormLabel>Judul</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.name")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Deskripsi</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("course.description")}
                        onChange={val => setValue("course.description", val)}
                        disabled
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Kategori</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.course_category.name")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Tipe</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.course_type.name")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Level</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.course_level.name")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Metode Ajar</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.course_teach_method.name")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>Durasi</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        {...register("course.duration")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>

                  {/* Cover Preview */}
                  {watch("preview_cover") && (
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Preview Cover</FormLabel>
                        <img
                          src={watch("preview_cover")}
                          alt="cover"
                          width="400px"
                        />
                      </FormGroup>
                    </Grid>
                  )}

                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>Bahasa</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.language")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>Harga</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        {...register("course.price")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>Harga Diskon</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        {...register("course.discount_price")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>Pratinjau Video</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("course.preview_video")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-competence-aspect-label">
                          Status
                        </FormLabel>
                        <Select
                          IconComponent={KeyboardArrowDown}
                          labelId="option-competence-aspect-label"
                          value={watch("course.status") || ""}
                          {...register("course.status")}
                          disabled
                        >
                          {statusCourseOptions.map((val, key) => (
                            <MenuItem key={key} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>Tanggal Publish</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="datetime-local"
                        defaultValue={moment(watch("course.publish_at")).format(
                          "yyyy-MM-DDTHH:mm:ss"
                        )}
                        {...register("course.publish_at")}
                        disabled
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Kelompok Sasaran</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("course.target_group")}
                        onChange={val => setValue("course.target_group", val)}
                        readOnly
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                {/* Certificate */}
                {watch("course.certificate") &&
                  watch("preview_certificate_sample") && (
                    <Grid container className="mt-4">
                      {/* Certificate Sample Preview */}
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormLabel>Preview Sampel Sertifikat</FormLabel>
                          <img
                            src={watch("preview_certificate_sample")}
                            alt="certificate-sample"
                            width="400px"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}

                {/* Certificate Competency */}
                {toggleCertificateCompetence && (
                  <Grid container className="mt-4">
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Kompetensi Sertifikat</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <CertificateCompetenceDataTable
                        data={watch("course.certificate_competencies")}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Competence Aspect */}
                {watch("course.show_competence_aspects") === 1 && (
                  <Grid container className="mt-4">
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Aspek Kompetensi</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <CompetenceAspectDataTable
                        data={watch("course.aspects")}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Training Goals */}
                {toggleTrainingGoal && (
                  <Grid container className="mt-4">
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Tujuan Pelatihan</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <TrainingGoalDataTable
                        data={watch("course.training_goals")}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Training Material */}
                {toggleTrainingMaterial && (
                  <Grid container className="mt-4">
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Materi Pelatihan</h2>
                    </Grid>

                    <Grid item xs={12}>
                      <TrainingMaterialDataTable
                        data={watch("course.training_materials")}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Webinar */}
                {watch("course.course_teach_method.name") === "Webinar" ||
                watch("course.course_teach_method.name") === "Hybrid" ? (
                  <Grid container className="mt-4">
                    <Grid item xs={12} className="mb-2">
                      <h2 className="text-muted">Webinar</h2>
                    </Grid>

                    <Grid item xs={12} className="mt-4">
                      <WebinarScheduleDataTable
                        data={watch("course.webinars")}
                      />
                    </Grid>

                    {/* Modal Add Webinar Schedule */}
                    <Dialog
                      open={showModalAddWebinarSchedule}
                      onClose={() => setShowModalAddWebinarSchedule(false)}
                      fullWidth={true}
                      maxWidth="md"
                    >
                      <Container className="py-4">
                        <Box textAlign="center">
                          <h2>Data Waktu Webinar</h2>
                        </Box>

                        <Grid container className="pt-4">
                          <Grid item xs={12} md={4}>
                            <FormGroup>
                              <FormLabel>Tanggal</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="date"
                                defaultValue={moment().format("yyyy-MM-DD")}
                                {...register("webinar_time_list.date")}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormGroup>
                              <FormLabel>Waktu Mulai</FormLabel>
                              <OutlinedInput
                                fullWidth
                                type="time"
                                defaultValue={moment().format("HH:mm:ss")}
                                {...register("webinar_time_list.start_time")}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowModalAddWebinarSchedule(true)}
                          >
                            Tambah Jadwal
                          </Button>
                        </Grid>

                        <Grid item xs={12} className="mt-4">
                          <WebinarScheduleDataTable
                            data={watch("course.webinars")}
                            handleDeleteData={handleDeleteWebinarSchedule}
                          />
                        </Grid>
                      </Container>
                    </Dialog>
                  </Grid>
                ) : null}
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
