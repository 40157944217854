import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

export default function SelectPermission({
  loading,
  permissions,
  roles,
  userPermissions,
  handleSelectRole,
}) {
  return (
    <>
      <TableContainer>
        <Box component={Table} alignItems="start" marginBottom="0!important">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" align="left">
                Aksi
              </TableCell>
              {roles?.map(role => (
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  key={role?.id}
                  style={{ width: "100px" }}
                >
                  {role?.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {permissions.map((permission, key) => (
            <>
              <TableHead key={key}>
                <TableRow
                  style={{
                    backgroundColor: themeColors.primary.main,
                  }}
                >
                  <TableCell
                    align="left"
                    colSpan={roles?.length + 1}
                    // style={{ color: themeColors.white.main }}
                  >
                    {permission?.name}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!loading && permission?.children?.length ? (
                  permission?.children?.map(sub_permission => (
                    <TableRow key={sub_permission.id}>
                      <TableCell
                        component="td"
                        scope="row"
                        align="left"
                        style={{
                          wordWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        {sub_permission?.name}
                      </TableCell>

                      {/* role checkable */}
                      {roles?.map(role => (
                        <TableCell
                          component="td"
                          scope="row"
                          align="center"
                          style={{ wordWrap: "break-word" }}
                          key={role?.id}
                        >
                          <Checkbox
                            checked={userPermissions[
                              userPermissions?.findIndex(
                                i => i?.id === sub_permission?.id
                              )
                            ]?.roles?.includes(role?.id)}
                            onChange={e =>
                              handleSelectRole(
                                sub_permission?.id,
                                role?.id,
                                e.target.checked
                              )
                            }
                            color="primary"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableCell component="td" colSpan={6}>
                    {loading ? (
                      <Box textAlign="center">Harap Tunggu...</Box>
                    ) : (
                      <Box textAlign="center">Tidak ada data</Box>
                    )}
                  </TableCell>
                )}
              </TableBody>
            </>
          ))}
        </Box>
      </TableContainer>
    </>
  );
}
