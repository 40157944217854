import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import AutocompleteDebounce from "components/AutocompleteDebounce";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";

import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

export default function BlastMessagePage() {
  const dispatch = useDispatch();

  const { setValue, watch, handleSubmit, reset, register } = useForm();

  const [userOptions, setUserOptions] = useState([]);
  const [templateWhatsapps, setTemplateWhatsapps] = useState([]);

  const [userSelected, setUserSelected] = useState([]);

  const [valueTemplate, setValueTemplate] = useState(null);
  const [valueUser, setValueUser] = useState(null);

  /**
   * Fetch Data
   */
  const getUserOptions = async (search = "") => {
    const { data } = await http.get(api.v1.admin.report.user, {
      params: { page: 1, limit: 5, search_by: "EMAIL", search },
    });

    setUserOptions(data.data.records);
  };

  const getTemplateWhatsapps = async () => {
    const { data } = await http.get(api.v1.admin.whatsapp.template.main, {
      params: { limit: 999, page: 1 },
    });

    const { records } = data.data;

    setTemplateWhatsapps(records);
  };

  /**
   * Handle
   */
  const submitMessage = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { whatsapp_template_message_id, name } = val;

      const user_ids = userSelected.map(item => item.id);

      await http.post(api.v1.admin.whatsapp.blast.send_multiple, {
        name,
        whatsapp_template_message_id,
        user_ids,
      });

      setUserSelected([]);
      reset();
      setValueTemplate(null);
      setValueUser(null);

      Swal.fire("Sukses", "Blast Pesan Whatsapp Berhasil", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const handleDeleteUser = index => {
    const tempUsers = [...userSelected];

    tempUsers.splice(index, 1);

    setUserSelected(tempUsers);
  };

  useEffect(() => {
    getTemplateWhatsapps();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Blast Pesan Whatsapp</title>
      </Helmet>

      <DefaultHeader section="Blast Pesan Whatsapp" />

      <Container maxWidth={false} component={Box} id="blast-whatsapp">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit(submitMessage)}>
                  <Grid container>
                    <Grid item sm={12}>
                      <FormGroup>
                        <FormLabel>Nama</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          {...register("name")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <FormGroup>
                          <FormLabel>Pilih Template Whatsapp</FormLabel>
                          <Autocomplete
                            id="combo-box-template-whatsapp"
                            options={templateWhatsapps}
                            getOptionLabel={option => option?.name}
                            closeIcon={null}
                            value={valueTemplate}
                            onChange={(e, option) => {
                              setValueTemplate(option);

                              const { id } = templateWhatsapps.filter(
                                data => data.id === option.id
                              )[0];

                              setValue("whatsapp_template_message_id", id);
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label=""
                                variant="outlined"
                              />
                            )}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <FormGroup>
                          <FormLabel>Pilih Peserta</FormLabel>
                          <AutocompleteDebounce
                            options={userOptions}
                            getOption={getUserOptions}
                            label={option => `${option.name} - ${option.email}`}
                            onChange={(e, val) => {
                              setValueUser(val);

                              if (
                                !userSelected.filter(item => item.id === val.id)
                                  .length
                              ) {
                                setUserSelected(prev => [...prev, val]);
                              }
                            }}
                            value={valueUser}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    {userSelected.length ? (
                      <Grid item xs={12}>
                        <FormLabel>Peserta Terpilih</FormLabel>
                        <Box className="mb-2">
                          {userSelected.map((item, key) => (
                            <Chip
                              key={key}
                              onDelete={() => handleDeleteUser(key)}
                              label={item.name}
                              className="mr-2 mb-2"
                            />
                          ))}
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        disabled={
                          !userSelected.length ||
                          !watch("whatsapp_template_message_id") ||
                          !watch("name")
                        }
                      >
                        Blast Pesan
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
