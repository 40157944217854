import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";

import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";

export default function BlastMessagePage() {
  const dispatch = useDispatch();

  const { setValue, handleSubmit, reset, register } = useForm();

  const [templateEmails, setTemplateEmails] = useState([]);

  const [valueEmail, setValueEmail] = useState(null);

  /**
   * Fetch Data
   */

  const getTemplateEmails = async () => {
    const { data } = await http.get(api.v1.admin.email.template.main, {
      params: { limit: 999, page: 1 },
    });

    const { records } = data.data;

    setTemplateEmails(records);
  };

  /**
   * Handle
   */
  const submitMessage = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { email_template_message_id, name, recipient_excel } = val;
      const formData = new FormData();

      formData.append("recipient_excel", recipient_excel[0]);
      formData.append("email_template_message_id", email_template_message_id);
      formData.append("name", name);

      await http.post(api.v1.admin.email.blast.send_multiple, formData);

      reset();
      setValueEmail(null);

      Swal.fire("Sukses", "Blast Pesan Email Berhasil", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getTemplateEmails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Blast Pesan Email</title>
      </Helmet>

      <DefaultHeader section="Blast Pesan Email" />

      <Container maxWidth={false} component={Box} id="blast-email">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit(submitMessage)}>
                  <Grid container>
                    <Grid item sm={12}>
                      <FormGroup>
                        <FormLabel>Nama</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          {...register("name")}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <FormGroup>
                          <FormLabel>Pilih Template Email</FormLabel>
                          <Autocomplete
                            id="combo-box-template-email"
                            options={templateEmails}
                            getOptionLabel={option => option?.name}
                            closeIcon={null}
                            value={valueEmail}
                            onChange={(e, option) => {
                              setValueEmail(option);

                              const { id } = templateEmails.filter(
                                data => data.id === option.id
                              )[0];

                              setValue("email_template_message_id", id);
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label=""
                                variant="outlined"
                              />
                            )}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormLabel>File Excel</FormLabel>
                        <OutlinedInput
                          fullWidth
                          type="file"
                          {...register("recipient_excel")}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        Blast Pesan
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
