const roles = {
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN",
  INSTRUCTOR: "INSTRUCTOR",
  DATA_MANAGER: "DATA_MANAGER",
  USER: "USER",
};

const RoleConstant = {
  ALL_ROLES: Object.keys(roles),
  ROLES: roles,
};

export default RoleConstant;
