import React, { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import { Redirect, Switch, useLocation } from "react-router-dom";

import mainRoutes from "routes/main.routes";

import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar";

import componentStyles from "assets/theme/layouts/admin";

import ProtectedRoute from "routes/ProtectedRoute";

const useStyles = makeStyles(componentStyles);

export default function AdminLayouts() {
  const classes = useStyles();
  const location = useLocation();

  const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (["", "/admin-instructor"].includes(prop.layout)) {
        return (
          <ProtectedRoute
            key={key}
            roles={prop.roles}
            path={prop.layout + prop.path}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <>
      <Box display="flex">
        <Sidebar
          routes={mainRoutes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/index",
            imgSrc: require("assets/img/brand/main-logo.png"),
            imgAlt: "...",
          }}
        />
        <Box position="relative" flex="1" className={classes.mainContent}>
          <AdminNavbar
            openSidebarResponsive={() => setSidebarOpenResponsive(true)}
          />
          <Switch>
            {getRoutes(mainRoutes)}

            <Redirect from="*" to="/dashboard" />
          </Switch>
        </Box>
      </Box>
    </>
  );
}
