import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useParams } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

export default function QuizMixAndMatchDataTable({
  loading,
  data,
  handleDeleteData,
  pagination,
  getDetailData,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const { courseId, moduleId, subModuleId, videoQuizId } = useParams();

  const columns = ["#", "Pertanyaan", "Jawaban", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((quiz, index) => {
              return (
                <TableRow key={quiz.id}>
                  <TableCell component="td" scoped="row" align="center">
                    {pagination.current_page > 1
                      ? (pagination.current_page - 1) * pagination.limit +
                        (index + 1)
                      : index + 1}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {quiz.question}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {quiz.answer.answer}
                  </TableCell>
                  <TableCell component="td" align="center">
                    <IconButton
                      onClick={() =>
                        getDetailData(
                          courseId,
                          moduleId,
                          subModuleId,
                          videoQuizId,
                          quiz.id
                        )
                      }
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      onClick={() =>
                        handleDeleteData(
                          courseId,
                          moduleId,
                          subModuleId,
                          videoQuizId,
                          quiz.id
                        )
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={4}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
