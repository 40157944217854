import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Assignment } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

import ChipData from "components/Chip/ChipData";
import Pagination from "components/Pagination";

const UserScheduleDataTable = ({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  detailCourse,
  handleAttendance,
  webinar,
}) => {
  const columns = [
    "#",
    "Nama Peserta",
    "Email",
    "Nomor Handphone",
    ...(detailCourse?.course_teach_method?.id !== 1 ? ["Progres Kelas"] : []),
    ...(detailCourse?.course_teach_method?.id === 4 ? ["Opsi"] : []),
    ...(webinar ? ["Opsi"] : []),
  ];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((column, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && data.length ? (
            data.map((course, index) => (
              <TableRow key={course.user.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {pagination.current_page > 1
                    ? (pagination.current_page - 1) * pagination.limit +
                      (index + 1)
                    : index + 1}
                </TableCell>
                <TableCell
                  component="td"
                  style={{ whiteSpace: "normal" }}
                  align="center"
                >
                  {course.user.name}
                </TableCell>
                <TableCell
                  component="td"
                  style={{ whiteSpace: "normal" }}
                  align="center"
                >
                  {course.user.email}
                </TableCell>
                <TableCell
                  component="td"
                  style={{ whiteSpace: "normal" }}
                  align="center"
                >
                  {course.user.phone_number}
                </TableCell>

                {detailCourse?.course_teach_method?.id !== 1 && (
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <ChipData text={`${course.progress_percentage ?? 0}%`} />
                  </TableCell>
                )}

                {detailCourse?.course_teach_method?.id === 4 && (
                  <TableCell
                    component="td"
                    style={{ whiteSpace: "normal" }}
                    align="center"
                  >
                    {/* <IconButton
                      aria-label="Attendance"
                      onClick={() =>
                        handleAttendance({ active: true, data: course.user })
                      }
                    >
                      <Assignment />
                    </IconButton> */}
                  </TableCell>
                )}

                {webinar && (
                  <TableCell
                    component="td"
                    style={{ whiteSpace: "normal" }}
                    align="center"
                  >
                    {!course?.user?.attendance
                      ?.user_learning_webinar_attendances?.length ? (
                      <IconButton
                        aria-label="Attendance"
                        onClick={() =>
                          handleAttendance({ active: true, data: course.user })
                        }
                      >
                        <Assignment />
                      </IconButton>
                    ) : null}
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={6}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={6}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
};

export default UserScheduleDataTable;
