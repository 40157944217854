import {
  Box,
  Button,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import React from "react";

export default function CreateUserDataTable({
  loading,
  data,
  handleChangeData,
  CreateAccount,
  setSuccessCopy = () => {},
}) {
  const columns = ["#", "nama", "email", "nomor telepon", "password", "Opsi"];

  return (
    <TableContainer component={Paper}>
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((item, index) => (
              <>
                <TableRow key={item.id}>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ whiteSpace: "normal" }}
                  >
                    <OutlinedInput
                      fullWidth
                      rows={4}
                      placeholder="Nama"
                      name="name"
                      value={item.name}
                      onChange={e => {
                        handleChangeData(e, index);
                      }}
                      oh
                    />
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <OutlinedInput
                      fullWidth
                      rows={4}
                      placeholder="Email"
                      name="email"
                      value={item.email}
                      onChange={e => {
                        handleChangeData(e, index);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <OutlinedInput
                      fullWidth
                      rows={4}
                      placeholder="Nomor Telepon"
                      name="phone_number"
                      value={item.phone_number}
                      onChange={e => {
                        handleChangeData(e, index);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <Tooltip title="Salin password" placement="top">
                      <OutlinedInput
                        fullWidth
                        rows={4}
                        placeholder="Password"
                        name="password"
                        readOnly
                        value={item.password}
                        onClick={() => {
                          navigator.clipboard.writeText(item.password);
                          setSuccessCopy(true);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{ wordWrap: "break-word" }}
                  >
                    <Button
                      variant="contained"
                      color=""
                      onClick={() => CreateAccount(index)}
                      fullWidth
                    >
                      Buat Akun
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={8}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Box>
    </TableContainer>
  );
}
