import React from "react";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

import themeColors from "assets/theme/colors";

export default function WebinarTimeListDataTable({
  data,
  handleDeleteData,
  handleEditData,
}) {
  const columns = [
    "Tanggal",
    "Waktu Mulai",
    "Waktu Selesai",
    "Link Webinar",
    "Link Telegram",
    "Catatan",
    "Opsi",
  ];

  return (
    <TableContainer component={Paper} width="100%">
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell key={key} align="center">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Tidak Ada Data
              </TableCell>
            </TableRow>
          ) : (
            data.map((item, key) => (
              <TableRow key={key}>
                <TableCell align="center">{item.date}</TableCell>
                <TableCell align="center">{item.start_time}</TableCell>
                <TableCell align="center">{item.end_time}</TableCell>
                <TableCell align="center">{item.webinar_url}</TableCell>
                <TableCell align="center">{item.telegram_url}</TableCell>
                <TableCell align="center">{item.notes}</TableCell>
                <TableCell align="center" width="10px">
                  <IconButton onClick={() => handleEditData(key)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteData(key)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
