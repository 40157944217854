import React from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit, ViewModule } from "@material-ui/icons";
import { useParams } from "react-router-dom";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";
import formatVideoQuiz from "helpers/formatVideoQuiz";
import VideoQuizConstant from "constant/VideoQuizConstant";
import { useHistory } from "react-router-dom";

export default function VideoQuizDataTable({
  loading,
  data,
  handleDeleteData,
  pagination,
  getDetailData,
  handleChangePage,
  handleChangeRowsPage,
}) {
  const { courseId, moduleId, subModuleId } = useParams();

  const columns = ["#", "Judul", "Durasi", "Tipe", "Opsi"];

  const history = useHistory();

  const secondToHms = second => {
    const sec = parseInt(second, 10);

    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
    // Return is HH : MM : SS
  };

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && data.length ? (
            data.map((videoQuiz, index) => {
              return (
                <TableRow key={videoQuiz.id}>
                  <TableCell component="td" scoped="row" align="center">
                    {pagination.current_page > 1
                      ? (pagination.current_page - 1) * pagination.limit +
                        (index + 1)
                      : index + 1}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {videoQuiz.title}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {secondToHms(videoQuiz.duration)}
                  </TableCell>
                  <TableCell component="td" align="center">
                    {formatVideoQuiz(videoQuiz.type)}
                  </TableCell>
                  <TableCell component="td" align="center">
                    <IconButton
                      aria-label="Video Quiz"
                      onClick={() => {
                        switch (videoQuiz.type) {
                          case VideoQuizConstant.VIDEO_QUIZ_CONSTANT_TYPES
                            .MIX_AND_MATCH:
                            return history.push(
                              `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/video-quiz/${videoQuiz.id}/mix-and-match`
                            );
                          case VideoQuizConstant.VIDEO_QUIZ_CONSTANT_TYPES.QUIZ:
                            return history.push(
                              `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/video-quiz/${videoQuiz.id}/pilihan-ganda`
                            );
                          case VideoQuizConstant.VIDEO_QUIZ_CONSTANT_TYPES
                            .TRUE_OR_FALSE:
                            return history.push(
                              `/kelas/${courseId}/modul/${moduleId}/sub-modul/${subModuleId}/video-quiz/${videoQuiz.id}/benar-atau-salah`
                            );
                        }
                      }}
                    >
                      <ViewModule />
                    </IconButton>

                    <IconButton
                      onClick={() =>
                        getDetailData(
                          courseId,
                          moduleId,
                          subModuleId,
                          videoQuiz.id
                        )
                      }
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      onClick={() =>
                        handleDeleteData(
                          courseId,
                          moduleId,
                          subModuleId,
                          videoQuiz.id
                        )
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={4}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={2}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
