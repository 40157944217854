import { useEffect, useState } from "react";

import {
  Button,
  Box,
  Card,
  Grid,
  Dialog,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import http from "services/http";
import api from "services/api";

import themeColors from "assets/theme/colors";
import CourseParticipantDataTable from "./participant/CourseParticipantDataTable";
import ModalScore from "./participant/ModalScore";
import ModalCheckStatus from "./participant/ModalCheckStatus";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import ModalSubmission from "./participant/ModalSubmission";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";

const filterOrderOption = [
  {
    label: "Tanggal Submit - Terlama",
    value: { order_by: "submit_at", sort_by: "ASC" },
  },
  {
    label: "Tanggal Submit - Terbaru",
    value: { order_by: "submit_at", sort_by: "DESC" },
  },
];

const ModalCourseParticipant = ({
  detailData,
  permissions,
  openModal,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { register, watch, setValue, reset } = useForm({
    defaultValues: {
      filter: {
        filter_order: filterOrderOption[1].value,
      },
    },
  });

  const [courseParticipants, setCourseParticipant] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [error, setError] = useState(null);

  const [errorStatus, setErrorStatus] = useState(null);

  const [pagination, setPagination] = useState({ limit: 10 });

  const [userData, setUserData] = useState(null);

  const [openModalScore, setOpenModalScore] = useState(false);

  const [statusData, setStatusData] = useState(null);

  const [openModalCheckStatus, setOpenModalCheckStatus] = useState(false);

  const [openModalSubmission, setOpenModalSubmission] = useState(false);

  const filterScoreOption = [
    {
      label: "Semua",
      value: "ALL",
    },
    {
      label: "Sudah Dinilai",
      value: "RATED",
    },
    {
      label: "Belum Dinilai",
      value: "NOT_YET_RATED",
    },
    {
      label: "Belum Dapat Dinilai",
      value: "CANNOT_BE_RATED",
    },
  ];

  /**
   * Fetch Data
   */
  const getCourseParticipant = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const {
        filter_search,
        filter_score = "ALL",
        filter_order,
      } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.learning_progress.main}/participant`,
        {
          params: {
            limit,
            page,
            course_id: detailData.course_id,
            course_module_id: detailData.module_id,
            course_sub_module_id: detailData.sub_module_id,
            type: detailData.sub_module_type,
            course_webinar_id: detailData.course_webinar_id,
            filter_search,
            filter_score,
            order_by: filter_order.order_by,
            sort_by: filter_order.sort_by,
          },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setCourseParticipant(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getUserStatusData = async (courseId, subModuleId, userId) => {
    setOpenModalCheckStatus(true);

    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(
        `${api.v1.admin.learning_progress.main}/course/${courseId}/submission/${subModuleId}/participant/${userId}`
      );

      setStatusData(data?.data);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setErrorStatus(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getCourseParticipant(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getCourseParticipant();
  };

  const handleCloseCheckStatus = () => {
    setStatusData(null);

    setErrorStatus(null);

    setOpenModalCheckStatus(false);
  };

  useEffect(() => {
    if (openModal) {
      getCourseParticipant();
    }
  }, [detailData, openModal]);

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setValue("filter.filter_search", null);
        setValue("filter.filter_score", null);

        reset();

        onClose();
      }}
      fullWidth={true}
      maxWidth="xl"
    >
      <Grid className="py-4">
        <Box textAlign="center" className="mb-4">
          <h2>Data Peserta</h2>
        </Box>
        <Grid xs={12}>
          {error ? (
            <Box textAlign="center" color={themeColors.error.main}>
              {error}
            </Box>
          ) : (
            <Grid>
              <Card>
                {detailData?.sub_module_type === "TPM" ||
                detailData?.sub_module_type === "UK" ? (
                  <Grid className="p-3 d-flex justify-content-end">
                    <Grid sm={8}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        {/* Filter by Level Course */}
                        <Grid sm={4} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={filterScoreOption}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = filterScoreOption.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.filter_score", value);
                              getCourseParticipant();
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Filter Nilai"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>

                        <Grid sm={5} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={filterOrderOption}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = filterOrderOption.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.filter_order", value);
                              getCourseParticipant();
                            }}
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Urut Berdasarkan"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Nama atau Email"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e =>
                            e.key === "Enter" && getCourseParticipant()
                          }
                          {...register("filter.filter_search")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getCourseParticipant()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <CourseParticipantDataTable
                  loading={loadingData}
                  type={detailData?.sub_module_type}
                  data={courseParticipants}
                  permissions={permissions}
                  pagination={pagination}
                  getUserStatusData={getUserStatusData}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPage={handleChangeRowsPage}
                  handleSetUser={setUserData}
                  handleOpenScore={setOpenModalScore}
                  handleOpenSubmission={setOpenModalSubmission}
                />
              </Card>

              {/* Modal Check Status */}
              <ModalCheckStatus
                data={statusData}
                error={errorStatus}
                openModal={openModalCheckStatus}
                onClose={handleCloseCheckStatus}
              />

              {/* Modal Score */}
              <ModalScore
                openModal={openModalScore}
                onClose={() => setOpenModalScore(false)}
                type={detailData?.sub_module_type}
                courseId={detailData?.course_id}
                courseSubmoduleId={detailData?.sub_module_id}
                userId={userData?.id}
                userName={userData?.name}
                userScore={userData?.score}
                userComment={userData?.comment}
                refetch={getCourseParticipant}
              />

              {/* Modal Submission */}
              <ModalSubmission
                openModal={openModalSubmission}
                onClose={() => setOpenModalSubmission(false)}
                type={detailData?.sub_module_type}
                courseId={detailData?.course_id}
                courseSubmoduleId={detailData?.sub_module_id}
                userId={userData?.id}
                userName={userData?.name}
                refetch={getCourseParticipant}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalCourseParticipant;
