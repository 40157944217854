import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";

import http from "services/http";
import api from "services/api";
import themeColors from "assets/theme/colors";

import DefaultHeader from "components/Headers/DefaultHeader";
import InstructorReportDataTable from "./InstructorReportDataTable";

export default function InstructorReportPage() {
  const { register, watch } = useForm();

  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [instructorData, setInstructorData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;
      const { name } = watch("filter");

      const { data } = await http.get(api.v1.admin.instructors.main, {
        params: { limit, page, name },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setInstructorData(records);
      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      }
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Laporan Instruktur</title>
      </Helmet>

      <DefaultHeader
        section="Data Instruktur"
        subsection="Laporan Instruktur"
      />

      <Container maxWidth={false} component={Box} id="course-instructor-page">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Card>
                  <Grid className="p-3 d-flex justify-content-end">
                    {/* Search by Name */}
                    <Grid sm={4}>
                      <Grid className="d-flex align-items-center justify-content-end">
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Nama"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.name")}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <InstructorReportDataTable
                    handleChangePage={handleChangePage}
                    handleChangeRowsPage={handleChangeRowsPage}
                    data={instructorData}
                    pagination={pagination}
                    loading={loadingData}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
